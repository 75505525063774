const OutsideClickDirective = {
  beforeMount (el: any, binding: any, vNode: any) {
    // Define Handler and cache it on the element
    const bubble = binding.modifiers.bubble
    const handler = (e: any) => {
      /** Use class 'exclude-from-outside-click' to exclude an element from outside click. */
      if ((bubble || (!el.contains(e.target) && el !== e.target)) &&
          !e.srcElement.className.includes('exclude-from-outside-click')
          ) {
        // We can pass parameters too to this directive
        binding.value(e)
      }
    }

    el.__vueClickOutside__ = handler
    // add Event Listeners
    document.addEventListener('click', handler)
  },

  unmounted (el: any, binding: any) {
    // Remove Event Listeners
    document.removeEventListener('click', el.__vueClickOutside__)
    el.__vueClickOutside__ = null
  }
}

export default OutsideClickDirective
