
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
export default defineComponent({
	data () {
		return {
			store: useStore()
		}
 },

	computed: {
		user (): any {
      return this.store.getters.user
    },

		usersByUsername (): any {
			return this.store.getters.usersByUsername
		},

		organization (): any {
			return this.store.getters.organization
		}
	}
})
