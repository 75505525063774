import { createElementVNode as _createElementVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/img/icon-search-white-14.svg'


const _withScopeId = n => (_pushScopeId("data-v-5b2f2e14"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _imports_0,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.gotoResults(_ctx.query))),
      class: "cursor-pointer o-6"
    }),
    _withDirectives(_createElementVNode("input", {
      type: "text",
      placeholder: "Search",
      class: "ms-1 ps-0 p-1",
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.query) = $event)),
      onKeyup: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.gotoResults(_ctx.query)), ["enter"]))
    }, null, 544), [
      [_vModelText, _ctx.query]
    ])
  ]))
}