
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import HopReportForm from './HopReportForm.vue'
import moment from 'moment'

export default defineComponent({
	components: {
    HopReportForm
  },

  data () {
    return {
      store: useStore(),
      moment: moment,
			openModal: ''
    }
  },

  methods: {
		toggleModal (e: any, popup: any): void {
			this.openModal = popup
			e!.stopPropagation()
		},

		closeModal () {
      this.openModal = ''
    }
  },

  computed: {
    user (): any {
      return this.store.getters.user
    }
  }
})
