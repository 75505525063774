import { importIpcRenderer, ipcRenderer } from '../../config/PlatformConfig'
import ApiService from './api/ApiService'
import store from '@/store'

importIpcRenderer()

const apiService = new ApiService()
const timelineData = {
  screenshot: 'test'
}

const desktopCapturer = {
  getSources: (opts) => {
    if (ipcRenderer) ipcRenderer.invoke('DESKTOP_CAPTURER_GET_SOURCES', opts)
  }
}

export default {
  runningApps: [],

  fullscreenScreenshot: async function () {
    this.takeWebcamPicture()
    // ipcRenderer?.once('SET_SOURCE', async (event, sources) => {
    //   for (const source of sources) {
    //     timelineData = {}

    //     // Filter: main screen
    //     if ((source.name === 'Entire Screen')) {
    //       try {
    //       navigator.mediaDevices.getUserMedia({
    //           audio: false,
    //           video: {
    //             mandatory: {
    //               chromeMediaSource: 'desktop',
    //               minWidth: 1280,
    //               maxWidth: 1280,
    //               minHeight: 720,
    //               maxHeight: 720
    //             }
    //           }
    //         }).then((res) => {
    //           this.handleStream(res, 'screenshot')
    //         })
    //         .catch((e) => console.error(e))
    //       } catch (e) {
    //         console.log(e)
    //       }
    //     } else {
    //       this.runningApps.push(source.name)
    //     }
    //   }
    // })

    // if (localStorage.getItem('screenCaptureEnabled') === 'true') {
      // desktopCapturer.getSources({ types: ['window', 'screen'] })
    // }
  },

  async takeWebcamPicture () {
    if (window.innerWidth >= 960) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: {
            mandatory: {
              minWidth: 1280,
              maxWidth: 4000,
              minHeight: 720,
              maxHeight: 4000
            }
          }
        })
        setTimeout(() => {
          var video = document.getElementById('liveVideo')
          var isVideoElementPresent = true
          if (!video) {
            isVideoElementPresent = false
            video = document.createElement('video')
            video.className = 'invisible-video-element'
            video.id = 'liveVideo'
            document.body.appendChild(video)
          }
          this.handleStream(stream, 'webcamshot', video)
          this.handleVideoStream(stream, video, isVideoElementPresent)
        }, 1000)
      } catch (e) {
        console.log(e)
      }
    } else {
      console.log('Not Desktop')
    }
  },

  handleStream (stream, streamType, video) {
    var fileBase64 = ''
    // Event connected to stream
    video.onloadedmetadata = (e) => {
      video.style.height = e.target.offsetHeight + 'px'
      video.style.width = e.target.offsetWidth + 'px'
      video.play()

      // Create canvas
      var canvas = document.createElement('canvas')
      canvas.width = e.target.offsetWidth
      canvas.height = e.target.offsetHeight
      var ctx = canvas.getContext('2d')

      // Draw video on canvas
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
      const sc = canvas.toDataURL('image/jpeg')

      // strip off the data: url prefix to get just the base64-encoded bytes
      fileBase64 = sc.replace(/^data:image\/\w + base64,/, '')
      fetch(fileBase64).then((res) => {
        const fileBlob = res.blob()
        fileBlob.then((file) => {
          this.saveTimelineData(file, streamType)
        })
      })

      return fileBase64
    }
    video.srcObject = stream
  },

  handleVideoStream (stream, video, isVideoElementPresent) {
    var videoRecorder = null
    const blobsRecorded = []

    if (isVideoElementPresent) {
      var videoContainer = document.getElementById('liveShot')
      var cameraElement = document.getElementById('liveVideo')
      var liveIndicator = document.createElement('div')
      cameraElement.style.zIndex = 1001
      liveIndicator.id = 'liveIndicator'
      liveIndicator.className = 'rec-timer'
      liveIndicator.innerText = '00:00'
      videoContainer.appendChild(liveIndicator)
    }

    videoRecorder = new MediaRecorder(stream, { mimeType: 'video/webm' })
    videoRecorder.addEventListener('dataavailable', function (e) {
      blobsRecorded.push(e.data)
    })

    var secondsRecorded = 1
    const timer = setInterval(() => {
      if (secondsRecorded === 6) {
        clearInterval(timer)
        videoRecorder.stop()
        if (isVideoElementPresent) {
          videoContainer.removeChild(liveIndicator)
        } else {
          video.remove()
        }
        try {
          // Destroy connection to stream
          stream.getTracks()[0].stop()
        } catch (e) {}
      }
      if (liveIndicator) {
        liveIndicator.innerText = `00:0${secondsRecorded}`
      }
      secondsRecorded++
    }, 1000)

    videoRecorder.addEventListener('stop', () => {
      const videoLocal = URL.createObjectURL(new Blob(blobsRecorded, { type: 'video/webm' }))
      store.commit('setProfileVideo', {
        video: videoLocal,
        username: store.getters.user.username,
        submittedOn: new Date()
      })

      fetch(videoLocal).then((res) => {
        const fileBlob = res.blob()
        fileBlob.then((file) => {
          this.saveProfileVideo(file)
        })
      })
    })

    videoRecorder.start(6000)
  },

  saveProfileVideo(file) {
    const formData = new FormData()
    file.lastModifiedDate = new Date()
    formData.append('file', file)
    store.dispatch('createFileUploadUrl', { file, formData })
      .then((res) => {
        store.dispatch('updateProfileVideo', { filename: res.fileName, username: store.getters.user.username })
      })
  },

  handleError (e) {
    console.error(e)
  },

  async saveTimelineData (file, fileType) {
    const formData = new FormData()
    file.lastModifiedDate = new Date()
    formData.append('file', file)
    store.dispatch('createFileUploadUrl', { file, formData })
		.then((response) => {
      timelineData[fileType] = response.fileName
      if (timelineData.screenshot && !timelineData.webcamshot) {
        this.takeWebcamPicture()
      } else if (timelineData.screenshot && timelineData.webcamshot) {
        store.dispatch('saveTimeline', timelineData)
      }
		})
  }
}
