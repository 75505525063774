import { EventType, IContent, MatrixClient, MatrixEvent, NotificationCountType, RelationType, Room } from 'matrix-js-sdk'
import { UserMultiselectItem } from '@/common/components/UserMultiselectModal.vue'
import { getMessageTypeForMedia, uploadFile } from './MxEventContentHandler'
import { CustomMxEvent } from '../enums'
import store from '@/store'

export default {
  sendEvent (mxClient: MatrixClient, eventType: EventType, content: IContent | any, roomId: string) {
    mxClient.sendEvent(roomId, eventType, content, '')
  },

  sendMessage (mxClient: MatrixClient, message: string, roomId: string, replyTo?: MatrixEvent) {
    let content
    if (replyTo) {
      content = {
        body: message,
        msgtype: 'm.text',
        'm.relates_to': {
          'm.in_reply_to': {
            event_id: replyTo.event.event_id
          }
        }
      }
    } else {
      content = {
        body: message,
        msgtype: 'm.text'
      }
    }

    this.sendEvent(mxClient, EventType.RoomMessage, content, roomId)
  },

  sendReaction (mxClient: MatrixClient, reaction: any, reactionEvent: MatrixEvent, roomId: string) {
    const content = {
      'm.relates_to': {
        rel_type: RelationType.Annotation,
        event_id: reactionEvent!.getId(),
        key: reaction
      }
    }
    this.sendEvent(mxClient, EventType.Reaction, content, roomId)
  },

  /** Using matrix rooms for push notifications */
  sendCustomNotification (mxClient: MatrixClient, roomId: string, content: any) {
    content = { ...content, timestamp: new Date() }
    this.sendEvent(mxClient, EventType.RoomMessage, content, roomId)
  },

  async uploadFile (mxClient: MatrixClient, file: File, roomId: string, replyTo?: MatrixEvent) {
    /**
     * TODO: Check for file size
     */
    const content: IContent = {
      body: file.name || 'Attachment',
      info: {
        size: file.size,
        mimetype: file.type
      },
      msgtype: getMessageTypeForMedia(file)
    }

    const result = await uploadFile(mxClient, roomId, file)
    content.url = result?.file?.url
    content.file = result.file
    content.file.mimetype = file.type

    if (replyTo && replyTo.event.event_id) {
      content['m.relates_to'] = {
        rel_type: RelationType.Thread,
        event_id: replyTo.event.event_id,
        'm.in_reply_to': {
          event_id: replyTo.event.event_id
        }
      }
    }

    this.sendEvent(mxClient, EventType.RoomMessage, content, roomId)
  },

  leaveChat (mxClient: MatrixClient, roomId: string): void {
    mxClient.leaveRoomChain(roomId)
  },

  /**
   * @param mxUserId Matrix user id of the user
   * @returns Channels shared by logged in user with given user
   */
  async getRoomsSharedWithUser (mxClient: MatrixClient, mxUserId: string): Promise<Room[]> {
    const sharedRooms: Room[] = []
    const roomIdList = await mxClient._unstable_getSharedRooms(mxUserId)
    roomIdList.forEach((roomId: string) => {
      const room = mxClient.getRoom(roomId)
      if (!this.isRoomDM(room)) sharedRooms.push(room)
    })
    return sharedRooms
  },

  getDmRoomBySenderId (rooms: Room[], senderId: string): Room | null {
    const filteredRooms = rooms.filter((room: Room) =>
      this.isRoomDM(room) && this.getDMUser(room) === senderId
    )
    if (filteredRooms.length) {
      return filteredRooms[0]
    }
    return null
  },

  isRoomDM (room: Room): boolean {
    if (room.getDMInviter()) return true

    if (room.getType() === 'DM') return true

    const members = room.currentState.getMembers()
    if (members.length <= 2 && members.some(member => member.getDMInviter())) {
      return true
    }

    return false
  },

  /**
   *  Get blox user id of the other user in DM. Strip the homeserver part from user id.
   *  This will only work for DM rooms.
   */
  getDMUser (room: Room) {
    if (room.getAvatarFallbackMember()) {
      return this.getBloxUserId(room.getAvatarFallbackMember().userId)
    } return ''
  },

  getDmRoomName(room: Room, currentUser: string) {
    let dmUser
    room.getMembers().forEach((member: any) => {
      const bloxUserId = this.getBloxUserId(member.userId)
      if (bloxUserId !== currentUser) {
        dmUser = bloxUserId
      }
    })
    /** DM room name will be same as  */
    return dmUser
  },

  getBloxUserId (matrixUserId: string) {
    return matrixUserId.substring(1, matrixUserId.indexOf(':'))
  },

  mapToRoomOverview (rooms: Room[], usersByUsername: any): UserMultiselectItem[] {
    return rooms.map((room: Room) => {
      return {
        name: room.name,
        username: room.roomId,
        avatarUrl: this.isRoomDM(room) ? usersByUsername[this.getDMUser(room)]?.avatarUrl : '',
        isChecked: false
      }
    })
  },

  removeEventFromRoom (mxClient: MatrixClient, roomId: string, eventId: string): void {
    mxClient.getRoom(roomId).removeEvent(eventId)
  },

  getRoomMembership(room: Room) {
    room.getMyMembership()
  },

  /** Chatter is a custom room created for organization. */
  sendMessageToChatter(mxClient: MatrixClient, message: string, type?: CustomMxEvent) {
    const chatterRoomId = store.getters.organization.organizationChatterSpaceId
    if (type) {
      const content = {
        body: message,
        chatter_event_type: type,
        msgtype: 'm.text'
      }
      this.sendEvent(mxClient, EventType.RoomMessage, content, chatterRoomId)
    } else {
      this.sendMessage(mxClient, message, chatterRoomId)
    }
  },

  /** Clear notifications. */
  clearNotifications(mxClient: MatrixClient, room: Room) {
    if (room?.getUnreadNotificationCount(NotificationCountType.Total)) {
      room.setUnreadNotificationCount(NotificationCountType.Total, 0)
      mxClient.sendReadReceipt(
        room.getLiveTimeline().getEvents()[room.getLiveTimeline().getEvents().length - 1]
      )
    }
  }
}
