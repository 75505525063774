
import { defineComponent } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  data () {
    return {
      store: useStore(),
      tileSizes: ['one-fifth-tile', 'one-fifth-tile', 'one-fifth-tile', 'one-fifth-tile', 'one-fifth-tile',
                  'one-third-tile', 'one-third-tile', 'one-third-tile',
                  'quarter-tile', 'quarter-tile', 'quarter-tile', 'quarter-tile']
    }
  }
})
