import { UserStatus } from '@/common/enums'
import moment from 'moment'

const actions = {
  async getAllMeetings (context: any) {
    context.dispatch('getActiveMeetings')
    const myMeetings = await context.dispatch('getMyMeetings')
    const shared = await context.dispatch('getSharedMeetings')
    const allMeetings = { ...myMeetings, ...shared }
    let immediateMeeting

    if (allMeetings) {
      for (let i = 0; i < allMeetings.length; i++) {
        const totalTime = moment(allMeetings[i].start).diff(moment(), 'seconds')
        if (totalTime < 300 && totalTime > 0) {
          immediateMeeting = allMeetings[i]
          context.commit('updateImmediateMeeting', immediateMeeting)
        }
      }
    }
  },

  updateUserStatusOnJoiningMeeting(context: any) {
    localStorage.setItem('statusBeforeMeeting', context.getters.user.status)
    context.dispatch('updateUserStatus', { status: UserStatus.BUSY, statusDescription: '' })
  },

  updateUserStatusAfterMeeting(context: any) {
    const status = localStorage.getItem('statusBeforeMeeting')
    context.dispatch('updateUserStatus', { status, statusDescription: '' })
  }
}

export default actions
