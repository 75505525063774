
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import { Todo, TodoTask } from '@/common/types/TodoInterface'

export default defineComponent({
  data() {
    return {
      store: useStore(),
      activeDeleteIcon: 0
    }
  },

  mounted() {
    this.store.dispatch('getTodoList')
  },

  methods: {
    addNewTodo() {
      const emptyTodo: Partial<TodoTask> = {
        title: '',
        description: '',
        targetDate: '',
        location: '',
        url: '',
        attachment: {
          fileName: '',
          fileId: ''
        },
        tags: []
      }
      if (!this.todoList) {
        const todoList = {
          title: '',
          tasks: [emptyTodo],
          done: false
        }
        this.store.dispatch('createTodoList', todoList)
      } else {
        this.store.dispatch('addTodo', { id: this.todoList.id, tasks: [emptyTodo] })
      }
    },

    updateTodo(todo: Partial<TodoTask>) {
      this.store.dispatch('updateTodo', { todoListId: this.todoList.id, todo })
    },

    completeTodo(e: any, todo: any) {
      if (e.target.checked) {
        todo.done = true
        this.store.dispatch('completeTodo', { todoListId: this.todoList.id, todo })
      } else {
        todo.done = false
        this.store.dispatch('reopenTodo', { todoListId: this.todoList.id, todo })
      }
    },

    deleteTodo(todo: TodoTask) {
      this.store.dispatch('deleteTodo', { todoListId: this.todoList.id, todo })
    },

    toggleDeleteIcon(todoNumber: number) {
      this.activeDeleteIcon = todoNumber
    }
  },

  computed: {
    todos(): TodoTask[] {
      // TODO: Create new todo list for user per organization from BE?
      return this.todoList.tasks
    },

    todoList(): Todo {
      return this.store.getters.todoList
    },

    isComponentLoading(): any {
      return this.store.getters.isComponentLoading
    }
  }
})
