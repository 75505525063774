
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
export default defineComponent({
   data () {
    return {
      selectedRhythm: '' as any
    }
  },

   props: {
    title: { type: String, required: true },
    list: { type: Object as any, required: true }
  },
  methods: {
    close (event: any) {
      event.stopPropagation()
      this.$emit('close', event)
    },

    sendSelected (event: any): any {
      if (this.selectedRhythm) {
        this.$emit('selected', event, this.selectedRhythm)
      }
    },

    selectRhythm (event: MouseEvent, rhythm: string): any {
      this.selectedRhythm = rhythm
    }
  }
})
