import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
import UtilityService from '../../common/services/TimeUtilityService'
import { Meeting } from '../../common/types/MeetingInterface'
import DeleteModal from '@/common/components/DeleteModal.vue'
import Loader from '@/common/components/Loader.vue'
import StyleUtils from '@/common/services/StyleUtils'
import { CustomMxEvent, EventState } from '@/common/enums'
import MxRoom from '@/common/services/MxRoom'
import { MatrixClient } from 'matrix-js-sdk'
import RelatedMeetings from '@/components/RelatedMeetings.vue'

export default defineComponent({
	components: { DeleteModal, Loader, RelatedMeetings },

	data () {
		return {
			moment: moment,
			store: useStore(),
			utilityService: new UtilityService(),
			styleUtils: new StyleUtils(),
			meetingId: '',
			relatedMeetings: [],
			showDeleteModal: false,
			deleteMeetingId: '',
			isHeaderExpanded: false,
			recordings: [] as any[],
			jitsiUrl: process.env.VUE_APP_JITSI_URL
		}
	},

	async created () {
		// TODO: change query url form to slash form
		if (this.$route.query.id) {
			this.meetingId = this.$route.query.id.toString()
			const meeting = await this.store.dispatch('getMeeting', this.meetingId)
			this.relatedMeetings = await this.store.dispatch('getRelatedMeetings', meeting.title)
		}
	},

	methods: {
		toggleExpand() {
			this.isHeaderExpanded = !this.isHeaderExpanded
		},

		startMeeting (meeting: Meeting): void {
			meeting.participants = meeting.participants?.map((member: any) => member.username)
			this.store.dispatch('updateAndStartMeeting', { form: meeting, id: this.meetingId })
        .then(() => {
          meeting.participants!.forEach((participant: any) => {
            if (participant !== this.user.username) {
              /** Notify other participants */
              this.notifyMeetingStarted(participant)
            }
          })
        })
      this.store.commit('setConferenceActive', true)
		},

    notifyMeetingStarted(participant: string) {
      const content = {
        body: '',
        metadata: {
          to: participant,
          meeting_id: this.meetingId
        },
        msgtype: CustomMxEvent.MEETING_STARTED
      }
      MxRoom.sendCustomNotification(
        this.matrixClient,
        this.store.getters.organization.organizationNotificationSpaceId,
        content
      )
    },

		joinMeetingRequest (): void {
      if (this.meeting.state === EventState.IN_PROGRESS) {
        this.store.commit('setConferenceActive', true)
      } else {
        this.$router.push({
          name: 'ConferenceWaitingRoom',
          path: '/waiting-room',
          params: { id: this.meetingId }
        })
      }
		},

    getMeetingLink () {
      /** Meeting link was generated from meeting title and id by replacing spaces in title with '-' */
      return `https://${this.jitsiUrl}/${this.meeting.title.replaceAll(' ', '-').replaceAll(/[^a-zA-Z0-9]/g, '-') + '-' + this.meeting.id}`
    },

		momentFormat (value: any, format: string): string {
			return moment(value).format(format)
		},

		editMeeting (meetingId: string) {
			this.$router.push({
				name: 'EditMeeting',
				path: '/edit',
				params: { id: meetingId }
			})
			this.store.commit('setBreadcrumbs', [
        { label: 'Meetings', routeName: '/meetings' },
        { label: this.meeting.title, routeName: '/edit' }
      ])
		},

    copyMeetingUrl () {
      navigator.clipboard.writeText(this.getMeetingLink())
    },

		toggleDeleteModal (meetingId: string) {
			this.deleteMeetingId = meetingId
			this.showDeleteModal = !this.showDeleteModal
		},

    isJoinDisabled (): boolean {
      if (this.meeting.state === EventState.IN_PROGRESS) {
        return false
      }
      if (this.meeting.state === EventState.NOT_STARTED) {
        const now = moment(new Date())
        const startTime = moment(new Date(this.meeting.start))
        if (startTime.diff(now, 'seconds') < (5 * 60)) return false
      }
      return true
    },

		close () {
			this.showDeleteModal = !this.showDeleteModal
		},

		deleteMeeting () {
			this.showDeleteModal = !this.showDeleteModal
			if (this.deleteMeetingId) {
				this.store.dispatch('deleteMeeting', this.deleteMeetingId)
			}
		},

		endMeeting (meetingId: string) {
			if (this.meeting.recurringDays.length) {
				this.store.dispatch('endMeeting', meetingId)
			} else {
				this.meeting.state = 'NOT_STARTED'
				this.store.dispatch('updateMeeting', { form: this.meeting, id: meetingId })
			}
		}
	},

	computed: {
		meeting (): any {
			return this.store.getters.meeting
		},

		user (): any {
			return this.store.getters.user
		},

		usersByUsername ():any {
			return this.store.getters.usersByUsername
		},

		isPageLoading (): any {
			return this.store.getters.isPageLoading
		},

    meetingUid (): any {
      return `${this.meeting?.title?.replaceAll(' ', '-')}-${this.meetingId}`
    },

    matrixClient(): MatrixClient {
      return this.store.getters.matrixClient
    }
	}
})
