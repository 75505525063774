import { createApp } from 'vue'
import store from './store'
import './registerServiceWorker'
import router from './router'
import './assets/css/styles.scss'
import './assets/css/dark-styles.scss'
import Keycloak from 'keycloak-js'
import { keycloak } from './config/PlatformConfig'
import { setupAxiosInterceptors } from './config/AxiosInterceptor'
import RightsideDock from './common/components/rightsideDock/RightsideDock.vue'
import OutsideClickDirective from './common/directives/OutsideClickDirective'
import PinInput from 'v-pin-input'

import mitt from 'mitt'
const emitter = mitt()

import App from './App.vue'

import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'

const app = createApp(App)
app.config.globalProperties.emitter = emitter

app.use(store)
  .use(router)
  .use(setupAxiosInterceptors)
  .use(PinInput)
  .component('vue-slider', VueSlider)
  .component('rightside-dock', RightsideDock)
  .directive('outside-click', OutsideClickDirective)
  .provide('emitter', emitter)
  .mixin({
    methods: {
      logout: () => {
        console.log('logging out')
        localStorage.clear()
        keycloak.logout()
      }
    }
  })
  .mount('#app')

router.push('/')
