
import { defineComponent } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  data() {
    return {
      store: useStore(),
      users: '',
      emailError: false,
      inviteText: 'You are invited to join {{orgname}} on BLOX by {{role}} {{me}}'
    }
  },

  methods: {
    invite(emailList: string) {
      this.emailError = this.validateMultipleEmailsCommaSeparated(emailList)
      emailList.split(',').forEach((email: string) => {
        const payload = {
          username: email.substring(0, email.indexOf('@')),
          name: '',
          email: email
        }
        this.store.dispatch('inviteNewUser', payload)
      })
      this.gotoHomepage()
    },

    gotoHomepage() {
      this.$router.push('/')
    },

    validateEmail(email: string) {
      const regex = /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b/i
      return (regex.test(email))
    },

    validateMultipleEmailsCommaSeparated(emailList: any) {
      const result = emailList.split(',')
      for (let i = 0; i < result.length; i++) {
        if (!this.validateEmail(result[i])) return true
      }
      return false
    },

    close(event: any) {
      event.stopPropagation()
      this.$emit('close', event)
    }
  },

  computed: {
    organization(): any {
      return this.store.getters.organization
    }
  }
})
