<template>
	<div class="common-tile feed-event p-2">
		<div class="d-flex justify-content-between w-100">
			<div class="d-flex justify-content-start">
				<img src="@/assets/img/avatar-placeholder.svg" height="20" width="20" alt="" class="rounded-circle me-1 mt-1">
				<div class="mt-1">Uruveua Himona</div>
			</div>
			<div class="float-end">
				2h Ago
			</div>
		</div>
		<div class="d-flex justify-content-start event-content p-3 w-100">
			<div class="float-start">
				<img src="@/assets/img/dummy/earth.jpeg" width="20" height="20" alt="">
			</div>
			<div class="event-title ps-1 float-start">Earth Day Event Invitation</div>
		</div>
		<div class="event-description py-1 px-3">Join us on earth day 2022. Avoid all high energy consuming devices. Kombucha on us!</div>
			<div class="event-location">
				<img src="@/assets/img/icon-location-grey.svg" width="20" height="20" alt="">
				<span class="event-location-title ps-1">Butterfly Beach</span>
			</div>
			<div class="event-date">
				<img src="@/assets/img/icon-calendar-black.svg" width="20" height="20" alt="">
				<span class="event-location-title ps-1">7:00 PM, April 22, 2022</span>
			</div>
		<div class="w-100 d-flex justify-content-between">
			<div><button class="btn primary-green p-1">Yes</button></div>
			<div><button class="btn ghost-blue p-1">Maybe</button></div>
			<div><button class="btn ghost-red p-1">No</button></div>
		</div>
	</div>
</template>
<script lang="script" src="./FeedEvent.ts"></script>
<style lang="scss" scoped src="./FeedEvent.scss"></style>
