import UserApi from '@/common/services/api/UserApiService'

const actions = {
  updateUserDetails (context: any, payload: any) {
    UserApi.updateUserDetails(payload)
      .then((response: any) => {
        context.commit('setUser', response.data)
      }).catch((error: any) => {
        console.error('Error in updating user details', error)
      })
  },

  changePassword (context: any, password: string) {
    UserApi.changePassword(password)
      .catch((error: any) => {
        console.error('Error in change password API', error)
      })
  },

  getSkills (context: any, username: string) {
    UserApi.getSkills(username)
      .then((response: any) => {
        context.commit('setSkills', { username, skills: response.data })
      })
      .catch((error: any) => {
        console.error('Error in getting user skills', error)
      })
  },

  addSkills (context: any, payload: any) {
    return UserApi.addSkills(payload)
    .then((response: any) => {
      // context.commit('setSkills', { username, skills: response.data })
      return response.data
    }).catch((error: any) => {
      console.error('Error in adding skills', error)
    })
  },

  removeSkills (context: any, payload: any) {
    return UserApi.removeSkills(payload)
    .then((response: any) => {
      // context.commit('setSkills', { username, skills: response.data })
      return response.data
    }).catch((error: any) => {
      console.error('Error in removing skills', error)
    })
  },

  getHopReports (context: any, username: string) {
    UserApi.getReports()
      .then((response: any) => {
        context.commit('setHopReports', response.data)
      })
      .catch((error: any) => {
        console.error('Error in getting hop reports', error)
      })
  },

  isPinSet (context: any) {
    return UserApi.isPinSet()
      .then((response: any) => {
        return response.data
      })
      .catch((error: any) => {
        console.error('Error api to check if', error)
      })
  },

  updatePin (context: any, pin: string) {
    UserApi.updatePin(pin)
      .then((response: any) => {
        context.commit('setHopReports', response.data)
      })
      .catch((error: any) => {
        console.error('Error in updating pin API', error)
      })
  },

  verifyPin (context: any, pin: string) {
    return UserApi.verifyPin(pin)
      .then((response: any) => {
        return response.data
      })
      .catch((error: any) => {
        console.error('Error in verifying pin API', error)
      })
  },

  updateProfileVideo (context: any, payload: any) {
    UserApi.updateProfileVideo(payload.filename)
      .then((res: any) => {
        context.dispatch('createFileDownloadUrl', payload.filename)
          .then((video: any) => {
            context.commit('setProfileVideo', { video, username: payload.username, submittedOn: new Date() })
          }).catch((e: any) => console.error('Filed to download profile video', e))
      })
      .catch((error: any) => {
        console.error('Error in getting all company sizes', error)
      })
  },

  getUserPreferences (context: any) {
    UserApi.getUserPreferences()
      .then((response: any) => {
        context.commit('setUserPreferences', JSON.parse(response.data.userPrefs))
      })
      .catch((error: any) => {
        console.error('Error in getting user preferences', error)
      })
  },

  updateUserPreferences (context: any, payload: any) {
    UserApi.updateUserPreferences(payload)
      .then((response: any) => {
        context.commit('setUserPreferences', JSON.parse(response.data.userPrefs))
      })
      .catch((error: any) => {
        console.error('Error in updating user preferences', error)
      })
  },

  // TODO: Make all mutations go through actions.

  setFacesFound(context: any, payload: any) {
    context.commit('setFacesFound', payload)
  }
}

export default actions
