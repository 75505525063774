<template>
  <div
    class="conf-container"
    :class="viewStyle"
    :style="{ top: dragPosition.y + 'px', left: dragPosition.x + 'px' }"
  >
    <div
      v-if="viewStyle === 'side'"
      class="drag-handle"
      @mousedown="startDragging"
    ></div>

    <button
      class="jitsi-switch-view"
      v-if="viewStyle === 'side'"
      @click="toggleView('full')"
    >
      <img
        src="@/assets/img/icon-expand-arrows-white.svg"
        alt="Expand"
      >
    </button>
    <button
      class="jitsi-switch-view"
      v-else
      @click="toggleView('side')"
    >
      <img
        src="@/assets/img/icon-collapse-arrows-white.svg"
        alt="Collapse"
      >
    </button>
    <div class="qr-button mt-1">
      <div class="d-flex align-items-baseline">
        <div
          @click="toggleQR"
          class="cursor-pointer"
        >
          <img
            src="@/assets/img/icon-qr-code.svg"
            alt="QR"
          >
        </div>
        <div class="mx-3 fs-12">Whiteboard</div>
        <a
          :href="`https://wbo.ophir.dev/boards/${this.meeting?.title?.replaceAll(' ', '-')}-${this.meeting?.id}`"
          target="_blank"
          class="cursor-pointer"
        >
          <img
            src="@/assets/img/icon-open-link.svg"
            alt="Link"
          >
        </a>
      </div>
    </div>
    <div
      v-if="showQR"
      class="whiteboard-qr"
    >
      <img
        class="mt-2"
        :srcset="`https://api.qrserver.com/v1/create-qr-code/?size=240x240&data=https://wbo.ophir.dev/boards/${this.meeting.title?.replaceAll(' ', '-')}-${this.meeting?.id} 2x`"
      >
      <div class="fs-12 mt-2">Scan to open on phone</div>
    </div>
    <div
      id="jitsiContainer"
      ref="jitsiContainer"
    ></div>
    <!-- <ConferenceDock /> -->
  </div>
</template>

<script lang='js'>
import { useStore } from 'vuex'
import {
  isWebPlatform,
  importIpcRenderer,
  ipcRenderer,
  importJitsiScreenshareModules,
  setupScreenSharingRender,
  initPopupsConfigurationRender,
  setupPowerMonitorRender
} from '@/config/PlatformConfig'
import StatusUtils from '@/common/services/StatusUtils'
import { UserStatus } from '@/common/enums'

importIpcRenderer()
importJitsiScreenshareModules()

export default {
  data() {
    return {
      store: useStore(),
      meetingId: '',
      viewStyle: 'full',
      options: {
        width: '100%',
        height: '100%',
        noSSL: false,
        configOverwrite: {
          enableNoisyMicDetection: false,
          disableModeratorIndicator: true,
          prejoinConfig: { enabled: false },
          prejoinPageEnabled: false,
          recordingService: { enabled: true, sharingEnabled: true },
          toolbarButtons: ['hangup', 'microphone', 'camera', 'tileview', 'raisehand', 'fullscreen', 'desktop', 'recording'],
          logoImageUrl: ''
        },
        interfaceConfigOverwrite: {
          DEFAULT_LOGO_URL: '',
          SHOW_JITSI_WATERMARK: false,
          SHOW_WATERMARK_FOR_GUESTS: false,
          HIDE_DEEP_LINKING_LOGO: true,
          SHOW_BRAND_WATERMARK: false,
          SHOW_CHROME_EXTENSION_BANNER: false,
          SHOW_PROMOTIONAL_CLOSE_PAGE: false,
          VIDEO_LAYOUT_FIT: 'height'
        }
      },
      jitsiApi: {},
      showQR: false,
      dragElement: null,
      dragPosition: { x: 196, y: 80 },
      isDragging: false,
      dragOffset: { x: 0, y: 0 }
    }
  },

  mounted() {
    this.store.dispatch('updateUserStatusOnJoiningMeeting')
    /** Loading Jitsi script. */
    this.loadScript(`https://${process.env.VUE_APP_JITSI_URL}/external_api.js`, this.embedJitsiWidget)
    this.askMediaPermissions()
    if (this.$route.params.id) {
      this.meetingId = this.$route.params.id.toString()
      this.store.dispatch('getMeeting', this.meetingId)
    }
    window.addEventListener('mousemove', this.dragging)
    window.addEventListener('mouseup', this.stopDragging)
    this.$watch('$route', this.handleRouteChange)
  },

  beforeUnmount() {
    window.removeEventListener('mousemove', this.dragging)
    window.removeEventListener('mouseup', this.stopDragging)
  },

  methods: {
    loadScript(src, cb) {
      const scriptEl = document.createElement('script')
      scriptEl.src = src
      scriptEl.async = true
      document.querySelector('head').appendChild(scriptEl)
      scriptEl.addEventListener('load', cb)
    },

    handleRouteChange(newRoute) {
      if (newRoute !== '/conference') {
        this.viewStyle = 'side'
      }
    },

    askMediaPermissions() {
      if (ipcRenderer) {
        ipcRenderer.on('CAMERA_ACCESS_SUCCESS', (res) => {
          localStorage.setItem('cameraEnabled', 'true')
          ipcRenderer.invoke('ASK_MICROPHONE_ACCESS')
        })
        ipcRenderer.on('MICROPHONE_ACCESS_SUCCESS', (res) => {
          localStorage.setItem('microphoneEnabled', 'true')
        })
        ipcRenderer.invoke('ASK_CAMERA_ACCESS')
        ipcRenderer.invoke('ASK_MICROPHONE_ACCESS')
      }
    },

    embedJitsiWidget() {
      const options = {
        ...this.options,
        userInfo: {
          email: this.user.email,
          displayName: this.user.name,
          avatarUrl: this.store.getters.usersByUsername[this.user.username].avatarUrl
        },
        roomName: this.generateRoomName(),
        parentNode: document.querySelector('#jitsiContainer')
      }

      this.jitsiApi = new window.JitsiMeetExternalAPI(process.env.VUE_APP_JITSI_URL, options)

      if (!isWebPlatform()) {
        initPopupsConfigurationRender(this.jitsiApi)
        setupScreenSharingRender(this.jitsiApi)
        setupPowerMonitorRender(this.jitsiApi)
      }

      this.jitsiApi.addEventListener('videoConferenceJoined', () => {
        const listener = ({ enabled }) => {
          this.jitsiApi.removeEventListener('tileViewChanged', listener)
          if (!enabled) {
            this.jitsiApi.executeCommand('toggleTileView')
          }
        }
        this.jitsiApi.addEventListener('tileViewChanged', listener)
        this.jitsiApi.executeCommand('toggleTileView')
      })

      this.jitsiApi.on('readyToClose', () => {
        console.log('conference end for all')
        if (this.meeting.owner === this.user.username) {
          this.store.dispatch('endMeeting', this.meeting.id)
        }
        this.endJitsiMeeting(true)
      })

      this.jitsiApi.on('videoConferenceLeft', () => {
        console.log('conference left')
        document.getElementById('jitsiContainer').innerHTML = ''
        this.endJitsiMeeting(false)
        // if (!this.jitsiApi.getParticipantsInfo().length) {
        //   this.endJitsiMeeting()
        // } else {
          // this.jitsiApi.dispose()
          // this.gotoMeetingDetails()
        // }
      })

      this.copyMeetingLink()
    },

    endJitsiMeeting(endMeeing) {
      if (endMeeing && this.meeting.recurringDays.length) {
        this.updateMeetingStatus('NOT_STARTED')
      } else if (endMeeing) {
        this.store.dispatch('endMeeting', this.meeting.id)
      }
      document.getElementById('jitsiContainer').innerHTML = ''
      this.jitsiApi.dispose()
      this.store.dispatch('updateUserStatusAfterMeeting')
      this.store.commit('setConferenceActive', false)
      this.gotoMeetingDetails()
    },

    toggleView(view) {
      this.viewStyle = view
      this.dragPosition = { x: 196, y: 80 }
    },

    updateMeetingStatus(status) {
      this.meeting.state = status
      this.store.dispatch('updateMeeting', { form: this.meeting, id: this.meeting.id })
        .then(() => this.gotoMeetingDetails())
    },

    gotoMeetingDetails() {
      this.store.commit('setConferenceActive', false)
      this.$router.push({
        name: 'MeetingDetails',
        path: '/meeting-details',
        params: { id: this.meetingId },
        query: { id: this.meetingId }
      })
      this.store.commit('setBreadcrumbs', [
        { label: 'Meetings', routeName: '/meetings' },
        { label: 'Meeting Details', routeName: '/meeting-details' }
      ])
    },

    generateRoomName() {
      /** Generating room name from meeting title and id by replacing spaces in title with '-' */
      return this.meeting.title.replaceAll(' ', '-').replaceAll(/[^a-zA-Z0-9]/g, '-') + '-' + this.meeting.id
    },

    copyMeetingLink() {
      const link = `https://${process.env.VUE_APP_JITSI_URL}/${this.generateRoomName()}`
      navigator.clipboard.writeText(link)
    },

    addEventListener(event, fn) {
      this.jitsiApi.on(event, fn)
    },

    toggleQR() {
      this.showQR = !this.showQR
    },

    startDragging(event) {
      this.isDragging = true
      const { left, top } = this.dragElement.getBoundingClientRect()
      this.dragOffset = {
        x: event.clientX - left,
        y: event.clientY - top
      }
    },
    dragging(event) {
      if (this.isDragging) {
        document.body.style.userSelect = 'none'
        requestAnimationFrame(() => {
          this.dragPosition = {
            x: event.clientX - this.dragOffset.x - 240,
            y: event.clientY - this.dragOffset.y
          }
        })
      }
    },
    stopDragging() {
      this.isDragging = false
      document.body.style.userSelect = ''
    }
  },

  computed: {
    user() {
      return this.store.getters.user
    },

    meeting() {
      return this.store.getters.meeting
    }
  }
}
</script>
