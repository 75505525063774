
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
import WaveSurfer from 'wavesurfer.js'
import { MatrixClient } from 'matrix-js-sdk'
import VoiceRecordModal from '@/common/components/VoiceRecordModal.vue'
import TimeUtilityService from '@/common/services/TimeUtilityService'
import MxRoom from '@/common/services/MxRoom'

export default defineComponent({
  components: {
    VoiceRecordModal
  },

  data() {
    return {
      store: useStore(),
      moment: moment,
      timeUtility: new TimeUtilityService(),
      player: ref(null),
      canvas: ref(null),
      waveSurfer: {} as any,
      isPlaying: false,
      isPaused: false,
      isPlayed: false,
      duration: 0,
      currentTime: 0,
      replyButton: 'record',
      message: '',
      mySource: '../assets/audio/call-ring.mp3',
      isVoiceRecordModalOpen: false
    }
  },

  mounted() {
    this.waveSurfer = WaveSurfer.create({
      container: '#waveform-incoming',
      waveColor: '#999999',
      progressColor: '#00A1FF',
      barWidth: 2,
      barRadius: 2,
      barHeight: 2,
      barMinHeight: 1,
      cursorWidth: 0,
      height: 60,
      normalize: true,
      responsive: true
    })
    this.waveSurfer.load(this.mediaObjectUrl)
    this.waveSurfer.on('ready', () => {
      this.duration = this.waveSurfer.getDuration()
    })
    this.waveSurfer.on('audioprocess', () => {
      this.currentTime = this.waveSurfer.getCurrentTime()
    })
    this.waveSurfer.on('finish', () => {
      this.isPlaying = false
      this.isPlayed = true
    })
  },

  methods: {
    toggleAudio() {
      this.isPlayed = false
      this.waveSurfer.playPause()
      this.isPlaying = this.waveSurfer.isPlaying()
      this.isPaused = !this.waveSurfer.isPlaying()
    },

    formatTime(timeInSeconds: any) {
      const minutes = Math.floor(timeInSeconds / 60)
      const seconds = Math.floor(timeInSeconds % 60)
      return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
    },

    toggleVoiceRecordModal() {
      this.isVoiceRecordModalOpen = !this.isVoiceRecordModalOpen
    },

    sendMessage() {
      if (this.message) {
        const room = this.matrixClient.getRoom(this.voiceMessages.roomId)
        MxRoom.sendMessage(
          this.matrixClient,
          this.message,
          this.voiceMessages.roomId,
          room.findEventById(this.voiceMessages.eventId)
        )
        this.replyButton = 'record'
        this.message = ''
        this.dismissAudioTile()
      }
    },

    changeReplyButton(e: any, type?: string) {
      e.stopPropagation()
      this.replyButton = type || 'record'
    },

    dismissAudioTile() {
      this.store.commit('setVoiceMessages', {})
    }
  },

  computed: {
    user(): any {
      return this.store.getters.user
    },

    usersByUsername(): any {
      return this.store.getters.usersByUsername
    },

    matrixClient(): MatrixClient {
      return this.store.getters.matrixClient
    },

    voiceMessages(): any {
      return this.store.getters.voiceMessages
    },

    mediaObjectUrl(): any {
      return this.matrixClient.mxcUrlToHttp(this.voiceMessages.content.file.url)
    }
  }
})
