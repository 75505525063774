import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  class: "container",
  id: "meetings"
}
const _hoisted_2 = { class: "middle-content" }
const _hoisted_3 = { class: "content-container px-1 row w-100" }
const _hoisted_4 = { class: "m-3 mt-0" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "w-100 head d-flex align-items-start text-start justify-content-start px-3 pt-3 pb-0" }
const _hoisted_7 = {
  key: 0,
  class: "icon"
}
const _hoisted_8 = {
  key: 1,
  class: "icon"
}
const _hoisted_9 = { class: "title text-start" }
const _hoisted_10 = { class: "desc text-start" }
const _hoisted_11 = {
  key: 0,
  class: "meeting-wrap w-100 py-0 px-3"
}
const _hoisted_12 = { class: "participant-details" }
const _hoisted_13 = {
  key: 1,
  class: "p-image float-start text-center align-items-center no-of-people"
}
const _hoisted_14 = { class: "date-time float-end text-end" }
const _hoisted_15 = {
  key: 0,
  class: "live"
}
const _hoisted_16 = {
  key: 1,
  class: "t-duration text-end"
}
const _hoisted_17 = { class: "tm-dt" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.sectionTitle), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.meetings, (meeting) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "meeting m-3 mt-0 p-0 d-flex text-start align-items-start justify-content-start flex-column",
            key: meeting.id,
            onClick: ($event: any) => (_ctx.openMeeting(meeting.id))
          }, [
            _createElementVNode("div", _hoisted_6, [
              (meeting.icon)
                ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(meeting.icon), 1))
                : (_openBlock(), _createElementBlock("span", _hoisted_8, "🎨")),
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_9, _toDisplayString(meeting.title), 1),
                _createElementVNode("div", _hoisted_10, _toDisplayString(meeting.description), 1)
              ])
            ]),
            (meeting.participants.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", {
                      class: "avatar avatar-bg rounded-circle p-image float-start",
                      style: _normalizeStyle({
                  'background-image':
                    `url(${_ctx.usersByUsername[meeting.participants[0].username]?.avatarUrl}),
                    url(${require('@/assets/img/avatar-placeholder.svg')})`
                })
                    }, null, 4),
                    _createElementVNode("div", {
                      class: "avatar avatar-bg rounded-circle p-image float-start",
                      style: _normalizeStyle({
                  'background-image':
                    `url(${_ctx.usersByUsername[meeting.participants[1].username]?.avatarUrl}),
                    url(${require('@/assets/img/avatar-placeholder.svg')})`
                })
                    }, null, 4),
                    (meeting.participants.length == 3)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: "avatar avatar-bg rounded-circle p-image float-start",
                          style: _normalizeStyle({
                  'background-image':
                    `url(${_ctx.usersByUsername[meeting.participants[2]]?.avatarUrl}),
                    url(${require('@/assets/img/avatar-placeholder.svg')})`
                })
                        }, null, 4))
                      : _createCommentVNode("", true),
                    (meeting.participants.length > 3)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_13, "+" + _toDisplayString(meeting.participants.length
                      - 2), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_14, [
                    (meeting.state === 'IN_PROGRESS')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_15, "LIVE"))
                      : (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(_ctx.utilityService.calculateTimeDiff(meeting.start, meeting.end)), 1)),
                    _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.momentFormat(meeting.start, "HH:mm A, MMMM D, YYYY, ddd")), 1)
                  ])
                ]))
              : _createCommentVNode("", true)
          ], 8, _hoisted_5))
        }), 128))
      ])
    ])
  ]))
}