
import { defineComponent } from 'vue'

export default defineComponent({
  methods: {
    close (): void {
      this.$emit('close')
    }
  }
})
