
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import successMessages from '@/assets/json/successMessages.json'
import { importIpcRenderer, ipcRenderer } from '@/config/PlatformConfig'

importIpcRenderer()

export default defineComponent({
  data () {
    return {
      store: useStore(),
      permissions: {
        camera: localStorage.getItem('cameraEnabled') === 'true',
        microphone: localStorage.getItem('microphoneEnabled') === 'true'
      }
    }
  },

  methods: {
    askCameraPermission () {
      if (ipcRenderer) {
          ipcRenderer.on('CAMERA_ACCESS_SUCCESS', () => {
          localStorage.setItem('cameraEnabled', this.permissions.camera.toString())
          this.store.commit('showSuccess', successMessages.checkCameraPermission)
        })
        ipcRenderer.invoke('ASK_CAMERA_ACCESS')
      }
    },

    askMicrophonePermission () {
      if (ipcRenderer) {
        ipcRenderer.on('MICROPHONE_ACCESS_SUCCESS', () => {
          localStorage.setItem('microphoneEnabled', this.permissions.microphone.toString())
          this.store.commit('showSuccess', successMessages.checkMicrophonePermission)
          this.getPermissionsProvided()
        })
        ipcRenderer.invoke('ASK_MICROPHONE_ACCESS')
      }
    },

    askScreenRecordPermission () {
      // localStorage.setItem('screenCaptureEnabled', this.permissions.screenshot.toString())
      this.store.commit('showSuccess', true)
      this.getPermissionsProvided()
    },

    getPermissionsProvided () {
      return this.permissionsProvided
    }
  },

  computed: {
    user (): any {
      return this.store.getters.user
    },

    permissionsProvided () {
      return !(localStorage.getItem('cameraEnabled') === 'true' &&
      localStorage.getItem('microphoneEnabled') === 'true')
    }
  }
})
