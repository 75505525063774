
import { EventTimelineSet, EventType, MatrixEvent, RelationType } from 'matrix-js-sdk'
import { defineComponent, PropType } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  props: {
    event: { type: Object as PropType<MatrixEvent>, required: true },
    timelineSet: { type: Object as PropType<EventTimelineSet>, required: true }
  },
  data () {
    return {
      reactions_: [] as [string, Set<MatrixEvent>][] | undefined,
      store: useStore()
    }
  },
  mounted () {
    this.getReactions()
    this.store.getters.matrixClient.on('Room.timeline', (event: MatrixEvent) => {
      // TODO: Add more filters to this 'if' condition to get only current event.
      if (event.getType() === EventType.Reaction) {
        this.getReactions()
      }
    })
  },
  methods: {
    getReactions () {
      this.reactions_ = this.timelineSet.getRelationsForEvent(
        this.event!.getId(), RelationType.Annotation, EventType.Reaction)?.getSortedAnnotationsByKey()
      this.$forceUpdate()
    }
  },
  computed: {
    reactions () {
      return this.reactions_
    }
  }
})
