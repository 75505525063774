import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3aafb256"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container container-content" }
const _hoisted_2 = { class: "tile-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(14, (i) => {
        return _createElementVNode("div", {
          class: "tile",
          key: i
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["common-tile", _ctx.tileSizes[i-1]]),
            style: _normalizeStyle({backgroundImage: 'url(https://picsum.photos/600?random=' + i + ')'})
          }, null, 6)
        ])
      }), 64))
    ])
  ]))
}