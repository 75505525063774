
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

export default defineComponent({
  components: {
    Cropper
  },

  props: {
    img: { type: String, required: true }
  },

  data() {
    return {
      store: useStore(),
      resizedImage: '' as any
    }
  },

  methods: {
    updateImage(e: any) {
      this.resizedImage = e.canvas.toDataURL('image/jpeg')
    },

    save(e: any) {
      const form = new FormData()
      // strip off the data: url prefix to get just the base64-encoded bytes
      const file = this.resizedImage.replace(/^data:image\/\w + base64,/, '')
      fetch(file).then((res) => {
        const fileBlob = res.blob()
        fileBlob.then((file) => {
          form.append('picture', file)
          this.store.dispatch('uploadAvatar', form)
          this.close(e)
        })
      })
    },

    close(e: any) {
      e.stopPropagation()
      this.$emit('close')
    }
  }
})
