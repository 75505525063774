
import { IRoomEventFilter, ISearchRequestBody, MatrixClient, Room, SearchOrderBy } from 'matrix-js-sdk'
import { defineComponent, PropType } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'

export default defineComponent({
  props: {
    room: {} as PropType<Room>
  },

  data() {
    return {
      moment: moment,
      store: useStore(),
      query: '',
      searchResults: [] as any
    }
  },

  methods: {
    async search(query: string) {
      const filter: IRoomEventFilter = {
        limit: 100
      }
      if (this.room?.roomId) filter.rooms = [this.room.roomId]
      const body: ISearchRequestBody = {
        search_categories: {
          room_events: {
            search_term: query,
            filter,
            order_by: SearchOrderBy.Recent,
            event_context: {
              before_limit: 1,
              after_limit: 1,
              include_profile: true
            }
          }
        }
      }
      this.searchResults = await this.matrixClient.search({ body: body })
    },

    getEventSender(roomId: any, eventId: any) {
      const room = this.matrixClient.getRoom(roomId)
      const event = room.findEventById(eventId)
      return event?.sender.name
    },

    getUserAvatar(userId: string): string {
      const username = userId.slice(1, userId.indexOf(':'))
      return this.store.getters.usersByUsername[username]?.avatarUrl
    },

    backToRoom() {
      this.$emit('hide')
    }
  },

  computed: {
    matrixClient(): MatrixClient {
      return this.store.getters.matrixClient
    }
  }
})
