import axios, { AxiosPromise } from 'axios'
import { Todo, TodoTask, UpdateTodoTask } from '../../types/TodoInterface'
import { Meeting } from '../../types/MeetingInterface'

export default class ApiService {
	multipartHeader = {
    'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW'
  }

  // User
  getUser (): AxiosPromise<any> {
    return axios.get('jwork/user')
  }

  exchangeKeys (payload: any): AxiosPromise<any> {
    return axios.post('keys/exchange', payload)
  }

  getUserByUsername (username: string): AxiosPromise<any> {
    return axios.get(`jwork/user/${username}`)
  }

  getMyOrganization (): AxiosPromise<any> {
		return axios.get('blox/organizations/mine')
	}

	getMyMeetings (): AxiosPromise<Meeting[]> {
		return axios.get('blox/meetings')
	}

  getSharedMeetings (): AxiosPromise<Meeting[]> {
		return axios.get('blox/meetings/shared')
	}

  getRelatedMeetings (meetingTitle: string): AxiosPromise<Meeting[]> {
		return axios.get(`blox/meetings/related?title=${meetingTitle}`)
	}

	getMeeting (id: string): AxiosPromise<Meeting> {
		return axios.get(`blox/meetings/${id}`)
	}

  createMeeting (meeting: any): AxiosPromise<Meeting> {
    return axios.post('blox/meetings', meeting)
  }

	updateMeeting (meeting: any): AxiosPromise<Meeting> {
    return axios.put(`blox/meetings/${meeting.id}`, meeting.form)
  }

  deleteMeeting (meetingId: any): AxiosPromise<any> {
    return axios.delete(`blox/meetings/${meetingId}`, meetingId)
  }

  endMeeting (meetingId: any): AxiosPromise<any> {
    return axios.put(`blox/meetings/${meetingId}/stop`)
  }

  startMeeting (meetingId: any): AxiosPromise<any> {
    return axios.put(`blox/meetings/${meetingId}/start`)
  }

  addAttendance (payload: any): AxiosPromise<any> {
    return axios.post('jwork/attendance', payload)
  }

  getLatestAttendance (): AxiosPromise<any> {
    return axios.get('jwork/attendance/latest')
  }

  getTimers (date: string): AxiosPromise<any> {
    return axios.get(`jwork/timer/filter?date=${date}`)
  }

  getTimerByUser (username: string, date: string): AxiosPromise<any> {
    return axios.get(`jwork/timer/${username}/filter?date=${date}`)
  }

  createTimer (timer: any): AxiosPromise<any> {
    return axios.post('jwork/timer', timer)
  }

  updateTimer (timer: any): AxiosPromise<any> {
    return axios.put('jwork/timer', timer)
  }

	// Calendar Events
  getAllEvents (): AxiosPromise<any> {
    return axios.get('blox/calendarEvents')
  }

  getEventsByUserByMonth (username: string, month: number, year?: number): AxiosPromise<any> {
    return axios.get(`blox/calendarEvents/filter?username=${username}&month=${month}&year=${year}`)
  }

	getMonthEvents (year: number, month: number): AxiosPromise<any> {
    return axios.get(`blox/calendarEvents/filter?year=${year}&month=${month}`)
  }

	getDayEvents (year: number, month: number, day: number): AxiosPromise<any> {
    return axios.get(`blox/calendarEvents/filter?year=${year}&month=${month}&day=${day}`)
  }

  getWeekEvents (weekNumber: any): AxiosPromise<any> {
    return axios.get(`blox/calendarEvents/filter?week=${weekNumber}`)
  }

	createEvent (event: any): AxiosPromise<any> {
    return axios.post('blox/calendarEvents', event)
  }

	createFileUploadUrl (file: any): AxiosPromise<any> {
    return axios.post('blox/file', file, {
      headers: this.multipartHeader
    })
  }

	uploadFile (uploadUrl: string, file: any): AxiosPromise<any> {
    return axios.put(uploadUrl, file, {
      headers: {
        'Content-Type': file.type
      }
    })
  }

  updateUserStatus (status: string, statusDescription?: string, breakDuration?: number): AxiosPromise<any> {
    let url = `jwork/user/status?status=${status}`
    url += `&statusDescription=${statusDescription}&duration=${breakDuration || ''}`
    return axios.post(url)
  }

  // Organization
  createOrganisation (payload: any): AxiosPromise<any> {
    return axios.post('blox/organizations', payload)
  }

  getOrganization (id: string): AxiosPromise<any> {
    return axios.get(`blox/organizations/${id}`)
  }

  getRooms (): AxiosPromise<any> {
    return axios.get('blox/rooms')
  }

  getActiveMeetings (): AxiosPromise<Meeting[]> {
    return axios.get('blox/meetings/active')
  }

	createTodo (todo: any): AxiosPromise<any> {
    return axios.post('blox/todos', todo)
  }

	getTodoList (): AxiosPromise<any> {
    return axios.get('blox/todos')
  }

	addTodo (todo: Todo): AxiosPromise<Todo> {
    return axios.put(`blox/todos/${todo.id}/tasks`, todo.tasks)
  }

	updateTodoList (todo: Todo): AxiosPromise<Todo> {
    return axios.put(`blox/todos/${todo.id}`, todo)
  }

	updateTodo (todoListId: string, todo: TodoTask): AxiosPromise<TodoTask> {
    return axios.put(`blox/todos/${todoListId}/tasks/${todo.number}`, todo)
  }

  deleteTodo (todoId: any): AxiosPromise<any> {
    return axios.delete(`blox/todos/${todoId}`, todoId)
  }

  deleteTodoTask (todoListId: string, todo: any): AxiosPromise<any> {
    return axios.delete(`blox/todos/${todoListId}/tasks?numbers=${todo.number}`, todo)
  }

	completeTodoTask (todoListId: string, todo: TodoTask): AxiosPromise<UpdateTodoTask> {
    return axios.put(`blox/todos/${todoListId}/tasks/${todo.number}/done`, todo)
  }

	reopenTodoTask (todoListId: string, todo: TodoTask): AxiosPromise<UpdateTodoTask> {
    return axios.put(`blox/todos/${todoListId}/tasks/${todo.number}/reopen`, todo)
  }

  // Employee timeline data
  getUserTimeline (username: string, perPage: number, page: number): AxiosPromise<any> {
    return axios.get(`jwork/timelines/user/${username}?pageSize=${perPage}&page=${page}`)
  }

  getUserTimelineByDate (username: string, date: string): AxiosPromise<any> {
    return axios.get(`jwork/timelines/user/${username}?date=${date}`)
  }

  addUserTitle (userTitle: string): AxiosPromise<any> {
    return axios.put(`jwork/user?title=${userTitle}`)
  }

  saveTimeline (data: any): AxiosPromise<any> {
    return axios.post('jwork/timelines/upload', data)
  }

  getLatestEmployeeTimeline (): AxiosPromise<any> {
    return axios.get('jwork/timelines/organization')
  }

  saveZoomCode (payload: any): AxiosPromise<any> {
    return axios.put(`blox/zoom/token?authCode=${payload}`)
  }

  isZoomAuthenticated (): AxiosPromise<any> {
    return axios.get('blox/zoom/isAuthenticated')
  }

  createHopReport (report: any): AxiosPromise<any> {
    return axios.post('jwork/hop-report/submit', report)
  }

  createBugReport (report: any): AxiosPromise<any> {
    return axios.post('jwork/bug-report/submit', report)
  }

  public getReports (assignee?: string): AxiosPromise<any> {
    if (assignee !== undefined && assignee !== null && assignee !== 'null') {
      return axios.get(`jwork/hop-report/all?userId=${assignee}`)
    }
    return axios.get('jwork/hop-report/all')
  }

  public detectFace (formData: any): AxiosPromise<any> {
    return axios.post('http://api.pyimagesearch.com/face_detection/detect/', formData, {
      headers: this.multipartHeader
    })
  }

  getAllEmployeeInfo (): AxiosPromise<any> {
		return axios.get('blox/employee-info')
	}

  updateEmployeeInfo (employeeInfo: any): AxiosPromise<any> {
		return axios.put('blox/employee-info', employeeInfo)
	}
}
