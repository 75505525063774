
import { defineComponent } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  data () {
    return {
			store: useStore()
    }
  },

  methods: {
    toggleModal (e: MouseEvent): void {
      e.stopPropagation()
      this.store.dispatch('toggleError', false)
    }
  },

  computed: {
    showError (): any {
      return this.store.getters.showError
    }
  }
})
