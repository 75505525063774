import axios, { AxiosPromise } from 'axios'

export default class FileApiService {
  multipartHeader = {
    'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW'
  }

  createFileDownloadUrl (fileId: any): AxiosPromise<any> {
    return axios.get(`blox/getfile?fileName=${fileId}`)
  }

  downloadFile (downloadUrl: any): AxiosPromise<any> {
    return axios.get(`${downloadUrl}`, { responseType: 'blob' })
  }
}
