
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import ImageCropper from './ImageCropper.vue'

export default defineComponent({
  components: {
    ImageCropper
  },

  data() {
    return {
      store: useStore(),
      form: {
        email: '',
        firstname: '',
        lastname: ''
      },
      isImageCropperVisible: false,
      fileReader: new FileReader(),
      selectedAvatar: null as any
    }
  },

  mounted() {
    const name = this.user.name.split(' ')
    this.form = {
      email: this.user.email,
      firstname: name[0],
      lastname: name[1]
    }
    this.fileReader.onload = event => {
      this.selectedAvatar = event.target!.result
    }
  },

  methods: {
    closeMe(e: MouseEvent) {
      e.preventDefault()
      this.$emit('close')
    },

    save(e: MouseEvent) {
      this.store.dispatch('updateUserDetails', this.form)
      this.closeMe(e)
    },

    clickAvatarUpload() {
      const fileUploadFieldClick = document
        .getElementById('avatar-upload')
        ?.click()
    },

    uploadAvatar(e: any) {
      e.stopPropagation()
      if (e.target.files.length) {
        const file = e.target.files[0]
        this.fileReader.readAsDataURL(file)
        this.isImageCropperVisible = true
      }
    },

    closeImageCropper() {
      this.isImageCropperVisible = false
    }
  },

  computed: {
    user(): any {
      return this.store.getters.user
    },
    avatar(): any {
      return this.store.getters.avatar
    }
  }
})
