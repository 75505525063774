
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import { importIpcRenderer, ipcRenderer } from '@/config/PlatformConfig'
import AudioRoomApi from '@/common/services/api/AudioRoomApi'
import { User } from '@/common/types/UserInterface'
import { Organization } from '@/modules/onboarding/interfaces'
import { AudioRoom } from '@/common/types/AudioRoomInterface'
import { AxiosResponse } from 'axios'

importIpcRenderer()

export default defineComponent({
  data() {
    return {
      store: useStore(),
      audioRooms: [] as AudioRoom[]
    }
  },

  mounted() {
    this.loadScript(`https://${process.env.VUE_APP_JITSI_URL}/external_api.js`)
    this.askMediaPermissions()

    /** Get all audio rooms for current organization. */
    AudioRoomApi.getAllByOrganization(this.organization.id)
      .then((response: AxiosResponse<AudioRoom[]>) => {
        this.audioRooms = response.data
      }).catch((error: any) => console.error(error))
  },

  methods: {
    loadScript(src: any) {
      const scriptEl = document.createElement('script')
      scriptEl.src = src
      scriptEl.async = true
      document.querySelector('head')!.appendChild(scriptEl)
    },

    askMediaPermissions() {
      if (ipcRenderer) {
        ipcRenderer.on('MICROPHONE_ACCESS_SUCCESS', (res: any) => {
          localStorage.setItem('microphoneEnabled', 'true')
        })
      }
    },

    gotoRoomDetails(roomId: string) {
      this.$router.push({
				name: 'Audio Room Details',
				path: '/audio-rooms/',
				params: { id: roomId }
			})
    }
  },

  computed: {
    user(): User {
      return this.store.getters.user
    },

    organization(): Organization {
      return this.store.getters.organization
    }
  }
})
