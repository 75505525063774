import { IContent, MatrixClient, MatrixEvent, Room } from 'matrix-js-sdk'
import { defineComponent, PropType } from 'vue'
import { useStore } from 'vuex'
import { decryptFile } from '../../../../common/services/MxEventContentHandler'

export default defineComponent({
  props: {
    room: { type: Object as PropType<Room>, required: true },
    event: { type: Object as PropType<MatrixEvent>, required: true },
    content: { type: Object as PropType<IContent>, required: true },
    isEncrypted: { type: Boolean }
  },

  data () {
    return {
      store: useStore(),
      mediaObjectUrl: '',
      colors1: ['#ff9ff3', '#feca57', '#ff6b6b', '#48dbfb', '#0abde3'],
			colors2: ['#01a3a4', '#2e86de', '#341f97', '#BDC581', '#B33771']
    }
  },

  async mounted () {
    this.createMediaUrl()
  },

  methods: {
    createMediaUrl () {
      if (this.content.file) {
        if (this.isEncrypted) {
          decryptFile(this.store.getters.matrixClient, this.content.file).then(blob => {
            this.mediaObjectUrl = URL.createObjectURL(blob)
          })
        } else {
          this.mediaObjectUrl = this.store.getters.matrixClient.mxcUrlToHttp(this.content.file.url)
        }
      }
    },

    randomColor () {
			return 'background-image: linear-gradient(55deg, ' +
			this.colors1[Math.floor(Math.random() * this.colors1.length)] +
			' 4%, ' +
			this.colors2[Math.floor(Math.random() * this.colors2.length)] +
			' 97%);'
		}
  },

  computed: {
    matrixClient (): MatrixClient {
      return this.store.getters.matrixClient
    }
  }
})
