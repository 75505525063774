import { Options, Vue } from 'vue-class-component'
import { useStore } from 'vuex'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import moment from 'moment'

@Options({
  components: { flatPickr }
})
export default class TodoModal extends Vue {
  store = useStore()
	moment = moment
  isTitleEmpty: any = []
  isListNameEmpty = false
  form: any = {
    title: 'List Name',
    tasks: [],
    done: false
  }

  flatTimePickerConfig = {
    noCalendar: true,
    dateFormat: 'H:i',
    time_24hr: true
  }

tagColorpicker = [
	{
		name: 'report',
		color: '#9c88ff'
	},
	{
		name: 'codeReview',
		color: '#00a8ff'
	},
	{
		name: 'design',
		color: '#4f0661'
	},
	{
		name: 'project',
		color: '#e84118'
	},
	{
		name: 'social',
		color: '#f075c5'
	},
	{
		name: 'incident',
		color: '#576574'
	},
	{
		name: 'bioHazard',
		color: '#f1c40f'
	}
]

  mounted () {
    this.addEmptyTask()
  }

  addEmptyTask () {
    this.form.tasks.push({
      title: 'To do item',
      description: '',
      targetDate: '',
      location: '',
      url: '',
      attachment: {
        fileName: '',
        fileId: ''
      },
			tags: [
			]
    })
  }

  close (e: any) {
    this.$emit('close', e)
  }

	formattedStartDate (date: string) {
		return moment(date).format('YYYY-MM-DD')
	}

	submitTodo (e: MouseEvent) {
		e.stopPropagation()
		this.isTitleEmpty = []
		if (this.form.title === '') {
			this.isListNameEmpty = true
    }
		for (let i = 0; i < this.form.tasks.length; i++) {
			if (this.form.tasks[i].targetDate) {
				this.form.tasks[i].targetDate = ((this.form.tasks[i].targetDate).concat('T00:00:00.000Z'.toString()))
				this.form.tasks[i].targetDate = moment(this.form.tasks[i].targetDate).toISOString()
			}
			if (!this.form.tasks[i].title) {
				this.isTitleEmpty.push(i + 1)
			}
		}
		if (this.isListNameEmpty === false && this.isTitleEmpty.length === 0) {
			this.store.dispatch('submitTodoRequest', this.form)
      .then((response) => {
        this.close(e)
				if (response) {
					this.store.commit('showSuccess', true)
				} else {
					this.store.commit('showError', true)
				}
			})
		}
	}

	addTag (e: any, task: any, item: any) {
		if (e.target.checked) {
			task.tags.push({
				name: item.name,
				color: item.color
			})
		} else {
			task.tags = task.tags.filter(
				(obj: any) => obj.name !== item.name
			)
		}
	}

	uploadFile (e: any, task: any) {
		if (e.target.files.length) {
			const file = e.target.files[0]
			const formData = new FormData()
			formData.append('file', file)
			this.store.dispatch('createFileUploadUrl', { file, formData })
			.then((response) => {
				task.attachment.fileName = file.name
				task.attachment.fileId = response.fileName
			})
		}
	}
}
