import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/img/icon-close-grey-20.svg'


const _withScopeId = n => (_pushScopeId("data-v-04fe8734"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-backdrop" }
const _hoisted_2 = {
  class: "modal",
  id: "multiuser-modal",
  role: "dialog"
}
const _hoisted_3 = { class: "row container p-0" }
const _hoisted_4 = { class: "col-7" }
const _hoisted_5 = { class: "d-flex justify-content-between align-items-center mb-3" }
const _hoisted_6 = { class: "fs-20 mb-2" }
const _hoisted_7 = { class: "stages-indicator" }
const _hoisted_8 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SlideData = _resolveComponent("SlideData")!
  const _component_OrgCreationStepOne = _resolveComponent("OrgCreationStepOne")!
  const _component_OrgCreationStepTwo = _resolveComponent("OrgCreationStepTwo")!
  const _component_OrgCreationStepThree = _resolveComponent("OrgCreationStepThree")!
  const _component_InviteNewUser = _resolveComponent("InviteNewUser")!

  return (_openBlock(), _createBlock(_Transition, { name: "modal-fade" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            src: _imports_0,
            width: "32",
            height: "32",
            alt: "close",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args))),
            class: "close-img-btn"
          }),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_SlideData, { class: "col-5" }),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("form", {
                onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"])),
                class: "org-form"
              }, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.tabNames[_ctx.currentTab]), 1),
                  _createElementVNode("div", _hoisted_7, [
                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(2, (each) => {
                      return _createElementVNode("div", {
                        class: _normalizeClass(["cursor-pointer", { 'current': _ctx.currentTab === each, 'done': _ctx.currentTab > each }]),
                        key: each,
                        onClick: ($event: any) => (_ctx.currentTab = each)
                      }, _toDisplayString(each), 11, _hoisted_8)
                    }), 64))
                  ])
                ]),
                (_ctx.currentTab === 1)
                  ? (_openBlock(), _createBlock(_component_OrgCreationStepOne, {
                      key: 0,
                      formProp: _ctx.form,
                      onNext: _ctx.goToNextTab
                    }, null, 8, ["formProp", "onNext"]))
                  : _createCommentVNode("", true),
                (_ctx.currentTab === 2)
                  ? (_openBlock(), _createBlock(_component_OrgCreationStepTwo, {
                      key: 1,
                      formProp: _ctx.form,
                      logo: _ctx.companyLogo,
                      onNext: _ctx.goToNextTab,
                      onClose: _ctx.close
                    }, null, 8, ["formProp", "logo", "onNext", "onClose"]))
                  : _createCommentVNode("", true),
                (_ctx.currentTab === 3)
                  ? (_openBlock(), _createBlock(_component_OrgCreationStepThree, {
                      key: 2,
                      onNext: _ctx.goToNextTab
                    }, null, 8, ["onNext"]))
                  : _createCommentVNode("", true),
                (_ctx.currentTab === 4)
                  ? (_openBlock(), _createBlock(_component_InviteNewUser, { key: 3 }))
                  : _createCommentVNode("", true)
              ], 32)
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}