
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import Feed from '@/modules/feed/components/feed/Feed.vue'
import MyStatus from './MyStatus.vue'
import PermissionTile from './PermissionTile.vue'
import Loader from '@/common/components/Loader.vue'
import ReportTile from './ReportTile.vue'
import ReleaseNote from './ReleaseNote.vue'
import ImmediateMeeting from './ImmediateMeeting.vue'
import MyAudioTile from './MyAudioTile.vue'
import moment from 'moment'
import OptionsTooltip from '@/modules/chats/components/OptionsTooltip.vue'
import myTooltipJson from '@/common/json/allTeamsMyTooltip.json'
import StatusUtils from '@/common/services/StatusUtils'
import InfoTile from './tiles/InfoTile.vue'
import { UserStatus } from '@/common/enums'
import StatusDescriptionModal from '@/components/StatusDescriptionModal.vue'
import TodoTile from './TodoTile.vue'

export default defineComponent({
  components: {
    Feed,
    PermissionTile,
    MyStatus,
    Loader,
    ReportTile,
    ReleaseNote,
    ImmediateMeeting,
    MyAudioTile,
    OptionsTooltip,
    InfoTile,
    StatusDescriptionModal,
    TodoTile
  },

  data() {
    return {
      store: useStore(),
      showVoiceRecordModal: false,
      moment: moment,
      statusUtils: new StatusUtils(),
      showStatusDescriptionModal: false,
      isInfoTileVisible: false,
      isMyTooltipVisible: false,
      tooltipPos: {},
      myTooltipOptions: [
        {
          ...myTooltipJson.myTooltipOptions.retakeImage,
          onClick: this.updateWebcamshot.bind(this)
        },
        {
          ...myTooltipJson.myTooltipOptions.changeStatus
        },
        {
          ...myTooltipJson.myTooltipOptions.available,
          onClick: this.updateUserStatus.bind(this, 'AVAILABLE')
        },
        {
          ...myTooltipJson.myTooltipOptions.DND,
          onClick: this.updateUserStatus.bind(this, 'DND')
        },
        {
          ...myTooltipJson.myTooltipOptions.learning,
          onClick: this.updateUserStatus.bind(this, 'LEARNING')
        },
        {
          ...myTooltipJson.myTooltipOptions.busy,
          onClick: this.updateUserStatus.bind(this, 'BUSY')
        },
        {
          ...myTooltipJson.myTooltipOptions.unavailable,
          onClick: this.toggleStatusDescriptionModal.bind(this)
        }
      ] as any
    }
  },

  async mounted() {
    this.store.dispatch('getActiveMeetings')

    if (window.location.search.includes('code') && localStorage.getItem('isZoomRedirection') === 'true') {
      this.store.dispatch(
        'saveZoomCode', window.location.search.slice(window.location.search.indexOf('=') + 1)
      ).then(() => {
        localStorage.setItem('isZoomRedirection', 'false')
        this.$router.push('/new-meeting')
      })
    }
    this.store.dispatch('getLatestEmployeeTimeline')
    this.store.commit('setBreadcrumbs', [{ label: 'Home', routeName: '/' }])

    await this.store.dispatch('getMyMeetings')
    await this.store.dispatch('getSharedMeetings')

    if (this.allMeetings) {
      for (let i = 0; i < this.allMeetings.length; i++) {
        const totalTime = moment(this.allMeetings[i].start * 1000).diff(moment(), 'seconds')
        if (totalTime < 300 && totalTime > 0) {
          this.store.commit('updateImmediateMeeting', this.allMeetings[i])
        }
      }
    }
  },

  methods: {
    updateWebcamshot() {
      new StatusUtils().updateStatusAndTimeline()
    },

    toggleVoiceRecordModal() {
      this.showVoiceRecordModal = !this.showVoiceRecordModal
    },

    updateUserStatus(status: UserStatus) {
      this.statusUtils.updateStatusAndTimeline(status)
    },

    showOptionsTooltip(e: any) {
      this.isMyTooltipVisible = true
      const x = e.pageX - e.currentTarget.offsetLeft
      const y = e.pageY - e.currentTarget.offsetTop
      this.tooltipPos = { top: y + 120 + 'px', left: x + 220 + 'px' }
    },

    hideMyTooltip() {
      this.isMyTooltipVisible = false
    },

    toggleInfoTile() {
      this.isInfoTileVisible = !this.isInfoTileVisible
    },

    toggleStatusDescriptionModal() {
      this.showStatusDescriptionModal = !this.showStatusDescriptionModal
    }
  },

  computed: {
    user(): any {
      return this.store.getters.user
    },

    isPageLoading(): any {
      return this.store.getters.isPageLoading
    },

    voiceMessages(): any {
      console.log(this.store.getters.voiceMessages)
      return this.store.getters.voiceMessages
    },

    allMeetings(): any {
      return this.store.getters.allMeetings
    },

    immediateMeeting(): any {
      return this.store.getters.immediateMeeting
    }
  }
})
