import AudioRooms from '@/components/AudioRooms.vue'
import AudioRoomTables from '@/components/AudioRoomTables.vue'

const routes = [
  {
    path: '/audio-rooms',
    name: 'Audio Rooms',
    component: AudioRooms
  },
  {
    path: '/audio-rooms/:id',
    name: 'Audio Room Details',
    props: true,
    component: AudioRoomTables
  }
]

export default routes
