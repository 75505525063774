import { Vue, Options } from 'vue-class-component'
import { useStore } from 'vuex'
import UtilityService from '@/common/services/TimeUtilityService'
import { MatrixEvent, Room } from 'matrix-js-sdk'
import MxRoom from '@/common/services/MxRoom'

export interface ITimer {
  id: string
  time: number
  timeElapsed: any
  timerRef: any
  status: string
  startTime: any
  timerUpdatedAt: any
  endTime: any
  timerClass: string
}

@Options({})
export default class RightsideDock extends Vue {
  store = useStore()
  utilityService = new UtilityService()
  mxRoom = MxRoom

  gotoChats () {
    this.$router.push('/mx/chats')
  }

  getDMUser (room: any) {
    return this.store.getters.usersByUsername[MxRoom.getDMUser(room)]
  }

  openChat (room: any) {
    this.store.commit('setCurrentRoom', room)
    this.$router.push('/mx/chats')
  }

  getLatestMessage (room: Room): MatrixEvent {
    /** TODO: This needs to be refactored. */
    return room.getLiveTimeline()?.getEvents()[room.getLiveTimeline()?.getEvents().length - 1]
  }

  get chats (): Room[] {
    const rooms = this.store.getters.matrixRooms.slice(0, 3)
    return rooms
  }

  get employees () {
    return this.store.getters.allMembers
  }

  get attendance () {
    return this.store.getters.attendance
  }

  get savedTimer () {
    return this.store.getters.timer
  }
}
