import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import moment from 'moment'
import UtilityService from '../../common/services/TimeUtilityService'
import UserMultiselectModal from '@/common/components/UserMultiselectModal.vue'
import Loader from '@/common/components/Loader.vue'
import { EmojiButton } from '@joeattardi/emoji-button'
import { Meeting } from '../../common/types/MeetingInterface'
import { CustomMxEvent, MeetingPreference } from '@/common/enums'
import MxRoom from '@/common/services/MxRoom'
import { MatrixClient } from 'matrix-js-sdk'

export default defineComponent({
	components: { flatPickr, UserMultiselectModal, Loader },

	data () {
    return {
      store: useStore(),
      moment: moment,
      meetingPreference: MeetingPreference,
      utilityService: new UtilityService(),
      emojiPicker: new EmojiButton({
        theme: 'dark'
      }),
      meetingId: '',
      form: {} as Meeting,
      flatTimePickerConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'H:i',
        time_24hr: true,
        minuteIncrement: 1,
        defaultDate: new Date().toTimeString()
      },
      meetingDate: moment().format('DD-MM-YYYY'),
      meetingTime: new Date().toTimeString(),
      duration: '15', /** TODO: Why duration is considered as end time? Refactor. */
      customDuration: '',
      selectedMembers: [] as any,
      isMembersModalVisible: false,
      days: [
        { label: 'M', dayString: 'Monday' },
        { label: 'T', dayString: 'Tuesday' },
        { label: 'W', dayString: 'Wednesday' },
        { label: 'T', dayString: 'Thursday' },
        { label: 'F', dayString: 'Friday' },
        { label: 'S', dayString: 'Saturday' },
        { label: 'S', dayString: 'Sunday' }
      ],
      formErrors: [] as any
    }
	},

	mounted () {
    if (this.$route.params.members) {
			this.selectedMembers.push(JSON.parse(this.$route.params.members as string))
		}
		if (this.$route.params.id) {
			this.meetingId = this.$route.params.id.toString()
			this.store.dispatch('getMeeting', this.meetingId)
        .then(() => {
          this.setMeetingDetails()
          console.log('meeting edit', this.meetingId)
        })
		} else {
      this.resetMeetingForm()
    }
		this.emojiPicker.on('emoji', (selection: any) => {
      this.addReactionToMessage(selection.emoji)
    })
	},

	methods: {
		validateForm () {
			if (!this.form.title) {
				this.formErrors.push('Title is required')
			}
			if (this.form.participants.length < 1) {
				this.formErrors.push('Atleast a member should be added')
			}
			if (!this.meetingDate) {
				this.formErrors.push('Meeting date should be selected')
			}
			if (!this.meetingTime) {
				this.formErrors.push('Meeting Time should be selected')
			}
		},

    updatePreferences(preference: MeetingPreference) {
      if (this.form.preferences.indexOf(preference) > -1) {
        this.form.preferences.splice(this.form.preferences.indexOf(preference), 1)
      } else {
        this.form.preferences.push(preference)
      }
    },

		timeToISOString () {
      const concat = moment(`${this.meetingDate} ${this.meetingTime}`, 'DD-MM-YYYY HH:mm:ss Z')
      this.form.start = moment(concat).toISOString()
			if (!this.duration) {
				this.duration = this.customDuration
			}
      /** TODO: Refactor! Create separate field for duration with default values defined in an enumeration. */
			this.form.end = concat.add(this.duration, 'minutes').toISOString()
		},

    generateRoomName (meetingTitle: string) {
      /** Generating room name from meeting title and id by replacing spaces in title with '-' */
      return meetingTitle.replaceAll(' ', '-').replaceAll(/[^a-zA-Z0-9]/g, '-') + '-' + this.meetingId
    },

		submit (): void {
			this.formErrors = []
			this.validateForm()
			if (!this.formErrors.length) {
				this.timeToISOString()
        this.form.participants = this.form.participants.map((member: any) => member.username)
        this.form.participants.push(this.user.username)

				if (this.meetingId === '') {
					this.store.dispatch('createMeeting', this.form)
            .then((response) => {
              this.handleResponse(response)
            })
					this.resetMeetingForm()
				} else {
					this.store.dispatch('updateMeeting', { form: this.form, id: this.meetingId })
            .then((response) => {
              this.handleResponse(response)
            })
				}
			}
		},

		startMeeting (): void {
			this.formErrors = []
			this.validateForm()

			if (!this.formErrors.length) {
				this.timeToISOString()
        this.form.participants = this.form.participants.map((member: any) => member.username)
				if (this.meetingId === '') {
					this.store.dispatch('createAndStartMeeting', this.form)
            .then((response: any) => {
              this.resetMeetingForm()
              this.goToConference(response.id)
            })
				} else {
					this.store.dispatch('updateAndStartMeeting', { form: this.form, id: this.meetingId })
          this.goToConference(this.meetingId)
				}
			}
		},

    goToConference(meetingId: string) {
      this.$router.push({
        name: 'Conference',
        path: '/conference',
        params: { id: meetingId }
      })
    },

    handleResponse(response: any) {
      if (response) {
        this.store.commit('showSuccess', true)
        this.sendNotificationToParticipants(response.data)
        this.goToMeetingDetails(response)
      } else {
        this.store.commit('showError', true)
      }
    },

		membersListArray (selectedMembersList: any, event: any) {
			this.toggleMembersModal(event)
			selectedMembersList = selectedMembersList.filter((filter: any) => filter.name !== '')
			this.selectedMembers = selectedMembersList
			this.form.participants = this.selectedMembers
		},

		setDuration () {
			this.duration = ''
		},

		toggleMembersModal (event: MouseEvent) {
			event.stopPropagation()
			this.isMembersModalVisible = !this.isMembersModalVisible
		},

		setMeetingDetails () {
      this.form = this.meeting
			this.meetingDate = moment(this.meeting.start * 1000).format('DD-MM-YYYY')
			this.meetingTime = moment(this.meeting.start * 1000).format('HH:mm')

			if (this.utilityService.calculateTimeDiff(this.meeting.start, this.meeting.end) === '0h15m') {
				this.duration = '15'
			} else if (this.utilityService.calculateTimeDiff(this.meeting.start, this.meeting.end) === '0h30m') {
				this.duration = '30'
			} else {
				this.customDuration = this.utilityService.convertToMinutes(this.meeting.start, this.meeting.end)
			}
			this.selectedMembers = this.meeting.participants.filter((user: any) => user.username !== this.user.username)
		},

    setRecurringDays(event: any) {
      this.form.recurringDays = event.target.checked ? this.days.map(day => day.dayString) : []
    },

		toggleEmojiPicker (clickEvent: MouseEvent) {
			this.emojiPicker.togglePicker(clickEvent.target)
		},

		addReactionToMessage (emoji: any) {
			this.form.icon = emoji
		},

    goToMeetingDetails(meeting: Meeting) {
      this.$router.push({
        name: 'MeetingDetails',
        path: '/meeting-details',
        params: { id: meeting.id },
        query: { id: meeting.id }
      })
    },

    sendNotificationToParticipants(meeting: Meeting) {
      meeting.participants.forEach((participant: any) => {
        const content = {
          body: '',
          metadata: {
            to: participant.username,
            meeting_id: this.meetingId
          },
          msgtype: CustomMxEvent.NEW_MEETING
        }
        MxRoom.sendCustomNotification(
          this.matrixClient,
          this.store.getters.organization.organizationNotificationSpaceId,
          content
        )
      })
    },

    resetMeetingForm() {
      this.form = {
        title: '',
        icon: '🎨',
        description: '',
        type: 'MEETING',
        recurringDays: [],
        preferences: [],
        start: '',
        end: '',
        participants: []
      }
      this.meetingDate = moment().format('DD-MM-YYYY')
      this.meetingTime = new Date().toTimeString()
      this.duration = '15'
      this.customDuration = ''
    }
	},

	computed: {
		displayMembersList (): any {
			return this.selectedMembers
		},

		user (): any {
			return this.store.getters.user
		},

		meeting (): any {
			return this.store.getters.meeting
		},

		usersByUsername (): any {
			return this.store.getters.usersByUsername
		},

		membersData (): any {
			return this.store.getters.organizationMembers
        .filter((member: any) => member.username !== this.user.username)
		},

		isPageLoading (): any {
			return this.store.getters.isPageLoading
		},

    matrixClient(): MatrixClient {
      return this.store.getters.matrixClient
    }
	}
})
