import { Todo, TodoTask, UpdateTodoTask } from '../common/types/TodoInterface'
import { Meeting } from '../common/types/MeetingInterface'
import { Room } from 'matrix-js-sdk'
import ObjectUtils from '@/common/services/ObjectUtils'
import FloorMutations from './mutations/Floor'
import UserMutations from './mutations/User'
import moment from 'moment'
import StatusUtils from '@/common/services/StatusUtils'
import { UserStatus } from '@/common/enums'

const statusUtils = new StatusUtils()

const mutations = {
  setAuthToken (state: any): void {
    state.accessToken = localStorage.getItem('access_token')
    if (state.accessToken !== null) {
      state.isAuthenticated = true
    }
  },

  setLoginWithPin (state: any, value: boolean): void {
    localStorage.setItem('loginWithPin', value.toString())
    state.loginWithPin = value
  },

  setRealmRoles (state: any): void {
    state.accessToken = localStorage.getItem('access_token')
    try {
      const tokenParsed = JSON.parse(window.atob(state.accessToken.split('.')[1]))
      state.userData.realmRoles = tokenParsed.realm_access.roles
    } catch (e) {
      console.error('Error while parsing JWT token', e)
    }
  },

  setAllUsers (state: any, users: any): void {
    state.allUsers = users
  },

  setSystemLocked(state: any, value: boolean) {
    state.system.isLocked = value
  },

  clearToken (state: any): void {
    state.accessToken = ''
    state.isAuthenticated = false
  },

  setMobileMenu (state: any, value: boolean) {
    state.mobileMenu = value
  },

  setUser (state: any, user: any): void {
    state.user = { ...state.user, ...user }
  },

  setStatusTimeStamp (state: any, user: any) {
    state.statusTimeStamp = user.statusTimeStamp
    state.usersByUsername[user.username].statusTimeStamp = user.statusTimeStamp
  },

  setAvatar (state: any, avatar: any): void {
    state.avatar = avatar
  },

  setFeedRoom (state: any, room: Room) {
    state.feedRoom = room
  },

  setFeedRoomId (state: any, roomId: string) {
    state.feedRoomId = roomId
  },

  setMyMeetings (state: any, meetings: Meeting[]): void {
    state.myMeetings = meetings
  },

  setSharedMeetings (state: any, meetings: Meeting[]): void {
    state.sharedMeetings = meetings
    const allMeetings = [...state.myMeetings, ...state.sharedMeetings]
    state.immediateMeeting = {}
    for (let i = 0; i < allMeetings.length; i++) {
      const totalTime = moment(allMeetings[i].start).diff(moment(), 'seconds')
      if (totalTime < 300 && totalTime > 0) {
        state.immediateMeeting = allMeetings[i]
      }
    }
  },

	setMeeting (state: any, payload: Meeting): void {
    state.meeting = payload
  },

  setCurrentMeetingList (state: any, payload: Meeting): void {
    state.currentMeetingList = payload
  },

  setAllMembers (state: any, members: any): void {
    state.allMembers = members
  },

  setUserByUsername (state: any, user: any) {
    if (!state.usersByUsername[user.username]) {
      state.usersByUsername[user.username] = { ...user, avatarUrl: state.usersByUsername[user.username]?.avatarUrl }
    }
    state.usersByUsername[user.username].status = user.status
    state.usersByUsername[user.username].statusDescription = user.statusDescription
    if (user.status === UserStatus.UNAVAILABLE) {
      const breakDuration = moment.duration(user.breakDuration, 'minutes')
      state.usersByUsername[user.username].statusTimeStamp =
        statusUtils.getBreakEndTime({
            days: breakDuration.days(),
            hours: breakDuration.hours(),
            minutes: breakDuration.minutes()
          },
          user.statusLastUpdatedAt
        )
    }
  },

  setAvatarByUsername (state: any, user: any) {
    state.usersByUsername[user.username].avatarUrl = user.avatarUrl
  },

  updateUserStatusByUsername (state: any, user: any) {
    state.usersByUsername[user.username].status = user.status
    state.usersByUsername[user.username].statusDescription = user.statusDescription
    if (user.status === UserStatus.UNAVAILABLE) {
      const breakDuration = moment.duration(user.breakDuration, 'minutes')
      state.usersByUsername[user.username].statusTimeStamp =
        statusUtils.getBreakEndTime({
            days: breakDuration.days(),
            hours: breakDuration.hours(),
            minutes: breakDuration.minutes()
          },
          user.statusLastUpdatedAt
        )
    }
  },

  addMeeting (state: any, payload: Meeting): void {
    state.myMeetings.push(payload)
  },

	updateMeeting (state: any, payload: Meeting): void {
    for (let i = 0; i < state.myMeetings.length; i++) {
			if (state.myMeetings[i].id === payload.id) {
				state.myMeetings[i] = payload
			}
		}
  },

  deleteMeeting (state: any, meetingId: string): void {
    state.myMeetings = state.myMeetings.filter((meeting: Meeting) => meeting.id === meetingId)
  },

  toggleModal (state: any): void {
    state.showModal = !state.showModal
  },

  showSuccess (state: any, payload: boolean): void {
    state.showSuccess = payload
  },

  showError (state: any, payload: boolean): void {
    state.showError = payload
  },

  addAttendance (state: any, payload: any): void {
    state.attendance.unshift(payload)
  },

  setLatestAttendance (state: any, payload: any): void {
    state.attendance = payload
  },

  setAttendance (state: any, payload: any): void {
    state.userData.attendance = payload
  },

  setAllAttendance (state: any, payload: any): void {
    payload.forEach((item: any) => {
      item.date = new Date(item.startTime).toLocaleDateString()
    })
    state.attendanceList = ObjectUtils.groupByKey(payload, 'date')
  },

  setTimer (state: any, payload: any): void {
    state.timer = payload
  },

  addTimer (state: any, payload: any): void {
    state.timer.push(payload)
  },

  setTimerByUser (state: any, payload: any): void {
    state.usersByUsername[payload.username].timers = payload.timers
  },

  updateTimer (state: any, payload: any): void {
    state.timer.forEach((item: any, index: number) => {
      if (item.id === payload.id) {
        state.timer[index] = payload
      }
    })
  },

  updateTimerTimeElapsed (state: any, payload: any): void {
    state.timer.forEach((item: any, index: number) => {
      if (item.id === payload.id) {
        state.timer[index].timeElapsed = payload.timeElapsed
      }
    })
  },

  setAllEvents (state: any, events: any): void {
    state.allEvents = events
  },

	setDayEvents (state: any, events: any): void {
    state.dayEvents = events
  },

	setMonthEvents (state: any, events: any): void {
    state.monthEvents = events
  },

	setWeekEvents (state: any, events: any): void {
    state.weekEvents = events
  },

  addEvent (state: any, payload: any): void {
    state.allEvents.push(payload)
  },

  setOrganization (state: any, payload: any): void {
    state.organization = payload
  },

  setRooms (state: any, payload: any): void {
    state.rooms = payload
  },

  setActiveMeetings (state: any, payload: Meeting[]) {
    state.activeMeetings = payload
  },

	setTodoList (state: any, todos: any): void {
    todos[0].tasks = todos[0]?.tasks.sort((a: TodoTask, b: TodoTask) => a.number - b.number)
    state.todoList = todos[0]
  },

	addTodo (state: any, todos: TodoTask[]) {
		state.todoList.tasks = todos.sort((a: TodoTask, b: TodoTask) => a.number - b.number)
    state.todoList = { ...state.todoList }
	},

	updateTodo (state: any, updatedTask: TodoTask) {
    state.todoList.tasks.forEach((task: TodoTask) => {
      if (task.number === updatedTask.number) {
        task = updatedTask
      }
    })
	},

  deleteTodo (state: any, taskToDelete: TodoTask) {
    state.todoList.tasks = state.todoList.tasks.filter((task: any) => task.number !== taskToDelete.number)
	},

  addToEmployeeTimeline (state: any, payload: any): void {
    /** TODO: commented below lines as it is adding duplicates. Check how we can fix this for pagination. */
    // if (state.usersByUsername[payload.username].timeline) {
    //   state.usersByUsername[payload.username].timeline.push(payload.timeline)
    // } else {
    // payload.timeline.forEach((item: any) => {
    //   item.date = moment(item.submittedOn).format('MMMM D')
    // })
    state.usersByUsername[payload.username].timeline = payload.timeline
    if (state.usersByUsername[payload.username].timelineGrouped) {
      state.usersByUsername[payload.username].timelineGrouped[payload.date] = payload.timeline.reverse()
    } else {
      state.usersByUsername[payload.username].timelineGrouped = {
        [payload.date]: payload.timeline.reverse()
      }
    }
  },

  setWebcamshotsByTimestamp(state: any, payload: any) {
    if (state.usersByUsername[payload.username].downloadedWebcamshots) {
      state.usersByUsername[payload.username].downloadedWebcamshots[payload.key] = payload.webcamshot
    } else {
      state.usersByUsername[payload.username].downloadedWebcamshots = {
        [payload.key]: payload.webcamshot
      }
    }
  },

  setEmployeeTimelineItem (state: any, payload: any): void {
    state.usersByUsername[payload.username].timeline.splice(payload.index, 1, payload.payload)
    state.usersByUsername[payload.username].timeline = [...state.usersByUsername[payload.username].timeline]
  },

  setUserTitle (state: any, updatedUser: any) {
    state.user = updatedUser
  },

  setLatestWebcamshot (state: any, payload: any) {
    const currentTime = state.usersByUsername[payload.username].latestWebcamshotCapturedAt
    if (!currentTime || (currentTime && new Date(payload.submittedOn) > new Date(currentTime))) {
      state.usersByUsername[payload.username].latestWebcamshotCapturedAt = payload.submittedOn
      state.usersByUsername[payload.username].latestWebcamshot = payload.webcamshot
    }
  },

  setProfileVideo(state: any, payload: any) {
    const liveVideoRecordIndicator = document.getElementById('liveIndicator')
    /** Do not set video if video record is in progress since we need to show the live recording instead of this video. */
    if (
      !liveVideoRecordIndicator &&
      (!state.usersByUsername[payload.username].profileVideo ||
      new Date(payload.submittedOn) > new Date(state.usersByUsername[payload.username].profileVideo.timestamp))
    ) {
      state.usersByUsername[payload.username].profileVideo = {
        video: payload.video,
        timestamp: payload.submittedOn
      }
    }
  },

  setLatestScreenshot (state: any, payload: any) {
    state.usersByUsername[payload.username].latestScreenshot = payload.screenshot
  },

  isComponentLoading (state: any, payload: boolean): void {
    state.isComponentLoading = payload
  },

  isTimeline (state: any) {
    state.loader.metaData.isWebcamshotLoading = true
    state.loader.metaData.isScreenshotLoading = true
  },

  isPageLoading (state: any, payload: boolean): void {
    state.loader.isPageLoading = payload
  },

  isTimelineLoading (state: any, payload: boolean): void {
    state.loader.isTimelineLoading = payload
  },

  setBreadcrumbs (state: any, payload: boolean): void {
		state.breadcrumbs = payload
  },

  setAllSkills (state: any, payload: string[]) {
    state.dataSources.allSkills = payload
  },

  setConferenceActive (state: any, payload: boolean) {
    state.meetingData.isConferenceActive = payload
  },

  setJitsiVideoCallActive (state: any, payload: any) {
    state.meetingData.isJitsiVideoCallActive = payload.value
    state.meetingData.activeJitsiVideoCallRoom = payload.room
  },

  addHopReport (state: any, payload: any) {
    state.hopReports.push(payload)
  },

  addBugReport (state: any, payload: any) {
    state.bugReports.push(payload)
  },

  setReports (state: any, reports: any) {
    state.hopReports = reports
  },

  setWaveData (state: any, payload: any) {
    state.wave[payload.from] = payload.data
  },

  setNotes (state: any, payload: any) {
    state.notes[payload.from] = payload.data
  },

  setVoiceMessages (state: any, payload: any) {
    state.voiceMessages = payload
  },

  setGlobalSearchQuery (state: any, payload: any) {
    state.search.globalSearchQuery = payload
  },

  setAllEmployeeInfo (state: any, payload: any): void {
    state.user.employeeInfo = payload
  },

  updateEmployeeInfo (state: any, payload: any): void {
    state.employeeInfo = payload
  },

  updateImmediateMeeting (state: any, payload: any) {
    state.immediateMeeting = payload
  },

  setSearchResults(state: any, payload: any) {
    state.searchResults = payload
  },

  setLiveRefreshInProgress(state: any, value: boolean) {
    state.isLiveRefreshInProgress = value
  }
}

export default { ...mutations, ...FloorMutations, ...UserMutations }
