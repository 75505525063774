<template>
	<div class="container h-100 container-content overflow-auto">
		<div class="content-container h-100 w-100 py-3">
			<div class="ps-2 calendar-full-view float-start ms-0 w-75 position-relative">
				<VueCal ref="vuecal"
					class="vuecal--green-theme"
					active-view="week"
					:watchRealTime="true"
					:time-from="0"
					:time-to="24 * 60"
					:time-step="60"
					:disable-views="['years', 'year', 'month']"
					:selected-date="selectedDate"
					:events="weekEventsCalendar"
					@cell-focus="selectedDate = $event"
					@custom-event-creation="customEventCreation"
					@ready="expandedViewCalendar"
					@view-change="expandedViewCalendar">
					<template #title="{ view }">
						<div class="d-flex align-items-center justify-content-between w-100">
							<div class="fw-normal text-start" v-if="view.id === 'day'">
								<div class="fs-18">
									{{ moment(view.startDate).format('D MMMM YYYY') }}
								</div>
								<div class="fs-12">
									{{ moment(view.startDate).format('dddd') }}, Week {{ moment(view.startDate).locale('en-gb').week() }}
								</div>
							</div>
							<div class="fs-18 fw-normal text-start" v-else>
								<div class="fs-18">
									{{
										moment(view.startDate).month() === moment(view.endDate).month()
										? moment(view.startDate).format('MMMM YYYY')
										: moment(view.startDate).year() === moment(view.endDate).year()
											? moment(view.startDate).format('MMMM') + ' - ' + moment(view.endDate).format('MMMM YYYY')
											: moment(view.startDate).format('MMMM YYYY') + ' - ' + moment(view.endDate).format('MMMM YYYY')
									}}
								</div>
								<div class="fs-12">
									Week {{ moment(view.startDate).locale('en-gb').week() }}
								</div>
							</div>
							<button @click="toggleEventModal($event)" class="btn title-btn">
								Add Event
							</button>
						</div>
					</template>
					<template #arrow-prev>
						<img src="@/assets/img/left-arrow.svg" height="12" width="12" alt="<" class="ms-4">
					</template>
					<template #arrow-next>
						<img src="@/assets/img/right-arrow.svg" height="12" width="12" alt=">">
					</template>
				</VueCal>
			</div>
			<div class="calendar-month-view float-start w-25">
				<VueCal
					hide-view-selector
					show-week-numbers
					:time="false"
					active-view="month"
					:disable-views="['years', 'year', 'day', 'week']"
					:selected-date="selectedDate"
					class="vuecal--rounded-theme vuecal--green-theme m-0 mt-1 mb-3 p-0 pe-2"
					:xsmall="true"
					@cell-focus="selectedDate = $event"
					@ready="monthViewCalendar"
					@view-change="monthViewCalendar"
					:events="monthEventsCalendar">
					<template #title="{ title }">
						<div class="d-flex align-items-center justify-content-between w-100">
							<div class="fs-16 fw-normal">{{ title }}</div>
							<button @click="selectedDate = new Date()" class="btn today-btn">
								Today
							</button>
						</div>
					</template>
					<template #arrow-prev>
						<img src="@/assets/img/left-arrow.svg" height="12" width="12" alt="">
					</template>
					<template #arrow-next>
						<img src="@/assets/img/right-arrow.svg" height="12" width="12" alt="">
					</template>
				</VueCal>
				<div class="upcoming-events text-start pe-2">
					<div class="fs-14 m-2">
						Upcoming Events
					</div>
						<div class="event-day-head m-2">
							TODAY
							<div class="count">
								{{ todayEvents.length }}
							</div>
						</div>
						<div v-for="(event, index) in todayEvents" v-bind:key="index"
							:style="{ 'background-color': event.colorPicker }" class="br-8 m-2">
							<div v-if="moment().format('YYYY-MM-DD') == moment(event.start).format('YYYY-MM-DD')"
								class="calendar-event upcoming-event"
								:class="event.class">
								<div class="event-title">
									{{ event.title }}
								</div>
								<div class="event-time">
									{{ moment(event.start).format('HH.mm') }} - {{ moment(event.end).format('HH.mm') }}
								</div>
							</div>
						</div>
						<div class="event-day-head m-2 mt-4">
							TOMORROW
							<div class="count">
								{{ tomorrowEvents.length }}
							</div>
						</div>
						<div v-for="(event, index) in tomorrowEvents" v-bind:key="index" class="br-8 m-2">
							<div v-if="moment().add(1, 'days').format('YYYY-MM-DD') == moment(event.start).format('YYYY-MM-DD')"
								class="calendar-event upcoming-event"
								:class="event.class">
								<div class="event-title">
									{{ event.title }}
								</div>
								<div class="event-time">
									{{ moment(event.start).format('HH.mm') }} - {{ moment(event.end).format('HH.mm') }}
								</div>
							</div>
						</div>
				</div>
			</div>
		</div>
	</div>
	<AddCalendarEvent v-if="isEventModalVisible" @close="toggleEventModal" @toggleEventModal="toggleEventModal" />
</template>
<script src="./Calendar.ts"></script>
<style lang="scss" scoped>
.title-btn:hover,
.title-btn{
	border: 1px solid $blue-80;
	color: $blue-80;
	background: transparent;
	border-radius: 8px;
	padding: 6px 12px;
	width: auto;
}
.event-day-head{
	font-size: 12px;
	color: #656b6e;
	text-transform: uppercase;
	display: flex;
	justify-content: space-between;
	text-align: left;
	.count{
		text-align: right;
	}
}
</style>
