<template>
	<div class="container container-content" v-if="isFeedRoomReady && post.isBeingDecrypted && !post.isBeingDecrypted()">
		<div class="middle-content">
			<div class="clearfix"></div>
			<div v-if="post.getType && post.getType() === 'm.room.message'">
				<div class="feed-content row">
					<div class="col-lg-8 col-md-8 col-sm-12" v-if="post.getContent().msgtype === 'm.image'">
						<img :src="mediaObjectUrl" :alt="post.getContent().body" class="w-100 img-post"/>
					</div>
					<!-- <div v-else-if="post.getContent().msgtype === 'm.text'">
						{{ post.getContent().body }}
					</div> -->
					<div class="mt-2 col-lg-4 col-md-4 col-sm-12">
						<div class="w-100 header d-flex justify-content-start mt-sm-2">
							<div class="d-flex justify-items-start">
								<img :src="avatar" height="40" width="40" alt="" class="rounded-circle">
							</div>
							<div class="w-100">
								<div class="d-flex justify-content-between w-100"><div><h3 class="ms-2 mb-0">{{ post.sender.name }}</h3></div>
								<div class="post-tip fs-14">{{ utilityService.elapsedTime(post.getDate()) }}</div></div>
								<div class="ms-2 w-100">
									<p class="fs-14 text-grey" v-if="post.getContent().msgtype === 'm.image'">
										{{ post.getContent().file.text }}
									</p>
									<p class="fs-14 text-grey" v-else-if="post.getContent().msgtype === 'm.text'">
										{{ post.getContent().body }}
									</p>
								</div>
								<div class="ms-2 w-100"></div>
							</div>
						</div>
						<div class="w-100 d-flex justify-content-start mt-2">
							<div class="w-100"><input class="w-100" type="text" placeholder="Add a comment"></div>
							<div>
								<button class="btn ghost-blue border-0 align-items-center m-2 p-1">Post</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script lang="script" src="./FeedExpanded.ts"></script>
<style lang="scss" scoped src="./FeedExpanded.scss"></style>
