import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const state = {
  organization: [],
	organizationRooms: [],
	organizationMembers: [],
	industries: [],
	companySizes: [],
	teams: [],
  rhythm: {},
  locations: ['Goa', 'Mumbai', 'Banglore', 'Remote'],
  logo: {},
  chatterRoom: null,
  chatterNotificationsCount: 0
}

const onboardingModule = {
  state,
  getters,
  mutations,
  actions
}

export default onboardingModule
