import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import { EventType, IContent, MatrixClient, Room } from 'matrix-js-sdk'
import { getMessageTypeForMedia, uploadFile } from '@/common/services/MxEventContentHandler'
import MxRoom from '@/common/services/MxRoom'

export default defineComponent({
	data () {
		return {
			store: useStore(),
			type: 'POST',
			postImage: '',
			message: '',
			content: {} as IContent,
			isHidden: false,
			matrixRoom_: {} as Room,
			uploadedImage: ''
		}
	},

	methods: {
		displayPostButton (): void {
			this.isHidden = !(this.message === '' && this.isHidden)
		},

		createPost (): void {
			if (!this.content.file) {
				this.content = {
					body: this.message,
					msgtype: 'm.text'
				}
			}
			// added this since button is not displayed instantly after input change
			if (Object.keys(this.content).length !== 0) {
				MxRoom.sendEvent(
					this.matrixClient,
					EventType.RoomMessage,
					this.content,
					this.selectedRoom.roomId
				)
				// this.$emit('sendEvent', EventType.RoomMessage, this.content)
			}
			this.content = {}
			this.message = ''
			this.isHidden = false
			this.uploadedImage = ''
		},

		clickFileUpload () {
			const fileUploadFieldClick = document.getElementById('file-upload')?.click()
			this.isHidden = true
		},

		/** TODO: Use uploadFile method from MxRoom.ts */
		async uploadFile (e: any) {
			/**
			 * TODO: Check for file size
			 */
			if (e.target.files.length) {
				const file = e.target.files[0]
				this.uploadedImage = URL.createObjectURL(file)
				this.content = {
					body: file.name || 'Attachment',
					info: {
						text: file.text,
						size: file.size,
						mimetype: file.type
					},
					msgtype: getMessageTypeForMedia(file)
				}
				const result = await uploadFile(
					this.store.getters.matrixClient,
					this.selectedRoom.roomId,
					file
				)
				this.content.url = result?.file?.url
				this.content.file = result.file
				this.content.file.text = this.message
				this.content.file.mimetype = file.type
			}
		}
	},

	computed: {
    matrixClient (): MatrixClient {
      return this.store.getters.matrixClient
    },

		selectedRoom (): Room {
			return this.store.getters.feedRoom
		},

		user (): any {
			return this.store.getters.user
		},

		avatarUrl (): any {
			return this.store.getters.avatar
		}
	}
})
