
import { MatrixClient } from 'matrix-js-sdk'
import moment from 'moment'
import { defineComponent } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  data() {
    return {
      store: useStore(),
      moment: moment
    }
  },

  methods: {
    getEventSender(roomId: any, eventId: any) {
      const room = this.matrixClient.getRoom(roomId)
      const event = room.findEventById(eventId)
      return event?.sender.name
    },

    getUserAvatar(userId: string): string {
      const username = userId.slice(1, userId.indexOf(':'))
      return this.store.getters.usersByUsername[username]?.avatarUrl
    }
  },

  computed: {
    matrixClient(): MatrixClient {
      return this.store.getters.matrixClient
    },

    globalSearchQuery(): string {
      return this.store.getters.globalSearchQuery
    },

    searchResults(): any {
      return this.store.getters.searchResults
    }
  }
})
