
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
import UtilityService from '@/common/services/TimeUtilityService'

export default defineComponent({
  props: {
    meeting: { type: Object as any, required: true }
  },
  data() {
    return {
      store: useStore(),
      moment: moment,
      utilityService: new UtilityService()
    }
  },

  methods: {
    momentFormat(value: string, format: string): string {
      return moment(value).format(format)
    }
  },

  computed: {
    usersByUsername(): any {
      return this.store.getters.usersByUsername
    }
  }
})
