
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
export default defineComponent({
   data () {
    return {
      selectedPlan: '' as any,
			selectedAdvance: '' as any,
			selectedReview: '' as any
    }
  },

   props: {
    list: { type: Object as any, required: true }
  },
  methods: {
    close (event: any) {
      event.stopPropagation()
      this.$emit('close', event)
    },

    sendSelected (event: any): any {
			if (this.selectedPlan && this.selectedAdvance && this.selectedReview) {
				this.$emit('selected', event, this.selectedPlan, this.selectedAdvance, this.selectedReview)
			}
    },

    selectSprintPlan (event: MouseEvent, plan: string, type: any): any {
			if (type === 'plan_every') {
				this.selectedPlan = plan
			} else if (type === 'in_advance') {
				this.selectedAdvance = plan
			} else {
				this.selectedReview = plan
			}
    }
  }
})
