import router from '@/router'
import store from '@/store'
import axios from 'axios'
import crypto from 'crypto'

export default {
  base64URLEncode (str: any) {
    return str.toString('base64').replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '')
  },

  sha256_ASCII (buffer: any) {
    return crypto.createHash('sha256').update(buffer).digest('base64').toString()
  },

  authorize () {
    store.dispatch('isZoomAuthenticated').then(isAuthenticated => {
      if (isAuthenticated) {
        router.push('/new-meeting')
      } else {
        axios.put('blox/zoom/challenge').then((res: any) => {
          localStorage.setItem('isZoomRedirection', 'true')
          window.open(
            'https://zoom.us/oauth/authorize?response_type=code' +
            `&client_id=${process.env.VUE_APP_ZOOM_CLIENT_ID}` +
            `&redirect_uri=${process.env.VUE_APP_ZOOM_AUTH_REDIRECT_URL}` +
            `&code_challenge=${res.codeChallenge}`,
            '_self')
        })
      }
    })
  }
}
