
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import 'vue3-carousel/dist/carousel.css'
import UtilityService from '@/common/services/TimeUtilityService'
import { FreeStyleShimmer } from 'vue3-shimmer'
import moment from 'moment'
import History from './History.vue'
import ApiService from '@/common/services/api/ApiService'
import Webcamshots from '@/components/Webcamshots.vue'
import ObjectUtils from '@/common/services/ObjectUtils'

export default defineComponent({
  props: {
    employee: { type: String, required: true }
  },

  components: {
    History,
    FreeStyleShimmer,
    Webcamshots
  },

  data() {
    return {
      store: useStore(),
      moment: moment,
      ObjectUtils: ObjectUtils,
      selectedWeek: moment().week(),
      selectedYear: moment().year(),
      selectedTimelines: null,
      selectedTimelineDate: '',
      apiService: new ApiService(),
      utilityService: new UtilityService(),
      timeSliderValue: 0,
      currentAssignee: '',
      activeSlideIndex: 0,
      activeModal: '',
      selectedItem: {} as any,
      currentShot: {} as any,
      expandTimeline: true,
      facesFound: true, // Checks if selected webcamshot has a face in it.
      faces: [[0, 0, 0, 0]],
      dateRange: [] as any// Dates to display user stats including off-days since timelineData doesn't include off-days.
    }
  },

  mounted() {
    this.getDateRange()
  },

  methods: {
    scrollLeft(id: string, value: number) {
      document.getElementById(id)!.scrollLeft += value
    },

    momentFormat(value: string, format: string): string {
      return moment(value).calendar(null, {
        sameDay: '[Today]',
        lastDay: '[Yesterday]',
        lastWeek: format,
        sameElse: format
      })
    },

    showWebcamshots(timelines: any, date: any) {
      this.selectedTimelines = null
      setTimeout(() => {
        this.selectedTimelines = timelines
        this.selectedTimelineDate = date
        console.log('selected', this.selectedTimelineDate)
        timelines.forEach((item: any) => {
          this.store.dispatch('downloadWebcamshot', {
            username: this.employee,
            submittedOn: item.submittedOn,
            webcamshot: item.webcamshot
          })
        })
      }, 500)
    },

    getDateRange() {
      const today = moment()
      const startDate = moment(today).startOf('week')
      for (let d = today; d >= startDate; d = d.subtract(1, 'days')) {
        this.dateRange.push(moment(d))
      }
    },

    slideSync(index: number) {
      (this.$refs.webcam as any).goTo(index);
      (this.$refs.screenshots as any).goTo(index);
      (this.$refs.slider as any).goTo(index)
    },

    openItem(e: MouseEvent, item: any) {
      this.selectedItem = item
      this.facesFound = true
    },

    openImagePopup(e: MouseEvent, index: any, popup: string, shot: any) {
      this.activeSlideIndex = index
      this.currentShot = shot
      this.activeModal = popup
      e.stopPropagation()
    },

    closeImagePopup(e: MouseEvent) {
      this.activeModal = ''
      e.stopPropagation()
    },

    sortedTimeline(timeline: any) {
      timeline.sort((a: any, b: any) => {
        return (a.submittedOn > b.submittedOn) ? -1 : ((a.submittedOn < b.submittedOn) ? 1 : 0)
      })
      return timeline
    },

    calculateTimeGap(prev: any, current: any, index: any, groupIndex: any, grouped: any) {
      let width = 0
      let margin = 0
      console.log('calculating....')
      if (current[index + 1]) {
        const startTime = moment(prev.submittedOn * 1000)
        const endTime = moment(current[index + 1].submittedOn * 1000)
        const totalDiff = endTime.diff(startTime, 'seconds')
        if (totalDiff > 120) {
          width = totalDiff / 120
        } else {
          width = 1
        }
        // from midnight
        startTime.set('hours', 0)
        startTime.set('minutes', 0)
        startTime.set('seconds', 0)
        margin = endTime.diff(startTime, 'seconds') / 120 - width
        // minus width because of LIFO
      } else if (grouped[groupIndex + 1]) {
        const startTime = moment(prev.submittedOn * 1000)
        const endTime = moment(grouped[groupIndex + 1][0].submittedOn * 1000)
        const totalDiff = endTime.diff(startTime, 'seconds')
        if (totalDiff > 120) {
          width = totalDiff / 120
        } else {
          width = 1
        }
        // from midnight
        startTime.set('hours', 0)
        startTime.set('minutes', 0)
        startTime.set('seconds', 0)
        margin = endTime.diff(startTime, 'seconds') / 120 - width
      }
      return 'width: ' + width + 'px; margin-left: ' + margin + 'px'
    },

    gotoWeek(week: string) {
      /** TODO: Refactor below conditions */
      if (week === 'next') {
        const lastDayOfYear = moment().year(this.selectedYear).endOf('year')
        const lastWeekOfSelectedYear = moment(lastDayOfYear).week()
        if (lastWeekOfSelectedYear === this.selectedWeek) {
          this.selectedWeek = 1
          this.selectedYear += 1
        } else if (this.selectedYear === moment().year()) {
          if (this.selectedWeek !== moment().week()) {
            this.selectedWeek += 1
          }
        } else {
          this.selectedWeek += 1
        }
      } else {
        if (this.selectedWeek === 1) {
          this.selectedYear -= 1
          const lastDayOfYear = moment().year(this.selectedYear).endOf('year')
          this.selectedWeek = moment(lastDayOfYear).week()
        } else {
          this.selectedWeek -= 1
        }
      }
      this.store.dispatch('getFilteredEmployeeTimeline', {
        username: this.employee,
        week: this.selectedWeek,
        year: this.selectedYear
      })
      if (this.selectedWeek === moment().week() && this.selectedYear === moment().year()) {
        this.dateRange = this.utilityService.getDatesInWeekTillToday()
      } else {
        this.dateRange = this.utilityService.getDatesInWeek(this.selectedWeek, this.selectedYear)
      }
    }
  },

  computed: {
    timeline() {
      return this.usersByUsername[this.employee]?.timeline
    },

    timelineData() {
      return this.usersByUsername[this.employee]?.timelineGrouped
    },

    assignees(): any {
      return this.store.getters.allMembers
    },

    usersByUsername() {
      return this.store.getters.usersByUsername
    },

    isScreenshotLoading(): any {
      return this.store.getters.isScreenshotLoading
    },

    isWebcamshotLoading(): any {
      return this.store.getters.isWebcamshotLoading
    }
  }
})
