import moment from 'moment'

export default class UtilityService {
	/** Returns (for example): '12:30 PM' */
  formatTime (date: string): string | undefined {
    if (date) {
      return (new Date(date)).toLocaleTimeString([], { hour12: true, hour: '2-digit', minute: '2-digit' })
      .replace('am', 'AM').replace('pm', 'PM')
    }
  }

	calendarTime (calendarDate: any): string {
		// TODO: Change the function to TimeStringFormat
		const date = new Date(calendarDate)
    const mnth = ('0' + (date.getMonth() + 1)).slice(-2)
		const day = ('0' + date.getDate()).slice(-2)
		const hours = ('0' + date.getHours()).slice(-2)
		const minutes = ('0' + date.getMinutes()).slice(-2)
    const calDate = [date.getFullYear(), mnth, day].join('-')
    const calTime = [hours, minutes].join(':')
    return [calDate, calTime].join(' ')
	}

	calculateTimeDiff (start: string, end: string) {
		/** return (for example) '0h30m' */
		const startTime = moment(start)
		const endTime = moment(end)
		const totalHours = endTime.diff(startTime, 'hours')
		const totalMinutes = endTime.diff(startTime, 'minutes')
		const clearMinutes = totalMinutes % 60
		return totalHours + 'h' + clearMinutes + 'm'
	}

	convertToMinutes (start: string, end: string) {
		/** return (for example) '30' */
		const startTime = moment(start)
		const endTime = moment(end)
		const totalTime = endTime.diff(startTime, 'minutes')
		return totalTime.toString()
	}

	elapsedTime (date: string) {
		/** can use this function if you want to display months, days and years in full string */
    if (date) {
      const now = moment(new Date())
      const startTime = moment(date)
      const minutes = now.diff(startTime, 'minutes')
      const hours = now.diff(startTime, 'hours')
      const days = now.diff(startTime, 'days')
      const weeks = now.diff(startTime, 'weeks')
      const months = now.diff(startTime, 'months')
      const years = now.diff(startTime, 'years')

      if (minutes === 0) {
        return 'now'
      } else if (minutes <= 60 && minutes >= 1) {
        return minutes + 'min' + ' ' + 'ago'
      } else if (hours <= 24 && hours >= 1) {
        return hours + 'h' + ' ' + 'ago'
      } else if (days <= 7 && days >= 1) {
        return days + 'd' + ' ' + 'ago'
      } else if (weeks <= 4 && weeks >= 1) {
        return weeks + 'w' + ' ' + 'ago'
      } else if (months <= 12 && months >= 1) {
        return months + 'mo' + ' ' + 'ago'
      } else {
        return years + 'y' + ' ' + 'ago'
      }
    }
	}

	getFormattedHopDate (to: Date) {
    const from = new Date(to)
    from.setDate(from.getDate() - 1)
    return `${from.getDate()} - ${to.getDate()} ${to.toLocaleString('default', { month: 'long' })}, ${to.getFullYear()}`
  }

  CalculateDuration (start: any, end: any) {
    const startTime = moment(start)
		const endTime = moment(end)
		const totalHours = endTime.diff(startTime, 'hours')
		const totalMinutes = endTime.diff(startTime, 'minutes')
		const clearMinutes = totalMinutes % 60
		return totalHours + '0:' + clearMinutes + ':00'
  }

  getDatesInWeek(week: number, year: number) {
    const datesInWeek = []
    const startDate = moment().year(year).day('Sunday').week(week)
    const endDate = moment(startDate).endOf('week')
    if (startDate !== endDate) {
      for (let d = endDate; d >= startDate; d = d.subtract(1, 'days')) {
        datesInWeek.push(moment(d))
      }
    } else {
      datesInWeek.push(moment(startDate))
    }
    return datesInWeek
  }

  getDatesInWeekTillToday() {
    const datesInWeek = []
    const today = moment()
    const startDate = moment(today).startOf('week')
    for (let d = today; d >= startDate; d = d.subtract(1, 'days')) {
      datesInWeek.push(moment(d))
    }
    return datesInWeek
  }
}
