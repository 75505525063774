
import { version } from '../../package.json'
import releaseNotes from '../../release-notes.json'
import { defineComponent } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  data () {
    return {
      store: useStore(),
      currentVersion: version,
      notes: releaseNotes
    }
  },

  methods: {
		//
  },

  computed: {
    user (): any {
      return this.store.getters.user
    }
  }
})
