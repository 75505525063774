import axios from 'axios'
import KeyGenerator from './KeyGenerator'

const TIMEOUT = 1000000
const multipartContentType = 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW'
const onRequestSuccess = (config: any) => {
  config.timeout = TIMEOUT

  if (config.url.includes('api.pyimagesearch.com')) {
    return config
  }

	if (config.url.includes(process.env.VUE_APP_S3_BUCKET)) {
		config.headers.common['Access-Control-Allow-Origin'] = '*'
		config.headers.common['Access-Control-Allow-Credentials'] = true
		config.headers.common['Access-Control-Allow-Methods'] = 'GET,PUT,POST,PATCH,DELETE,OPTIONS'
		config.headers.common['Access-Control-Allow-Headers'] = 'Content-type, X-Access-Token, X-Key, Access-Control-Allow-Headers, Access-Control-Allow-Origin'
		return config
	}

  if (!config.url.includes(process.env.VUE_APP_MATRIX_HOMESERVER)) {
    // config.headers.uri = '/' + config.url
    config.url = `${process.env.VUE_APP_API_URL}/${config.url}`
    config.headers.common.Authorization = 'Bearer ' + localStorage.getItem('access_token')
    // config.headers.clientId = KeyGenerator.clientId()
    // config.headers.method = config.method

    // if (config.data && !config.url.includes('/exchange') &&
    //     config.headers['Content-Type'] !== multipartContentType
    // ) {
    //   config.data = { payload: KeyGenerator.encrypt(config.data) }
    // }

    if (process.env.NODE_ENV === 'production') {
      config.headers.common['Access-Control-Allow-Origin'] = '*'
      config.headers.common['Access-Control-Allow-Credentials'] = true
      config.headers.common['Access-Control-Allow-Methods'] = 'GET,PUT,POST,PATCH,DELETE,OPTIONS'
      config.headers.common['Access-Control-Allow-Headers'] = 'Content-type, X-Access-Token, X-Key, Access-Control-Allow-Headers, Access-Control-Allow-Origin'
    }
  } else {
    // Matrix APIs don't require CORS
    config.url = `https://${process.env.VUE_APP_MATRIX_HOMESERVER}/${config.url}`
  }
  return config
}

const setupAxiosInterceptors = () => {
  const onResponseSuccess = (res: any) => {
    // if (
    //   res.config.url.startsWith(process.env.VUE_APP_API_URL) &&
    //   // res.config.headers['Content-Type'] !==  &&
    //   res.data &&
    //   res.headers['content-type'] !== multipartContentType &&
    //   res.headers['content-type'] !== 'image/jpeg' &&
    //   !res.config.url.includes('/exchange')
    // ) {
    //   res.data = JSON.parse(KeyGenerator.decrypt(res.data))
    // }
    return res
  }

  const onResponseError = (err: any) => {
    console.log('API error...!!', err)
    return Promise.reject(err)
  }

  if (axios.interceptors) {
    axios.interceptors.request.use(onRequestSuccess)
    axios.interceptors.response.use(onResponseSuccess, onResponseError)
  }
}

export { onRequestSuccess, setupAxiosInterceptors }
