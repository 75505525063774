import axios, { AxiosPromise } from 'axios'

export default class ChatsApiService {
  // Login to element using SSO login token
  loginToElement (loginToken: string): AxiosPromise<any> {
    return axios.post('_matrix/client/r0/login', {
      type: 'm.login.token',
      token: loginToken
    })
  }
}
