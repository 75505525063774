
import { defineComponent, PropType } from 'vue'
import { useStore } from 'vuex'

export interface UserMultiselectItem {
  name: string,
  avatarUrl: string,
  username?: string,
  isChecked: boolean
}

export default defineComponent({
  props: {
    title: { type: String, required: true },
    buttonLabel: { type: String, required: true },
    list: { type: Object as PropType<UserMultiselectItem[]>, required: true },
    selected: { type: Object as PropType<UserMultiselectItem[]>, required: true },
    metadata: {},
    assignee: { type: String, required: false }
  },

  data() {
    return {
      /** Copy only value */
      selectedList: [...this.selected],
      store: useStore(),
      selectedAssignee: '' as any
    }
  },

  methods: {
    onSelect(item: UserMultiselectItem, event: any): void {
      if (event.target.checked) {
        this.selectedList.push({
          avatarUrl: item.avatarUrl,
          name: item.name,
          username: item.username,
          isChecked: true
        })
      } else {
        this.selectedList = this.selectedList.filter(
          (obj: UserMultiselectItem) => obj.username !== item.username
        )
        var selectAllEl = document.getElementById('select-all') as HTMLInputElement
        selectAllEl.indeterminate = true
      }
      if (this.title === 'Add Assignee') {
        this.selectedAssignee = item.username
        this.selectedList = this.selectedList.filter(
          (obj: UserMultiselectItem) => obj.username === item.username
        )
      }
    },

    selectAll() {
      this.selectedList = []
      this.list.forEach((item: any) => {
        this.selectedList.push({
          avatarUrl: item.avatarUrl,
          name: item.name,
          username: item.username,
          isChecked: true
        })
      })
    },

    isSelected(item: UserMultiselectItem): boolean {
      return !!(this.selectedList.find((obj: UserMultiselectItem) => obj.username === item.username))
    },

    sendSelected(event: MouseEvent): void {
      this.$emit('finished', this.selectedList, event, this.metadata)
      if (this.title === 'Add Assignee') {
        this.$emit('addedAssignee', this.selectedAssignee, event)
      }
    },

    closeMe(event: MouseEvent) {
      event.stopPropagation()
      this.$emit('close', event)
    }
  },

  computed: {
    usersByUsername(): any {
      return this.store.getters.usersByUsername
    }
  }
})
