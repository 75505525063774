
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
      image: { type: String, required: true }
  },

  methods: {
    close (): void {
        this.$emit('close')
    }
  }
})
