import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import CreatePost from '../../modules/feed/components/create-post/CreatePost.vue'
export default defineComponent({
	components: { CreatePost },

	data () {
		return {
			store: useStore(),
			isVisible: true
		}
	},

	methods: {
		close (e: any) {
			this.$emit('close', e)
		}
	}

})
