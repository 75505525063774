import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mx-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.reactions, ([key, value]) => {
    return (_openBlock(), _createElementBlock("div", {
      class: "reaction",
      key: key
    }, [
      _createTextVNode(_toDisplayString(key) + " ", 1),
      (value.size > 1)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(value.size), 1))
        : _createCommentVNode("", true)
    ]))
  }), 128))
}