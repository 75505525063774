
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import { UnavailableStatusReasons, DefaultStatusDurations } from '@/common/constants/CommonConstants'
import StatusUtils from '@/common/services/StatusUtils'

export default defineComponent({
  data() {
    return {
      store: useStore(),
      statusUtils: new StatusUtils(),
      statusReasons: UnavailableStatusReasons,
      statusDurations: DefaultStatusDurations,
      reason: '',
      duration: {} as any
    }
  },

  methods: {
    close(e: MouseEvent): void {
      e.stopPropagation()
      this.$emit('close')
    },

    selectReason(reason: string, duration: any) {
      this.reason = 'Out for ' + reason
      this.duration = duration
    },

    setDuration(duration: any) {
      this.duration = duration
    },

    save() {
      this.statusUtils.setStausUnavailable(this.user, this.reason, this.duration)
      this.$emit('close')
    }
  },

  computed: {
    user(): any {
      return this.store.getters.user
    }
  }
})
