import { MatrixClient, Room } from 'matrix-js-sdk'

const mutations = {
  setMatrixClient (state: any, matrixClient: MatrixClient) {
    state.matrixClient = matrixClient
  }

	// setFeedRoom (state: any, room: Room) {
  //   state.feedRoom = room
  // }
}

export default mutations
