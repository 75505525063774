import store from '@/store'
import { MatrixEvent, Room, EventType, NotificationCountType, MatrixClient } from 'matrix-js-sdk'
import moment from 'moment'
import { CustomMxEvent, MeetingPreference } from '../enums'
import StatusUtils from './StatusUtils'
import NotificationHandler from './NotificationHandler'

export default {
  feedRoomId: store.getters.feedRoomId,

  user(): any {
    return store.getters.user
  },

  matrixClient (): MatrixClient {
    return store.getters.matrixClient
  },

  listenEvents () {
    this.matrixClient().on('Room.timeline', async (event: MatrixEvent, room: Room) => {
      if (this.matrixClient().isRoomEncrypted(room.roomId)) await room.decryptAllEvents()
      this.handleCustomEvents(event, room)
      if (event.getRoomId() === this.feedRoomId) {
        this.updateFeedRoom()
      } else {
        this.updateRooms()
      }

      if (
        room &&
        room.getUnreadNotificationCount(NotificationCountType.Total)! > 0 &&
        event.getType() === 'm.room.message'
      ) {
        NotificationHandler.notify(room, event)

        if (event.getContent().msgtype === 'm.file') {
          /** Play audio to notify file drop. */
          const audio = document.getElementById('fileDropAudio') as HTMLAudioElement
          audio.play()
        }
      }
    })

    this.matrixClient().on('Event.decrypted', (event: MatrixEvent) => {
      this.handleDecryptionEvent(event)
    })

    /** Auto-join rooms when invite is received. */
    this.matrixClient().on('Room.myMembership', (room: Room, membership: string) => {
      this.handleRoomMembershipEvent(room, membership)
    })
  },

  handleDecryptionEvent (event: MatrixEvent) {
    this.updateRooms()
    this.updateFeedRoom()
    const room = this.matrixClient().getRoom(event.getRoomId()!)
    if (
      room &&
      room.getUnreadNotificationCount(NotificationCountType.Total)! > 0 &&
      event.getType() === 'm.room.message'
    ) {
      const nofitication = new Notification(
        'Chats', {
          body: `${room?.getUnreadNotificationCount(NotificationCountType.Total)} new message(s)`
        }
      )
    }
  },

  /** Custom events added to handle some requirements through matrix events. */
  async handleCustomEvents (event: MatrixEvent, room: Room) {
    if (event.getType() === EventType.RoomMessage) {
      const content = event.getContent()
      const eventTime = moment(content.timestamp)

      /** Only event content has details about the custom event. */
      if (content.msgtype === CustomMxEvent.NOTIFICATION && content.metadata &&
          content.metadata?.to === this.user().username && moment().diff(eventTime, 'seconds') <= 10
      ) {
        if (store.getters.user.status !== 'UNAVAILABLE' &&
            store.getters.user.status !== 'LEFT' &&
            store.getters.user.status !== 'AWAY' &&
            !store.getters.isLocked
        ) {
          new StatusUtils().updateStatusAndTimeline()
          store.commit('setLiveRefreshInProgress', true)
          setTimeout(() => {
            store.dispatch('getLatestEmployeeTimeline').then(() => {
              store.commit('setLiveRefreshInProgress', false)
            })
          }, 20000)
        }
      } else if (content.msgtype === CustomMxEvent.WAVE && content.metadata &&
          content.metadata?.to === this.user().username
      ) {
        store.dispatch('setWaveData',
        {
          from: content.metadata.from,
          data: {
            content,
            roomId: room.roomId,
            eventId: event.getId()
          }
        })
      } else if (content.msgtype === CustomMxEvent.NOTE && content.metadata &&
          content.metadata?.to === this.user().username
      ) {
        store.dispatch('setNotes',
        {
          from: content.metadata.from,
          data: {
            content,
            roomId: room.roomId,
            eventId: event.getId()
          }
        })
      } else if (content.msgtype === CustomMxEvent.VOICE_MESSAGE && content.metadata &&
        content.metadata?.to === this.user().username
    ) {
      store.dispatch('setVoiceMessages',
      {
        content,
        roomId: room.roomId,
        eventId: event.getId()
      })
    } else if (content.msgtype === CustomMxEvent.DROP_IN && content.metadata &&
      content.metadata?.to === this.user().username && moment().diff(eventTime, 'seconds') <= 10) {
        store.commit('setJitsiVideoCallActive', {
          value: true, room: content.metadata?.room
        }
      )
      this.ringForDropIn()
    } else if (
      content.msgtype === CustomMxEvent.MEETING_STARTED && content.metadata &&
      content.metadata?.to === this.user().username && moment().diff(eventTime, 'seconds') <= 30
    ) {
      store.dispatch('getMeeting', content.metadata.meeting_id).then((meeting: any) => {
        const timeToMeeting = moment(meeting.start).diff(moment(), 'seconds')
        if (
          meeting.preferences.includes(MeetingPreference.AUTO_JOIN) &&
          timeToMeeting <= 0
        ) {
          store.commit('setConferenceActive', true)
        }
      })
    } else if (
      content.msgtype === CustomMxEvent.NEW_MEETING && content.metadata &&
      content.metadata?.to === this.user().username && moment().diff(eventTime, 'seconds') <= 30
    ) {
      store.dispatch('getSharedMeetings')
    } else if (
      content.msgtype === CustomMxEvent.EMPLOYEE_ONLINE && content.metadata &&
      content.metadata?.to === this.user().username && moment().diff(eventTime, 'seconds') <= 300
    ) {
      store.dispatch('getLatestEmployeeTimeline')
      try {
        await Notification.requestPermission()
        const nofitication = new Notification(
          'Notifications', {
            body: `Status Update: ${content.metadata.fromUserName} is online now`
          }
        )
      } catch (e: any) {
        console.error('failed notif', e)
      }
    }
  }
  },

  ringForDropIn() {
    const audio = document.getElementById('dropInAlert') as HTMLAudioElement
    audio.play()
  },

  handleRoomMembershipEvent (room: Room, membership: string) {
    if (membership === 'join') {
      this.matrixClient().joinRoom(room.roomId).then(() => {
        this.updateRooms()
      })
    }
  },

  async updateRooms () {
    const matrixRooms_ = this.matrixClient().getRooms()

    /** Sorting rooms. */
    const tsOfNewestEvent = function tsOfNewestEvent (room: Room) {
      if (room.timeline.length) {
        return room.timeline[room.timeline.length - 1].getTs()
      }
      return Number.MAX_SAFE_INTEGER
    }

    matrixRooms_.sort((a: any, b: any) => tsOfNewestEvent(b) - tsOfNewestEvent(a))
    const filtered = matrixRooms_.filter((room: Room) => {
      if (room.roomId !== store.getters.organization.organizationChatterSpaceId) {
        store.commit('setOrgChatterRoom', room)
      }
      if (room.roomId !== store.getters.organization.organizationNotificationSpaceId
      ) {
        return room
      }
    })
    store.commit('setMatrixRooms', filtered)
  },

  async updateFeedRoom () {
    const room = this.matrixClient().getRoom(this.feedRoomId)
    if (room) {
      await room.decryptAllEvents()
      store.commit('setFeedRoom', room)
    }
  }
}
