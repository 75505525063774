
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import AllTeams from './AllTeams.vue'

export default defineComponent({
  components: { AllTeams },
  data () {
    return {
      store: useStore()
    }
  }
})
