
import { MatrixClient } from 'matrix-js-sdk'
import { Preset, Visibility } from 'matrix-js-sdk/lib/@types/partials'
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import UserMultiselectModal from '@/common/components/UserMultiselectModal.vue'

export default defineComponent({
  components: { UserMultiselectModal },
  data () {
    return {
      store: useStore(),
      showUsersList: false,
      usersList: [],
      selectedUsers: [],
      createOpts: {
        name: '',
        topic: '',
        encryption: false, /** Creating encryption disabled rooms by default. */
        preset: Preset.PrivateChat,
        visibility: Visibility.Private, /** Public channel option is not provided now. */
        invite: [],
        is_direct: false,
        initial_state: [
          // { TODO: Disabling encryption for now.
          //   type: EventType.RoomEncryption,
          //   state_key: '',
          //   content: {
          //     algorithm: MEGOLM_ALGORITHM
          //   }
          // }
        ]
      }
    }
  },

  mounted () {
    this.usersList = this.orgMembers.filter((user: any) =>
      user.username !== this.user.username
    )
  },

  methods: {
    createChannel (event: MouseEvent) {
      this.matrixClient.createRoom(this.createOpts).then(() => {
        this.closeMe(event)
      })
    },

    toggleMembersModal (event: MouseEvent) {
      event!.stopPropagation() // To prevent triggering outside click on this modal on clicking members modal close button.
      this.showUsersList = !this.showUsersList
    },

    selectUsers (selected: any, event: any) {
			this.toggleMembersModal(event)
      this.selectedUsers = selected.filter((user: any) =>
        user.name !== '' && user.username !== this.user.username
      );

      (this.createOpts.invite as string[]) = this.selectedUsers.map((user: any) =>
        '@' + user.username + ':' + process.env.VUE_APP_MATRIX_HOMESERVER
      )
    },

    closeMe (event: any) {
      event.stopPropagation()
      if (!this.showUsersList) {
        this.$emit('close', event)
      }
    }
  },

  computed: {
    users (): string {
      return this.selectedUsers.map((user: any) => user.name).join(', ')
    },

    matrixClient (): MatrixClient {
      return this.store.getters.matrixClient
    },

    user (): any {
      return this.store.getters.user
    },

    orgMembers(): any {
      return this.store.getters.organizationMembers
    }
  }
})
