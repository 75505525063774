
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
import StyleUtils from '@/common/services/StyleUtils'

export default defineComponent({
	data () {
		return {
			store: useStore(),
			moment: moment,
			styleUtils: new StyleUtils(),
			agendas: [
            {
							id: '1',
              title: 'agenda-1',
              duration: '30m'
            },
            {
							id: '2',
              title: 'agenda-2',
              duration: '15m'
            },
						{
							id: '3',
              title: 'agenda-3',
              duration: '25m',
							description: 'Lorem Ipsum doler sit amet adipiscer'
            }
        ]
		}
	}
})
