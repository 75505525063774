import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0993e4fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-backdrop whiteboard-modal" }
const _hoisted_2 = {
  class: "modal",
  id: "white-board-modal",
  role: "dialog",
  "aria-labelledby": "modalTitle",
  "aria-describedby": "modalDescription"
}
const _hoisted_3 = {
  class: "modal-header",
  id: "modalTitle"
}
const _hoisted_4 = {
  class: "modal-body",
  id: "modalDescription"
}
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "modal-fade" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("header", _hoisted_3, [
            _renderSlot(_ctx.$slots, "header", {}, () => [
              _createTextVNode(" Whiteboard ")
            ], true),
            _createElementVNode("button", {
              class: "btn-fullscreen position-absolute",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.expandWhiteboard()))
            }, "fullscreen")
          ]),
          _createElementVNode("section", _hoisted_4, [
            _createElementVNode("iframe", {
              src: _ctx.whiteboardUrl,
              frameborder: "0"
            }, null, 8, _hoisted_5)
          ])
        ])
      ])
    ]),
    _: 3
  }))
}