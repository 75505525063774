
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import UtilityService from '@/common/services/TimeUtilityService'

export default defineComponent({
  data() {
    return {
      store: useStore(),
      utilityService: new UtilityService(),
      currentHop: {
        today: new Date(),
        from: new Date(),
        to: new Date()
      },
      selectedUser: '',
      selectedReports: {} as any,
      currentHopReports: [] as any,
      nextHopReports: [] as any,
      reportTableFields: {
        submittedOn: document.getElementById('')
      }
    }
  },

  mounted() {
    this.store.dispatch('getReports')
    this.getCurrentHop()
    this.selectedReports = this.store.getters.hopReports[Object.keys(this.store.getters.hopReports)[0]]
    this.selectedUser = this.organizationMembers[0].username
  },

  computed: {
    reports(): any {
      return this.store.getters.hopReports
    },

    currentHopReportsData(): any {
      return this.currentHopReports
    },

    nextHopReportsData(): any {
      return this.nextHopReports
    },

    organizationMembers(): any {
      return this.store.getters.organizationMembers
    },

    usersByUsername(): any {
      return this.store.getters.usersByUsername
    }
  },

  methods: {
    getHopDate(date: string) {
      const formatted = new Date(date)
    },

    getCurrentHop() {
      const date = new Date()
      const today = date.getDay()
      if (today === 1 || today === 3 || today === 5) {
        const nextDate = new Date()
        nextDate.setDate(date.getDate() + 1)
        this.currentHop = {
          today: date,
          from: date,
          to: nextDate
        }
      } else {
        const nextDate = new Date()
        nextDate.setDate(date.getDate() - 1)
        this.currentHop = {
          today: date,
          from: nextDate,
          to: date
        }
      }
    },

    filterReports(username: string) {
      this.selectedUser = username
      this.selectedReports = this.reports[username]
    }
  }

})
