
import { defineComponent, getCurrentInstance, onMounted } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
import { MatrixClient, MatrixEvent, Room, NotificationCountType } from 'matrix-js-sdk'
import MxRoom from '@/common/services/MxRoom'
import { CustomMxEvent } from '@/common/enums'
import { EmojiButton } from '@joeattardi/emoji-button'
import emojiRegex from 'emoji-regex'

export default defineComponent({

  data() {
    return {
      moment: moment,
      store: useStore(),
      message: '',
      selectedEmoji: '',
      emojiPicker: new EmojiButton({
        theme: 'dark'
      }),
      isHistoryLoading: false,
      isHidden: true,
      emojiRegEx: emojiRegex(),
      userScrolling: false,
      currentIndex: 0,
      NotificationCountType: NotificationCountType,
      placeholders: ['Send a message.', 'Send a message..', 'Send a message...'] as any,
      emitter: getCurrentInstance()?.appContext.config.globalProperties.emitter
    }
  },

  updated() {
    if (!this.isHistoryLoading && !this.userScrolling) {
      this.scrollToBottom()
    }
  },

  mounted() {
    const scrollContainer = document.getElementById('chat-container')

    let scrollTimeout = 0

    if (scrollContainer) {
      scrollContainer!.addEventListener('scroll', (e: any) => {
        clearTimeout(scrollTimeout)

        this.userScrolling = true

        scrollTimeout = setTimeout(() => {
          this.userScrolling = false
        }, 3000) // Scroll detection 3s timeout
      })
    }

    setInterval(() => {
      if (!this.userScrolling) {
        this.currentIndex++
      }
    }, 500)

    this.emojiPicker.on('emoji', (selection: any) => {
      this.sendEmoji(selection.emoji)
    })
    this.scrollToBottom()

    const scrollElement = document.getElementById('chat-container')

    if (scrollElement) {
      scrollElement.addEventListener('scroll', (e: any) => {
        if (scrollElement.scrollTop === 0) {
          this.matrixClient.scrollback(this.chatterRoom)
          this.isHistoryLoading = true
        }
      })
    }
  },

  methods: {
    toggleHide() {
      this.isHidden = !this.isHidden
      if (!this.isHidden) {
        MxRoom.clearNotifications(this.matrixClient, this.chatterRoom)
        this.store.commit('setChatterNotificationsCount', 0)
      }
    },

    isEmoji(text: string) {
      const matches = text.match(this.emojiRegEx)
      return matches && matches.length === 1 && matches[0] === text
    },

    scrollToBottom() {
      const element = document.getElementById('chat-container')
      element!.scrollTop = element!.scrollHeight
    },

    getUserAvatar(userId: string): string {
      const username = userId.slice(1, userId.indexOf(':'))
      return this.usersByUsername[username]?.avatarUrl
    },

    getSenderDetails(userId: string): any {
      const username = userId.slice(1, userId.indexOf(':'))
      return this.usersByUsername[username]
    },

    send() {
      // this.message = this.user.name + ': ' + this.message
      if (this.message) {
        MxRoom.sendMessageToChatter(this.matrixClient, this.message, CustomMxEvent.CHATTER_MESSAGE)
        this.message = ''
        this.scrollToBottom()
      }
    },

    filterRoomEventsByDate(events: MatrixEvent[]) {
      return events.filter((event: MatrixEvent) => {
        return event.getDate()?.getDate() === new Date().getDate()
      })
    },

    toggleEmojiPicker(clickEvent: MouseEvent) {
      this.emojiPicker.togglePicker(clickEvent.target)
    },

    sendEmoji(emoji: any) {
      this.message += emoji
      this.send()
    }
  },

  computed: {
    placeholderText(): any {
      return this.placeholders[this.currentIndex % this.placeholders.length]
    },
    user(): any {
      return this.store.getters.user
    },

    usersByUsername(): any {
      return this.store.getters.usersByUsername
    },

    organizationMembers(): any {
      return this.store.getters.organizationMembers
    },

    matrixClient(): MatrixClient {
      return this.store.getters.matrixClient
    },

    chatterRoom(): Room {
      const room = this.store.getters.chatterRoom
      /** TODO: Use chatter room from store. */
      return this.matrixClient.getRoom(this.store.getters.organization.organizationChatterSpaceId)
    },

    chatterNotificationsCount(): number {
      return this.store.getters.chatterNotificationsCount
    },

    mobileMenu(): boolean {
      return this.store.getters.mobileMenu
    }
  }
})
