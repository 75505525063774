
import StatusUtils from '@/common/services/StatusUtils'
import { defineComponent } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  data () {
    return {
      store: useStore(),
      pin: 0,
      isPinError: false,
      isPinSet: false
    }
  },

  mounted() {
    this.store.dispatch('isPinSet').then((result: any) => {
      this.isPinSet = result
    })
  },

  methods: {
    login () {
      this.store.dispatch('verifyPin', this.pin)
        .then((response: any) => {
          if (response === true) {
            this.store.dispatch('updateUserStatus', { status: 'AVAILABLE', statusDescription: '' })
            new StatusUtils().notifyEmployeeStatus()
            this.store.commit('setLoginWithPin', false)
            this.$router.push('/')
          } else {
            this.isPinError = true
          }
        })
    }
  },

  computed: {
    user (): any {
      return this.store.getters.user
    },

    usersByUsername (): any {
      return this.store.getters.usersByUsername
    }
  }
})
