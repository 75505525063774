import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal-backdrop" }
const _hoisted_2 = {
  class: "modal todo-modal",
  role: "dialog",
  "aria-labelledby": "modalTitle",
  "aria-describedby": "modalDescription"
}
const _hoisted_3 = {
  class: "modal-header px-0 py-4 mb-0 fs-26",
  id: "modalTitle"
}
const _hoisted_4 = {
  class: "modal-body p-0 cursor-pointer test-center lign-items-center",
  id: "modalDescription"
}
const _hoisted_5 = ["onClick", "id", "value"]
const _hoisted_6 = {
  class: "ms-2",
  for: "rhythm"
}
const _hoisted_7 = { class: "modal-footer justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_outside_click = _resolveDirective("outside-click")!

  return (_openBlock(), _createBlock(_Transition, { name: "modal-fade" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("header", _hoisted_3, [
            _renderSlot(_ctx.$slots, "header", {}, () => [
              _createTextVNode(_toDisplayString(_ctx.title), 1)
            ])
          ]),
          _createElementVNode("section", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (rhythm) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "cursor-pointer",
                key: rhythm
              }, [
                _createElementVNode("input", {
                  onClick: ($event: any) => (_ctx.selectRhythm($event, rhythm)),
                  type: "radio",
                  id: rhythm,
                  name: "rhythm",
                  value: rhythm
                }, null, 8, _hoisted_5),
                _createElementVNode("label", _hoisted_6, _toDisplayString(rhythm), 1)
              ]))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("button", {
              type: "button",
              class: "btn ghost-green",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.sendSelected && _ctx.sendSelected(...args)))
            }, " Change ")
          ])
        ])), [
          [_directive_outside_click, _ctx.close]
        ])
      ])
    ]),
    _: 3
  }))
}