import { Vue, Options } from 'vue-class-component'
import Todo from '../to-do/ToDo.vue'
import CharityBox from '../charity-box/CharityBox.vue'
import EventTile from '../event-tile/EventTile.vue'
import { useStore } from 'vuex'

@Options({
	components: { Todo, CharityBox, EventTile }
})
export default class SocialBoard extends Vue {
	store = useStore()
 }
