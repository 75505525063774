
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
import ConferenceAgenda from './ConferenceAgenda.vue'
import ConferencePoll from './ConferencePoll.vue'
import ConferenceIcons from './ConferenceIcons.vue'
export default defineComponent({
	components: {
		ConferenceAgenda
	},

	data () {
		return {
			store: useStore(),
			moment: moment
		}
	},

	computed: {
		user (): any {
      return this.store.getters.user
    },

    meeting (): any {
			return this.store.getters.meeting
		},

		usersByUsername ():any {
			return this.store.getters.usersByUsername
		}
  }
})
