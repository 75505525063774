import { Organization, Room, InviteRequest } from '../interfaces'

const mutations = {
  setOrganization (state: any, payload: Organization): void {
    state.organization = payload
  },

  setLogo (state: any, payload: any): void {
    state.logo = payload
  },

	setOrganizationRooms (state: any, payload: Room[]): void {
    state.organization.rooms = payload
  },

	setOrganizationMembers (state: any, payload: InviteRequest[]): void {
    state.organizationMembers = payload
  },

  setOrgChatterRoom(state: any, payload: any) {
    state.chatterRoom = payload
  },

	deleteOrganizationMembers (state: any, payload: InviteRequest[]): void {
    // state.organization = payload
  },

  editOrganization (state: any, payload: any): void {
    state.organization = payload
  },

	setAllIndustries (state: any, industries: any): void {
    state.industries = industries
  },

  setAllRhythm (state: any, rhythm: any): void {
    state.rhythm = rhythm
  },

	setCompanySizes (state: any, sizes: any): void {
    state.companySizes = sizes
  },

	setTeams (state: any, payload: any): void {
    state.teams = payload
  },

  setChatterNotificationsCount(state: any, count: number): void {
    state.chatterNotificationsCount = count
  }
}

export default mutations
