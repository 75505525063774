
import { PropType, defineComponent } from 'vue'
import { useStore } from 'vuex'
import { Organization } from '../interfaces'

export default defineComponent({
  props: {
    formProp: {
      type: Object as PropType<Partial<Organization>>,
      required: true
    }
  },

  data() {
    return {
      store: useStore(),
      companyLogo: {} as any,
      form: {} as Partial<Organization>,
      fileInputPlaceholder: 'Upload logo'
    }
  },

  mounted() {
    this.form = this.formProp
    document.querySelector('#logoUpload')!
      .addEventListener('change', (event: any) => {
        this.companyLogo = event.target.files[0]
        this.fileInputPlaceholder = this.companyLogo.name
      })
  },

  methods: {
    createOrganisation(e: any): void {
      this.store.commit('setLogo', this.companyLogo)
      this.$emit('next', { form: this.form, logo: this.companyLogo, nextTab: 2 })
    },

    close(event: any) {
      event.stopPropagation()
      this.$emit('close', event)
    }
  },

  computed: {
    industries(): any {
      return this.store.getters.industries
    },

    companySizes(): any {
      return this.store.getters.companySizes
    },

    organization(): any {
      return this.store.getters.organization
    }
  }
})
