
import { useStore } from 'vuex'
import { defineComponent } from 'vue'
import moment from 'moment'
import StatusUtils from '@/common/services/StatusUtils'
import { EventType, IContent, MatrixEvent } from 'matrix-js-sdk'
import { uploadFile } from '../services/MxEventContentHandler'
import MxRoom from '../services/MxRoom'
import { CustomMxEvent } from '../enums'
import WaveSurfer from 'wavesurfer.js'
import MicrophonePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.microphone.min.js'

export default defineComponent({
  props: {
    receiverUsername: { type: String, required: true },
    replyToEvent: { type: Object as any }
  },

  data() {
    return {
      store: useStore(),
      moment: moment,
      statusUtils: new StatusUtils(),
      recorder: null as any,
      chunks: [] as any,
      device: null as any,
      blobObj: null as any,
      audioSrc: null as any,
      audio: null as any,
      recordingStatus: 'NOT_STARTED',
      waveSurfer: {} as any,
      microphone: {} as any,
      recordingDuration: 0,
      timer: null as any
    }
  },

  mounted() {
    this.waveSurfer = WaveSurfer.create({
      container: '#waveform',
      waveColor: '#ccc',
      barWidth: 2,
      barRadius: 2,
      barHeight: 2,
      cursorWidth: 0,
      height: 60,
      interact: false,
      responsive: true,
      plugins: [MicrophonePlugin.create()]
    })

    this.microphone = this.waveSurfer.microphone
  },

  unmounted() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },

  created() {
    this.device = navigator.mediaDevices.getUserMedia({ audio: true })
  },

  methods: {
    formatTime(timeInSeconds: any) {
      const minutes = Math.floor(timeInSeconds / 60)
      const seconds = Math.floor(timeInSeconds % 60)
      return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
    },
    recordVoice() {
      if (this.recordingStatus === 'PAUSED' || this.recordingStatus === 'PLAYBACK') {
        this.recorder.resume()
        this.recordingStatus = 'RECORDING'
        return
      }
      this.startTimer()
      this.device.then((stream: any) => {
        this.recorder = new MediaRecorder(stream)
        this.recorder.ondataavailable = (e: any) => {
          this.chunks.push(e.data)
          if (this.recorder.state === 'paused') {
            this.blobObj = new Blob(this.chunks, { type: 'audio/wav' })
            this.chunks = []
            if (this.recordingStatus === 'PLAYBACK') {
              this.audioSrc = URL.createObjectURL(this.blobObj)
              this.playAudio()
            }
          }
          if (this.recorder.state === 'inactive') {
            this.blobObj = new Blob(this.chunks, { type: 'audio/wav' })
            this.chunks = []
            if (this.recordingStatus === 'PLAYBACK') {
              this.audioSrc = URL.createObjectURL(this.blobObj)
              this.playAudio()
            } else if (this.recordingStatus === 'COMPLETED') {
              this.uploadFile(this.blobObj)
            }
          }
        }
        this.recorder.start()
        this.microphone.start()
        this.recordingStatus = 'RECORDING'
      })
    },

    async uploadFile(audioBlob: Blob) {
      const dmRoom = MxRoom.getDmRoomBySenderId(this.store.getters.matrixRooms, this.receiverUsername)
      /** TODO: Check for file size */
      if (dmRoom) {
        const content: any = {
          body: 'Attachment',
          info: {
            size: audioBlob.size,
            mimetype: audioBlob.type
          },
          metadata: {
            from: this.user.username,
            to: this.receiverUsername
          },
          msgtype: CustomMxEvent.VOICE_MESSAGE,
          timestamp: new Date()
        }
        const result = await uploadFile(
          this.store.getters.matrixClient,
          dmRoom?.roomId,
          audioBlob
        )
        content.url = result?.file?.url
        content.file = result.file
        content.file.mimetype = audioBlob.type

        if (this.replyToEvent) {
          content['m.relates_to'] = {
            'm.in_reply_to': {
              event_id: this.replyToEvent.eventId
            }
          }
        }

        MxRoom.sendEvent(
          this.matrixClient,
          EventType.RoomMessage,
          content,
          dmRoom?.roomId
        )

        if (this.replyToEvent) {
          this.store.commit('setVoiceMessages', {})
        }
      }
    },

    pauseVoice() {
      this.recorder.pause()
      this.microphone.stop()
      this.recordingStatus = 'PAUSED'
    },

    deleteVoice() {
      this.recorder.stop()
      this.microphone.stop()
      this.stopTimer()
      this.recordingStatus = 'NOT_STARTED'
    },

    startTimer() {
      this.timer = setInterval(() => {
        if (this.recordingStatus === 'RECORDING') {
          this.recordingDuration++
        }
      }, 1000)
    },

    stopTimer() {
      clearInterval(this.timer)
      this.recordingDuration = 0
    },

    playVoice() {
      this.recorder.pause()
      this.microphone.stop()
      this.recordingStatus = 'PLAYBACK'

      this.recorder.requestData((data: any) => {
        this.blobObj = new Blob(data.data, { type: 'audio/wav' })
        this.audioSrc = URL.createObjectURL(this.blobObj)
        this.playAudio()
      })
    },

    playAudio() {
      this.audio = document.getElementById('audio-player')
      this.audio.play()
    },

    sendVoice(e: any) {
      if (this.recorder.state === 'inactive') {
        this.uploadFile(this.blobObj)
      } else {
        this.recorder.stop()
      }
      this.microphone.stop()
      this.recordingStatus = 'COMPLETED'
    },

    close(e: any, popup: any): void {
      this.$emit('close', e, popup)
    }
  },

  computed: {
    user(): any {
      return this.store.getters.user
    },

    receiver(): any {
      return this.store.getters.usersByUsername[this.receiverUsername]
    },

    matrixClient(): any {
      return this.store.getters.matrixClient
    }
  }
})
