
import { defineComponent } from 'vue'
import WaitingRoomDock from '@/common/components/conference/WaitingRoomDock.vue'
import { useStore } from 'vuex'
import Loader from '@/common/components/Loader.vue'
import moment from 'moment'
import { EventState } from '@/common/enums'

export default defineComponent({
  components: { WaitingRoomDock, Loader },
	data () {
    return {
      store: useStore(),
      moment: moment,
      timer: {
        minutes: 0,
        seconds: 0
      },
      timeToMeeting: 0
    }
  },

	mounted () {
    const now = moment(new Date())
    const startTime = moment(new Date(this.meeting.start * 1000))
    this.timeToMeeting = startTime.diff(now, 'seconds')

    if (this.timeToMeeting <= 0) {
       this.joinMeeting()
    } else if (this.timeToMeeting <= (5 * 60) && this.timeToMeeting > 1) {
      const timerRef = setInterval(() => {
        if (this.timeToMeeting === 0) {
          clearInterval(timerRef)
          this.joinMeeting()
        } else {
          this.timer.minutes = Math.floor(JSON.parse(JSON.stringify(this.timeToMeeting)) / 60)
          this.timer.seconds = JSON.parse(JSON.stringify(this.timeToMeeting)) % 60
          this.timeToMeeting--
        }
      }, 1000)
    } else {

    }
  },

  methods: {
    getMeetingLink () {
      /** Meeting link was generated from meeting title and id by replacing spaces in title with '-' */
      return `https://${process.env.VUE_APP_JITSI_URL}/` +
             `${this.meeting.title.replaceAll(' ', '-').replaceAll(/[^a-zA-Z0-9]/g, '-') + '-' + this.meeting.id}`
    },

    joinMeeting() {
      setTimeout(() => {
        this.store.dispatch('getMeeting', this.meeting.id)
        .then(() => {
          if (this.meeting.state === EventState.IN_PROGRESS) {
            this.store.commit('setConferenceActive', true)
          } else {
            // started ? join
            // hot will let you in soon
            // join now btn - request to join - check api if started
            // if not - not started
          }
        })
      }, 1000)
    },

    copyMeetingUrl () {
      navigator.clipboard.writeText(this.getMeetingLink())
    }
  },

	computed: {
    user (): any {
      return this.store.getters.user
    },

    meeting (): any {
			return this.store.getters.meeting
		},

		usersByUsername ():any {
			return this.store.getters.usersByUsername
		},

		isPageLoading (): any {
			return this.store.getters.isPageLoading
		}
  }
})
