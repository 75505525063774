
import { defineComponent } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  data () {
    return {
      store: useStore(),
      isReady: false,
      matrixRooms_: [] as any
    }
  }
})
