
import { Options, Vue } from 'vue-class-component'
import { useStore } from 'vuex'
import { EventTimelineSet, EventType, IContent, MatrixClient, MatrixEvent, Room } from 'matrix-js-sdk'
import RoomList from './RoomList.vue'
import RoomInfo from './RoomInfo.vue'
import RoomView from './RoomView.vue'
import { globalMatrixClient } from '@/initMatrix'

@Options({
	components: { RoomList, RoomInfo, RoomView }
})
export default class TodoModal extends Vue {
  store = useStore()
  selectedRoom: any = {}
  matrixRooms_: any = []

  scrollBack (room: any) {
    this.matrixClient.scrollback(room)
  }

  // scrollToBottom () {
  //   document.getElementsByClassName('msg-content')[0].scrollTop =
  //   document.getElementsByClassName('msg-content')[0].scrollHeight
  // }

  // async updateRooms () {
  //   this.matrixRooms_ = this.matrixClient.getRooms()

  //   /** Sorting */
  //   const tsOfNewestEvent = function tsOfNewestEvent (room: Room) {
  //     if (room.timeline.length) {
  //       return room.timeline[room.timeline.length - 1].getTs()
  //     }
  //     return Number.MAX_SAFE_INTEGER
  //   }

  //   this.matrixRooms_.sort((a: any, b: any) => tsOfNewestEvent(b) - tsOfNewestEvent(a))
  //   this.store.commit('setMatrixRooms', this.matrixRooms_)
  // }

  get matrixRooms (): Room[] {
    return this.matrixRooms_
  }

  get currentRoom (): Room {
    return this.store.getters.currentRoom.room
  }

  get timelineSet (): EventTimelineSet {
    return this.currentRoom.getUnfilteredTimelineSet()
  }

  get matrixClient (): MatrixClient {
    return this.store.getters.matrixClient
  }
}
