
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import SlideData from './slideData.vue'
import OrgCreationStepOne from './OrgCreationStepOne.vue'
import OrgCreationStepTwo from './OrgCreationStepTwo.vue'
import OrgCreationStepThree from './OrgCreationStepThree.vue'
import InviteNewUser from './InviteNewUser.vue'
import { Organization } from '../interfaces'

export default defineComponent({
  components: {
    SlideData,
    OrgCreationStepOne,
    OrgCreationStepTwo,
    OrgCreationStepThree,
    InviteNewUser
  },

  data() {
    return {
      store: useStore(),
      companyLogo: {} as any,
      tabNames: {
        1: 'Create Organization',
        2: 'Create Organization',
        3: 'Setup rhythm',
        4: 'Invite members'
      } as any,
      currentTab: 1,
      form: {} as Partial<Organization>
    }
  },

  created() {
    this.initForm()
  },

  mounted() {
    this.store.dispatch('getAllIndustries')
    this.store.dispatch('getCompanySizes')
    this.store.dispatch('getAllRhythm')
  },

  methods: {
    goToNextTab(data: any) {
      this.form = data.form
      this.companyLogo = data.logo
      this.currentTab = data.nextTab
    },

    close(event: any) {
      event.stopPropagation()
      this.$emit('close', event)
    },

    initForm() {
      this.form = {
        name: '',
        industry: '',
        url: '',
        logo: '',
        companySize: '',
        about: '',
        ownerRole: '',
        locations: [],
        workDays: [],
        rhythm: {
          framework: '',
          sprint_duration: '',
          mini_sprint_duration: '',
          team_report: '',
          sprint_planning: {
            plan_every: '',
            in_advance: '',
            review_every: ''
          }
        }
      }
    }
  },

  computed: {
    industries(): any {
      return this.store.getters.industries
    },

    companySizes(): any {
      return this.store.getters.companySizes
    },

    organization(): any {
      return this.store.getters.organization
    }
  }
})
