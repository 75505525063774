import { Organization, Room, InviteRequest } from '../interfaces'
const getters = {
  getOrganization (state: any): Organization {
    return state.organization
  },

	organizationRooms (state: any): Room[] {
    return state.organizationRooms
  },

	organizationMembers (state: any): InviteRequest[] {
    return state.organizationMembers
  },

	industries (state: any) {
    return state.industries
  },

  rhythm (state: any) {
    return state.rhythm
  },

	companySizes (state: any) {
    return state.companySizes
  },

	teams (state: any) {
		return state.teams
	},

  locations (state: any) {
    return state.locations
  },

  logo (state: any) {
    return state.logo
  },

  chatterRoom(state: any) {
    return state.chatterRoom
  },

  chatterNotificationsCount(state: any): number {
    return state.chatterNotificationsCount
  }
}

export default getters
