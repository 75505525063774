import AudioRoomApi from '@/common/services/api/AudioRoomApi'

const actions = {
  getAudioRoomById (context: any, id: any) {
    return AudioRoomApi.getAudioRoomById(id)
      .then((response: any) => {
        return response.data
      }).catch((error: any) => {
        console.error('Error in creating floors', error)
      })
  },

  createAudioRoomTable (context: any, payload: any) {
    return AudioRoomApi.createTable(payload)
      .then((response: any) => {
        return response.data
      }).catch((error: any) => {
        console.error('Error in creating floors', error)
      })
  },

  joinTable (context: any, tableId: number) {
    return AudioRoomApi.joinTable(tableId)
      .then((response: any) => {
        return response.data
      }).catch((error: any) => {
        console.error('Error in creating floors', error)
      })
  },

  leaveTable (context: any, tableId: number) {
    return AudioRoomApi.leaveTable(tableId)
      .then((response: any) => {
        return response.data
      }).catch((error: any) => {
        console.error('Error in creating floors', error)
      })
  }
}

export default actions
