
import { defineComponent } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  data() {
    return {
      store: useStore(),
      newPassword: '',
      newPasswordConfirm: '',
      isPasswordCorrect: true
    }
  },

  methods: {
    checkIfPasswordMatches() {
      this.isPasswordCorrect = this.newPassword === this.newPasswordConfirm
    },

    close(): void {
      this.$emit('close')
    },

    changePassword() {
      this.store.dispatch('changePassword', this.newPassword).then(() => {
        this.close()
      }).catch((e: any) => {
        console.error('Change password API failed!!')
      })
    }
  }
})
