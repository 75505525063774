import ApiService from './api/ApiService'

/** Utility module for generic utility methods.  */
export default {
  /** Finds face from given image. */
  apiService: new ApiService(),

  findFace(image: any): any {
    if (image !== undefined) {
      return fetch(image).then((res) => {
        const fileBlob = res.blob()
        return fileBlob.then((file: any) => {
          const formData = new FormData()
          file.lastModifiedDate = new Date()
          formData.append('image', file)
          return this.apiService.detectFace(formData).then((response: any) => {
            const facesFound = response.data.num_faces
            const faces = response.data.faces
            return { facesFound, faces }
          })
        })
      })
    }
  }
}
