import { MatrixClient, MatrixEvent, Room } from 'matrix-js-sdk'
import { MatrixClientCreds } from '../interfaces/MatrixClientCreds'

const mutations = {
  setMatrixClientCreds (state: any, data: MatrixClientCreds) {
    state.matrixClientCreds = data
    localStorage.setItem('matrixCreds', JSON.stringify(data))
    localStorage.setItem('mx_access_token', data.accessToken)
  },

  setMatrixClient (state: any, client: MatrixClient) {
    state.matrixClient = client
  },

  setMatrixRooms (state: any, rooms: Room[]) {
    state.matrixRooms = rooms
    /** Setting default selected room. */
    if (!state.currentRoom.room) {
      state.currentRoom.room = rooms[0]
    }
  },

  setCurrentRoom (state: any, payload: Room) {
    state.currentRoom.room = payload
  },

  setPinnedEvents (state: any, payload: MatrixEvent[]) {
    state.currentRoom.pinnedEvents = payload
  },

  setChatNotificationsCount(state: any, count: number) {
    state.notificationsCount = count
  }
}

export default mutations
