
import { defineComponent, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import UserProfileModal from '@/common/components/UserProfileModal.vue'
import CreateFloor from './CreateFloor.vue'
import CreateOrganizationModal from '../modules/onboarding/components/CreateOrganisation.vue'
import ReportBugModal from '@/components/ReportBugModal.vue'
import sideMenuItems from '@/common/json/sideMenuItems.json'

export default defineComponent({
  components: {
    UserProfileModal,
    CreateFloor,
    CreateOrganizationModal,
    ReportBugModal
  },

  data() {
    return {
      store: useStore(),
      currentTab: '/',
      currentFavTab: '',
      activeModal: '',
      selectedStatus: '',
      showStatusTooltip: false,
      breadcrumbs: [] as any,
      accountPage: process.env.VUE_APP_KEYCLOAK_ACCOUNT_URL,
      isCreateOrgModalVisible: false,
      isInviteMembersModalVisible: false,
      menuFavorites: [] as string[],
      sideMenuItems: sideMenuItems as any,
      allMenuItems: { ...sideMenuItems.floors, ...sideMenuItems.management } as any,
      emitter: getCurrentInstance()?.appContext.config.globalProperties.emitter
    }
  },

  mounted() {
    this.store.dispatch('getUserPreferences')
  },

  methods: {
    setActivetab(tab: string, route: any) {
      this.currentTab = tab
      this.currentFavTab = ''
      this.setBreadcrumbs(tab, route)
      // this.store.commit('setMobileMenu', !this.mobileMenu)
    },

    setActiveFavTab(tab: string, route: any) {
      this.currentFavTab = tab
      this.currentTab = ''
      this.setBreadcrumbs(tab, route)
      // this.store.commit('setMobileMenu', !this.mobileMenu)
    },

    setBreadcrumbs(tab: string, route: string) {
      this.$router.push(tab)
      this.breadcrumbs = []
      this.breadcrumbs.push(route)
      this.store.commit('setBreadcrumbs', this.breadcrumbs)
      this.store.commit('setChatNotificationsCount', 0)
    },

    togglePopup(e: any, popup: string) {
      this.activeModal = popup
      if (e) e.stopPropagation()
    },

    toggleStatusTooltip() {
      this.showStatusTooltip = !this.showStatusTooltip
    },

    isSelectOptionDisabled(userStatus: string) {
      return userStatus === this.user.status
    },

    createNewFloor() {
      //
    },

    toggleFavorites(menuItem: string) {
      if (this.userPreferences?.favorites?.includes(menuItem)) {
        this.menuFavorites = this.userPreferences.favorites.filter(
          (item: string) => item !== menuItem
        )
      } else {
        this.menuFavorites = this.userPreferences.favorites || []
        this.menuFavorites.push(menuItem)
      }
      this.store.dispatch('updateUserPreferences', {
        status: this.user.status, // TODO: Remove user status from this API from BE
        userPreferences: { ...this.userPreferences, favorites: this.menuFavorites }
      })
    }
  },

  computed: {
    user(): any {
      return this.store.getters.user
    },

    mobileMenu(): boolean {
      return this.store.getters.mobileMenu
    },

    avatarUrl(): string {
      return this.store.getters.avatar
    },

    allFloors(): any {
      return this.store.getters.allFloors
    },

    chatNotificationsCount(): number {
      return this.store.getters.chatNotificationsCount
    },

    userPreferences(): any {
      return this.user.userPreferences
    }
  }
})
