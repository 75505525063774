
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import TimeUtilityService from '@/common/services/TimeUtilityService'
import Utility from '@/common/services/Utility'
import moment from 'moment'
import StatusUtils from '@/common/services/StatusUtils'
import { FreeStyleShimmer } from 'vue3-shimmer'

export default defineComponent({
  components: {
    // FreeStyleShimmer
  },

  props: {
    employee: { type: String, required: true },
    timelines: { type: Object as any, required: true }
  },

  data() {
    return {
      store: useStore(),
      moment: moment,
      timeUtilityService: new TimeUtilityService(),
      imageUtility: Utility,
      isPageLoading: true,
      selectedTimelineItem: {} as any,
      selectedTimelineIndex: 0,
      statusUtils: new StatusUtils(),
      // faces: [],
      // facesFound: 1,
      timelineReversed: Object.assign([], this.timelines).reverse(),
      timelineImageIndex: 0
    }
  },

  mounted() {
    this.isPageLoading = true
    setTimeout(() => {
      this.isPageLoading = false
      this.timelineImageIndex = this.timelineReversed.length
      // const interval = setInterval(() => {
      //   if (this.timelineImageIndex > 0) {
      //     this.setExpandedItem(this.timelineReversed[this.timelineImageIndex--], this.timelineImageIndex)
      //   } else {
      //     clearInterval(interval)
      //   }
      // }, 120)
    }, 3000)
  },

  methods: {
    close(): void {
      this.$emit('close')
    },

    getImgUrl(url: string): any {
      return require(`@/assets/img/${url}`)
    },

    setExpandedItem(item: any, index: any) {
      this.selectedTimelineIndex = index
    },

    gotoPrevious() {
      if (this.timelineReversed[this.selectedTimelineIndex + 1]) {
        this.selectedTimelineIndex++
      }
    },

    gotoNext() {
      if (this.timelineReversed[this.selectedTimelineIndex - 1]) {
        this.selectedTimelineIndex--
      }
    },

    momentFormat(value: string, format: string): string {
      return moment(value).calendar(null, {
        sameDay: '[Today at] hh:mm A',
        lastDay: '[Yesterday at] hh:mm A',
        lastWeek: format,
        sameElse: format
      })
    }

    // findFacesFromWebcamshot(webcamshot: string) {
    //   this.imageUtility.findFace(webcamshot)
    //     .then((facesResponse: any) => {
    //       if (facesResponse.faces) {
    //         this.faces = facesResponse.faces
    //         this.facesFound = facesResponse.facesFound
    //       }
    //     })
    // }
  },

  computed: {
    webcamshots(): any {
      return this.store.getters.usersByUsername[this.employee].downloadedWebcamshots
    }
  }
})
