import ApiService from '@/common/services/api/ApiService'
import userApi from '@/common/services/api/UserApiService'
import FileApiService from '@/common/services/api/FileApiService'
import router from '@/router'
import { Todo, TodoTask, UpdateTodoTask } from '../common/types/TodoInterface'
import { Meeting } from '../common/types/MeetingInterface'
import FloorActions from './actions/Floor'
import UserActions from './actions/User'
import MeetingActions from './actions/Meeting'
import AudioRoomActions from './actions/AudioRoom'
import CommonApi from '@/common/services/api/CommonApi'
import UtilityService from '@/common/services/TimeUtilityService'
import ObjectUtils from '@/common/services/ObjectUtils'
import Utility from '@/common/services/Utility'
import moment from 'moment'
import KeyGenerator from '@/config/KeyGenerator'

const apiService = new ApiService()
const fileApiService = new FileApiService()
const utilityService = new UtilityService()

const actions = {
  async getUser(context: any) {
    /** Generate DH keys before calling any other backend API */
    // if (!localStorage.getItem('sharedKey')) {
    //   console.log('key exchange..')
    //   const dh = KeyGenerator.generateKeys()
    //   console.log('generated keys', dh?.getPublicKey())
    //   const res = await context.dispatch('exchangeKeys', dh?.getPublicKey('base64'))
    //   localStorage.setItem('serverPublicKey', res.serverPubK)
    //   KeyGenerator.generateSharedKey(dh!, res.serverPubK, res.shared, res.iv, res.id)
    // }
    apiService.getUser()
      .then((response: any) => {
        context.commit('setUser', response.data)
        context.commit('setRealmRoles')
        context.dispatch('getAllAttendance', response.data.username)
      })
      .catch((error: any) => {
        console.error('Error in getting user', error)
      })
  },

  exchangeKeys(context: any, publicKey: string) {
    return apiService.exchangeKeys({ publicKey })
      .then((response: any) => {
        return response.data
      })
      .catch((error: any) => {
        console.error('Error in getting user with username', error)
      })
  },

  getUserByUsername(context: any, username: string) {
    return apiService.getUserByUsername(username)
      .then((response: any) => {
        return response.data
      })
      .catch((error: any) => {
        console.error('Error in getting user with username', error)
      })
  },

  getAllUsers(context: any) {
    return userApi.getAllUsers()
      .then((response: any) => {
        context.commit('setAllUsers', response.data)
        response.data.forEach((user: any) => {
          context.commit('setUserByUsername', user)
          context.dispatch('getAvatarByUser', user)
        })
      })
      .catch((error: any) => {
        console.error('Error in getting all users', error)
      })
  },

  getAvatar(context: any) {
    return userApi.getAvatar()
      .then((response: any) => {
        context.commit('setAvatar', URL.createObjectURL(response.data))
      })
      .catch((error: any) => {
        console.error('Error in getting avatar', error)
      })
  },

  getAvatarByUser(context: any, user: any) {
    return userApi.getAvatarByUser(user.username)
      .then((response: any) => {
        user.avatarUrl = URL.createObjectURL(response.data)
        context.commit('setAvatarByUsername', user)
      })
      .catch((error: any) => {
        console.error('Error in getting avatar', error)
      })
  },

  uploadAvatar(context: any, payload: any) {
    return userApi.uploadAvatar(payload)
      .then(() => {
        context.dispatch('getAvatar')
      })
      .catch((error: any) => {
        console.error('Error in uploading avatar', error)
      })
  },

  setElementToken(context: any, token: string) {
    context.commit('setElementToken', token)
  },

  getMyOrganization(context: any) {
    return apiService.getMyOrganization()
      .then((response: any) => {
        // response.data.avatar = URL.createObjectURL(new Blob(response.data.avatar))
        context.commit('setOrganization', response.data)
        context.commit('setFeedRoomId', response.data.matrixSpaceId)
        context.dispatch('getOrganizationMembers', response.data.id)
        return response.data
      })
      .catch((error: any) => {
        console.error('Error in getting my mneetings', error)
      })
  },

  getMyMeetings(context: any) {
    context.commit('isPageLoading', true)
    return apiService.getMyMeetings()
      .then((response: any) => {
        context.commit('isPageLoading', false)
        context.commit('setMyMeetings', response.data)
        return response.data
      })
      .catch((error: any) => {
        context.commit('isPageLoading', false)
        console.error('Error in getting my mneetings', error)
      })
  },

  getSharedMeetings(context: any) {
    context.commit('isPageLoading', true)
    return apiService.getSharedMeetings()
      .then((response: any) => {
        context.commit('isPageLoading', false)
        context.commit('setSharedMeetings', response.data)
        return response.data
      })
      .catch((error: any) => {
        context.commit('isPageLoading', false)
        console.error('Error in getting my mneetings', error)
      })
  },

  getRelatedMeetings(context: any, title: string) {
    return apiService.getRelatedMeetings(title)
      .then((response: any) => {
        // context.commit('setSharedMeetings', response.data)
        return response.data
      })
      .catch((error: any) => {
        context.commit('isPageLoading', false)
        console.error('Error in getting my mneetings', error)
      })
  },

  getMeeting(context: any, id: string): Promise<Meeting> {
    context.commit('isPageLoading', true)
    return apiService.getMeeting(id)
      .then((response: any) => {
        context.commit('isPageLoading', false)
        context.commit('setMeeting', response.data)
        return response.data
      })
      .catch((error: any) => {
        context.commit('isPageLoading', false)
        console.error('Error in getting meeting', error)
      })
  },

  createMeeting(context: any, meeting: Meeting) {
    context.commit('isPageLoading', true)
    return apiService.createMeeting(meeting)
      .then((response: any) => {
        context.commit('addMeeting', response.data)
        context.commit('isPageLoading', false)
        return response.data
      })
      .catch((error: any) => {
        context.commit('isPageLoading', false)
        console.error('Error in adding meeting', error)
      })
  },

  createAndStartMeeting(context: any, meeting: Meeting) {
    return context.dispatch('createMeeting', meeting)
      .then((response: any) => {
        return apiService.startMeeting(response.id)
          .then(() => response)
          .catch((error: any) => {
            console.error('Error in starting meeting with meetingId', error)
          })
      })
      .catch((error: any) => {
        context.commit('isPageLoading', false)
        console.error('Error in creating meeting', error)
      })
  },

  startMeeting(context: any, meetingId: string) {
    return apiService.startMeeting(meetingId)
    .catch((error: any) => {
      console.error('Error in starting meeting', error)
    })
  },

  updateMeeting(context: any, meeting: Meeting) {
    return apiService.updateMeeting(meeting)
      .then((response: any) => {
        context.commit('updateMeeting', response.data)
        return response
      })
      .catch((error: any) => {
        console.error('Error in updating meeting', error)
      })
  },

  updateAndStartMeeting(context: any, meeting: Meeting) {
    context.dispatch('updateMeeting', meeting)
      .then((response: any) => {
        return apiService.startMeeting(response.data.id)
          .then((response: any) => {
            return response
          })
          .catch((error: any) => {
            console.error('Error in starting meeting with meetingId', error)
          })
      })
      .catch((error: any) => {
        console.error('Error in updating meeting', error)
      })
  },

  deleteMeeting(context: any, meetingId: string) {
    apiService.deleteMeeting(meetingId)
      .then((response: any) => {
        context.commit('deleteMeeting', response.data)
        router.push('/meetings')
      })
      .catch((error: any) => {
        console.error('Error in updating meeting', error)
      })
  },

  endMeeting(context: any, meetingId: string) {
    apiService.endMeeting(meetingId)
      .then((response: any) => {
        router.push('/meetings')
      })
      .catch((error: any) => {
        console.error('Error in updating meeting', error)
      })
  },

  toggleModal(context: any) {
    context.commit('toggleModal')
  },

  toggleSuccess(context: any, payload: boolean) {
    context.commit('showSuccess', payload)
  },

  toggleError(context: any, payload: boolean) {
    context.commit('showError', payload)
  },

  startDay(context: any, payload: any) {
    apiService.addAttendance(payload)
      .then((response: any) => {
        context.commit('addAttendance', response.data)
      })
      .catch((error: any) => {
        console.error('Error in saving start of day', error)
      })
  },

  addAttendance(context: any, payload: any) {
    apiService.addAttendance(payload)
      .then((response: any) => {
        context.commit('addAttendance', response.data)
      })
      .catch((error: any) => {
        console.error('Error in saving start of day', error)
      })
  },

  getAllAttendance(context: any, username: string) {
    userApi.getAttendanceByUser(username)
      .then((response: any) => {
        context.commit('setAllAttendance', response.data)
      })
      .catch((error: any) => {
        console.error('Error in getting attendance', error)
      })
  },

  getAttendanceByDate(context: any, date: string) {
    userApi.getAttendanceByDate(date)
      .then((response: any) => {
        context.commit('setAttendance', response.data)
      })
      .catch((error: any) => {
        console.error('Error in getting attendance', error)
      })
  },

  getAllEvents(context: any) {
    apiService.getAllEvents()
      .then((response: any) => {
        context.commit('setAllEvents', response.data)
      })
      .catch((error: any) => {
        console.error('Error in getting all events', error)
      })
  },

  getEventsByUserByMonth(context: any, payload: any) {
    return apiService.getEventsByUserByMonth(payload.username, payload.month, payload.year)
      .then((response: any) => {
        return response.data
      })
      .catch((error: any) => {
        console.error('Error in getting all events for this user for this month', error)
      })
  },

  getDayEvents(context: any, payload: any) {
    return apiService.getDayEvents(payload.year, payload.month, payload.day)
      .then((response: any) => {
        context.commit('setDayEvents', response.data)
        return response.data
      })
      .catch((error: any) => {
        console.error('Error in getting all day events', error)
      })
  },

  getMonthEvents(context: any, payload: any) {
    return apiService.getMonthEvents(payload.year, payload.month)
      .then((response: any) => {
        context.commit('setMonthEvents', response.data)
        return response.data
      })
      .catch((error: any) => {
        console.error('Error in getting all month events', error)
      })
  },

  getWeekEvents(context: any, payload: any) {
    return apiService.getWeekEvents(payload)
      .then((response: any) => {
        context.commit('setWeekEvents', response.data)
        return response.data
      })
      .catch((error: any) => {
        console.error('Error in getting all week events', error)
      })
  },

  submitEventRequest(context: any, event: any) {
    return apiService.createEvent(event)
      .then((response: any) => {
        context.commit('addEvent', response.data)
        return response
      })
      .catch((error: any) => {
        console.error('Error in adding event', error)
      })
  },

  // File upload & download

  createFileUploadUrl(context: any, payload: any) {
    return apiService.createFileUploadUrl(payload.formData)
      .then((response: any) => {
        return context.dispatch('uploadFile', { uploadUrl: response.data.uploadUrl, file: payload.file })
          .then(() => {
            return response.data
          })
      })
      .catch((error: any) => {
        console.error('Error in adding file to first url', error)
      })
  },

  uploadFile(context: any, payload: any) {
    return apiService.uploadFile(payload.uploadUrl, payload.file)
      .then((response: any) => {
        return response
      })
      .catch((error: any) => {
        console.error('Error in adding file to second url', error)
      })
  },

  saveTimeline(context: any, payload: any) {
    return apiService.saveTimeline(payload)
      .then((response: any) => {
        context.dispatch('createFileDownloadUrl', response.data.webcamshot)
          .then((webcamshot: any) => {
            context.commit('setLatestWebcamshot', {
              username: response.data.username,
              webcamshot,
              submittedOn: response.data.submittedOn
            })
            // context.dispatch('findFacesFromLastWebcamshot', response.data.username)
            //   .catch((error: any) => {
            //     console.error('Error in adding file to second url', error)
            //   })
          })
      })
  },

  findFacesFromLastWebcamshot(context: any, username: string) {
    Utility.findFace(context.getters.usersByUsername[username].latestWebcamshot)
      .then((facesResponse: any) => {
        if (facesResponse.faces) {
          context.commit('setFacesFound', facesResponse)
        }
      })
  },

  createFileDownloadUrl(context: any, fileId: any) {
    return fileApiService.createFileDownloadUrl(fileId)
      .then((response: any) => {
        return context.dispatch('downloadFile', response.data)
      })
      .catch((error: any) => {
        console.error('Error in adding file to first url', error)
      })
  },

  downloadFile(context: any, url: any) {
    return fileApiService.downloadFile(url)
      .then((response: any) => {
        return URL.createObjectURL(response.data)
      })
      .catch((error: any) => {
        console.error('Error in adding file to second url', error)
      })
  },

  // File upload and download ends here

  getAttendance(context: any, payload: any) {
    return apiService.getLatestAttendance()
      .then((response: any) => {
        context.commit('setLatestAttendance', response.data)
      })
      .catch((error: any) => {
        console.error('Error in saving start of day', error)
      })
  },

  getTimer(context: any) {
    return apiService.getTimers(new Date().toISOString())
      .then((response: any) => {
        context.commit('setTimer', response.data) // get latest timer
        return response.data[response.data.length - 1]
      })
      .catch((error: any) => {
        console.error('Error in saving timer', error)
      })
  },

  getTimerByUser(context: any, payload: any) {
    return apiService.getTimerByUser(payload.user, payload.date)
      .then((response: any) => {
        context.commit('setTimerByUser', { timers: response.data, username: payload.user })
        return response.data
      })
      .catch((error: any) => {
        console.error('Error in saving timer', error)
      })
  },

  createTimer(context: any, payload: any) {
    return apiService.createTimer(payload)
      .then((response: any) => {
        context.commit('addTimer', response.data)
      })
      .catch((error: any) => {
        console.error('Error in saving timer', error)
      })
  },

  updateTimer(context: any, payload: any) {
    apiService.updateTimer(payload)
      .then((response: any) => {
        context.commit('updateTimer', response.data)
      })
      .catch((error: any) => {
        console.error('Error in updating timer', error)
      })
  },

  updateTimeElapsed(context: any, payload: any) {
    context.commit('updateTimerTimeElapsed', payload)
  },

  updateUserStatus(context: any, payload: any) {
    return apiService.updateUserStatus(payload.status, payload.statusDescription!, payload.duration)
      .then((response) => {
        response.data.statusTimeStamp = response.data.statusTimeStamp * 1000
        context.commit('setUser', response.data)
        context.commit('updateUserStatusByUsername', response.data)
        return response.data
      })
      .catch((error: any) => {
        console.error('Error in updating user', error)
      })
  },

  addUserTitle(context: any, payload: string) {
    apiService.addUserTitle(payload)
      .then((response: any) => {
        context.commit('setUserTitle', response.data)
      })
      .catch((error: any) => {
        console.error('Error in getting organization', error)
      })
  },

  // Organization and rooms

  getOrganization(context: any, id: any) {
    apiService.getOrganization(id)
      .then((response) => {
        context.commit('setOrganization', response.data)
      })
      .catch((error: any) => {
        console.error('Error in getting organization', error)
      })
  },

  getRooms(context: any) {
    context.commit('isPageLoading', true)
    apiService.getRooms()
      .then((response) => {
        context.commit('isPageLoading', false)
        context.commit('setRooms', response.data)
      })
      .catch((error: any) => {
        context.commit('isPageLoading', false)
        console.error('Error in getting rooms', error)
      })
  },

  getActiveMeetings(context: any) {
    apiService.getActiveMeetings()
      .then((response) => {
        context.commit('setActiveMeetings', response.data)
      })
      .catch((error: any) => {
        console.error('Error in getting rooms', error)
      })
  },

  addTask(context: any, payload: any) {
    context.commit('addTask', payload)
  },

  getTodoList(context: any) {
    context.commit('isComponentLoading', true)
    return apiService.getTodoList()
      .then((response: any) => {
        context.commit('isComponentLoading', false)
        context.commit('setTodoList', response.data)
      })
      .catch((error: any) => {
        context.commit('isComponentLoading', false)
        console.error('Error in getting my todos', error)
      })
  },

  addTodo(context: any, payload: Todo) {
    return apiService.addTodo(payload)
      .then((response) => {
        context.commit('addTodo', response.data.tasks)
      })
      .catch((error: any) => {
        console.error('Error in creating todo task', error)
      })
  },

  updateTodo(context: any, payload: any) {
    console.log('update todo', payload)
    return apiService.updateTodo(payload.todoListId, payload.todo)
      .then((response) => {
        context.commit('updateTodo', payload)
      })
      .catch((error: any) => {
        console.error('Error in updating todo task', error)
      })
  },

  deleteTodo(context: any, payload: any) {
    return apiService.deleteTodoTask(payload.todoListId, payload.todo)
      .then((response) => {
        context.commit('deleteTodo', payload.todo)
      })
      .catch((error: any) => {
        console.error('Error in deleting todo task', error)
      })
  },

  updateTodoList(context: any, payload: Todo) {
    return apiService.updateTodoList(payload)
      .then((response) => {
        context.commit('updateTodoList', payload)
      })
      .catch((error: any) => {
        console.error('Error in updating todo list', error)
      })
  },

  completeTodo(context: any, payload: any) {
    return apiService.completeTodoTask(payload.todoListId, payload.todo)
      .then((response) => {
        context.commit('updateTodo', response.data)
        return response.data
      })
      .catch((error: any) => {
        console.error('Error in completing todo task', error)
      })
  },

  reopenTodo(context: any, payload: any) {
    return apiService.reopenTodoTask(payload.todoListId, payload.todo)
      .then((response) => {
        context.commit('updateTodo', response.data)
        return response.data
      })
      .catch((error: any) => {
        console.error('Error in reopening todo tasks', error)
      })
  },

  getEmployeeTimeline(context: any, request: any) {
    context.commit('isTimeline')
    context.dispatch('getPaginatedEmployeeTimeline',
      {
        username: request.username,
        page: request.page,
        perPage: request.perPage
      }
    ).then((response: any) => {
      // if (response.length && response.length === request.perPage) {
      //   context.dispatch(
      //     'getEmployeeTimeline',
      //     { username: request.username, page: request.page + 1, perPage: request.perPage }
      //   )
      // }
    })
  },

  // TODO: Remove after testing
  getPaginatedEmployeeTimeline(context: any, request: any) {
    return apiService.getUserTimeline(request.username, request.perPage, request.page)
      .then((response) => {
        // response.data.forEach((timeline: any, index: number) => {
        // context.dispatch('createFileDownloadUrl', timeline.webcamshot).then((webcamshot: any) => {
        //   timeline.webcamshot = webcamshot
        //   context.commit('setEmployeeTimelineItem',
        //     { username: request.username, index: request.page + index, payload: timeline }
        //   )
        // TODO: Refactor it using creating an array to store the response data
        // Test if all the webcamnshots have been loaded by checking length
        //   if (request.page + index === response.data.length - 1) {
        //     context.commit('isTimelineLoading', 'webcamshot')
        //   }
        // })
        // })
        context.commit('addToEmployeeTimeline', { username: request.username, timeline: response.data })
        return response.data
      })
      .catch((error: any) => {
        console.error('Error in getting employee timeline', error)
      })
  },

  getEmployeeTimelineByDate(context: any, request: any) {
    apiService.getUserTimelineByDate(request.username, moment.utc(moment(request.date).startOf('day')).toISOString())
      .then((response: any) => {
        context.commit('addToEmployeeTimeline',
          { username: request.username, timeline: response.data, date: moment(request.date).format('MMMM D') }
        )
      })
  },

  getFilteredEmployeeTimeline(context: any, request: any) {
    let datesInWeek = []
    context.commit('isTimelineLoading', true)
    if (request.week === moment().week() && request.year === moment().year()) {
      datesInWeek = utilityService.getDatesInWeekTillToday()
    } else {
      datesInWeek = utilityService.getDatesInWeek(request.week, request.year)
    }
    datesInWeek.forEach((date: any, index: number) => {
      console.log('employee timeline date: ', moment.utc(moment(date).startOf('day')).toISOString())
      apiService.getUserTimelineByDate(request.username, moment.utc(moment(date).startOf('day')).toISOString())
        .then((response: any) => {
          context.commit('addToEmployeeTimeline',
            { username: request.username, timeline: response.data, date: moment(date).format('MMMM D') }
          )
          if (index === datesInWeek.length - 1) {
            context.commit('isTimelineLoading', false)
          }
        })
    })
  },

  downloadWebcamshot(context: any, payload: any) {
    context.dispatch('createFileDownloadUrl', payload.webcamshot).then((webcamshot: any) => {
      context.commit('setWebcamshotsByTimestamp',
        { username: payload.username, key: payload.submittedOn, webcamshot }
      )
    })
  },

  getLatestEmployeeTimeline(context: any) {
    // context.commit('isPageLoading', true)
    return apiService.getLatestEmployeeTimeline()
      .then((response) => {
        // context.commit('setLatestWebcamshots', response.data)
        // context.commit('isPageLoading', false)
        response.data.forEach((user: any) => {
          // context.commit('setProfileVideo', {
          //   video: '', username: user.username, submittedOn: user.submittedOn
          // })
          context.dispatch('createFileDownloadUrl', user.webcamshot)
            .then((webcamshot: any) => {
              context.commit('setLatestWebcamshot', {
                username: user.username,
                webcamshot,
                submittedOn: user.submittedOn
              })
            })

          if (user.avatarVideo) {
            context.dispatch('createFileDownloadUrl', user.avatarVideo)
            .then((video: any) => {
              context.commit('setProfileVideo', { video, username: user.username, submittedOn: user.submittedOn })
            }).catch((e: any) => console.error('Failed to download profile video', e))
          }
        })
        return response.data
      })
      .catch((error: any) => {
        // context.commit('isPageLoading', false)
        console.error('Error in getting latest webcam shots', error)
      })
  },

  isZoomAuthenticated() {
    return apiService.isZoomAuthenticated().then(res => res.data)
      .catch((error: any) => {
        console.error('Error in saving zoom code', error)
      })
  },

  saveZoomCode(context: any, code: string) {
    return apiService.saveZoomCode(code)
      .catch((error: any) => {
        console.error('Error in saving zoom code', error)
      })
  },

  getAllSkills(context: any) {
    CommonApi.getAllSkills()
      .then((response: any) => {
        context.commit('setAllSkills', response.data)
      })
      .catch((error: any) => {
        console.error('Error in getting user skills', error)
      })
  },

  addAllSkills(context: any, username: string) {
    CommonApi.getAllSkills()
      .then((response: any) => {
        context.commit('setAllSkills', response.data)
      })
      .catch((error: any) => {
        console.error('Error in getting user skills', error)
      })
  },

  submitHopReportRequest(context: any, report: any) {
    return apiService.createHopReport(report)
      .then((response: any) => {
        context.commit('addHopReport', response.data)
        return response.data
      })
      .catch((error: any) => {
        console.error('Error in adding report', error)
      })
  },

  submitBugReportRequest(context: any, report: any) {
    return apiService.createBugReport(report)
      .then((response: any) => {
        context.commit('addBugReport', response.data)
        return response.data
      })
      .catch((error: any) => {
        console.error('Error in adding report', error)
      })
  },

  getReports(context: any, assignee?: string) {
    apiService.getReports(assignee)
      .then((response: any) => {
        context.commit('setReports', ObjectUtils.groupByKey(response.data, 'username'))
      })
      .catch((error: any) => {
        console.error('Error in getting tasks', error)
      })
  },

  setWaveData(context: any, payload: any) {
    context.commit('setWaveData', payload)
  },

  setNotes(context: any, payload: any) {
    context.commit('setNotes', payload)
  },

  setVoiceMessages(context: any, payload: any) {
    context.commit('setVoiceMessages', payload)
  },

  setGlobalSearchQuery(context: any, payload: any) {
    context.commit('setGlobalSearchQuery', payload)
  },

  getAllEmployeeInfo(context: any) {
    context.commit('isPageLoading', true)
    return apiService.getAllEmployeeInfo()
      .then((response: any) => {
        context.commit('isPageLoading', false)
        context.commit('setAllEmployeeInfo', response.data)
        return response.data
      })
      .catch((error: any) => {
        context.commit('isPageLoading', false)
        console.error('Error in getting employee info', error)
      })
  },

  updateEmployeeInfo(context: any, payload: any) {
    return apiService.updateEmployeeInfo(payload)
      .then((response) => {
        context.commit('updateEmployeeInfo', response.data)
      })
      .catch((error: any) => {
        console.error('Error in updating employee info', error)
      })
  },

  setSearchResults(context: any, payload: any) {
    context.commit('setSearchResults', payload)
  }
}

export default {
  ...actions,
  ...FloorActions,
  ...UserActions,
  ...MeetingActions,
  ...AudioRoomActions
}
