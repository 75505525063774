import { MatrixClient, MatrixEvent, NotificationCountType, Room } from 'matrix-js-sdk'
import store from '@/store'
import MxRoom from './MxRoom'

export default {
  notify(room: Room, event: MatrixEvent) {
    if (room.roomId === store.getters.organization.organizationChatterSpaceId) {
      store.commit('setChatterNotificationsCount', room.getUnreadNotificationCount(NotificationCountType.Total))
    } else {
      this.setTotalChatNotifications()
    }
    if (this.filterNotificationRooms(room, event)) {
      const roomName = (MxRoom.isRoomDM(room)) ? this.getDMUser(room).name : room.name
      const nofitication = new Notification(
        'Chats', {
          body: `${roomName}: ${room.getUnreadNotificationCount(NotificationCountType.Total)} new message(s)`
        }
      )
    }
  },

  getDMUser (room: Room): any {
    return store.getters.usersByUsername[MxRoom.getDmRoomName(room, store.getters.user.username)!]
  },

  filterNotificationRooms(room: Room, event: MatrixEvent) {
    /** No need to show notifications sent to chatter and notification channels. */
    const roomsToExclude = [
      store.getters.organization.organizationNotificationSpaceId
    ]
    if (
      !roomsToExclude.includes(room.roomId) &&
      MxRoom.getBloxUserId(event.sender?.userId) !== store.getters.user.username
    ) {
      return true
    }
    return false
  },

  setTotalChatNotifications() {
    const mxClient: MatrixClient = store.getters.matrixClient
    let count = 0
    mxClient.getRooms().forEach((room: Room) => {
      if (
        room.roomId !== store.getters.organization.organizationChatterSpaceId &&
        room.roomId !== store.getters.organization.organizationNotificationSpaceId
      ) {
        count += room.getUnreadNotificationCount(NotificationCountType.Total) || 0
      }
    })
    store.commit('setChatNotificationsCount', count)
  }
}
