import axios, { AxiosPromise } from 'axios'

export default {
  multipartHeader: {
    'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW'
  },

  getAllUsers (): AxiosPromise<any> {
    return axios.get('jwork/user/all')
  },

  getAttendanceByUser (username: string): AxiosPromise<any> {
    return axios.get(`jwork/attendance/${username}`)
  },

  getAttendanceByDate (date: string): AxiosPromise<any> {
    return axios.get(`jwork/attendance?date=${date}`)
  },

  getAvatar (): AxiosPromise<any> {
    return axios.get('jwork/user/picture', { responseType: 'blob' })
  },

  getAvatarByUser (username: string): AxiosPromise<any> {
    return axios.get(`jwork/user/${username}/picture`, { responseType: 'blob' })
  },

  uploadAvatar (form: string): AxiosPromise<any> {
    return axios.post('jwork/user/picture', form, {
      headers: this.multipartHeader
    })
  },

  getSkills (username: string): AxiosPromise<any> {
    return axios.get(`jwork/user/${username}/skills`)
  },

  addSkills (skills: string[]): AxiosPromise<any> {
    return axios.post('jwork/user/skills', skills)
  },

  removeSkills (skills: any): AxiosPromise<any> {
    return axios.delete('jwork/user/skills', skills)
  },

  updateUserDetails (form: any): AxiosPromise<any> {
    return axios.put('jwork/user/keycloak', form)
  },

  changePassword (password: string): AxiosPromise<any> {
    return axios.put(`jwork/user/change-password?newPassword=${password}`)
  },

  getReports (): AxiosPromise<any> {
    return axios.get('jwork/hop-report/all')
  },

  isPinSet (): AxiosPromise<any> {
    return axios.get('jwork/pin/isPinSet')
  },

  updatePin (pin: string): AxiosPromise<any> {
    return axios.post(`jwork/pin?newPin=${pin}`)
  },

  verifyPin (pin: string): AxiosPromise<any> {
    return axios.get(`jwork/pin/verify?pin=${pin}`)
  },

  updateProfileVideo (payload: any): AxiosPromise<any> {
    return axios.put(`jwork/user/avatar-video?url=${payload}`)
  },

  getUserPreferences (): AxiosPromise<any> {
    return axios.get('jwork/user/prefs')
  },

  updateUserPreferences (payload: any): AxiosPromise<any> {
    const params = new URLSearchParams({
      status: payload.status,
      prefsData: JSON.stringify(payload.userPreferences)
    })
    return axios.post(`jwork/user/prefs?${params}`)
  }
}
