
import { EventType, MatrixEvent } from 'matrix-js-sdk'
import { defineComponent, PropType } from 'vue'
import { useStore } from 'vuex'
import MxRoom from '@/common/services/MxRoom'

export default defineComponent({
	props: {
    roomId: { type: String, required: true },
    event: { type: Object as PropType<MatrixEvent>, required: true }
  },

  data () {
    return {
      message: this.event.getContent().body,
      store: useStore()
    }
  },

	methods: {
    editMessage (message: string): void {
      const newContent = {
        body: message,
        msgtype: 'm.text'
      }

      const content = {
        msgtype: newContent.msgtype,
        body: ` * ${message}`,
        'm.new_content': newContent,
        'm.relates_to': {
          rel_type: 'm.replace',
          event_id: this.event.getId()
        }
      }

      MxRoom.sendEvent(
        this.matrixClient,
        EventType.RoomMessage,
        content,
        this.roomId
      )
      this.cancel()
    },

		cancel (): void {
			this.$emit('cancel', {})
		}
	},

  computed: {
    matrixClient () {
      return this.store.getters.matrixClient
    }
  }
})
