
import { useStore } from 'vuex'
import { defineComponent } from 'vue'
import moment from 'moment'
import StatusUtils from '@/common/services/StatusUtils'
import StatusDescriptionModal from '@/components/StatusDescriptionModal.vue'
import { UserStatus } from '../enums'

export default defineComponent({
  components: {
    StatusDescriptionModal
  },

  data() {
    return {
      store: useStore(),
      moment: moment,
      userStatus: UserStatus,
      statusUtils: new StatusUtils(),
      isStatusDescriptionModalVisible: false
    }
  },

  methods: {
    toggleModal(e: any, popup: any): void {
      this.$emit('togglePopup', e, popup)
    },

    toggleStatusDescriptionModal() {
      this.isStatusDescriptionModalVisible = !this.isStatusDescriptionModalVisible
    },

    updateUserStatus(status: UserStatus, statusDescription?: string) {
      if (status === UserStatus.UNAVAILABLE) {
        this.toggleStatusDescriptionModal()
      } else {
        this.statusUtils.manuallyUpdateStatus(status, statusDescription)
        this.$emit('togglePopup')
      }
    }
  },

  computed: {
    user(): any {
      return this.store.getters.user
    }
  }
})
