
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
// import SurgeTile from './SurgeTile.vue'

export default defineComponent({
  components: {
    // SurgeTile
  },
  data () {
    return {
      store: useStore()
    }
  }
})
