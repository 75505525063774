
import { useStore } from 'vuex'
import { defineComponent, getCurrentInstance } from 'vue'
import { Roles } from '../enums'
import ImageCropper from '@/components/ImageCropper.vue'
import UserPreference from '@/components/AccountSettings.vue'
import Attendance from '../services/Attendance'
import { importIpcRenderer, ipcRenderer, keycloak } from '@/config/PlatformConfig'

importIpcRenderer()

export default defineComponent({
  components: {
    ImageCropper,
    UserPreference
  },

  data() {
    return {
      store: useStore(),
      orgRoles: Roles,
      selectedStatus: '',
      selectedAvatar: null as any,
      isImageCropperVisible: false,
      fileReader: new FileReader(),
      openModal: '',
      accountPage: process.env.VUE_APP_KEYCLOAK_ACCOUNT_URL,
      emitter: getCurrentInstance()?.appContext.config.globalProperties.emitter
    }
  },

  mounted() {
    this.selectedStatus = this.user.status
    this.fileReader.onload = event => {
      this.selectedAvatar = event.target!.result
    }
  },

  methods: {
    setOpenModal(modal: string) {
      this.openModal = modal
    },

    close(): any {
      this.$emit('close')
    },

    createOrganization(e: any): any {
      this.$emit('togglePopup', e, 'create-organization')
    },

    gotoOrganizationSettings() {
      this.$router.push('organization-details')
      this.close()
    },

    inviteMembers(e: any): any {
      this.$emit('togglePopup', e, 'invite-members')
    },

    toggleModal(e: any, popup: any): void {
      this.$emit('togglePopup', e, popup)
    },

    isSelectOptionDisabled(userStatus: string) {
      return userStatus === this.user.status
    },

    clickAvatarUpload() {
      const fileUploadFieldClick = document
        .getElementById('file-upload')
        ?.click()
    },

    uploadAvatar(e: any) {
      if (e.target.files.length) {
        const file = e.target.files[0]
        this.fileReader.readAsDataURL(file)
        this.isImageCropperVisible = true
      }
    },

    addUserTitle(e: any) {
      this.store.dispatch('addUserTitle', e.target.value)
    },

    reportBug(e: any, popup: any): void {
      this.$emit('reportBug', e, popup)
    },

    punchout(): any {
      if (ipcRenderer) {
        ipcRenderer.on('PUNCH_OUT_CONFIRMATION', () => {
          this.beforeLogout()
        })
        ipcRenderer.invoke('PUNCH_OUT')
      } else {
        this.beforeLogout()
      }
    },

    beforeLogout() {
      Attendance.punchOut(this.emitter)
      this.store.dispatch('updateUserStatus', { status: 'LEFT', statusDescription: '' })
      this.store.dispatch('isPinSet').then((isPinSet: boolean) => {
        if (isPinSet) {
          /** Show pin login page. */
          this.store.commit('setLoginWithPin', true)
        } else {
          /** Go to username-password login page.  */
          this.logout()
        }
      })
    },

    logout() {
      this.store.dispatch('updateUserStatus', { status: 'LEFT', statusDescription: '' })
        .then(() => {
          localStorage.clear()
          keycloak.logout()
        })
    },

    closeImageCropper() {
      this.isImageCropperVisible = false
    }
  },

  computed: {
    user(): any {
      return this.store.getters.user
    },

    roles(): any {
      return this.store.getters.roles
    },

    avatar(): any {
      return this.store.getters.avatar
    },

    organization(): any {
      return this.store.getters.organization
    }
  }
})
