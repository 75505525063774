
import MxRoom from '@/common/services/MxRoom'
import sdk, { Room, MatrixClient } from 'matrix-js-sdk'
import { CallErrorCode, CallEvent, CallState, CallType, MatrixCall } from 'matrix-js-sdk/lib/webrtc/call'
import { CallFeed } from 'matrix-js-sdk/lib/webrtc/callFeed'
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import MediaDeviceHandler from '../services/MediaDeviceHandler'

// TODO: Added as a temporary fix as defining type for this variable inside 'data' is not woorking.
let matrixCall: MatrixCall

export default defineComponent({
  data() {
    return {
      store: useStore(),
      mediaDeviceHandler: new MediaDeviceHandler(),
      isAnswerBtnVisible: false
    }
  },

  async mounted() {
    await this.mediaDeviceHandler.requestMediaPermissions(this.store.getters.matrixClient)
    this.matrixClient.on('Call.incoming', (call: MatrixCall) => this.onIncomingCall(call))
  },

  methods: {
    async placeCall(type?: string) {
      await this.mediaDeviceHandler.requestMediaPermissions(this.store.getters.matrixClient)
      matrixCall = sdk.createNewMatrixCall(
        this.store.getters.matrixClient, this.room.roomId
      )
      this.addListeners(matrixCall)
      if (type && type === 'dropIn') matrixCall.callId = type + matrixCall.callId
      this.ring()
      matrixCall.placeVideoCall()
    },

    hangupCall() {
      matrixCall.hangup(CallErrorCode.UserHangup, false)
      this.pauseRing()
      this.$emit('setCallView', false)
    },

    answerCall() {
      matrixCall.answer()
      this.isAnswerBtnVisible = false
      this.pauseRing()
    },

    onIncomingCall(call: MatrixCall) {
      if (call.type === CallType.Video) {
        matrixCall = call
        this.$emit('setCallView', true)
        this.isAnswerBtnVisible = true
        this.addListeners(call)
        if (call.callId.includes('dropIn') && this.user.status !== 'UNAVAILABLE') {
          this.ringForDropIn()
          this.answerCall()
        } else this.ring()
      }
    },

    ring() {
      const audio = document.getElementById('ringAudio') as HTMLAudioElement
      audio.play()
    },

    pauseRing() {
      const audio = document.getElementById('ringAudio') as HTMLAudioElement
      audio.pause()
    },

    ringForDropIn() {
      const audio = document.getElementById('dropInAlert') as HTMLAudioElement
      audio.play()
    },

    addListeners(call: MatrixCall) {
      call.on(CallEvent.Hangup, () => {
        this.pauseRing()
        this.$emit('setCallView', false)
      })

      call.on(CallEvent.Error, (err: Error) => {
        this.pauseRing()
        console.error('Call error!!', err)
      })

      call.on(CallEvent.FeedsChanged, (feeds: CallFeed[]) => {
        const localFeed = feeds.find((feed) => feed.isLocal())
        const remoteFeed = feeds.find((feed) => !feed.isLocal())

        const remoteElement = document.getElementById('remote')! as HTMLVideoElement
        const localElement = document.getElementById('local')! as HTMLVideoElement

        if (remoteFeed) {
          this.pauseRing()
          remoteElement.srcObject = remoteFeed.stream
          remoteElement.play()
        }
        if (localFeed) {
          localElement.muted = true
          localElement.srcObject = localFeed.stream
          localElement.play()
        }
      })
    },

    openJitsiCall() {
      // this.store.commit('setJitsiVideoCallActive', true)
      this.hangupCall()
    }
  },

  computed: {
    matrixClient(): MatrixClient {
      return this.store.getters.matrixClient
    },

    room(): Room {
      return this.store.getters.currentRoom.room
    },

    user(): any {
      return this.store.getters.user
    },

    usersByUsername(): any {
      return this.store.getters.usersByUsername
    },

    roomName(): string | undefined {
      if (this.room && this.room.roomId && this.matrixClient) {
        const callRoom = MxRoom.getDMUser(this.room)
        return this.usersByUsername[MxRoom.getDMUser(this.room)]?.name
      }
      return ''
    }
  }
})
