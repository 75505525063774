<template>
	<div class="content-container">
		<div v-if="store.getters.feedRoom">
			<div class="clearfix"></div>
			<div class="tile-container">
				<!-- <div class="feed-tile-2w">
					<CreatePost @sendEvent="sendEvent"/>
				</div> -->
				<PostTile v-if="feedRoom?.getLiveTimeline" />
				<!-- <div class="tile">
					<FeedPoll />
				</div>
				<div class="tile">
					<FeedEvent />
				</div> -->
      </div>
		</div>
	</div>
</template>
<script lang="script" src="./Feed.ts"></script>
<style scoped lang="scss" src="./Feed.scss"></style>
