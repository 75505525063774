import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "chat container container-conten" }
const _hoisted_2 = { class: "d-flex justify-content-start" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RoomList = _resolveComponent("RoomList")!
  const _component_RoomView = _resolveComponent("RoomView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("main", _hoisted_2, [
      _createVNode(_component_RoomList, { rooms: _ctx.matrixRooms }, null, 8, ["rooms"]),
      (_ctx.store.getters.currentRoom.room)
        ? (_openBlock(), _createBlock(_component_RoomView, {
            key: 0,
            onScrollBack: _ctx.scrollBack
          }, null, 8, ["onScrollBack"]))
        : _createCommentVNode("", true)
    ])
  ]))
}