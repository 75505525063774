
import { defineComponent, PropType } from 'vue'
import MxRoom from '@/common/services/MxRoom'
import { EventType, MatrixClient, MatrixEvent, Room } from 'matrix-js-sdk'
import OptionsTooltip from './OptionsTooltip.vue'
import ForwardChatModal, { UserMultiselectItem } from '@/common/components/UserMultiselectModal.vue'
import { useStore } from 'vuex'

export default defineComponent({
  components: { OptionsTooltip, ForwardChatModal },

	props: {
    isVisible: { type: Boolean, default: false },
		room: { type: Object as PropType<Room>, required: true },
    event: { type: Object as PropType<MatrixEvent>, required: true }
	},

  data () {
    return {
      store: useStore(),
      modalProps: {
        title: 'Forward to',
        buttonLabel: 'send',
        list: [] as UserMultiselectItem[],
        selected: [],
        metadata: this.event
      },
      isForwardModalVisible: false,
      isTooltipVisible: false,
      tooltipPos: { top: '0', left: '0' },
      tooltipOptions: [
        {
          title: 'Info',
          icon: 'icon-info-grey-16.svg',
          onClick: this.getMessageInfo.bind(this)
        },
        {
          title: 'Edit',
          icon: 'icon-edit-grey-16.svg',
          onClick: this.editMessage.bind(this)
        },
        {
          title: 'Pin',
          icon: 'icon-pin-grey-16.svg',
          onClick: this.pinMessage.bind(this)
        },
        {
          title: 'Reply',
          icon: 'icon-reply-grey-16.svg',
          onClick: this.replyTo.bind(this)
        },
        {
          title: 'Forward',
          icon: 'icon-forward-grey-16.svg',
          onClick: this.onForwardMessageClick?.bind(this)
        },
        {
          title: 'Delete',
          icon: 'icon-delete-red-16.svg',
          onClick: this.deleteMessage.bind(this),
          style: ''
        }
      ]
    }
  },

  methods: {
    showOptionsTooltip (e: MouseEvent) {
      this.isTooltipVisible = true
      this.tooltipPos = { top: e.clientY + 12 + 'px', left: e.clientX - 90 + 'px' }
    },

    hideTooltip () {
      this.isTooltipVisible = false
    },

    reply () {
      this.$emit('reply', this.event)
    },

    toggleEmojiPicker (clickEvent: MouseEvent) {
      this.$emit('emojiPicker', clickEvent, this.event)
    },

    getMessageInfo () {
      /** TODO */
    },

    editMessage () {
      this.$emit('edit', this.event)
    },

    deleteMessage () {
      this.room.removeEvent(this.event.getId())
      this.hideTooltip()
    },

    pinMessage () {
      const cli = this.matrixClient
      const room = cli.getRoom(this.event.getRoomId()!)
      const eventId = this.event.getId()

      const pinnedIds = room?.currentState?.getStateEvents(EventType.RoomPinnedEvents, '')?.getContent().pinned || []
      if (pinnedIds.includes(eventId)) {
          pinnedIds.splice(pinnedIds.indexOf(eventId), 1)
      } else {
          pinnedIds.push(eventId)
          cli.setRoomAccountData(room.roomId, 'ReadPinsEventId', {
              event_ids: [
                ...(room.getAccountData('ReadPinsEventId')?.getContent()?.event_ids || []),
                eventId
              ]
          })
      }
      cli.sendStateEvent(this.event.getRoomId()!, EventType.RoomPinnedEvents, { pinned: pinnedIds }, '')
    },

    replyTo () {
      /** TODO */
    },

    onForwardMessageClick () {
      this.hideTooltip()
      this.isForwardModalVisible = true
      this.modalProps.list = MxRoom.mapToRoomOverview(this.rooms, this.usersByUsername)
    },

    forwardMessage (selected: UserMultiselectItem[], event: MouseEvent, data: MatrixEvent) {
      const content = data.getContent()
      selected.forEach((eachRoom: UserMultiselectItem) => {
        MxRoom.sendEvent(this.matrixClient, EventType.RoomMessage, content, eachRoom.username!)
      })
      this.isForwardModalVisible = false
    },

    closeModal () {
      this.isForwardModalVisible = false
    }
  },

  computed: {
    matrixClient (): MatrixClient {
      return this.store.getters.matrixClient
    },

    usersByUsername (): any {
      return this.store.getters.usersByUsername
    },

    rooms (): Room[] {
      return this.store.getters.matrixRooms
    }
  }
})
