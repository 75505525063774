
import { defineComponent } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  data () {
    return {
      store: useStore()
    }
  },

  computed: {
    user (): any {
      return this.store.getters.user
    }
  }
})
