import FloorApi from '@/common/services/api/FloorApi'

const actions = {
  getAllFloors (context: any) {
    FloorApi.getAllFloors()
      .then((response: any) => {
        context.commit('setAllFloors', response.data)
      })
      .catch((error: any) => {
        console.error('Error in getting all floors', error)
      })
  },

  createFloor (context: any, payload: any) {
    return FloorApi.createFloor(payload)
    .then((response: any) => {
      context.dispatch('getAllFloors')
      return response.data
    }).catch((error: any) => {
      console.error('Error in creating floors', error)
    })
  },

  editFloor (context: any, payload: any) {
    return FloorApi.editFloor(payload.floorId, payload.dto)
    .then((response: any) => {
      context.dispatch('getAllFloors')
      return response.data
    }).catch((error: any) => {
      console.error('Error in getting all floors', error)
    })
  },

  addRoom (context: any, payload: any) {
    return FloorApi.addRoom(payload.floorId, payload.position, payload.dto)
    .then((response: any) => {
      context.dispatch('getAllFloors')
      return response.data
    }).catch((error: any) => {
      console.error('Error in getting all floors', error)
    })
  }
}

export default actions
