import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import { EventType, IContent, MatrixClient, MatrixEvent, Room } from 'matrix-js-sdk'
import { globalMatrixClient } from '@/initMatrix'
import CreatePost from '../create-post/CreatePost.vue'
import FeedEvent from '../feed-event/FeedEvent.vue'
import FeedPoll from '../feed-poll/FeedPoll.vue'
import PostTile from '../post-tile/PostTile.vue'

export default defineComponent({
	components: {
		CreatePost, FeedEvent, FeedPoll, PostTile
	},

	data () {
		return {
			store: useStore()
		}
	},

	async mounted () {
		// if (this.matrixClient === null || this.matrixClient === undefined) {
		// 	await this.store.commit('setMatrixClient', globalMatrixClient)
		// }
		// this.listenMessageEvents()
  },

	methods: {
		/**
		 * TODO: make these functions common for chats and feed
		 */
		async sendEvent (eventType: EventType, content: IContent) {
			await this.matrixClient.sendEvent(this.feedRoom.roomId, eventType, content, '')
			// this.updateRoom()
		}
	},

	computed: {
    matrixClient (): MatrixClient {
      return this.store.getters.matrixClient
    },

		feedRoom (): Room {
			return this.store.getters.feedRoom
		}
	}
})
