
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
import TimeUtilityService from '@/common/services/TimeUtilityService'
import StringUtils from '@/common/services/StringUtils'
import StatusUtils from '@/common/services/StatusUtils'
import { UserStatus } from '@/common/enums'

export default defineComponent({
  data () {
    return {
      store: useStore(),
      moment: moment,
      userStatus: UserStatus,
			timeUtilityService: new TimeUtilityService(),
      statusUtils: new StatusUtils(),
      stringUtility: StringUtils
    }
  },

  methods: {
		updateWebcamshot () {
      new StatusUtils().updateStatusAndTimeline()
    },

    getLiveIndicatorElement() {
      return document.getElementById('liveIndicator')
    }
	},

  computed: {
    user (): any {
      return this.store.getters.user
    },

		usersByUsername (): any {
			return this.store.getters.usersByUsername
		}
  }
})
