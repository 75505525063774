import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-143765a6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex justify-content-end mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createElementVNode("input", {
      type: "text",
      class: "bottom-border",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.message) = $event))
    }, null, 512), [
      [_vModelText, _ctx.message]
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", {
        class: "btn btn-small ghost-white m-1",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.editMessage(_ctx.message)))
      }, "save"),
      _createElementVNode("button", {
        class: "btn btn-small ghost-red m-1",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args)))
      }, "cancel")
    ])
  ]))
}