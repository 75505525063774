import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/img/icon-round-prev.svg'
import _imports_1 from '@/assets/img/icon-round-next.svg'


const _withScopeId = n => (_pushScopeId("data-v-cdb51032"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "position-relative" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "webcam-container exclude-from-outside-click mt-2" }
const _hoisted_4 = ["src", "onClick"]
const _hoisted_5 = { class: "fs-8" }
const _hoisted_6 = {
  key: 1,
  class: "webcam"
}
const _hoisted_7 = {
  key: 0,
  class: "detail"
}
const _hoisted_8 = { class: "screenshot" }
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "selected" }
const _hoisted_11 = ["src"]
const _hoisted_12 = {
  key: 1,
  class: "fs-8 text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.webcamshots)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.timelineReversed, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: item.submittedOn,
                class: "webcam-wrapper exclude-from-outside-click"
              }, [
                (item.status !== 'UNAVAILABLE' && item.status !== 'AWAY')
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass({ 'active-webcam': index === _ctx.selectedTimelineIndex })
                    }, [
                      _createElementVNode("img", {
                        class: "webcam exclude-from-outside-click",
                        src: _ctx.webcamshots[item?.submittedOn],
                        onClick: _withModifiers(($event: any) => (_ctx.setExpandedItem(item, index)), ["stop"])
                      }, null, 8, _hoisted_4),
                      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.moment(item.submittedOn * 1000).format('hh:mm A')), 1)
                    ], 2))
                  : (_openBlock(), _createElementBlock("div", _hoisted_6))
              ]))
            }), 128))
          ]),
          (_ctx.timelineReversed[_ctx.selectedTimelineIndex]?.status !== 'UNAVAILABLE' && _ctx.timelineReversed[_ctx.selectedTimelineIndex]?.status !== 'AWAY')
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("img", {
                  src: _imports_0,
                  alt: "<",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.gotoPrevious && _ctx.gotoPrevious(...args))),
                  class: "cursor-pointer"
                }),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("img", {
                    src: require('@/assets/img/ss-dummy/ss' + (Math.floor(Math.random() * 5) + 1) + '.jpg')
                  }, null, 8, _hoisted_9)
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("img", {
                    src: _ctx.webcamshots[_ctx.timelineReversed[_ctx.selectedTimelineIndex]?.submittedOn]
                  }, null, 8, _hoisted_11),
                  _createElementVNode("div", {
                    class: _normalizeClass(["time", _ctx.timelineReversed[_ctx.selectedTimelineIndex]?.status])
                  }, [
                    _createElementVNode("div", null, [
                      _createElementVNode("span", {
                        class: _normalizeClass(_ctx.timelineReversed[_ctx.selectedTimelineIndex]?.status)
                      }, "●", 2),
                      _createTextVNode(" " + _toDisplayString(_ctx.statusUtils.capitalizeStatus(_ctx.timelineReversed[_ctx.selectedTimelineIndex]?.status)), 1)
                    ]),
                    _createElementVNode("div", null, _toDisplayString(_ctx.moment(_ctx.timelineReversed[_ctx.selectedTimelineIndex]?.submittedOn * 1000).calendar(null, {
        sameDay: '[Today at] hh:mm a',
        lastDay: '[Yesterday at] hh:mm a',
        lastWeek: 'MMM DD [at] hh:mm a',
        sameElse: 'MMM DD [at] hh:mm a'
      })), 1)
                  ], 2)
                ]),
                _createElementVNode("img", {
                  src: _imports_1,
                  alt: "<",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.gotoNext && _ctx.gotoNext(...args))),
                  class: "cursor-pointer"
                })
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_12, " User is " + _toDisplayString(_ctx.statusUtils.capitalizeStatus(_ctx.timelineReversed[_ctx.selectedTimelineIndex]?.status)) + " for this period ", 1))
        ]))
      : _createCommentVNode("", true)
  ]))
}