import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const state = {
	// feedRoom: null
}

const feedModule = {
  state,
  getters,
  mutations,
  actions
}

export default feedModule
