import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import UtilityService from '@/common/services/TimeUtilityService'
import { IContent, MatrixClient, Room } from 'matrix-js-sdk'
import MxRoom from '@/common/services/MxRoom'
import PostContent from '../post-content/PostContent.vue'
import FeedExpanded from '../feed-expanded/FeedExpanded.vue'

export default defineComponent({
	components: {
		PostContent, FeedExpanded
	},

	data () {
		return {
			store: useStore(),
			feedRoom: {} as Room,
			utilityService: new UtilityService(),
			content: {} as IContent
		}
	},

	methods: {
		getUserAvatar (userId: string): string {
			const username = userId.slice(1, userId.indexOf(':'))
			return this.store.getters.usersByUsername[username]?.avatarUrl
		},

		openFeed (event: any): void {
			this.$router.push({
				name: 'FeedExpanded',
				path: `/mx/feed/post/${event.event.event_id}`,
				params: { id: event.event.event_id }
			})
		}
	},

	computed: {
    matrixClient (): MatrixClient {
      return this.store.getters.matrixClient
    },

		feedRoomData (): Room {
			return this.store.getters.feedRoom
		}
	}
})
