import axios, { AxiosPromise } from 'axios'

export default {
  getAllFloors (): AxiosPromise<any> {
    return axios.get('blox/floors')
  },

  createFloor (dto: any): AxiosPromise<any> {
    return axios.post('blox/organizations/floors', [dto])
  },

  editFloor (floorId: string, dto: any): AxiosPromise<any> {
    return axios.put(`blox/floors/${floorId}`, dto)
  },

  addRoom (floorId: string, position: number, dto: any): AxiosPromise<any> {
    return axios.post(`blox/floors/${floorId}/rooms/${position}`, dto)
  }
}
