
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
// import Skills from './Skills.vue'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import AccountSettings from './AccountSettings.vue'
import PinConfigModal from './PinConfigModal.vue'
import ChangePasswordModal from './ChangePasswordModal.vue'

export default defineComponent({
  components: {
    // Skills
    flatPickr,
    AccountSettings,
    PinConfigModal,
    ChangePasswordModal
  },

  data() {
    return {
      store: useStore(),
      moment: moment,
      form: {} as any,
      openModal: ''
    }
  },

  mounted() {
    this.store.dispatch('getAllEmployeeInfo')
      .then((response) => {
        this.form = response
      })
  },

  methods: {
    submitInfoRequest(): void {
      this.store.dispatch('updateEmployeeInfo', this.form)
    },

    deleteItem(section: any, item: any) {
      this.form[section] = this.form[section].filter((filter: any) => filter !== item)
    },

    setOpenModal(modal: string) {
      this.openModal = modal
    },

    newProject(): void {
      if (this.form.projects.length === 0) {
        this.form.projects.push({
          title: '',
          description: '',
          startDate: '',
          endDate: '',
          mediaUrl: ''
        })
      } else if (this.form.projects[this.form.projects.length - 1].title !== (undefined || '')) {
        this.form.projects.push({
          title: '',
          description: '',
          startDate: '',
          endDate: '',
          mediaUrl: ''
        })
      }
    },

    newExperience(): void {
      if (this.form.experience.length === 0) {
        this.form.experience.push({
          companyName: '',
          location: '',
          role: '',
          description: '',
          startDate: '',
          endDate: '',
          mediaUrl: ''
        })
      } else if (this.form.experience[this.form.experience.length - 1].companyName !== (undefined || '')) {
        this.form.experience.push({
          companyName: '',
          location: '',
          role: '',
          description: '',
          startDate: '',
          endDate: '',
          mediaUrl: ''
        })
      }
    },

    newEducation(): void {
      if (this.form.education.length === 0) {
        this.form.education.push({
          school: '',
          course: '',
          location: '',
          description: '',
          startDate: '',
          endDate: '',
          mediaUrl: ''
        })
      } else if (this.form.education[this.form.education.length - 1].school !== (undefined || '')) {
        this.form.education.push({
          school: '',
          course: '',
          location: '',
          description: '',
          startDate: '',
          endDate: '',
          mediaUrl: ''
        })
      }
    },

    targetDate(date: any, dateInfo: string) {
      if (date === '') {
        return dateInfo
      } else {
        return moment(date).format('YYYY-MM-DD')
      }
    },

    updateDate(project: any, section: string, myDate: any) {
      project[section] = myDate.concat('T00:00:00.000Z'.toString())
      project[section] = moment(project[section]).toISOString()
    }
  },

  computed: {
    user(): any {
      return this.store.getters.user
    },

    usersByUsername(): any {
      return this.store.getters.usersByUsername
    },

    info(): any {
      return this.store.getters.employeeInfo
    }
  }
})
