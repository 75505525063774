import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3aa41fea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-backdrop" }
const _hoisted_2 = {
  class: "modal",
  id: "post-modal",
  role: "dialog",
  "aria-labelledby": "modalTitle",
  "aria-describedby": "modalDescription"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CreatePost = _resolveComponent("CreatePost")!
  const _directive_outside_click = _resolveDirective("outside-click")!

  return (_openBlock(), _createBlock(_Transition, { name: "modal-fade" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_CreatePost)
        ])), [
          [_directive_outside_click, _ctx.close]
        ])
      ])
    ]),
    _: 1
  }))
}