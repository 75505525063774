
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import SearchInput from '@/common/components/SearchInput.vue'

export default defineComponent({
  components: {
    SearchInput
  },

  data () {
    return {
      store: useStore()
    }
  },

  computed: {
    user (): any {
      return this.store.getters.user
    }
  }
})
