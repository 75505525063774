
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import successToastButtons from '@/assets/json/successToastButtons.json'

export default defineComponent({
  data() {
    return {
      store: useStore(),
      buttons: successToastButtons as any
    }
  },

  methods: {
    toggleModal(): void {
      this.store.dispatch('toggleSuccess', false)
    },

    invoke(methodName: any) {
      (this as any)[methodName]!()
    },

    dismiss() {
      this.toggleModal()
    }
  },

  computed: {
    successMessageData(): any {
      return this.store.getters.showSuccess
    }
  }
})
