import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import UtilityService from '@/common/services/TimeUtilityService'
import { MatrixEvent, MatrixClient, Room } from 'matrix-js-sdk'
import { decryptFile } from '@/common/services/MxEventContentHandler'
import { init } from '@matrix-org/olm'

export default defineComponent({
	data () {
		return {
			store: useStore(),
			post: {} as MatrixEvent | undefined,
			utilityService: new UtilityService(),
			id: '',
			avatar: '',
			mediaObjectUrl_: ''
		}
	},

	methods: {
		async init () {
			if (this.$route.params.id) {
				this.id = this.$route.params.id.toString()
				// const members = (await this.feedRoom.getEncryptionTargetMembers()).map(x => x.userId)
				// const memberkeys = await this.matrixClient.downloadKeys(members)
				// for (const userId in memberkeys) {
				// 	for (const deviceId in memberkeys[userId]) {
				// 		await this.matrixClient.setDeviceVerified(userId, deviceId)
				// 	}
				// }

				await this.feedRoom.decryptAllEvents()
				this.post = await this.feedRoom.findEventById(this.id)

				if (this.post !== undefined) {
					if (this.post.isBeingDecrypted()) {
						await this.post.getDecryptionPromise()
					}

					// if (this.post.getContent().msgtype === 'm.bad.encrypted') {
						// await this.feedRoom.decryptAllEvents()
						// this.post = await this.feedRoom.findEventById(this.id)!
					// }

					this.avatar = this.getUserAvatar(this.post.sender.userId)
					if (this.post.getContent().msgtype === 'm.image') {
						this.createMediaUrl(this.post)
					}
				}
			}
		},

		// TODO - make his function common
		getUserAvatar (userId: string): string {
			const username = userId.slice(1, userId.indexOf(':'))
			return this.store.getters.usersByUsername[username]?.avatarUrl
		},

		createMediaUrl (post: any) {
      if (post!.getContent().file) {
				if (post!.isEncrypted()) {
					decryptFile(this.store.getters.matrixClient, post.getContent().file).then(blob => {
            this.mediaObjectUrl_ = URL.createObjectURL(blob)
          })
        } else {
					this.mediaObjectUrl_ = this.store.getters.matrixClient.mxcUrlToHttp(post.getContent().file.url)
        }
      }
    }
	},

	computed: {
		isFeedRoomReady (): boolean {
			if (this.feedRoom) {
				this.init()
				return true
			}
			return false
		},

    matrixClient (): MatrixClient {
      return this.store.getters.matrixClient
    },

		feedRoom (): Room {
			return this.store.getters.feedRoom
		},

		feedRoomId (): string {
			return this.store.getters.feedRoomId
		},

		mediaObjectUrl (): string {
			return this.mediaObjectUrl_
		}
	}
})
