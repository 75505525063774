
import { IContent, MatrixClient, MatrixEvent, Room } from 'matrix-js-sdk'
import { defineComponent, PropType } from 'vue'
import { useStore } from 'vuex'
import { decryptFile } from '@/common/services/MxEventContentHandler'
import MessageEdit from './MessageEdit.vue'
import { CustomMxEvent } from '@/common/enums'

export default defineComponent({
  components: { MessageEdit },

  props: {
    room: { type: Object as PropType<Room>, required: true },
    event: { type: Object as PropType<MatrixEvent>, required: true },
    content: { type: Object as PropType<IContent>, required: true },
    isEncrypted: { type: Boolean },
    isEditView: { type: Boolean }
  },

  data () {
    return {
      store: useStore(),
      customMxEvent: CustomMxEvent,
      newMessage: this.content.body,
      mediaObjectUrl_: '',
      isEdit: this.isEditView
    }
  },

  async mounted () {
    this.createMediaUrl()
    // TODO: This is kind of a hack to update room timeline after sending attachments. Find a better way.
    // this.store.getters.matrixClient.on('Room.timeline', (event: MatrixEvent) => {
    //   if (event.getRoomId() === this.room.roomId) {
    //     console.error('force update')
    //     this.$forceUpdate()
    //   }
    // })
    if (this.content.msgtype === CustomMxEvent.NOTIFICATION) {
      console.log('custom event......', this.event)
    }
    if (this.event.getType() === 'Room.myMembership') {
      console.log('room event......', this.event)
    }
  },

  methods: {
    createMediaUrl () {
      if (this.content.file) {
        if (this.isEncrypted) {
          decryptFile(this.matrixClient, this.content.file).then(blob => {
            this.mediaObjectUrl_ = URL.createObjectURL(blob)
          })
        } else {
          this.mediaObjectUrl_ = this.matrixClient.mxcUrlToHttp(this.content.file.url) || ''
        }
      }
    },

    async highlightHyperlinks (text: string) {
      return text.replace(/([\w+]+\.)+[\w+]+/g, '<a href="http://$&">$&</a>')
    },

    cancelEdit (): void {
      this.$emit('cancelEdit', {})
    }
  },

  computed: {
    mediaObjectUrl () {
      return this.mediaObjectUrl_
    },

    matrixClient (): MatrixClient {
      return this.store.getters.matrixClient
    },

    isComponentLoading (): any {
      return this.store.getters.isComponentLoading
    }
  }
})
