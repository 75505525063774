<template>
  <div class="container container-content">
    <div class="middle-content ms-3">
      <div
        class="room conference-room m-3 ms-0 cursor-pointer"
        :id="'cr-' + (index + 1)"
        v-for="(table, index) in audioRoom?.tables"
        v-bind:key="index"
        @click="joinTable(table)"
      >
        <div class="label text-capitalize">{{ audioRoom?.title }}/Table {{ index + 1 }}</div>
        <div class="d-flex justify-content-between">
          <div class="d-flex justify-content-end p-3">
            <div
              v-for="(participant, index) in table?.members"
              v-bind:key="index"
            >
              <div
                class="avatar avatar-bg avatar"
                :style="{
                  'background-image':
                    `url(${usersByUsername[participant?.username]?.avatarUrl}),
                                       url(${require('@/assets/img/avatar-placeholder.svg')})`
                }"
              ></div>
              <div>{{ usersByUsername[participant?.username] }}</div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="room conference-room m-3 ms-0 cursor-pointer"
        :id="'cr-' + (audioRoom?.tables?.length + 1)"
        @click="createNewTable"
      >
        <div class="label text-capitalize">{{ audioRoom?.title }}/Table {{ audioRoom?.tables?.length + 1 }}</div>
        <div class="d-flex justify-content-between m-2">
          <div class="d-flex justify-content-start">
            <div class="d-flex flex-column align-items-start">
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <div class="d-flex p-3">
            <button class="btn primary-green fs-14 m-3 br-8">Join</button>
          </div>
        </div>
      </div>
    </div>
    <div
      id="jitsiAudioContainer"
      ref="jitsiAudioContainer"
    ></div>
  </div>
</template>

<script lang="js">
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import { importIpcRenderer, ipcRenderer } from '@/config/PlatformConfig'
import AudioRoomApi from '@/common/services/api/AudioRoomApi'
import { User } from '@/common/types/UserInterface'
import { Organization } from '@/modules/onboarding/interfaces'

importIpcRenderer()

export default defineComponent({
  data() {
    return {
      store: useStore(),
      isMeetingActive: false,
      audioRoom: {},
      options: {
        width: '100%',
        height: '100%',
        noSSL: false,
        configOverwrite: {
          startWithVideoMuted: true,
          enableNoisyMicDetection: false,
          disableModeratorIndicator: true,
          prejoinConfig: { enabled: false },
          prejoinPageEnabled: false,
          toolbarButtons: ['hangup', 'microphone', 'tileview', 'raisehand'],
          logoImageUrl: ''
        },
        interfaceConfigOverwrite: {
          DEFAULT_LOGO_URL: '',
          SHOW_JITSI_WATERMARK: false,
          SHOW_WATERMARK_FOR_GUESTS: false,
          HIDE_DEEP_LINKING_LOGO: true,
          SHOW_BRAND_WATERMARK: false,
          SHOW_CHROME_EXTENSION_BANNER: false,
          SHOW_PROMOTIONAL_CLOSE_PAGE: false,
          VIDEO_LAYOUT_FIT: 'height'
        }
      },
      jitsiApi: {}
    }
  },

  async mounted() {
    this.audioRoom = await this.store.dispatch('getAudioRoomById', this.$route.params.id)
    this.loadScript(`https://${process.env.VUE_APP_JITSI_URL}/external_api.js`)
  },

  methods: {
    loadScript(src, cb) {
      const scriptEl = document.createElement('script')
      scriptEl.src = src
      scriptEl.async = true
      document.querySelector('head').appendChild(scriptEl)
      scriptEl.addEventListener('load', cb)
    },

    async createNewTable() {
      await this.store.dispatch('createAudioRoomTable', {
        audioRoomId: this.$route.params.id
      })
      this.audioRoom = await this.store.dispatch('getAudioRoomById', this.$route.params.id)
    },

    async joinTable(table) {
      await this.store.dispatch('joinTable', table.id)
      this.audioRoom = await this.store.dispatch('getAudioRoomById', this.$route.params.id)
      console.log('Uadio room..', this.audioRoom)
      this.isMeetingActive = true
      this.embedJitsiWidget(table)
    },

    embedJitsiWidget(table) {
      const options = {
        ...this.options,
        userInfo: {
          email: this.user.email,
          username: this.user.username,
          displayName: this.user.name,
          avatarUrl: this.store.getters.usersByUsername[this.user.username].avatarUrl
        },
        roomName: table.id,
        parentNode: document.querySelector('#jitsiAudioContainer')
      }

      this.jitsiApi = new window.JitsiMeetExternalAPI(process.env.VUE_APP_JITSI_URL, options)

      this.jitsiApi.addEventListener('videoConferenceJoined', (res) => {
        console.log('joined conference: ', res)
      })

      this.jitsiApi.on('participantJoined', (res) => {
        console.log('joined conference particpant: ', res)
      })

      this.jitsiApi.on('readyToClose', () => {
        console.log('readyToClose')
        if (this.table.creatorUsername === this.user.username) {
          // End meeting
        }
        this.leaveTable(table)
      })

      this.jitsiApi.on('videoConferenceLeft', () => {
        console.log('videoConferenceLeft')
        this.leaveTable(table)
      })
    },

    async leaveTable(table) {
      document.getElementById('jitsiAudioContainer').innerHTML = ''
      this.jitsiApi.dispose()

      this.isMeetingActive = false
      const res = await this.store.dispatch('leaveTable', table.id)
      console.log('leave: ', res)
      this.audioRoom = await this.store.dispatch('getAudioRoomById', this.$route.params.id)
    }
  },

  computed: {
    user() {
      return this.store.getters.user
    },

    usersByUsername() {
      return this.store.getters.usersByUsername
    }
  }
})
</script>

<style scoped lang="scss">
#jitsiAudioContainer {
  position: absolute;
  z-index: 1002;
  right: 20px;
  top: 100px;
  height: calc(100vh - 120px);
  width: 50vw;
  max-width: 380px;
}

.middle-content {
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;

  .wrapper {
    width: calc(50% - .5rem);
    height: 50%;
    position: relative;
    padding: 0 1rem 1rem 0;

    .tile {
      background: $grey-20;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      padding: 1rem;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      position: relative;
      color: #fff;
      cursor: pointer;

      &::before {
        content: '';
        background: linear-gradient(90deg, #000000ff 0%, #00000000 100%);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 8px;
        z-index: 1;
      }

      div {
        z-index: 2;
        position: relative;
      }

      .label {
        font-size: 42px;
      }
    }

    .wrapper {
      width: 50%;
    }

    #coffee {
      background-image: url('../assets/img/bg/cofee.png');
    }

    #lunch {
      background-image: url('../assets/img/bg/lunch.png');
    }

    #deep {
      background-image: url('../assets/img/bg/deep.png');
    }

    #beach {
      background-image: url('../assets/img/bg/beach.png');
    }

    #forest {
      background-image: url('../assets/img/bg/forest.png');
    }

    #rain {
      background-image: url('../assets/img/bg/rain.png');
    }

    #more {
      background-image: url('../assets/img/bg/more.png');
    }
  }

  .ambient {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-right: 0;
  }
}</style>
