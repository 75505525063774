
import { defineComponent } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  data () {
    return {
      store: useStore(),
      floorName: ''
    }
  },

  mounted () {
    this.floorName = this.floor.name
  },

  methods: {
    editFloor (event: MouseEvent) {
      this.store.dispatch('editFloor', {
        floorId: this.floor.id,
        dto: { name: this.floorName }
      })
      this.closeMe(event)
    },

    closeMe (event: any) {
      event.stopPropagation()
      this.$emit('close', event)
    }
  },

  computed: {
    account (): any {
      return this.store.getters.user
    },

    floor (): any {
      return this.store.getters.allFloors[0]
    }
  }
})
