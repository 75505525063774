import TimelineCapturer from './TimelineCapturer'
import store from '@/store'
import MxRoom from './MxRoom'
import StatusUtils from './StatusUtils'

let timelineTimerRef: any

export default {
  punchIn () {
    if (!localStorage.getItem('punchedIn') ||
        localStorage.getItem('punchedIn') === 'false'
    ) {
      const apiPayload = {
        description: 'Login',
        startTime: (new Date()).toISOString(),
        isTimeOff: false
      }
      store.dispatch('startDay', apiPayload)
      const timerDto = {
        description: 'Login',
        startTime: new Date().toISOString(),
        status: 'IN_PROGRESS',
        timerClass: 'ATTENDANCE',
        isTimeOff: false
      }
      store.dispatch('createTimer', timerDto)
      /** Setting this in local storage to prevent punching in multiple times without punchout in case of page reload. */
      localStorage.setItem('punchedIn', 'true')
    }

    clearInterval(timelineTimerRef)
    TimelineCapturer.fullscreenScreenshot()
    let randomDelay = 0
    timelineTimerRef = setInterval(() => {
      randomDelay = Math.floor(Math.random() * 300000)
      setTimeout(() => {
        new StatusUtils().updateStatusAndTimeline()
      }, randomDelay)
    }, 60000 * 5 - randomDelay)
  },

  punchOut (emitter: any) {
    const apiPayload = {
      description: 'Logout',
      startTime: (new Date()).toISOString(),
      isTimeOff: false
    }
    store.dispatch('addAttendance', apiPayload)
    const timerDto = {
      description: 'Logout',
      startTime: new Date().toISOString(),
      status: 'COMPLETED',
      timerClass: 'ATTENDANCE',
      isTimeOff: false
    }

    store.dispatch('createTimer', timerDto)
    clearInterval(timelineTimerRef)
    localStorage.setItem('punchedIn', 'false')
    emitter.emit('endActiveTimer')
  },

  beforeUnmount () {
    clearInterval(timelineTimerRef)
  }
}
