import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-409d438c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "text-container"
}
const _hoisted_2 = { class: "px-3 ellipsis" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 0,
  class: "desc ellipsis"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.event.getContent().msgtype !== 'm.image' ? 'dec-text-tile' : ''),
    style: _normalizeStyle(_ctx.event.getContent().msgtype !== 'm.image' ? _ctx.randomColor() : '')
  }, [
    (_ctx.content.msgtype === 'm.text')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.event.getContent().body), 1)
        ]))
      : (_ctx.content.msgtype === 'm.image')
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", {
              class: "feed-thumb",
              style: _normalizeStyle('background-image: url(' + _ctx.mediaObjectUrl +')')
            }, [
              (_ctx.content.file.text)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.content.file.text), 1))
                : _createCommentVNode("", true)
            ], 4)
          ]))
        : _createCommentVNode("", true)
  ], 6))
}