
import { defineComponent, getCurrentInstance, PropType } from 'vue'
import { useStore } from 'vuex'
import MxRoom from '@/common/services/MxRoom'
import { MatrixClient, Room } from 'matrix-js-sdk'
import OptionsTooltip from './OptionsTooltip.vue'
import RoomInfoModal from './RoomInfo.vue'

export default defineComponent({
  components: { OptionsTooltip, RoomInfoModal },
	props: {
		room: { type: Object as PropType<Room>, required: true }
	},

  data () {
    return {
      store: useStore(),
      emitter: getCurrentInstance()?.appContext.config.globalProperties.emitter,
      mxRoom: MxRoom,
      isTooltip: false,
      tooltipPositions: { top: '0', left: '0' } as any,
      roomOptions: [
        {
          title: 'Info',
          icon: 'icon-info-grey-16.svg',
          onClick: this.toggleInfoModal.bind(this)
        },
        {
          title: 'Leave',
          icon: 'icon-delete-red-16.svg',
          onClick: this.leaveChat.bind(this)
        }
      ],
      isInfoModalVisible: false
    }
  },

  methods: {
    startVideoCall () {
      this.emitter.emit('setCallView')
    },

    startVoiceCall () {
      this.emitter.emit('setVoiceCallView')
    },

    openSearchView () {
      this.$emit('openSearch')
    },

    toggleOptionsTooltipBlock (e: MouseEvent) {
      e.stopPropagation()
      this.isTooltip = true
      this.tooltipPositions = { top: 110 + 'px', right: 30 + 'px' }
      // this.$emit('toggleOptionsTooltip', event, this.roomOptions)
    },

    hideTooltipBlock () {
      this.isTooltip = false
    },

    leaveChat () {
      this.matrixClient.leaveRoomChain(this.room.roomId)
    },

    toggleInfoModal () {
      this.isInfoModalVisible = !this.isInfoModalVisible
    },

    getDMUser (room: Room) {
      return this.usersByUsername[MxRoom.getDmRoomName(room, this.user.username)!]
    }
  },

  computed: {
    matrixClient (): MatrixClient {
      return this.store.getters.matrixClient
    },

    user(): any {
      return this.store.getters.user
    },

    usersByUsername(): any {
      return this.store.getters.usersByUsername
    }
  }
})
