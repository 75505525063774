
import { PropType, defineComponent } from 'vue'
import { useStore } from 'vuex'
import { Roles } from '../../../common/enums'
import ManagementRythmModal from './ManagementRhythmModal.vue'
import ManagementSprintPlanModal from './ManagementSprintPlanModal.vue'
import { Organization } from '../interfaces'

export default defineComponent({
  components: {
    ManagementRythmModal,
    ManagementSprintPlanModal
  },

  data() {
    return {
      store: useStore(),
      orgRoles: Roles,
      isRhythmModalVisible: false,
      isSprintModalVisible: false,
      title: '',
      rythmTypeName: '',
      rhythm: [] as any,
      sprintData: [] as any,
      form: {} as Partial<Organization>,
      companyLogo: '' as any
    }
  },

  mounted() {
    if (this.organization) {
      this.form = this.organization
      if (this.rhythm !== null && this.form) {
        this.rhythm = {
          framework: this.rhythmData.framework[0],
          sprint_duration: this.rhythmData.sprint_duration[0],
          mini_sprint_duration: this.rhythmData.mini_sprint_duration[0],
          team_report: this.rhythmData.team_report[0],
          sprint_planning: {
            plan_every: this.rhythmData.sprint_planning[0].plan_every[0],
            in_advance: this.rhythmData.sprint_planning[0].in_advance[0],
            review_every: this.rhythmData.sprint_planning[0].review_every[0]
          }
        }
      }
    }
  },

  methods: {
    editOrganisation(e: any): void {
      if (this.organization.id) {
        this.store.dispatch('editOrganization', { form: this.form, id: this.organization.id })
          .then((response) => {
            if (response) {
              this.store.commit('showSuccess', true)
              this.$emit('next')
            } else {
              this.store.commit('showError', true)
            }
          })
      }
    },

    toggleRhythmModal(event: MouseEvent) {
      event.stopPropagation()
      this.isRhythmModalVisible = !this.isRhythmModalVisible
    },

    toggleSprintPlanModal(event: any) {
      event.stopPropagation()
      this.isSprintModalVisible = !this.isSprintModalVisible
    },

    addDataToModal(type: string, rhythmName: any, rhythm: any, e: any) {
      this.toggleRhythmModal(e)
      this.title = type
      this.rythmTypeName = rhythmName
      this.rhythmData = rhythm
    },

    addSprintPlanModal(sprint: any, e: any) {
      this.toggleSprintPlanModal(e)
      this.sprintData = sprint
    },

    rhythmListArray(event: any, selectedRhythm: any) {
      this.toggleRhythmModal(event)
      this.rhythm[this.rythmTypeName] = selectedRhythm
    },

    sprintPlanListArray(event: any, selectedPlan: any, selectedAdvance: any, selectedReview: any) {
      this.toggleSprintPlanModal(event)
      this.rhythm.sprint_planning.plan_every = selectedPlan
      this.rhythm.sprint_planning.in_advance = selectedAdvance
      this.rhythm.sprint_planning.review_every = selectedReview
    },

    close(event: any) {
      event.stopPropagation()
      this.$emit('close', event)
    }
  },

  computed: {
    user(): any {
      return this.store.getters.user
    },

    usersByUsername(): any {
      return this.store.getters.usersByUsername
    },

    rhythmData(): any {
      return this.store.getters.rhythm
    },

    organization(): any {
      return this.store.getters.organization
    },

    logo(): any {
      return this.store.getters.logo
    }
  }
})
