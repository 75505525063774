
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
import tooltipJson from '@/common/json/allTeamsTooltip.json'
import OptionsTooltip from '@/modules/chats/components/OptionsTooltip.vue'
import EditOrganization from './EditOrganization.vue'
import UserMultiselectModal from '@/common/components/UserMultiselectModal.vue'
import Loader from '@/common/components/Loader.vue'

export default defineComponent({
  components: {
    Loader,
    OptionsTooltip,
    EditOrganization,
    UserMultiselectModal
  },

  data() {
    return {
      store: useStore(),
      moment: moment,
      isTooltipVisible: false,
      activeModal: '',
      members: [],
      selectedMembers: [] as any,
      membersList: [] as any,
      tooltipPos: { top: '0', left: '0' } as any,
      visibleMemberOptionsTooltip: '',
      hoverOn: '',
      tooltipOptions: [
        {
          ...tooltipJson.tooltipOptions.edit,
          onClick: this.editOrganization.bind(this)
        }
      ],
      memberTooltipOptions: [
        {
          ...tooltipJson.tooltipOptions.remove,
          onClick: this.removeMember.bind(this)
        }
      ]
    }
  },

  mounted() {
    this.members = this.store.getters.allUsers
    for (let i = 0; i < this.organizationMembers.length; i++) {
      this.members = this.members.filter((member: any) =>
        (member.username !== this.organizationMembers[i].username) &&
        (member.username !== this.user.username)
      )
    }
  },

  methods: {
    openTooltip(e: any) {
      this.tooltipPos = { top: '122px', right: '32px' }
      this.isTooltipVisible = true
    },

    openMemberEditTooltip(e: any, member: string) {
      this.visibleMemberOptionsTooltip = member
      this.tooltipPos = { top: '5px', right: '30px' }
    },

    showHoverOptions(member: string) {
      this.hoverOn = member
    },

    editOrganization() {
      this.setActiveModal('edit-organization')
    },

    closeTooltip() {
      this.isTooltipVisible = false
      this.visibleMemberOptionsTooltip = ''
    },

    setActiveModal(activeModal: string) {
      this.activeModal = activeModal
    },

    onUsersSelected(selectedMembersList: any, event: any) {
      this.selectedMembers = selectedMembersList
      this.setActiveModal('')
      this.addMembers()
    },

    addMembers() {
      this.membersList = []
      this.selectedMembers.forEach((item: any) => {
        this.membersList.push(item.username)
      })
      this.store.dispatch('addOrganizationMembers', this.membersList)
    },

    removeMember(member: string) {
      this.store.dispatch('deleteOrganizationMembers', [this.visibleMemberOptionsTooltip])
    }
  },

  computed: {
    user(): any {
      return this.store.getters.user
    },

    usersByUsername(): any {
      return this.store.getters.usersByUsername
    },

    organization(): any {
      return this.store.getters.organization
    },

    organizationMembers(): any {
      return this.store.getters.organizationMembers
    },

    isPageLoading(): any {
      return this.store.getters.isPageLoading
    }
  }
})
