import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  class: "toast-message",
  id: "success-toast",
  role: "dialog"
}
const _hoisted_2 = { class: "mb-2" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.successMessageData.message || 'Success'), 1),
    _createElementVNode("a", {
      class: "close",
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleModal && _ctx.toggleModal(...args)), ["stop"]))
    }, "+"),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.successMessageData.buttons, (button) => {
      return (_openBlock(), _createElementBlock("button", {
        class: _normalizeClass(["btn btn-small", _ctx.buttons[button].styleClass]),
        key: button,
        onClick: _withModifiers(($event: any) => (_ctx.invoke(_ctx.buttons[button].action)), ["stop"])
      }, _toDisplayString(_ctx.buttons[button].label), 11, _hoisted_3))
    }), 128))
  ]))
}