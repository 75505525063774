
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
import { CustomMxEvent, EventState } from '@/common/enums'
import MxRoom from '@/common/services/MxRoom'

export default defineComponent({

	data () {
		return {
			store: useStore(),
			moment: moment,
			meetingTimer: '' as any,
			timer: {
        minutes: 0,
        seconds: 0
      }
		}
	},

	methods: {
		calculateMeetingTime (meeting: any) {
			if (meeting.start) {
        const now = moment(new Date())
        const startTime = moment(new Date(meeting.start * 1000))
        let timeToMeeting = startTime.diff(now, 'seconds') + 1
        if (timeToMeeting <= (5 * 60) && timeToMeeting > 1) {
          const timerRef = setInterval(() => {
            if (timeToMeeting === 0) {
              clearInterval(timerRef)
              this.joinMeeting()
            } else {
              if (timeToMeeting === 3) {
                const audio = document.getElementById('enteringMeetingAudio') as HTMLAudioElement
                if (audio) audio.play()
              }
              const meetingStyle = document.getElementById('meetingTile')?.style.setProperty('--degree', (timeToMeeting * 1.2).toString() + 'deg')
              this.timer.minutes = Math.floor(JSON.parse(JSON.stringify(timeToMeeting)) / 60)
              this.timer.seconds = JSON.parse(JSON.stringify(timeToMeeting)) % 60
              timeToMeeting--
            }
          }, 1000)
        }
      }
		},

    joinMeeting (): void {
      this.store.commit('setMeeting', this.meeting)
      if (this.user.username === this.meeting.owner || this.meeting.state === EventState.IN_PROGRESS) {
        this.meeting.participants = this.meeting.participants.map((member: any) => member.username)
        this.notifyMeetingStarted(this.meeting.participants)
        this.store.dispatch('startMeeting', this.meeting.id)
        this.store.commit('setConferenceActive', true)
      } else {
        this.$router.push({
          name: 'ConferenceWaitingRoom',
          path: '/waiting-room',
          params: { id: this.meeting.id }
        })
      }
    },

    notifyMeetingStarted(participants: string[]) {
      participants.forEach((participant: string) => {
        const content = {
          body: '',
          metadata: {
            to: participant,
            meeting_id: this.meeting.id
          },
          msgtype: CustomMxEvent.MEETING_STARTED
        }
        MxRoom.sendCustomNotification(
          this.matrixClient,
          this.store.getters.organization.organizationNotificationSpaceId,
          content
        )
      })
    }
	},

	computed: {
		user (): any {
			return this.store.getters.user
		},

		meeting (): any {
      /** TODO: Move this code from here. */
      if (this.store.getters.immediateMeeting) {
        const meeting = this.store.getters.immediateMeeting
        this.calculateMeetingTime(meeting)
      }
			return this.store.getters.immediateMeeting
		},

    matrixClient (): any {
			return this.store.getters.matrixClient
		}
  }
})
