import { Vue, Options } from 'vue-class-component'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { useStore } from 'vuex'
import TodoModal from '../todoModal/TodoModal.vue'
import OptionsTooltip from '../../modules/chats/components/OptionsTooltip.vue'
import moment from 'moment'
import DeleteModal from '@/common/components/DeleteModal.vue'
import { FreeStyleShimmer } from 'vue3-shimmer'

const todoData = new FormData()

@Options({
	components: { OptionsTooltip, flatPickr, TodoModal, DeleteModal, FreeStyleShimmer }
})
export default class TodoList extends Vue {
	store = useStore()
	moment = moment
	activeModal = ''
	isDeleteModalVisible = false
	expandTodo = {}
	flatTimePickerConfig = {
    noCalendar: true,
    dateFormat: 'H:i',
    time_24hr: true
  }

  mediaObjectUrl_ = ''
  deleteTodoId = ''

	task: any = []
	isTooltipVisible = false
	tooltipPos = { top: '0', left: '0' }
	tooltipOptions = [
        {
          title: 'Delete',
          icon: 'icon-delete-red-16.svg',
          onClick: this.toggleDeleteModal.bind(this),
          style: ''
        }
	]

	mounted () {
    this.store.dispatch('getMyTodos')
  }

	newTodoItem (todo: any) {
		todo.tasks.push({
			title: 'New todo item',
			description: '',
			targetDate: '',
			location: '',
			url: '',
			attachment: {
				fileName: '',
				fileId: ''
			}
		})
		this.task.push(todo.tasks[todo.tasks.length - 1])
		this.store.dispatch('addTodoTask', { id: todo.id, tasks: this.task })
	}

  get todoData () {
    return this.store.getters.myTodos
  }

	get todoCreate () {
		return this.store.state.createTodo
	}

	get isComponentLoading () {
		return this.store.getters.isComponentLoading
	}

	togglePopup = (e: any, popup: string) => {
    this.activeModal = popup
    e!.stopPropagation()
  }

	toggleDeleteModal () {
		this.isDeleteModalVisible = !this.isDeleteModalVisible
	}

	toggleTodo (eachTodo: any) {
		this.expandTodo = eachTodo
	}

	targetDate (event: any) {
		return moment(event).format('YYYY-MM-DD')
	}

	updateTodo (todo: any) {
		this.store.dispatch('updateTodo', todo)
	}

	deleteTodo () {
		if (this.deleteTodoId) {
			this.store.dispatch('deleteTodo', this.deleteTodoId)
		}
		this.hideTooltip()
		this.isDeleteModalVisible = !this.isDeleteModalVisible
	}

	hideTooltip () {
		this.isTooltipVisible = false
	}

	showOptionsTooltip (e: any, todoId: string) {
		this.deleteTodoId = todoId
		this.isTooltipVisible = true
		// this.tooltipPos = { top: e.clientY + 12 + 'px', left: e.clientX + 12 + 'px' }
		const x = e.pageX - e.currentTarget.offsetLeft
		const y = e.pageY - e.currentTarget.offsetTop
		this.tooltipPos = { top: y + 'px', left: x + 190 + 'px' }
	}

	updateTodoTask (todoId: string, task: any) {
    this.store.dispatch('updateTodoTask', { todoId, task })
	}

	updateTodoTaskTitle (todoId: string, task: any, e: any) {
		if (e.target.value === '') {
			this.store.dispatch('deleteTodoTask', { todoId, task })
		} else {
			this.store.dispatch('updateTodoTask', { todoId, task })
		}
	}

	updateTodoTaskDate (todoId: string, task: any, myDate: any) {
		task.targetDate = myDate.concat('T00:00:00.000Z'.toString())
    task.targetDate = moment(task.targetDate).toISOString()
		this.store.dispatch('updateTodoTask', { todoId, task })
	}

	updateTodoTaskAttachment (todoId: string, task: any, e: any) {
		if (e.target.files.length) {
			const file = e.target.files[0]
			const formData = new FormData()
			formData.append('file', file)
			this.store.dispatch('createFileUploadUrl', { file, formData })
			.then((response) => {
				task.attachment.fileId = response.fileName
				task.attachment.fileName = file.name
				this.store.dispatch('updateTodoTask', { todoId, task })
		})
		}
	}

	createDownloadUrl (attachment: any) {
		this.store.dispatch('createFileDownloadUrl', attachment.fileId)
		.then((response) => {
			// const blob = new Blob([response])
			// const link = document.createElement('a')
			// link.href = URL.createObjectURL(blob)
			// link.download = attachment.fileName
			// link.click()
			// URL.revokeObjectURL(link.href)
			const link = document.createElement('a')
			link.href = response
			link.download = attachment.fileName
			link.click()
			URL.revokeObjectURL(link.href)
		})
	}

	completeTodoTask (e: any, todo: any, task: any) {
		if (e.target.checked) {
			task.done = true
			this.store.dispatch('completeTodoTask', { todoId: todo.id, task })
			// needed if API is there for completing a todo list and reopening it
			// let checkTodoList = 0
			// for (let i = 0; i < todo.tasks.length; i++) {
			// 	if (todo.tasks[i].done === false) {
			// 		checkTodoList++
			// 	}
			// }
			// if (checkTodolist === 0) {
			// 	this.store.dispatch('completeTodoList', { todoId: todo.id })
			// } else if (checkTodoList > 0) {
			// 	this.store.dispatch('reopenTodoList', { todoId: todo.id })
			// }
		} else {
			task.done = false
			this.store.dispatch('reopenTodoTask', { todoId: todo.id, task })
		}
	}

	get mediaObjectUrl () {
		return this.mediaObjectUrl_
	}
}
