import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const state = {
  matrixClientCreds: {},
  matrixRooms: [],
  currentRoom: {
    room: null,
    pinnedEvents: []
  },
  notificationsCount: 0
}

const chatsModule = {
  state,
  getters,
  mutations,
  actions
}

export default chatsModule
