
import { MatrixClient } from 'matrix-js-sdk'
import { useStore } from 'vuex'

export enum MediaDeviceKindEnum {
  AudioOutput = 'audiooutput',
  AudioInput = 'audioinput',
  VideoInput = 'videoinput'
}

export default class MediaDeviceHandler {
  store = useStore()

  public async requestMediaPermissions (matrixClient: MatrixClient) {
    let stream: MediaStream
    try {
      stream = await navigator.mediaDevices.getUserMedia(
        { video: true, audio: true }
      )
      this.getDevices(matrixClient, stream)
    } catch (error) {
      console.error('Failed to get media permissions: ', error)
    }
  }

  async getDevices (matrixClient: MatrixClient, stream: MediaStream) {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices()
      const output: any = {
        [MediaDeviceKindEnum.AudioOutput]: [],
        [MediaDeviceKindEnum.AudioInput]: [],
        [MediaDeviceKindEnum.VideoInput]: []
      }
      devices.forEach((device: MediaDeviceInfo) => output[device.kind].push(device))
      matrixClient.getMediaHandler().setAudioInput(output[MediaDeviceKindEnum.AudioInput])
      matrixClient.getMediaHandler().setVideoInput(output[MediaDeviceKindEnum.VideoInput])
      if (stream) {
        stream.getTracks().forEach((track) => track.stop())
      }
    } catch (error) {
      console.error('Unable to get devices: ', error)
    }
  }

  /**
   * Retrieves devices from the localStorage and tells the js-sdk to use them
   */
  public loadDevices (matrixClient: MatrixClient): void {
    const audioDeviceId = localStorage.getItem('webrtc_audioinput')!
    const videoDeviceId = localStorage.getItem('webrtc_videoinput')!
    matrixClient.getMediaHandler().setAudioInput(audioDeviceId)
    matrixClient.getMediaHandler().setVideoInput(videoDeviceId)
  }
}
