import { Vue, Options } from 'vue-class-component'
import { useStore } from 'vuex'
import StringUtils from '@/common/services/StringUtils'
import SearchInput from '@/common/components/SearchInput.vue'
import NotificationModal from '@/common/components/NotificationModal.vue'
import AddNewModal from '@/common/components/AddNewModal.vue'
import UserProfileModal from '@/common/components/UserProfileModal.vue'
import PollModal from '../pollModal/PollModal.vue'
import PostModal from '../postModal/PostModal.vue'
import ReportBugModal from '@/components/ReportBugModal.vue'
import StatusModal from '@/common/components/StatusModal.vue'
import Breadcrumbs from '@/common/components/Breadcrumbs.vue'
import { getCurrentInstance } from 'vue'

@Options({
  components: {
    SearchInput,
    NotificationModal,
    AddNewModal,
    UserProfileModal,
    PollModal,
    PostModal,
    ReportBugModal,
    StatusModal,
    Breadcrumbs
  }
})
export default class TopNavbar extends Vue {
  store = useStore()
  activeModal = ''
  selectedStatus = ''
  showStatusTooltip_ = false
  timelineCapturerRef = 0
  currentTabX = '/'
  stringUtility = StringUtils
  roadmapCheck = false
  emitter = getCurrentInstance()?.appContext.config.globalProperties.emitter

  mounted () {
    this.currentTabX = window.location.pathname
  }

  setActivetab (tab: string) {
    this.currentTabX = tab
    this.$router.push(tab)
  }

  get currentTab (): string {
    return this.currentTabX
  }

  togglePopup = (e: any, popup: string) => {
    this.activeModal = popup
    e!.stopPropagation()
  }

  toggleStatusTooltip () {
    this.showStatusTooltip_ = !this.showStatusTooltip_
  }

  isSelectOptionDisabled (userStatus: string) {
    return userStatus === this.user.status
  }

  beforeUnmount () {
    clearInterval(this.timelineCapturerRef)
  }

  toggleMobileMenu () {
    this.store.commit('setMobileMenu', !this.mobileMenu)
  }

  get showStatusTooltip () {
    return this.showStatusTooltip_
  }

  get user () {
    return this.store.getters.user
  }

  get avatarUrl () {
    return this.store.getters.avatar
  }

  get breadcrumbs () {
    return this.store.getters.breadcrumbs
  }

  get mobileMenu() {
    return this.store.getters.mobileMenu
  }
}
