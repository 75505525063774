import { Room } from 'matrix-js-sdk'
import FloorGetters from './getters/Floor'
import UserGetters from './getters/User'

const getters = {
  isAuthenticated (state: any) {
    return state.isAuthenticated
  },

  loginWithPin (state: any) {
    const isPinLogin = localStorage.getItem('loginWithPin')
    if (isPinLogin) {
      state.loginWithPin = isPinLogin === 'true'
    }
    return state.loginWithPin
  },

  user (state: any) {
    return state.user
  },

  mobileMenu (state: any) {
    return state.mobileMenu
  },

  roles (state: any) {
    return state.userData.realmRoles
  },

  isLocked (state: any) {
    return state.system.isLocked
  },

  avatar (state: any) {
    return state.avatar
  },

  allUsers (state: any) {
    return state.allUsers
  },

  feedRoom (state: any): Room {
    return state.feedRoom
  },

  feedRoomId (state: any) {
    return state.feedRoomId
  },

	myMeetings (state: any) {
		return state.myMeetings
	},

  sharedMeetings (state: any) {
		return state.sharedMeetings
	},

  allMeetings (state: any) {
		return [...state.myMeetings, ...state.sharedMeetings]
	},

  immediateMeeting (state: any) {
    return state.immediateMeeting
  },

	meeting (state: any) {
    return state.meeting
  },

  currentMeetingList (state: any) {
    return state.currentMeetingList
  },

  allMembers (state: any) {
    return state.allMembers
  },

  usersByUsername (state: any) {
    return state.usersByUsername
  },

  showModal (state: any) {
    return state.showModal
  },

  showSuccess (state: any) {
    return state.showSuccess
  },

  showError (state: any) {
    return state.showError
  },

  attendance (state: any) {
    return state.attendance
  },

  userAttendance (state: any) {
    return state.userData.attendance
  },

  getAllAttendance (state: any) {
    return state.attendanceList
  },

  timer (state: any) {
    return state.timer
  },

  allEvents (state: any) {
    return state.allEvents
  },

	dayEvents (state: any) {
    return state.dayEvents
  },

	monthEvents (state: any) {
    return state.monthEvents
  },

	weekEvents (state: any) {
    return state.weekEvents
  },

  organization (state: any) {
    return state.organization
  },

  rooms (state: any) {
    return state.rooms
  },

  activeMeetings (state: any) {
    return state.activeMeetings
  },

	todoList (state: any) {
    return state.todoList
  },

  /** TODO: Remove this getter if its not working */
  employeeTimeline (state: any, username: string) {
    return state.usersByUsername[username].timeline
  },

  latestWebcamshots (state: any) {
    return state.latestWebcamshots
  },

  isComponentLoading (state: any) {
    return state.isComponentLoading
  },

  isScreenshotLoading (state: any) {
    return state.loader.metaData.isScreenshotLoading
  },

  isWebcamshotLoading (state: any) {
    return state.loader.metaData.isWebcamshotLoading
  },

  // metaData (state: any) {
  //   if (state.loader.metaData.isWebcamshotLoading && state.loader.metaData.isScreenshotLoading) {
  //     return true
  //   }

  //   if (state.loader.metaData.isWebcamshotLoading === false && state.loader.metaData.isScreenshotLoading === false) {
  //     return false
  //   }
  // },

  isPageLoading (state: any) {
    return state.loader.isPageLoading
  },

  isTimelineLoading(state: any) {
    return state.loader.isTimelineLoading
  },

  breadcrumbs (state: any) {
    return state.breadcrumbs
  },

  allSkills (state: any) {
    return state.dataSources.allSkills
  },

  isConferenceActive (state: any) {
    return state.meetingData.isConferenceActive
  },

  isJitsiVideoCallActive (state: any) {
    return state.meetingData.isJitsiVideoCallActive
  },

  activeJitsiVideoCallRoom (state: any) {
    return state.meetingData.activeJitsiVideoCallRoom
  },

  hopReports (state: any) {
    return state.hopReports
  },

  bugReports (state: any) {
    return state.bugReports
  },

  waveData (state: any) {
    return state.wave
  },

  notes (state: any) {
    return state.notes
  },

  voiceMessages (state: any) {
    return state.voiceMessages
  },

  globalSearchQuery (state: any) {
    return state.search.globalSearchQuery
  },

  employeeInfo (state: any) {
		return state.user.employeeInfo
	},

  searchResults (state: any) {
		return state.searchResults
	},

  isLiveRefreshInProgress (state: any) {
		return state.isLiveRefreshInProgress
	}
}

export default { ...getters, ...FloorGetters, ...UserGetters }
