
import { defineComponent, Ref } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import UtilityService from '@/common/services/TimeUtilityService'
import UserMultiselectModal from '@/common/components/UserMultiselectModal.vue'

export default defineComponent({
	components: { flatPickr, UserMultiselectModal },
	data () {
    return {
			store: useStore(),
			utilityService: new UtilityService(),
			moment: moment,
			addEvent: false,
			isMembersModalVisible: false,
			selectedMembers: [] as any,
			flatTimePickerConfig: {
				enableTime: true,
				noCalendar: true,
				dateFormat: 'H:i',
				time_24hr: true
			},

			eventStartDate: '',
			eventStartTime: '',
			eventEndDate: '',
			eventEndTime: '',
			form: {
				title: '',
				description: '',
				type: 'EVENT',
				start: '',
				end: '',
				participants: [],
				url: '',
				attachment: {
					fileName: '',
					fileId: ''
				},
				recurringDays: [],
				class: 'mint'
			},

			formErrors: [] as any,
			attachFile: {} as any,
			selectedIndex: 0,
			classNames: ['mint', 'lemon', 'lavender', 'aqua'] as any
    }
  },

  methods: {
    toggleEventModal (e: any): void {
      this.$emit('toggleEventModal', e)
    },

		changeButtonColor () {
			this.selectedIndex === this.classNames.length - 1 ? this.selectedIndex = 0 : this.selectedIndex++
			this.form.class = this.classNames[this.selectedIndex]
		},

		membersListArray (selectedMembersList: any, event: any) {
			event.stopPropagation()
      this.toggleMembersModal(event)
      selectedMembersList = selectedMembersList.filter((filter: any) => filter.name !== '')
      this.selectedMembers = selectedMembersList
      this.form.participants = this.selectedMembers.map((member: any) => member.username)
		},

		toggleMembersModal (event: any) {
			event.stopPropagation()
			this.isMembersModalVisible = !this.isMembersModalVisible
		},

		uploadFile (e: any) {
			if (e.target.files.length) {
				const file = e.target.files[0]
				this.attachFile = file
			}
		},

		closeMe (event: MouseEvent) {
      event.stopPropagation()
      this.$emit('close', event)
    },

		createEvent (): void {
			this.store.dispatch('submitEventRequest', this.form)
				.then((response) => {
					if (response) {
						this.store.commit('showSuccess', true)
					} else {
						this.store.commit('showError', true)
					}
				})
			this.eventStartTime = ''
			this.eventStartDate = ''
			this.eventEndTime = ''
			this.eventEndDate = ''
			this.form = {
				title: '',
				description: '',
				type: 'EVENT',
				start: '',
				end: '',
				url: '',
				participants: [],
				attachment: {
					fileName: '',
					fileId: ''
				},
				recurringDays: [],
				class: 'mint'
			}
		},

		submitEventRequest (e: MouseEvent): void {
			this.formErrors = []
			this.checkEventErrors()
			if (!this.formErrors.length) {
				if (this.form.participants === null) {
					this.form.participants = []
				}
				this.form.start = (this.eventStartDate.concat(' '.toString()))
					.concat(this.eventStartTime.toString())
				this.form.start = moment(this.form.start).toISOString()
				this.form.end = this.eventEndDate.concat(' '.toString())
					.concat(this.eventEndTime.toString())
				this.form.end = moment(this.form.end).toISOString()
				if (Object.keys(this.attachFile).length === 0) {
					this.createEvent()
					this.toggleEventModal(e)
				} else {
					const formData = new FormData()
					formData.append('file', this.attachFile)
					this.store.dispatch('createFileUploadUrl', { file: this.attachFile, formData })
						.then((response) => {
							this.form.attachment.fileId = response.fileName
							this.form.attachment.fileName = this.attachFile.name
							this.createEvent()
							this.toggleEventModal(e)
						})
				}
			}
		},

		checkEventErrors () {
			if (!this.form.title) {
				this.formErrors.push('Title is required')
			}
			if (this.form.title.length !== 0 && this.form.title.length < 5) {
				this.formErrors.push('Title should contain atleast 5 characters')
			}
			if (this.eventStartDate === '' || this.eventEndDate === '') {
				this.formErrors.push('Event date should be selected')
			}
			if (this.eventStartTime === '' || this.eventEndDate === '') {
				this.formErrors.push('Event Time should be selected')
			}
			if (this.eventStartDate || this.eventEndDate) {
				const date = moment().format('YYYYMMDD')
				const selectedStartDate = moment(String(this.eventStartDate)).format('YYYYMMDD')
				const selectedEndDate = moment(String(this.eventEndDate)).format('YYYYMMDD')
				if (selectedStartDate < date) {
					this.formErrors.push('Please select a Date in the future')
				}
				if (selectedEndDate < date) {
					this.formErrors.push('Please select a Date in the future')
				}
				if (selectedEndDate < selectedStartDate) {
					this.formErrors.push('End date should be after start date')
				}
			}
		}
  },

	computed: {
		displayMembersList (): any {
		return this.selectedMembers
		},

		user (): any {
			return this.store.getters.user
		},

		membersData (): any {
		return this.store.getters.organizationMembers.filter((member: any) => member.username !== this.user.username)
		},

		usersByUsername ():any {
		return this.store.getters.usersByUsername
		}
	}
})
