import { AudioRoom, AudioRoomTable } from '@/common/types/AudioRoomInterface'
import axios, { AxiosPromise } from 'axios'

export default {
  getAllByOrganization(organizationId: string): AxiosPromise<any> {
    return axios.get(`blox/audiorooms/org/${organizationId}`)
  },

  getAudioRoomById(audiRoomId: string): AxiosPromise<any> {
    return axios.get(`blox/audiorooms/${audiRoomId}`)
  },

  createTable(table: any): AxiosPromise<any> {
    console.log('payload', table)
    return axios.post('blox/audiorooms/addTable', table)
  },

  joinTable(tabledId: number): AxiosPromise<any> {
    return axios.put(`blox/audiorooms/table/${tabledId}/join`)
  },

  leaveTable(tabledId: number): AxiosPromise<any> {
    return axios.put(`blox/audiorooms/table/${tabledId}/leave`)
  }
}
