<template>
	<div class="common-tile create-post p-3">
			<div class="d-flex justify-content-start w-100">
					<div
					class="avatar avatar-bg rounded-circle me-1"
					:style="{
					'background-image':
					`url(${avatarUrl}),
					url(${require('@/assets/img/avatar-placeholder.svg')})`
					}"
					></div>
				<div>
					<textarea class="textarea-desc w-100 p-0" type="text" rows="3" @change="displayPostButton()"  placeholder="Create a new post" v-model="message"></textarea>
					<img v-if="uploadedImage" class="post-file mb-2" :src="uploadedImage" />
				</div>
			</div>
			<div class="w-100 d-flex justify-content-start mx-2">
				<div class="mx-1">
					<button
						class="btn btn-icon p-0"
						type="button"
						@click="clickFileUpload()">
						<img src="@/assets/img/icon-image-grey.svg" alt="">
						<input
							class="file-upload mt-1 border-0"
							id="file-upload"
							type="file"
							style="display: none"
							@change="uploadFile($event)">
					</button>
				</div>
				<div class="mx-1">
					<button
						class="btn btn-icon p-0"
						type="button">
						<img src="@/assets/img/icon-poll-grey.svg" alt="">
					</button>
				</div>
				<div class="mx-1">
					<button
						class="btn btn-icon p-0"
						type="button">
							<img src="@/assets/img/icon-calendar-grey.svg" alt="">
						</button>
				</div>
				<div class="mx-1">
					<button
						class="btn btn-icon p-0"
						type="button">
							<img src="@/assets/img/icon-location-grey.svg" alt="">
						</button>
				</div>
				<div class="mx-1">
					<button
						type="submit"
						v-if="isHidden"
						@click="createPost()"
						class="btn primary-blue p-1">
						Post
					</button>
				</div>
			</div>
	</div>
</template>
<script lang="script" src="./CreatePost.ts"></script>
<style lang="scss" scoped src="./CreatePost.scss"></style>
