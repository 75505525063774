
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import UtilityService from '@/common/services/TimeUtilityService'

export default defineComponent({
  data () {
    return {
			store: useStore(),
      utilityService: new UtilityService(),
			form: {
				recap: '',
				qowPoints: 10,
				qowRemarks: '',
				independencePoints: 10,
				independenceRemarks: '',
				initiativeNCreativityPoints: 10,
				initiativeNCreativityRemarks: '',
				timelinessPoints: 10,
				timelinessRemarks: '',
				whatNext: '',
				notables: '',
				kudos: '',
				kudosMentions: '',
				otherRemarks: ''
			},
			errors: ''
  }
  },

	methods: {
		submitFormRequest (): void {
		// todo validate form
			this.store.dispatch('submitHopReportRequest', this.form)
			.then((response) => {
					if (response) {
						this.store.commit('showSuccess', true)
					} else {
						this.store.commit('showError', true)
					}
				})
			this.form = {
				recap: '',
				qowPoints: 10,
				qowRemarks: '',
				independencePoints: 10,
				independenceRemarks: '',
				initiativeNCreativityPoints: 10,
				initiativeNCreativityRemarks: '',
				timelinessPoints: 10,
				timelinessRemarks: '',
				whatNext: '',
				notables: '',
				kudos: '',
				kudosMentions: '',
				otherRemarks: ''
			}
			this.$emit('close')
		},

		close (event: MouseEvent) {
			event.stopPropagation()
      this.$emit('close', event)
    }
	},

	computed: {
		showSuccess (): any {
    return this.store.getters.showSuccess
  }
  }
})
