
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import { EventType, MatrixClient, NotificationCountType, Room, RoomCreateTypeField, RoomType } from 'matrix-js-sdk'
import CreateChannel from './CreateChannel.vue'
import MxRoom from '@/common/services/MxRoom'
import { Preset, Visibility } from 'matrix-js-sdk/src/@types/partials'
import { MEGOLM_ALGORITHM } from 'matrix-js-sdk/lib/crypto/olmlib'
import UserMultiselectModal from '@/common/components/UserMultiselectModal.vue'

export default defineComponent({
  components: { CreateChannel, UserMultiselectModal },

  data() {
    return {
      showCreateChannelModal: false,
      showDMUsers: false,
      dMUsersList: [],
      selectedDMUsers: [],
      store: useStore(),
      mxRoom: MxRoom
    }
  },

  mounted() {
    this.rooms.forEach((room: Room) => {
      if (room.getMyMembership() === 'invite') {
        this.matrixClient.joinRoom(room.roomId)
      }
    })
    // if (
    //    !this.matrixClient.isRoomEncrypted(this.activeRoom?.roomId)
    // ) {
    //   this.matrixClient.setRoomEncryption(this.activeRoom?.roomId, {
    //     algorithm: 'm.megolm.v1.aes-sha2'
    //   }).then((res: any) => {
    //     console.log('turned on encryption for room: ', this.activeRoom, res)
    //   }).catch((err: any) => {
    //     console.log('failed on encryption for room: ', this.activeRoom, err)
    //   })
    // }
  },

  methods: {
    async setActiveRoom(room: Room) {
      await room.decryptAllEvents()
      this.store.commit('setCurrentRoom', room)

      /** Clear notifications. */
      MxRoom.clearNotifications(this.matrixClient, room)
      this.setPinnedEvents()
    },

    isRoomVisibleInChannels(room: Room) {
      return !this.mxRoom.isRoomDM(room) && room.getMyMembership() !== 'leave' &&
        room.roomId !== this.organization.organizationMatrixSpaceId &&
        room.roomId !== this.organization.organizationChatterSpaceId &&
        room.roomId !== this.organization.organizationNotificationSpaceId
    },

    async setPinnedEvents() {
      const readPins = this.activeRoom.getAccountData('ReadPinsEventId')?.getContent()?.event_ids
      const events = []
      if (readPins) {
        for (const eventId of readPins) {
          const timeline = await this.matrixClient.getEventTimeline(this.activeRoom.getUnfilteredTimelineSet(), eventId)
          const event = timeline.getEvents().find(ev => ev.getId() === eventId)
          if (event) {
            events.push(event)
          }
        }
      }
      this.store.commit('setPinnedEvents', events)
    },

    sendDmInvites() {
      this.orgMembers.forEach((member: any) => {
        if (member.username !== this.user.username) {
          this.createDmRoom(member)
        }
      })
    },

    getDMUser(room: Room) {
      return this.usersByUsername[MxRoom.getDmRoomName(room, this.user.username)!]
    },

    toggleCreateChannelModal(clickEvent: MouseEvent) {
      clickEvent.stopPropagation()
      this.showCreateChannelModal = !this.showCreateChannelModal
    },

    toggleDMUsersModal(clickEvent: MouseEvent) {
      clickEvent.stopPropagation()
      const existingDMUsers: string[] = []

      // Remove users already present in DM
      this.rooms.forEach((room: Room) => {
        existingDMUsers.push(this.getDMUser(room)?.username)
      })
      this.dMUsersList = this.orgMembers.filter((user: any) =>
        (!existingDMUsers.includes(user.username) && user.username !== this.user.username)
      )
      console.log('DM user list', this.dMUsersList)
      this.showDMUsers = !this.showDMUsers
    },

    startDM(selected: any, event: any) {
      this.toggleDMUsersModal(event)
      selected.forEach((user: any) => {
        this.createDmRoom(user)
      })
    },

    createDmRoom(user: any): Promise<any> {
      const createOpts = {
        name: user.name,
        topic: user.name,
        encryption: false, /** Creating encryption disabled rooms by default. */
        preset: Preset.PrivateChat,
        visibility: Visibility.Private, /** Public channel option is not provided now. */
        invite: [`@${user.username}:${process.env.VUE_APP_MATRIX_HOMESERVER}`],
        is_direct: true,
        creation_content: {
          [RoomCreateTypeField]: 'DM'
        },
        initial_state: []
      }
      return this.matrixClient.createRoom(createOpts)
    }
  },

  computed: {
    matrixClient(): MatrixClient {
      return this.store.getters.matrixClient
    },

    rooms(): Room[] {
      return this.store.getters.matrixRooms
    },

    activeRoom(): Room {
      return this.store.getters.currentRoom?.room
    },

    usersByUsername(): any {
      return this.store.getters.usersByUsername
    },

    user(): any {
      return this.store.getters.user
    },

    users(): any {
      return this.store.getters.allUsers
    },

    organization(): any {
      return this.store.getters.organization
    },

    orgMembers(): any {
      return this.store.getters.organizationMembers
    }
  }
})
