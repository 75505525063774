
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
		title: { type: String, required: true }
	},

  methods: {
    toggleDeleteModal (): void {
      this.$emit('toggleDeleteModal')
    },

    deleteMeeting (): void {
      this.$emit('deleteMeeting')
    }
  }
})
