
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import MeetingTile from './MeetingTile.vue'

export default defineComponent({
  components: { MeetingTile },

  props: {
    selectedMeeting: {} as any,
    relatedMeetings: [] as any[]
  },

  data() {
    return {
      store: useStore()
    }
  }
})
