export const UnavailableStatusReasons = [{
    description: 'Coffee',
    duration: {
      days: 0,
      hours: 0,
      minutes: 15
    }
  }, {
    description: 'Lunch',
    duration: {
      days: 0,
      hours: 0,
      minutes: 30
    }
  }, {
    description: 'Vacation',
    duration: {
      days: 5,
      hours: 0,
      minutes: 0
    }
  }
]

export const DefaultStatusDurations = [
  {
    days: 0,
    hours: 0,
    minutes: 15,
    label: '15m'
  },
  {
    days: 0,
    hours: 0,
    minutes: 30,
    label: '30m'
  },
  {
    days: 0,
    hours: 1,
    minutes: 0,
    label: '1h'
  },
  {
    days: 0,
    hours: 2,
    minutes: 0,
    label: '2h'
  },
  {
    days: 1,
    hours: 0,
    minutes: 0,
    label: '1d'
  }
]
