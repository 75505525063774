const mutations = {
  setSkills (state: any, payload: any): void {
    state.usersByUsername[payload.username].skills = payload.skills
  },
  setHopReports(state: any, payload: any): void {
    state.hopReports = payload
  },
  setFacesFound(state: any, payload: any): void {
    state.facesFound = payload
  },
  setUserPreferences(state: any, payload: any): void {
    state.user.userPreferences = payload || {}
  }
}

export default mutations
