export enum UserStatus {
  AVAILABLE = 'AVAILABLE',
  UNAVAILABLE = 'UNAVAILABLE',
  DND = 'DND',
  LEARNING = 'LEARNING',
  BUSY = 'BUSY',
  AWAY = 'AWAY',
  LEFT = 'LEFT',
  ON_MOBILE = 'ON_MOBILE'
}

export enum TimerState {
	IN_PROGRESS = 'IN_PROGRESS',
	PAUSED = 'PAUSED',
	COMPLETED = 'COMPLETED'
}

export enum CalendarEventType {
  CALENDAR_EVENT = 'CALENDAR_EVENT',
  MEETING = 'MEETING'
}

export enum MeetingPreference {
	SHOW_AGENDA_IN_WAITING_ROOM = 'Show Agenda in waiting Room',
	POLL = 'Poll',
	ASK_TO_TURN_VIDEO_ON = 'Ask paricipants to turn video ON',
  ALLOW_PARTICIPANTS_TO_START_MEETING = 'Allow participants to start meeting',
  AUTO_JOIN = 'Auto join'
}

export enum EventState {
  NOT_STARTED = 'NOT_STARTED',
	IN_PROGRESS = 'IN_PROGRESS',
	COMPLETED = 'COMPLETED'
}

export enum Roles {
	ORG_OWNER = 'jwork_org_owner',
	MANAGER = 'jwork_manager'
}

/** Custom matrix event types */
export enum CustomMxEvent {
  NOTIFICATION = 'mx.notification',
  WAVE = 'mx.wave',
  NOTE = 'mx.note',
  VOICE_MESSAGE = 'mx.voice_message',
  CHATTER_MESSAGE = 'mx.chatter_message',
  DROP_IN = 'mx.drop_in',
  NEW_MEETING = 'mx.new_meeting_created',
  MEETING_STARTED = 'mx.meeting_started',
  EMPLOYEE_ONLINE = 'mx.employee_online'
}

export enum PushNotificationTopic {
  LIVE_VIDEO_CAPTURE = 'LIVE_VIDEO_CAPTURE'
}
