import { MatrixClient } from 'matrix-js-sdk'
import ChatsApiService from '../services/ChatsApiService'

const chatsApiService = new ChatsApiService()

const actions = {
  loginToElement (context: any, loginToken: any) {
    return chatsApiService.loginToElement(loginToken)
      .then((response: any) => {
        const matrixCreds = {
          homeserverUrl: 'hsUrl',
          identityServerUrl: 'isUrl',
          userId: response.data.user_id,
          deviceId: response.data.device_id,
          accessToken: response.data.access_token
        }
        context.commit('setMatrixClientCreds', matrixCreds)
        return matrixCreds
      })
      .catch((error: any) => {
        console.error('Error in getting element access token', error)
      })
  },

  setMatrixClient (context: any, client: MatrixClient) {
    context.commit('setMatrixClient', client)
  }
}

export default actions
