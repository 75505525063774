<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div
        class="modal p-2 text-center w-100 h-100"
        role="dialog"
      >
        <div class="loader">
          <!-- Can use this instead of image if needed -->
        <!-- <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="24px" height="30px" viewBox="0 0 24 30" style="enable-background:new 0 0 50 50;" xml:space="preserve">
            <rect x="0" y="10" width="4" height="10" fill="#333" opacity="0.2">
            <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s" repeatCount="indefinite" />
            <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
            <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
            </rect>
            <rect x="8" y="10" width="4" height="10" fill="#333"  opacity="0.2">
            <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
            <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
            <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
            </rect>
            <rect x="16" y="10" width="4" height="10" fill="#333"  opacity="0.2">
            <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
            <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
            <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
            </rect>
        </svg> -->
        <img src="@/assets/img/loader.svg" alt="Loader">
        <h3>LOADING</h3>
        </div>
      </div>
    </div>
  </transition>
</template>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700');
body{
  background: $white-70;
}
.loader{
  display: block;
  margin: 200px auto auto;
  width: 100px;
  svg{
    padding: 0 38px;
    rect{
      fill: $red;
    }
  }
  h3{
    text-align: center;
    font-weight: 500;
    color: $white-40;
  }
}
</style>
