import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a9ddede0"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "d-flex align-items-center" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.options.length)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "right-click-tooltip p-2",
        style: _normalizeStyle({
      top: _ctx.pos.top || 'auto',
      left: _ctx.pos.left || 'auto',
      right: _ctx.pos.right || 'auto',
      bottom: _ctx.pos.bottom || 'auto',
      ..._ctx.tooltipStyle
    })
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
          return (_openBlock(), _createElementBlock("div", {
            onClick: _withModifiers(($event: any) => {option.onClick(_ctx.tooltipProp); _ctx.hide()}, ["stop"]),
            key: option
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["option d-flex justify-content-between p-1", option.class])
            }, [
              _createElementVNode("div", _hoisted_2, [
                (option.span && option.span.show)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      class: _normalizeClass(option.span.class)
                    }, _toDisplayString(option.span.value), 3))
                  : _createCommentVNode("", true),
                _createElementVNode("span", null, _toDisplayString(option.title), 1)
              ]),
              (option.icon)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("img", {
                      src: _ctx.getImgUrl(option.icon),
                      alt: "icon",
                      height: "20",
                      width: "20"
                    }, null, 8, _hoisted_4)
                  ]))
                : _createCommentVNode("", true)
            ], 2)
          ], 8, _hoisted_1))
        }), 128))
      ], 4))
    : _createCommentVNode("", true)
}