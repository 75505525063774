
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import UserMultiselectModal from '@/common/components/UserMultiselectModal.vue'

export default defineComponent({
  components: { UserMultiselectModal },

  props: {
    roomProp: Object as any
  },

  data () {
    return {
      store: useStore(),
      room: {
        name: this.roomProp.name,
        type: this.roomProp.type
      },
      roomTypes: ['DEPARTMENT', 'OTHER'],
      members: [],
      showAddUserModal: false,
      selectedUsers: [],
      usersList: []
    }
  },

  methods: {
    toggleAddUserModal (event: MouseEvent) {
      event.stopPropagation()
      this.showAddUserModal = !this.showAddUserModal
    },

    onUsersSelected (selectedMembersList: any, event: any) {
      this.selectedUsers = selectedMembersList
      this.toggleAddUserModal(event)
    },

    editRoom (event: MouseEvent) {
      // this.store.dispatch('addRoom', { dto: this.room, floorId: this.floor, position: 5 })
      //   .then((response: any) => {
          this.selectedUsers.forEach(async (member: any) => {
            await this.store.dispatch('addMemberToRoom', { roomId: this.roomProp.id, username: member.username })
          })
          this.$emit('close', event)
        // })
    },

    toggleMembersModal (event: MouseEvent) {
      event!.stopPropagation() // To prevent triggering outside click on this modal on clicking members modal close button.
      this.showAddUserModal = !this.showAddUserModal
    },

    closeMe (event: any) {
      event.stopPropagation()
      if (!this.showAddUserModal) {
        this.$emit('close', event)
      }
    }
  },
  mounted () {
    this.usersList = this.store.getters.allUsers
      if (this.roomProp) {
        for (let i = 0; i < this.roomProp.occupants.length; i++) {
          this.usersList = this.usersList.filter((member: any) => member.username !== this.roomProp.occupants[i].username)
        }
      }
  },

  computed: {
    users (): string {
      return this.selectedUsers.map((user: any) => user.name).join(', ')
    },

    account (): any {
      return this.store.getters.user
    },

    floor (): any {
      return this.store.getters.allFloors[0].id
    }
  }
})
