import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-72e14809"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-backdrop" }
const _hoisted_2 = {
  class: "modal d-flex",
  id: "callview-modal",
  role: "dialog",
  "aria-labelledby": "modalTitle",
  "aria-describedby": "modalDescription"
}
const _hoisted_3 = {
  class: "modal-body",
  id: "modalDescription"
}
const _hoisted_4 = { class: "d-flex justify-content-center m-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "modal-fade" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("section", _hoisted_3, [
            _createTextVNode(_toDisplayString(_ctx.roomName) + " ", 1),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("button", {
                id: "answerVoice",
                class: "btn btn-success m-2",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.answerCall()))
              }, "Answer"),
              _createElementVNode("button", {
                id: "hangupVoice",
                class: "btn btn-danger m-2",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hangupCall()))
              }, "Hangup")
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}