
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import Management from '../Management.vue'
// import ProfileCard from '../ProfileCard.vue'
// import ProfilePast from '../ProfilePast.vue'
import { Roles } from '@/common/enums'
// import History from '../History.vue'

export default defineComponent({
  components: {
    Management
    // ProfileCard,
    // ProfilePast
  },

  props: {
    employee: { type: String, required: true }
  },

  data () {
    return {
      store: useStore(),
      orgRoles: Roles,
      timeElapsed: {} as any,
      activeCard: 'profileCard'
    }
  },

  mounted () {
    this.store.dispatch('getAllAttendance', this.employee)
    this.store.dispatch('getRooms')
    this.store.dispatch('getTimer')
    this.store.dispatch('getSkills', this.employee)
    if (this.timer && this.timer.time) {
      this.timer.time = this.timer?.timeElapsed | 0
      this.timeElapsed = this.parseTimeForTimer(this.timer)
    }
  },

  methods: {
    close (event: MouseEvent) {
      event.stopPropagation()
      this.$emit('close', event)
    },

    gotoChats () {
      this.$router.push('/mx/chats')
    },

    parseTimeForTimer (item: any): any {
      const hours = Math.floor(item.time / 3600)
      const minutes = Math.floor((item.time % 3600) / 60)
      const seconds = Math.floor((item.time % 3600) % 60)
      item = {
        seconds: seconds < 10 ? `0${seconds}` : seconds,
        minutes: minutes < 10 ? `0${minutes}` : minutes,
        hours: hours < 10 ? `0${hours}` : hours
      }
      return item
    },

    activeCardView (activeCard: string) {
      this.activeCard = activeCard
    }
  },

  computed: {
    timelineData () {
      return this.usersByUsername[this.employee].timeline
    },

    attendance () {
      return this.store.getters.attendanceList
    },

    timer () {
      return this.usersByUsername[this.employee].timers
    },

    usersByUsername () {
      return this.store.getters.usersByUsername
    },

    user () {
      return this.store.getters.user
    },

    roles () {
      return this.store.getters.roles
    },

    organization (): any {
      return this.store.getters.organization
    }
  }
})

