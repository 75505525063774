
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import UtilityService from '@/common/services/TimeUtilityService'
import { FreeStyleShimmer } from 'vue3-shimmer'

export default defineComponent({
  components: {
    FreeStyleShimmer
  },

  data () {
    return {
      store: useStore(),
      timeElapsed: {} as any,
      membersData: {} as any,
      utilityService: new UtilityService()
    }
  },

  mounted () {
    if (this.members) {
      this.membersData = this.members
      for (let i = 0; i < this.membersData.length; i++) {
        const timers = this.store.getters.usersByUsername[this.membersData[i].username].timers
        this.calculateTotalWorkTime(timers, this.membersData[i])
      }
    }
  },

  methods: {
    calculateTotalWorkTime (timers: any, membersData: any) {
      if (timers.length) {
        let workTime = 0
        let offTime = 0
        timers.forEach((log: any) => {
          if (log.timeOff) {
            offTime += log.timeElapsed
          } else {
            workTime += log.timeElapsed
          }
          if (log.description === 'Logout') {
            membersData.endTime = this.utilityService.formatTime(log.startTime)
          }
        })
        membersData.startTime = this.utilityService.formatTime(timers[0]?.startTime)
        membersData.workTime = this.parseTotalTime(workTime)
        membersData.offTime = this.parseTotalTime(offTime)
      }
    },

    parseTotalTime (timeInMilli: any): any {
      const hours = Math.floor(timeInMilli / 3600)
      const minutes = Math.floor((timeInMilli % 3600) / 60)
      return `${hours}h ${minutes}m`
    }
  },

  computed: {
    user (): any {
      return this.store.getters.user
    },

    usersByUsername (): any {
      return this.store.getters.usersByUsername
    },

    members (): any {
      return this.store.getters.organizationMembers
    },

    timelineData (): any {
      return this.store.getters.employeeTimeline
    },

    attendance (): any {
      return this.store.getters.attendanceList
    },

    timer (): any {
      return this.store.getters.timer
    },

    isComponentLoading (): any {
      return this.store.getters.isComponentLoading
    }
  }
})
