
import { defineComponent } from 'vue'

export default defineComponent({
	props: {
		options: [] as any,
		pos: { type: Object, required: true }, /** Tooltip position */
		tooltipProp: {},
    tooltipStyle: {} as any
	},

	methods: {
		hide (): void {
			this.$emit('hide')
		},

    getImgUrl (url: string): any {
      return require(`@/assets/img/${url}`)
    }
	}
})
