import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import UserMultiselectModal from '@/common/components/UserMultiselectModal.vue'
import { MatrixClient } from 'matrix-js-sdk'

export default defineComponent({
  components: { UserMultiselectModal },

  data () {
    return {
      store: useStore(),
      inviteForm: {
        emailList: ''
      } as any,
      membersList: [] as any,
      selectedMembers: [] as any,
      emailError: false,
      showAddUserModal: false,
      members: [],
      managersList: [] as any
    }
  },

  mounted (): void {
    const inputField = document.getElementById('email-input')!
    inputField.addEventListener('blur', () => {
      this.emailError = this.validateMultipleEmailsCommaSeparated(this.inviteForm.emailList)
      if (!this.emailError) {
        this.addMembers()
      }
    })
    this.store.dispatch('getOrganizationMembers', this.store.getters.organization?.id)
    this.members = this.store.getters.organizationMembers
    if (this.organizationMembers) {
      for (let i = 0; i < this.organizationMembers.length; i++) {
        this.members = this.members.filter((member: any) => member.username !== this.organizationMembers[i].username)
      }
    }
  },

  methods: {
  addMembers () {
    // const emailList = this.inviteForm.emailList.split(',')
    this.membersList = []
    this.selectedMembers.forEach((item: any) => {
      this.membersList.push({
        email: item.name,
        username: item.username,
        avatar: '',
        role: '',
        team: ''
      })
    })
  },

  async submitInvitation (event: any) {
    this.emailError = false
    // this.emailError = this.validateMultipleEmailsCommaSeparated(this.inviteForm.emailList)
		this.store.dispatch('addOrganizationMembers', this.selectedUserNames).then(async () => {
      await this.membersList.forEach(async (member: any) => {
        await this.store.dispatch('addMemberToRoom', { roomId: member.team, username: member.username })
      })
      this.$router.push('/')
      for (let i = 0; i < this.membersList.length; i++) {
        if (this.membersList[i].role === 'manager') {
          this.managersList.push(this.membersList[i].username)
        }
      }
      this.store.dispatch('addOrganizationManagers', this.managersList)
      this.managersList = []
    })
		// this.store.dispatch('deleteOrganizationMembers', this.deleteMembers)
  },

	gotoHomepage () {
		this.$router.push('/')
	},

  validateEmail (email: string) {
    const regex = /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b/i
    return (regex.test(email))
  },

  validateMultipleEmailsCommaSeparated (emailList: any) {
    const result = emailList.split(',')
    for (let i = 0; i < result.length; i++) {
      if (!this.validateEmail(result[i])) return true
    }
    return false
  },

  toggleAddUserModal () {
    this.showAddUserModal = !this.showAddUserModal
  },

  onUsersSelected (selectedMembersList: any, event: any) {
    this.selectedMembers = selectedMembersList
    console.log('selected', selectedMembersList)
    this.toggleAddUserModal()
    this.addMembers()
  },

  changeRole (selectedRole: string, member: any) {
    if (selectedRole === 'manager' && member.role !== 'manager') {
      this.managersList.push(member.username)
      this.store.dispatch('addOrganizationManagers', this.managersList)
    }
    if (selectedRole !== 'manager' && member.role === 'manager') {
      this.managersList.push(member.username)
      this.store.dispatch('removeOrganizationManagers', this.managersList)
    }
    this.managersList = []
  }
  },

  computed: {
    organization (): any {
      return this.store.getters.getOrganization
    },

    teams (): any {
      return this.store.getters.teams
    },

    selectedUsers (): any {
      return this.selectedMembers
    },

    selectedUserNames (): any {
      return this.selectedUsers.map((member: any) => member.username)
    },

    rooms (): any {
      return this.store.getters.organization.rooms
    },

    organizationMembers (): any {
      return this.store.getters.organizationMembers
    },

    matrixClient (): MatrixClient {
      return this.store.getters.matrixClient
    },

    floor (): any {
      return this.store.getters.allFloors[0]
    }
  }
})
