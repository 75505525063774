
import { defineComponent, getCurrentInstance } from 'vue'
import WhiteboardModal from './whiteboardModal/WhiteboardModal.vue'
import UserTimelineModal from './user-timeline/UserTimeline.vue'
import { useStore } from 'vuex'
import Loader from '@/common/components/Loader.vue'
import OptionsTooltip from '../modules/chats/components/OptionsTooltip.vue'
import EditFloor from './EditFloor.vue'
import CreateRoom from './CreateRoom.vue'
import EditRoom from './EditRoom.vue'
import { CustomMxEvent, Roles, UserStatus } from '@/common/enums'
import StringUtils from '@/common/services/StringUtils'
import TimeUtilityService from '@/common/services/TimeUtilityService'
import HopReportForm from './HopReportForm.vue'
import MxRoom from '@/common/services/MxRoom'
import { MatrixClient } from 'matrix-js-sdk'
import moment from 'moment'
import TimelineCapturer from '@/common/services/TimelineCapturer'
import StatusUtils from '@/common/services/StatusUtils'
import tooltipJson from '@/common/json/allTeamsTooltip.json'
import myTooltipJson from '@/common/json/allTeamsMyTooltip.json'
import VoiceRecordModal from '@/common/components/VoiceRecordModal.vue'
import LeaveNoteModal from './LeaveNoteModal.vue'
import TeamStatsModal from './TeamStatsModal.vue'
import StatusDescriptionModal from '@/components/StatusDescriptionModal.vue'
import Schedule from '@/common/components/Schedule.vue'

export default defineComponent({
  components: {
    WhiteboardModal,
    UserTimelineModal,
    Loader,
    OptionsTooltip,
    EditFloor,
    CreateRoom,
    EditRoom,
    HopReportForm,
    LeaveNoteModal,
    VoiceRecordModal,
    TeamStatsModal,
    StatusDescriptionModal,
    Schedule
  },

  data() {
    return {
      store: useStore(),
      stringUtility: StringUtils,
      timeUtilityService: new TimeUtilityService(),
      moment: moment,
      userStatus: UserStatus,
      statusUtils: new StatusUtils(),
      orgRoles: Roles,
      showWhiteboard: false,
      showUserTimelineModal_: false,
      whiteboardUrl: '',
      userStatusArray: {} as any,
      selectedUser_: '',
      selectedRoom: {},
      meetingParticipant: {} as any,
      officeView_: 'full-office',
      animationDoor: false,
      isTooltipVisible: false,
      isMyTooltipVisible: false,
      openModal: '',
      userTimelineModal: false,
      seatWidth: window.innerWidth < 960 ? 100 : 19,
      filters: {} as any,
      view: 'Regular',
      isOrgEditTooltipVisible: false,
      isHoverTooltipVisible: false,
      visibleTooltip: '',
      activeEmployeeVideo: '',
      tooltipProp: '',
      tooltipPos: { top: '0', left: '0' } as any,
      hoverTooltipStyle: {
        width: '124px',
        display: 'flex'
      },
      tooltipOptions: [
        {
          ...tooltipJson.tooltipOptions.wave,
          onClick: this.wave.bind(this)
        },
        {
          ...tooltipJson.tooltipOptions.call,
          onClick: this.startVideoCall.bind(this)
        },
        {
          ...tooltipJson.tooltipOptions.chat,
          onClick: this.startChat.bind(this)
        },
        {
          ...tooltipJson.tooltipOptions.voiceMessage,
          onClick: this.setOpenModal.bind(this, 'record-voice')
        },
        {
          ...tooltipJson.tooltipOptions.sendSticker,
          onClick: this.setOpenModal.bind(this, 'send-sticker')
        },
        {
          ...tooltipJson.tooltipOptions.fileDrop,
          onClick: this.clickFileUpload.bind(this)
        },
        {
          ...tooltipJson.tooltipOptions.leaveNote,
          onClick: this.setOpenModal.bind(this, 'leave-a-note')
        },
        {
          ...tooltipJson.tooltipOptions.addToFloor
        },
        {
          ...tooltipJson.tooltipOptions.dropIn,
          onClick: this.dropIn.bind(this)
        },
        {
          ...tooltipJson.tooltipOptions.schedule,
          onClick: this.viewSchedule.bind(this)
        }
      ] as any,
      hoverTooltipOptions: [
        {
          span: {
            ...tooltipJson.tooltipOptions.call.span
          },
          title: '',
          onClick: this.startVideoCall.bind(this)
        },
        {
          span: {
            ...tooltipJson.tooltipOptions.voiceMessage.span
          },
          title: '',
          onClick: this.setOpenModal.bind(this, 'record-voice')
        },
        {
          span: {
            ...tooltipJson.tooltipOptions.dropIn.span
          },
          title: '',
          onClick: this.dropIn.bind(this)
        }
      ] as any,
      myTooltipOptions: [
        {
          ...myTooltipJson.myTooltipOptions.retakeImage,
          onClick: this.updateWebcamshot.bind(this)
        },
        {
          ...myTooltipJson.myTooltipOptions.changeStatus
        },
        {
          ...myTooltipJson.myTooltipOptions.available,
          onClick: this.updateUserStatus.bind(this, 'AVAILABLE')
        },
        {
          ...myTooltipJson.myTooltipOptions.DND,
          onClick: this.updateUserStatus.bind(this, 'DND')
        },
        {
          ...myTooltipJson.myTooltipOptions.learning,
          onClick: this.updateUserStatus.bind(this, 'LEARNING')
        },
        {
          ...myTooltipJson.myTooltipOptions.busy,
          onClick: this.updateUserStatus.bind(this, 'BUSY')
        },
        {
          ...myTooltipJson.myTooltipOptions.unavailable,
          onClick: this.setStausUnavailable.bind(this)
        }
      ] as any,
      orgEditTooltipDefaultOptions: [
        {
          title: 'Edit floor',
          onClick: this.setOpenModal.bind(this, 'edit-floor')
        },
        {
          title: 'Add room',
          onClick: this.addRoom.bind(this)
        }
      ] as any,
      orgEditTooltipOptions: [] as any,
      occupants: {},
      selected: '',
      badges: [
        'badge.jpg',
        'bitcoin.jpg',
        'jc2.svg'
      ],
      offlineTime: '' as any,
      filteredMembers: [] as any,
      emitter: getCurrentInstance()?.appContext.config.globalProperties.emitter,
      statusDuration: {} as any
    }
  },

  async mounted() {
    await this.store.dispatch('getOrganizationMembers', this.store.getters.organization?.id)
    this.store.dispatch('getAllSkills')
    this.store.dispatch('getRooms')
    await this.store.dispatch('getLatestEmployeeTimeline')
    if (this.sortedUsers) {
      this.filteredMembers = this.sortedUsers
    }
  },

  methods: {

    setSeatWidth(width: any, viewClass = 'Regular') {
      if (window.innerWidth < 960) {
        this.seatWidth = 100
      } else {
        this.seatWidth = width
      }
      this.view = viewClass
    },

    randomBadge(badges: any) {
      return require(`@/assets/img/${badges[Math.floor(Math.random() * badges.length)]}`)
    },

    setOpenModal(modal: string, username: string) {
      this.selectedUser_ = username
      this.openModal = modal
    },

    openWhiteboard(url: string) {
      this.whiteboardUrl = url
      this.openModal = 'whiteboard'
    },

    navigate(route: string) {
      this.$router.push(route)
    },

    addRoom() {
      this.openModal = 'room-creation'
    },

    toggleHopReportModal(e: any, modal: string) {
      this.openModal = modal
      e!.stopPropagation()
    },

    gotoMeeting(id: any) {
      this.$router.push({
        name: 'Conference',
        path: '/conference',
        params: { id }
      })
    },

    changeOfficeView(view: string) {
      this.officeView_ = view
    },

    openUserTimelineModal(event: MouseEvent, username: string) {
      this.selectedUser_ = username
      this.userTimelineModal = true
      this.store.dispatch('getFilteredEmployeeTimeline', { username, week: moment().week(), year: moment().year() })
    },

    toggleUserTimelineModal(event: MouseEvent) {
      event.stopPropagation()
      // this.closeModal()
      this.userTimelineModal = false
      this.setSeatWidth(19)
    },

    openTheDoor(event: any) {
      event.preventDefault()
      this.animationDoor = false
    },

    startDragToConfRoom(member: any) {
      this.meetingParticipant = member
      this.animationDoor = true
      setTimeout(() => {
        this.animationDoor = false
      }, 4000)
    },

    closeModal() {
      this.openModal = ''
    },

    startMeeting() {
      this.$router.push({
        name: 'NewMeeting',
        params: { members: JSON.stringify(this.meetingParticipant) }
      })
    },

    showHoverOptionsTooptip(e: any, employee: any) {
      if (employee !== this.user.username && this.visibleTooltip === '') {
        this.tooltipPos = {
          top: '100%',
          left: '15%'
        }
        this.tooltipProp = employee
        this.isHoverTooltipVisible = true
      }
    },

    hideHoverTooltip() {
      this.isHoverTooltipVisible = false
    },

    hideTooltip() {
      this.visibleTooltip = ''
    },

    showOptionsTooltip(e: any, employee: string) {
      if (employee === this.user.username) {
        this.visibleTooltip = 'my-tooltip'
      } else {
        this.visibleTooltip = 'employee-tooltip'
      }
      this.isHoverTooltipVisible = false
      const x = e.pageX - e.currentTarget.offsetLeft
      const y = e.pageY - e.currentTarget.offsetTop
      this.tooltipPos = { top: y + 20 + 'px', left: x + 20 + 'px' }
      this.tooltipProp = employee
    },

    openOrgEdit(e: any) {
      if (Object.keys(this.filters).length) {
        this.orgEditTooltipOptions = [
          ...this.orgEditTooltipDefaultOptions,
          {
            title: 'Save filter as new floor',
            onClick: this.saveFilterAsFloor.bind(this)
          }
        ]
      } else {
        this.orgEditTooltipOptions = this.orgEditTooltipDefaultOptions
      }
      this.tooltipPos = { top: '130px', right: '26px' }
      this.visibleTooltip = 'org-edit'
    },

    saveFilterAsFloor() {
      // TODO
    },

    openEditRoom(room: any) {
      this.selectedRoom = room
      this.openModal = 'room-edit'
      this.occupants = room.occupants
    },

    startChat(employee: string) {
      // this.store.commit('setCurrentRoom', room)
      const dmRoom = MxRoom.getDmRoomBySenderId(this.store.getters.matrixRooms, employee)
      this.store.commit('setCurrentRoom', dmRoom)
      this.$router.push('/mx/chats')
    },

    startVideoCall(employee: string) {
      // this.store.commit('setCurrentRoom', room)
      // this.$router.push('/mx/chats')
      const dmRoom = MxRoom.getDmRoomBySenderId(this.store.getters.matrixRooms, employee)
      this.store.commit('setCurrentRoom', dmRoom)
      // this.emitter.emit('setCallView')
    },

    filterByDepartment(department: string) {
      this.filters.department = department
      for (let i = 0; i < this.rooms.length; i++) {
        if (this.rooms[i].name === department) {
          this.filteredMembers = this.rooms[i].occupants.sort((m1: any, m2: any) => m1.name.localeCompare(m2.name))
        }
      }
    },

    filterByLocation(location: string) {
      this.filters.location = location
      this.filteredMembers = []
      if (location === 'Goa') {
        for (let i = 0; i < this.sortedUsers.length; i++) {
          if (this.sortedUsers[i].location === location) {
            this.filteredMembers.push(this.sortedUsers[i])
          }
        }
      } else {
        for (let i = 0; i < this.sortedUsers.length; i++) {
          if (this.sortedUsers[i].location !== location) {
            this.filteredMembers.push(this.sortedUsers[i])
          }
        }
      }
    },

    filterByRole(role: string) {
      this.filters.role = role
      this.filteredMembers = []
      if (role === 'Manager') {
        for (let i = 0; i < this.sortedUsers.length; i++) {
          if (this.sortedUsers[i].title === role) {
            this.filteredMembers.push({ ...this.sortedUsers[i] })
          }
        }
      } else {
        for (let i = 0; i < this.sortedUsers.length; i++) {
          if (this.sortedUsers[i].title !== role) {
            this.filteredMembers.push({ ...this.sortedUsers[i] })
          }
        }
      }
    },

    filterEmployees(filterBy: any, value: any, capitalizeLabel = true) {
      this.filters[filterBy] = capitalizeLabel
        ? StringUtils.capitalizeFirstLetter(value)
        : value
      this.filteredMembers = [...this.sortedUsers]
      this.filteredMembers = this.sortedUsers.filter((member: any) =>
        member[filterBy] === value
      )
    },

    allTeamMembers() {
      if (Object.keys(this.filters).length) {
        this.filters = {}
        this.filteredMembers = this.sortedUsers
      }
    },

    wave(receiver: string) {
      const content = {
        body: '',
        metadata: {
          from: this.user.username,
          to: receiver
        },
        msgtype: CustomMxEvent.WAVE
      }
      MxRoom.sendCustomNotification(
        this.matrixClient,
        this.notificationChannel,
        content
      )
    },

    leaveNote(receiver: string, note: string) {
      const content = {
        body: '',
        metadata: {
          from: this.user.username,
          to: receiver,
          note
        },
        msgtype: CustomMxEvent.NOTE
      }
      MxRoom.sendCustomNotification(
        this.matrixClient,
        this.notificationChannel,
        content
      )
    },

    refreshWebcamshots() {
      this.store.dispatch('getOrganizationMembers', this.store.getters.organization?.id)
      this.store.dispatch('getLatestEmployeeTimeline')
      this.organizationMembers.forEach((member: any) => {
        const content = {
          body: '',
          metadata: {
            to: member.username
          },
          msgtype: CustomMxEvent.NOTIFICATION
        }
        MxRoom.sendCustomNotification(
          this.matrixClient,
          this.notificationChannel,
          content
        )
      })
    },

    clickFileUpload(username: string) {
      this.selectedUser_ = username
      const fileUploadFieldClick = document.getElementById('file-upload')?.click()
    },

    async uploadFile(e: any) {
      const dmRoom = MxRoom.getDmRoomBySenderId(this.store.getters.matrixRooms, this.selectedUser_)
      if (e.target.files.length && dmRoom) {
        const file = e.target.files[0]
        MxRoom.uploadFile(this.matrixClient, e.target.files[0], dmRoom.roomId)
      }
    },

    updateUserStatus(status: UserStatus, statusDescription?: string) {
      this.statusUtils.manuallyUpdateStatus(status, statusDescription)
    },

    setStausUnavailable() {
      this.openModal = 'status-description'
    },

    updateWebcamshot() {
      new StatusUtils().updateStatusAndTimeline()
    },

    clearWave(event: MouseEvent, from: string) {
      MxRoom.removeEventFromRoom(
        this.matrixClient,
        this.waveData[from].roomId,
        this.waveData[from].eventId
      )
      this.store.dispatch('setWaveData', { from, data: null })
    },

    dropIn(employee: string) {
      const dmRoom = MxRoom.getDmRoomBySenderId(this.store.getters.matrixRooms, employee)
      this.store.commit('setCurrentRoom', dmRoom)
      // this.emitter.emit('setCallView', 'dropIn')
      const content = {
        body: '',
        metadata: {
          from: this.user.username,
          to: employee,
          room: this.user.username + '_' + employee + '_drop_in'
        },
        msgtype: CustomMxEvent.DROP_IN
      }
      MxRoom.sendCustomNotification(
        this.matrixClient,
        this.notificationChannel,
        content
      )
      this.store.commit('setJitsiVideoCallActive', {
        value: true, room: this.user.username + '_' + employee + '_drop_in'
      })
    },

    viewSchedule(username: string) {
      this.openModal = 'schedule'
      this.selectedUser_ = username
    },

    openStatsModal() {
      this.openModal = 'team-stats'
      this.organizationMembers.forEach((user: any) => {
        this.store.dispatch('getEmployeeTimelineByDate', { username: user.username, date: moment() })
      })
    }
  },

  computed: {
    usersByUsername(): any {
      return this.store.getters.usersByUsername
    },

    roles(): any {
      return this.store.getters.roles
    },

    rooms(): any {
      return this.store.getters.allFloors[0].rooms
    },

    selectedUser(): string {
      return this.selectedUser_
    },

    showUserTimelineModal(): boolean {
      return this.showUserTimelineModal_
    },

    officeView(): any {
      return this.officeView_
    },

    isPageLoading(): any {
      return this.store.getters.isPageLoading
    },

    user(): any {
      return this.store.getters.user
    },

    organization(): any {
      return this.store.getters.organization
    },

    notificationChannel(): string {
      return this.organization.organizationNotificationSpaceId
    },

    organizationMembers(): any {
      const membersSorted = this.store.getters.organizationMembers
      return membersSorted.sort((m1: any, m2: any) => m1.name.localeCompare(m2.name))
    },

    matrixClient(): MatrixClient {
      return this.store.getters.matrixClient
    },

    waveData(): any {
      return this.store.getters.waveData
    },

    notes(): any {
      return this.store.getters.notes
    },

    sortedUsers(): any {
      let sortedUsers = [] as any
      let user = {} as any
      sortedUsers = this.store.getters.organizationMembers.filter((member: any) => {
        if (member.username === this.user.username) {
          user = member
        } else { return member }
      })
      sortedUsers = [{ ...user }, ...sortedUsers]
      return sortedUsers
    },

    isLiveRefreshInProgress(): boolean {
      return this.store.getters.isLiveRefreshInProgress
    }
  }
})
