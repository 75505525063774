
import { CustomMxEvent } from '@/common/enums'
import MxRoom from '@/common/services/MxRoom'
import { MatrixClient } from 'matrix-js-sdk'
import { defineComponent } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  props: {
    receiver: { type: String, required: true }
  },

  data () {
    return {
      store: useStore(),
      note: ''
    }
  },

  methods: {
    leaveNote (note: string) {
      const content = {
        body: '',
        metadata: {
          from: this.user.username,
          to: this.receiver,
          note
        },
        msgtype: CustomMxEvent.NOTE
      }
      MxRoom.sendCustomNotification(
        this.matrixClient,
        this.notificationChannel,
        content
      )
    },

    close (): void {
      this.$emit('close')
    }
  },

  computed: {
    user (): any {
      return this.store.getters.user
    },

    matrixClient (): MatrixClient {
      return this.store.getters.matrixClient
    },

    /** TODO: Using organization room as notification channel for now. Change this. */
    notificationChannel (): string {
      return this.store.getters.organization.organizationNotificationSpaceId
    }
  }
})
