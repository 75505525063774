import store from '@/store'
import moment from 'moment'
import TimelineCapturer from '@/common/services/TimelineCapturer'
import { CustomMxEvent, UserStatus } from '../enums'
import MxRoom from './MxRoom'
import { use } from 'chai'

export default class StatusUtils {
  capitalizeStatus (status: string) {
    const words = status.toLowerCase().split('_')
    const capitalizedWords = words.map(word => {
      return word[0].toUpperCase() + word.slice(1)
    })
    return capitalizedWords.join(' ')
  }

	updateUserStatus (status: UserStatus, user: any, statusDescription?: string) {
		user.statusLastUpdatedAt = moment()
		if (status !== UserStatus.UNAVAILABLE) {
			statusDescription = ''
			user.statusDescription = statusDescription
			store.dispatch('updateUserStatus', { status, statusDescription })
		}

    /**
     * If user status got updated to 'AVAILABLE' from 'UNAVAILABLE' or 'AWAY' or 'LEFT',
     * then inform other employees in the organization.
     */
    if (
      (store.getters.user.status === UserStatus.UNAVAILABLE ||
      store.getters.user.status === UserStatus.AWAY ||
      store.getters.user.status === UserStatus.LEFT) &&
      status === UserStatus.AVAILABLE
    ) this.notifyEmployeeStatus()
	}

  notifyEmployeeStatus() {
    store.getters.organizationMembers.forEach((member: any) => {
      if (member.username !== store.getters.user.username) {
        const content = {
          body: '',
          metadata: {
            to: member.username,
            from: store.getters.user.username,
            fromUserName: store.getters.user.name
          },
          msgtype: CustomMxEvent.EMPLOYEE_ONLINE
        }
        MxRoom.sendCustomNotification(
          store.getters.matrixClient,
          store.getters.organization.organizationNotificationSpaceId,
          content
        )
      }
    })
  }

  updateStatusAndTimeline(status?: UserStatus) {
    if (
      store.getters.user.status !== UserStatus.UNAVAILABLE &&
      store.getters.user.status !== UserStatus.AWAY &&
      store.getters.user.status !== UserStatus.LEFT &&
      !store.getters.isLocked
    ) {
      this.updateUserStatus(status || store.getters.user.status, store.getters.user)
      TimelineCapturer.fullscreenScreenshot()
    }
  }

  manuallyUpdateStatus(status: UserStatus, statusDescription?: string) {
    this.updateUserStatus(status || store.getters.user.status, store.getters.user, statusDescription)
    if (
      status !== UserStatus.UNAVAILABLE
    ) {
      TimelineCapturer.fullscreenScreenshot()
    }
  }

  setStausUnavailable (user: any, statusDescription: string, duration: any) {
		user.statusLastUpdatedAt = moment()
    store.dispatch('updateUserStatus', {
      status: UserStatus.UNAVAILABLE,
      statusDescription,
      duration: moment.duration(duration).asMinutes()
    })
    .then((res: any) => {
      user.statusDescription = statusDescription
    })
	}

  getBreakEndTime (duration: any, statusLastUpdatedAt: any) {
    return moment(statusLastUpdatedAt * 1000).add({
      days: duration.days, hours: duration.hours, minutes: duration.minutes
    })
  }

  calculateStatusDuration (timelines: any) {
    const statusDuration = {
      available: 0,
      unavailable: 0,
      dnd: 0,
      learning: 0,
      away: 0,
      left: 0
    }
    timelines.forEach((timeline: any, index: number) => {
      if (index !== timelines.length - 1) {
        const startTime = moment(timeline.submittedOn * 1000)
        const endTime = moment(timelines[index + 1].submittedOn * 1000)
        const diff = endTime.diff(startTime, 'milliseconds')

        switch (timeline.status) {
          case 'AVAILABLE':
          case 'BUSY':
            statusDuration.available += diff
            break

          case 'UNAVAILABLE':
            statusDuration.unavailable += diff
            break

          case 'DND':
            statusDuration.dnd += diff
            break

          case 'LEARNING':
            statusDuration.learning += diff
            break

          case 'LEFT':
            statusDuration.left += diff
            break

          case 'AWAY':
            statusDuration.away += diff
            break

          default:
            break
        }
      }
    })

    return statusDuration
  }
}
