
import { defineComponent } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  data() {
    return {
      store: useStore(),
      newPin: '',
      newPinConfirm: '',
      isPinCorrect: true
    }
  },

  methods: {
    checkIfPinMatches() {
      this.isPinCorrect = this.newPin === this.newPinConfirm
    },

    close(): void {
      this.$emit('close')
    },

    updatePin() {
      this.store.dispatch('updatePin', this.newPin).then(() => {
        this.close()
      }).catch((e: any) => {
        console.error('Update pin API failed!!')
      })
    }
  }
})
