
import { defineComponent } from 'vue'
import { MatrixClient, Room } from 'matrix-js-sdk'
import { useStore } from 'vuex'
import MxRoom from '@/common/services/MxRoom'
import PinnedMessages from './PinnedMessages.vue'

export default defineComponent({
  components: {
    PinnedMessages
  },

  data () {
    return {
      mxRoom: MxRoom,
      store: useStore(),
      sharedRooms_: [] as any
    }
  },

  methods: {
    close (event: MouseEvent) {
      this.$emit('close', event)
    },

    async getSharedRooms () {
      this.sharedRooms_ = await MxRoom.getRoomsSharedWithUser(
        this.matrixClient, this.room.getAvatarFallbackMember().userId
      )
      // const roomIdList = await this.matrixClient._unstable_getSharedRooms(this.room.getAvatarFallbackMember().userId)
      // roomIdList.forEach((roomId: string) => {
      //   const room = this.matrixClient.getRoom(roomId)
      //   if (!this.roomUtils.isRoomDM(room)) this.sharedRooms_.push(room)
      // })
    }
  },

  computed: {
    user (): any {
      return this.store.getters.user
    },

    usersByUsername (): any {
      return this.store.getters.usersByUsername
    },

    room (): Room {
      return this.store.getters.currentRoom.room
    },

    matrixClient (): MatrixClient {
      return this.store.getters.matrixClient
    },

    sharedRooms (): Room[] {
      this.getSharedRooms()
      return this.sharedRooms_
    },

    getDMUser (): any {
      return this.usersByUsername[MxRoom.getDmRoomName(this.room, this.user.username)!]
    }
  }
})
