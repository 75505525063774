import axios, { AxiosPromise } from 'axios'

export default {
  getAllSkills (): AxiosPromise<any> {
    return axios.get('blox/skills')
  },

  addSkills (skills: string[]): AxiosPromise<any> {
    return axios.post('blox/skills', skills)
  }
}
