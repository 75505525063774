
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import UserMultiselectModal from '@/common/components/UserMultiselectModal.vue'

export default defineComponent({
  components: { UserMultiselectModal },

  data () {
    return {
      store: useStore(),
      room: {
        name: '',
        type: ''
      },
      roomTypes: ['DEPARTMENT', 'OTHER'],
      members: [],
      showAddUserModal: false,
      selectedUsers: []
    }
  },

  methods: {
    toggleAddUserModal (event: MouseEvent) {
      event.stopPropagation()
      this.showAddUserModal = !this.showAddUserModal
    },

    onUsersSelected (selectedMembersList: any, event: any) {
      this.selectedUsers = selectedMembersList
      this.toggleAddUserModal(event)
    },

    createRoom () {
      this.store.dispatch('addRoom', {
        dto: this.room, floorId: this.floor.id, position: this.floor.rooms.length + 2
      }).then((response: any) => {
          this.selectedUsers.forEach(async (member: any) => {
            await this.store.dispatch('addMemberToRoom', { roomId: response.id, username: member.username })
          })
        })
    },

    toggleMembersModal (event: MouseEvent) {
      event!.stopPropagation() // To prevent triggering outside click on this modal on clicking members modal close button.
      this.showAddUserModal = !this.showAddUserModal
    },

    selectUsers (selected: any) {
      this.selectedUsers = selected.filter((user: any) =>
        user.name !== '' && user.username !== this.account.username
      );

      (this.members as string[]) = this.selectedUsers.map((user: any) =>
        '@' + user.username + ':' + process.env.VUE_APP_MATRIX_HOMESERVER
      )
    },

    closeMe (event: any) {
      event.stopPropagation()
      if (!this.showAddUserModal) {
        this.$emit('close', event)
      }
    }
  },

  computed: {
    users (): string {
      return this.selectedUsers.map((user: any) => user.name).join(', ')
    },

    usersList (): any {
      return this.store.getters.organizationMembers
    },

    account (): any {
      return this.store.getters.user
    },

    floor (): any {
      return this.store.getters.allFloors[0]
    }
  }
})
