
import { defineComponent } from 'vue'

export default defineComponent({
  methods: {
    login () {
      this.$router.push('/')
    }
  }
})
