import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'
import MxStream from '@/components/MxStream.vue'
import Meetings from '../components/Meetings.vue'
import Floor from '../components/Floor.vue'
import Calendar from '../components/calendar/Calendar.vue'
import Tasks from '../components/Tasks.vue'
import Board from '../components/Board.vue'
import NewMeeting from '@/components/newMeeting/NewMeeting.vue'
import MeetingList from '@/components/MeetingList.vue'
import MeetingDetails from '../components/meeting-details/MeetingDetails.vue'
import Whiteboard from '../components/whiteboard/Whiteboard.vue'
import eBooks from '@/components/e-books/eBooks.vue'
import eGaming from '@/components/e-gaming/eGaming.vue'
import OfficeBoard from '@/components/office-board/OfficeBoard.vue'
import OfficePets from '@/components/office-pets/OfficePets.vue'
import SocialBoard from '@/components/social-board/SocialBoard.vue'
import WaitingRooms from '@/components/waiting-rooms/WaitingRooms.vue'
import Signup from '@/components/signup/Signup.vue'
import Login from '@/components/login/Login.vue'
import CreateOrganisation from '@/modules/onboarding/components/CreateOrganisation.vue'
import CreateWorkspace from '@/modules/onboarding/components/CreateWorkspace.vue'
import OrganisationInvite from '@/modules/onboarding/components/organisation-invite/OrganisationInvite.vue'
import OrganizationDetails from '@/modules/onboarding/components/OrganizationDetails.vue'
import InviteNewUser from '@/modules/onboarding/components/InviteNewUser.vue'
import AdminPanel from '../components/admin-panel/AdminPanel.vue'
import Chats from '@/modules/chats/components/Chats.vue'
import Feed from '@/modules/feed/components/feed/Feed.vue'
import FeedExpanded from '@/modules/feed/components/feed-expanded/FeedExpanded.vue'
import Download from '@/components/Download.vue'
import { isWebPlatform } from '@/config/PlatformConfig'
import Conference from '@/components/Conference.vue'
import ConferenceWaitingRoom from '@/components/ConferenceWaitingRoom.vue'
import OwnerBoard from '@/components/OwnerBoard.vue'
import MemberBoard from '@/components/MemberBoard.vue'
import Staff from '@/components/Staff.vue'
import Manager from '@/components/Manager.vue'
import Settings from '@/components/Settings.vue'
import Library from '@/components/Library.vue'
import Utility from '@/components/Utility.vue'
import ChatDM from '@/components/ChatDM.vue'
import ChatGroup from '@/components/ChatGroup.vue'
import Help from '@/components/Help.vue'
import AudioRooms from '@/components/AudioRooms.vue'
import SearchResults from '@/common/components/SearchResults.vue'
import PinLogin from '@/components/PinLogin.vue'
import Dashboard from '@/components/Dashboard.vue'
import ReportList from '@/common/components/ReportList.vue'

/** Routes */
import audioRoomRoutes from './audioRoomRoutes'

const mxRoutes = [
  {
    path: 'chats',
    name: 'Chats',
    component: Chats
  },
  {
    path: 'feed',
    name: 'Feed',
    component: Feed
  },
  {
    path: 'feed/post/:id',
    name: 'FeedExpanded',
    props: true,
    component: FeedExpanded
  }
]

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Board
  },
  {
    path: '/pin-login',
    name: 'Login with PIN',
    component: PinLogin
  },
  {
    path: '/workspace',
    name: 'Create Workspace',
    component: CreateWorkspace
  },
  {
    path: '/add-members',
    name: 'add-members',
    component: OrganisationInvite
  },
  {
    path: '/organization-details',
    name: 'Organization Details',
    component: OrganizationDetails
  },
  {
    path: '/all-teams',
    name: 'My Desk',
    component: Floor
  },
  {
    path: '/chats',
    name: 'Chats',
    component: Chats
  },
  {
    path: '/meetings',
    name: 'Meetings',
    component: Meetings
  },
  {
    path: '/chats',
    name: 'Chats',
    component: Chats
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: Calendar
  },
  {
    path: '/tasks',
    name: 'Tasks',
    component: Tasks
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
	{
		path: '/new-meeting',
		name: 'NewMeeting',
		component: NewMeeting
	},
  {
		path: '/meetings/:sectionTitle',
		name: 'MeetingList',
		component: MeetingList
	},
	{
    path: '/meetings/edit/:id',
    name: 'EditMeeting',
    component: NewMeeting
  },
	{
    path: '/meeting/details',
    name: 'MeetingDetails',
    component: MeetingDetails
  },
  {
    path: '/conference/:id',
    name: 'Conference',
    component: Conference
  },
  {
    path: '/waiting-room/:id',
    name: 'ConferenceWaitingRoom',
    component: ConferenceWaitingRoom
  },
  {
    path: '/whiteboard',
    name: 'Whiteboard',
    component: Whiteboard
  },
  {
    path: '/onboarding',
    name: 'Onboarding',
    component: CreateOrganisation
  },
	{
    path: '/invite',
    name: 'Invite',
    component: InviteNewUser
  },
  {
    path: '/admin-panel',
    name: 'Adminpanel',
    component: AdminPanel
  },
  {
    path: '/e-books',
    name: 'eBooks',
    component: eBooks
  },
  {
    path: '/e-gaming',
    name: 'eGaming',
    component: eGaming
  },
  {
    path: '/office-pets',
    name: 'OfficePets',
    component: OfficePets
  },
  {
    path: '/office-board',
    name: 'OfficeBoard',
    component: OfficeBoard
  },
  {
    path: '/social-board',
    name: 'SocialBoard',
    component: SocialBoard
  },
  {
    path: '/waiting-rooms',
    name: 'WaitingRooms',
    component: WaitingRooms
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/mx',
    name: 'Mx',
    component: MxStream,
    children: mxRoutes
  },
  {
    path: '/download',
    name: 'Download',
    component: Download
  },
  {
    path: '/owner-board',
    name: 'Owner Board',
    component: OwnerBoard
  },
  {
    path: '/member-board',
    name: 'Member Board',
    component: MemberBoard
  },
  {
    path: '/staff',
    name: 'Staff',
    component: Staff
  },
  {
    path: '/manager',
    name: 'Manager',
    component: Manager
  },
  {
    path: '/library',
    name: 'Library',
    component: Library
  },
  {
    path: '/utility',
    name: 'Utility',
    component: Utility
  },
  {
    path: '/demo-chat-group',
    name: 'ChatGroup',
    component: ChatGroup
  },
  {
    path: '/demo-chat-dm',
    name: 'ChatDM',
    component: ChatDM
  },
  {
    path: '/help',
    name: 'Help',
    component: Help
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings
  },
  // {
  //   path: '/audio-rooms',
  //   name: 'Audio Rooms',
  //   component: AudioRooms
  // },
  {
    path: '/search-results',
    name: 'Search Results',
    component: SearchResults
  },
  {
    path: '/hop-reports',
    name: 'Hop Reports',
    component: ReportList
  },
  ...audioRoomRoutes
]

const router = createRouter({
  history: isWebPlatform() ? createWebHistory() : createWebHashHistory(),
  routes
})

export default router
