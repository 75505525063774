
import { defineComponent, ref } from 'vue'
import Quill, { RangeStatic } from 'quill'
import 'quill/dist/quill.snow.css'
import { Vue, Options } from 'vue-class-component'
import { useStore } from 'vuex'
import UtilityService from '@/common/services/TimeUtilityService'
import { MatrixClient, MatrixEvent, Room } from 'matrix-js-sdk'
import EventUtils from '../services/EventUtils'
import EventContent from './EventContent.vue'
import RoomHeader from './RoomHeader.vue'
import { EmojiButton } from '@joeattardi/emoji-button'
import ReactionButton from './ReactionButton.vue'
import MessageActions from './MessageActions.vue'
import MxRoom from '@/common/services/MxRoom'
import ChatSearch from './ChatSearch.vue'
import { FreeStyleShimmer } from 'vue3-shimmer'

export default defineComponent({
  components: {
    RoomHeader,
    EventContent,
    ReactionButton,
    MessageActions,
    ChatSearch,
    FreeStyleShimmer
  },

  data () {
    return {
      replyEvent: null as any,
      utilityService: new UtilityService(),
      emojiPicker: new EmojiButton(),
      eventUtils: new EventUtils(),
      store: useStore(),
      mxRoom: MxRoom,
      isReplying: false,
      isSearching: false,
      editor: ref(null),
      isHistoryLoading: false,
      userScrolling: false,
      currentIndex: 0,
      runningTexts: ['.._.', '._..'] as any,
      /**
       * TODO: Find a better way to implement emoji pickers for reaction and message separately.
       * This variable is used to check which emoji picker is clicked now.
       */
      reactionEvent: null as any,
      roomEvents: [] as any,

      user: {},
      message: '',
      chatHover: '',
      eventToEdit: {} as MatrixEvent,
      quillInstance: Quill as any,
      quillToolbarOptions: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['blockquote', 'code-block'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ['clean'] // remove formatting button
      ],

      cursorPosition: 0,
      divHeight: 0
    }
  },

  updated() {
    if (!this.isHistoryLoading && !this.userScrolling) {
      this.scrollToBottom()
    }
  },

  mounted () {
    setInterval(() => {
      if (!this.userScrolling) {
        this.currentIndex++
      }
    }, 500)

    const scrollContainer = document.getElementById('history')

    let scrollTimeout = 0

    if (scrollContainer) {
      scrollContainer!.addEventListener('scroll', (e: any) => {
        clearTimeout(scrollTimeout)

        this.userScrolling = true

        scrollTimeout = setTimeout(() => {
          this.userScrolling = false
        }, 3000) // Scroll detection 3s timeout
      })
    }

    this.quillInstance = new Quill('#editor',
    {
      modules: { toolbar: this.quillToolbarOptions },
      placeholder: 'Send a message',
      // placeholder: 'Send to ' + (this.mxRoom.isRoomDM(this.room) ? '' : '# ') + this.room.name,
      // ToDo - dynamic placholder bugfix
      theme: 'snow'
    })
    this.quillInstance.on('text-change', () => {
      this.message = this.quillInstance!.root.innerHTML
      this.cursorPosition = this.quillInstance.getLength() - 1
    })
    this.quillInstance.on('selection-change', (range: RangeStatic | null) => {
      if (range) {
        this.cursorPosition = range.index
      }
    })
    this.user = JSON.parse(localStorage.getItem('matrixCreds')!) || null

    this.emojiPicker.on('emoji', (selection: any) => {
      if (this.reactionEvent) {
        this.sendReaction(selection.emoji, this.reactionEvent)
      } else {
        this.addReactionToMessage(selection.emoji)
      }
    })

    this.matrixClient.on('Room.timeline', (event: MatrixEvent, room: Room) => {
      if (room.roomId === this.room.roomId && event.getType() === 'm.room.message') {
        this.currentRoomEvents = room.getLiveTimeline().getEvents()
        this.scrollToBottom()
      }
    })

    this.scrollToBottom()

    this.loadHistoryOnScrollUp()
  },

  methods: {
    toggleChatOptions (value: string) {
      this.chatHover = value
    },

    loadHistoryOnScrollUp() {
      const scrollElement = document.getElementById(this.room.roomId)
      if (scrollElement) {
        scrollElement.addEventListener('scroll', (e: any) => {
          if (scrollElement.scrollTop === 0) {
            const prevScrollHeight = scrollElement.scrollHeight
            this.matrixClient.scrollback(this.room).then(() => {
              scrollElement.scrollTop = scrollElement.scrollHeight - prevScrollHeight - 100
              this.isHistoryLoading = false
            })
            this.isHistoryLoading = true
          }
        })
      }
    },

    toggleEmojiPicker (clickEvent: MouseEvent, event?: MatrixEvent) {
      this.emojiPicker.togglePicker(clickEvent.target)
      this.reactionEvent = event
    },

    sendReaction (reaction: any, reactionEvent: MatrixEvent) {
      MxRoom.sendReaction(this.matrixClient, reaction, reactionEvent, this.room.roomId)
      /** Setting event on which reaction happened to null. */
      this.reactionEvent = null
      this.scrollToBottom()
    },

    addReactionToMessage (emoji: any) {
      this.quillInstance.insertText(this.cursorPosition, emoji, 'user')
      this.cursorPosition += emoji.length // update position
      this.quillInstance.setSelection(this.cursorPosition)
      // const length = this.quillInstance.getLength()
      // this.cursorPosition = this.quillInstance.getSelection()?.index ?? length
      // if (this.cursorPosition === length) {
      //   this.cursorPosition -= 1
      // }
      // this.quillInstance.insertText(this.cursorPosition, emoji)
      // this.message += emoji
    },

    sendMessage () {
      if (this.message) {
        MxRoom.sendMessage(this.matrixClient, this.message, this.room.roomId, this.replyEvent)
      }
      this.message = ''
      this.quillInstance.setText('', 'user') // clear editor
      this.cursorPosition = 0
      this.isReplying = false
      this.scrollToBottom()
    },

    reply (event: MatrixEvent) {
      this.isReplying = true
      this.replyEvent = event
    },

    toggleEditView (event: MatrixEvent | any): void {
      this.eventToEdit = event
    },

    clickFileUpload () {
      const fileUploadFieldClick = document.getElementById('file-upload')?.click()
    },

    async uploadFile (e: any) {
      /**
       * TODO: Check for file size
       */
      if (e.target.files.length) {
        const file = e.target.files[0]
        MxRoom.uploadFile(this.matrixClient, e.target.files[0], this.room.roomId, this.replyEvent)
        this.isReplying = false
      }
    },

    toggleSearchView () {
      this.isSearching = !this.isSearching
    },

    getUserAvatar (userId: string): string {
      const username = userId.slice(1, userId.indexOf(':'))
      return this.store.getters.usersByUsername[username]?.avatarUrl
    },

    /** related event for given event. */
    getRelatedEventContent (event: MatrixEvent): MatrixEvent | null {
      const relatedEvent = this.room.findEventById(this.eventUtils.getRelatedEvent(event)!)
      if (relatedEvent && !this.eventUtils.isReplaceEvent(relatedEvent)) {
        return relatedEvent
      }
      return null
    },

    getBloxUserForMatrixId(matrixUserId: string) {
      return this.usersByUsername[MxRoom.getBloxUserId(matrixUserId)]
    },

    scrollToBottom () {
      const element = document.getElementById('history')
      element!.scrollTop = element!.scrollHeight
      console.log('----------------------SCROLLING!!!!----------------------')
    }
  },

  computed: {
    runningText(): any {
      return this.runningTexts[this.currentIndex % this.runningTexts.length]
    },
    matrixClient (): MatrixClient {
      return this.store.getters.matrixClient
    },

    room (): Room {
      return this.store.getters.currentRoom.room
    },

    usersByUsername (): any {
      return this.store.getters.usersByUsername
    },

    isComponentLoading (): any {
      return this.store.getters.isComponentLoading
    },

    currentRoomEvents (): any {
      return this.store.getters.currentRoomEvents
    }
  }
})
