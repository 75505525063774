export default {
  groupByKey (objArray: any[], key: any) {
    return objArray.reduce((result, currentItem) => {
      (result[currentItem[key]] =
        result[currentItem[key]] || []
      ).push(currentItem)
      return result
    }, {}) // Empty object is the initial value for result object
  },

  /** Group objects where consecutive values for the key are same. */
  groupByConsecutiveKeys (objArray: any[], key: any) {
    const grouped = []
    let group: any = []
    let prevValue: any

    if (objArray) {
      objArray.forEach(obj => {
        if (obj[key] !== prevValue) {
          if (group.length) {
            grouped.push(group)
          }
          group = [obj]
          prevValue = obj[key]
        } else {
          group.push(obj)
        }
      })

      if (group.length) {
        grouped.push(group)
      }

      console.log(grouped)
      return grouped
    } else return []
  }
}
