import { MatrixClient, Room } from 'matrix-js-sdk'

const getters = {
  matrixClientCreds (state: any) {
    return state.matrixClientCreds
  },

  matrixClient (state: any): MatrixClient {
    return state.matrixClient
  },

  matrixRooms (state: any) {
    return state.matrixRooms
  },

  currentRoom (state: any) {
    return state.currentRoom
  },

  currentRoomEvents (state: any) {
    return state.currentRoom.events
  },

  chatNotificationsCount(state: any) {
    return state.notificationsCount
  }
}

export default getters
