
import { Vue, Options } from 'vue-class-component'
import { useStore } from 'vuex'
import { MultiSelectComponent } from '@syncfusion/ej2-vue-dropdowns'

@Options({
  components: { 'ejs-multiselect': MultiSelectComponent }
})
export default class CreateWorkspace extends Vue {
  store = useStore()
  workspaceForm = {
  teams: []
  // socialRooms: [],
  // boards: [],
  // whiteboards: [],
  // extras: []
  }

	rooms = []

  async createWorkspace () {
    const rooms = []
    const teams: any = []
    // TODO: update the code -> directly add data to workspaceForm
    for (let i = 0; i < this.workspaceForm.teams.length; i++) {
      rooms.push({ name: this.workspaceForm.teams[i], type: 'DEPARTMENT' })
      teams.push(this.workspaceForm.teams[i])
      await this.store.dispatch('addRoom', {
        dto: {
          name: this.workspaceForm.teams[i],
          type: 'DEPARTMENT'
        },
        floorId: this.floor.id,
        position: this.floor.rooms.length
      })
      this.$router.push('/onboarding-member')
    }

    // for (let i = 0; i < this.workspaceForm.socialRooms.length; i++) {
    // 	room.push({ name: this.workspaceForm.socialRooms[i], type: 'SOCIAL_ROOMS' })
    // }
		this.store.dispatch('setTeams', teams)
		// this.store.dispatch('createOrganizationRooms', room)
  }

  get organization () {
    return this.store.getters.getOrganization
  }

  get teams () {
    return []
  }

  get socialRooms () {
    return ['Chill Room', 'Library', 'Pantry']
  }

  get boards () {
    return ['Office Board', 'Social Board']
  }

  get extras () {
    return ['Office Pets', 'Suggestions', 'Game']
  }

  get floor (): any {
    return this.store.getters.allFloors[0]
  }
}
