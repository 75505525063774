
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import { NO_FACE_FOUND } from '@/common/constants/MessageConstants'

export default defineComponent({
  data() {
    return {
      store: useStore(),
      noFaceFoundMsg: NO_FACE_FOUND
    }
  },

  methods: {
    hide() {
      this.store.dispatch('setFacesFound', {})
    }
  },

  computed: {
    user(): any {
      return this.store.getters.user
    },

    /** Get details of faces found in latest webcamshot. */
    facesFound(): any {
      return this.store.getters.facesFound
    }
  }
})
