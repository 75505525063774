
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import StatusUtils from '@/common/services/StatusUtils'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement
} from 'chart.js'
import { Line } from 'vue-chartjs'
import moment from 'moment'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement)

export default defineComponent({
  components: {
    Line
  },

  data () {
    return {
      store: useStore(),
      chartType: 'available',
      statusUtils: new StatusUtils(),
      chartData: {
        datasets: []
      },
      filteredData: {
        available: [],
        unavailable: [],
        dnd: [],
        learning: []
      } as any,
      chartOptions: {
        responsive: true,
        scales: {
          x: {
            type: 'time',
            distribution: 'linear',
            time: {
              unit: 'day'
            }
          },
          y: {
            type: 'time',
            time: {
              unit: 'hours'
            }
          }
        }
      }
    }
  },

  mounted () {
    this.organizationMembers.forEach((member: any) => {
      if (!this.usersByUsername[member.username].timelineGrouped) {
        this.store.dispatch('getFilteredEmployeeTimeline', {
          username: member.username,
          week: moment().week(),
          year: moment().year()
        })
      }
    })
    Object.keys(this.filteredData).forEach((key: any) => {
      this.filteredData[key] = this.generateChartData(key)
    })
  },

  methods: {
    onFilterSelected (filter: string) {
      switch (filter) {
        case 'punch-in':
          break

        case 'work-hours':
          break

        case 'dnd':
          break

        case 'learning':
          break

        case 'off-hours':
          break

        default:
          break
      }
    },

    getRandomColor () {
      var letters = '0123456789ABCDEF'.split('')
      var color = '#'
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)]
      }
      return color
    },

    generateChartData (filter: string): any {
      const datasets = []
      for (const key of Object.keys(this.statsComputed)) {
        const dataEntry = {
          label: key,
          data: [] as any,
          // backgroundColor: this.getRandomColor(),
          borderColor: this.getRandomColor()
        }
        for (const date of Object.keys(this.statsComputed[key])) {
          dataEntry.data.push({
            date: new Date(date),
            x: date,
            y: moment.duration(this.statsComputed[key][date][filter], 'milliseconds').hours()
          })
        }
        dataEntry.data.sort((a: any, b: any) => a.date - b.date)
        datasets.push(dataEntry)
      }
      return datasets
    }
  },

  computed: {
    user (): any {
      return this.store.getters.user
    },

    usersByUsername (): any {
      return this.store.getters.usersByUsername
    },

    organizationMembers (): any {
      return this.store.getters.organizationMembers
    },

    statsComputed (): any {
      const statsData: any = {}
      this.organizationMembers.forEach((member: any) => {
        statsData[member.username] = {}
        const timelines = this.usersByUsername[member.username].timelineGrouped
        if (timelines) {
          for (const key of Object.keys(timelines)) {
            const stats = this.statusUtils.calculateStatusDuration(timelines[key])
            statsData[member.username][key] = stats
          }
        }
      })
      return statsData
    }
  }
})
