import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1f677a48"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-backdrop" }
const _hoisted_2 = {
  class: "modal",
  role: "dialog"
}
const _hoisted_3 = { class: "modal-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_outside_click = _resolveDirective("outside-click")!

  return (_openBlock(), _createBlock(_Transition, { name: "modal-fade" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              name: "",
              id: "",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.note) = $event))
            }, null, 512), [
              [_vModelText, _ctx.note]
            ]),
            _createElementVNode("button", {
              class: "btn btn-medium primary-green fs-14 m-3 br-8",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.leaveNote(_ctx.note)))
            }, "send")
          ])
        ])), [
          [_directive_outside_click, _ctx.close]
        ])
      ])
    ]),
    _: 1
  }))
}