
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import ImageModal from '@/common/components/ImageModal.vue'

export default defineComponent({
  components: {
    ImageModal
  },

  data () {
    return {
      store: useStore(),
      isImageModalOpen: false,
      imageUrl: require('@/assets/img/dummy/screens/person-modal-2.png')
    }
  },

  methods: {
    toggleImageModal () {
      this.isImageModalOpen = !this.isImageModalOpen
    }
  },

  computed: {
    user (): any {
      return this.store.getters.user
    }
  }
})
