
import { ISearchRequestBody, MatrixClient, SearchOrderBy } from 'matrix-js-sdk'
import { defineComponent } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  data() {
    return {
      store: useStore(),
      query: ''
    }
  },

  methods: {
    async gotoResults(query: string) {
      const body: ISearchRequestBody = {
        search_categories: {
          room_events: {
            search_term: query,
            order_by: SearchOrderBy.Recent,
            event_context: {
              before_limit: 1,
              after_limit: 1,
              include_profile: true
            }
          }
        }
      }
      if (query) {
        this.store.dispatch('setGlobalSearchQuery', query)
        this.store.commit('setBreadcrumbs', [{
            label: 'Search Results',
            routeName: '/search-results'
          }]
        )
        const res = await this.matrixClient.search({ body })
        this.store.dispatch('setSearchResults', res)
        this.$router.push('/search-results')
      }
    }
  },

  computed: {
    matrixClient(): MatrixClient {
      return this.store.getters.matrixClient
    }
  }
})
