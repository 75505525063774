/**
 * Read the file as an ArrayBuffer.
 * @param {File} file The file to read
 * @return {Promise} A promise that resolves with an ArrayBuffer when the file
 *   is read.
 */
export function readFileAsArrayBuffer (file: File | Blob): Promise<ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = function (e) {
      resolve(e.target?.result as ArrayBuffer)
    }
    reader.onerror = function (e) {
      reject(e)
    }
    reader.readAsArrayBuffer(file)
  })
}
