<template>
  <div
    v-for="event in feedRoomData?.getLiveTimeline()?.getEvents()"
    @click="openFeed(event)"
    v-bind:key="event"
  >
    <div
      v-if="event.getType() === 'm.room.message'"
      class="feed-tile common-tile post-tile d-flex flex-column m-3"
    >
      <div class="header d-flex align-items-center justify-content-between">
        <div class="d-flex justify-items-start align-items-center">
          <div
					class="avatar avatar-bg rounded-circle me-1"
					:style="{
					'background-image':
					`url(${getUserAvatar(event.sender.userId)}),
					url(${require('@/assets/img/avatar-placeholder.svg')})`
					}"
					></div>
          <h3
            class="ms-2"
            :class="
              event.getContent().msgtype === 'm.image' ? 'white-tile' : ''
            "
          >
            {{ event.sender.name }}
          </h3>
        </div>
        <div class="post-tip fs-14">
          {{ utilityService.elapsedTime(event.getDate()) }}
        </div>
      </div>
      <PostContent
        :room="selectedRoom"
        :event="event"
        :content="event.getContent()"
        :isEncrypted="event.isEncrypted()"
      />
    </div>
  </div>
</template>
<script lang="script" src="./PostTile.ts"></script>
<style lang="scss" scoped src="./PostTile.scss"></style>
