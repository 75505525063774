
import { defineComponent } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  data() {
    return {
      store: useStore(),
      companyLogo: {} as any,
      location: '' as any,
      addedLocations: '' as any,
      view: 'members',
      members: [],
      days: [
        { label: 'M', dayString: 'Monday' },
        { label: 'T', dayString: 'Tuesday' },
        { label: 'W', dayString: 'Wednesday' },
        { label: 'T', dayString: 'Thursday' },
        { label: 'F', dayString: 'Friday' },
        { label: 'S', dayString: 'Saturday' },
        { label: 'S', dayString: 'Sunday' }
      ],
      onboardingForm: {
        name: '',
        industry: '',
        url: '',
        companySize: '',
        about: '',
        ownerRole: '',
        locations: [] as any,
        workDays: [] as any,
        rhythm: {
          framework: '',
          sprint_duration: '',
          mini_sprint_duration: '',
          team_report: '',
          sprint_planning: {
            plan_every: '',
            in_advance: '',
            review_every: ''
          }
        }
      } as any
    }
  },

  mounted() {
    this.store.dispatch('getAllRhythm')
    document.querySelector('#logoUpload')!
      .addEventListener('change', (event) => {
        this.uploadCompanyLogo(event)
      })
    this.store.dispatch('getAllIndustries')
    this.store.dispatch('getCompanySizes')

    if (this.organization.id) {
      this.onboardingForm = this.organization
    }
  },

  methods: {
    editOrganisation(e: any): void {
      this.store.dispatch('editOrganization', { form: this.onboardingForm, id: this.organization.id })
        .then(() => {
          this.store.commit('showSuccess', true)
          this.view = 'members'
        })
    },

    close(event: any) {
      event.stopPropagation()
      this.$emit('close', event)
    },

    uploadCompanyLogo(event: any): void {
      this.companyLogo = event.target.files[0]
      if (this.organization.id) {
        const apiPayload = new FormData()
        apiPayload.append('logo', this.companyLogo)
        this.store.dispatch('editOrganizationLogo', apiPayload)
          .then((response) => {
            this.organization.avatar = response.avatar
          })
      }
    }
  },

  computed: {
    industries(): any {
      return this.store.getters.industries
    },

    companySizes(): any {
      return this.store.getters.companySizes
    },

    organization(): any {
      return this.store.getters.organization
    },

    locations(): any {
      return this.store.getters.locations
    },

    roles(): any {
      return this.store.getters.roles
    },

    rhythm(): any {
      return this.store.getters.rhythm
    },

    usersByUsername(): any {
      return this.store.getters.usersByUsername
    }
  }
})
