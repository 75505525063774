
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import UtilityService from '@/common/services/TimeUtilityService'
import StatusUtils from '@/common/services/StatusUtils'
import moment from 'moment'

export default defineComponent({
  props: {
    employee: { type: String, required: true },
    date: { type: String, required: true },
    timelines: [] as any
  },

  mounted() {
    const dateParsed = new Date(this.date)
    dateParsed.setHours(0, 0, 0, 0)
    this.statusDuration = this.statusUtils.calculateStatusDuration(this.timelines)
  },

  data() {
    return {
      store: useStore(),
      utilityService: new UtilityService(),
      statusUtils: new StatusUtils(),
      totalTime: {} as any,
      punchoutTime: '' as any,
      logs: [] as any,
      statusDuration: {} as any,
      moment: moment
    }
  },

  methods: {
    // parseTotalTime(timeInMilli: any): any {
    //   const hours = Math.floor(timeInMilli / 3600)
    //   const minutes = Math.floor((timeInMilli % 3600) / 60)
    //   return `${hours}h ${minutes}m`
    // }
  },

  computed: {
    usersByUsername() {
      return this.store.getters.usersByUsername
    },

    user() {
      return this.store.getters.user
    },

    getPunchoutDetails(): any {
      if (this.logs.length) {
        this.logs.forEach((log: any) => {
          if (log.description === 'Logout') {
            this.punchoutTime = this.utilityService.formatTime(log.startTime)
          }
        })
      }
      return this.punchoutTime
    },

    roles() {
      return this.store.getters.roles
    },

    organization(): any {
      return this.store.getters.organization
    }
  }
})
