const getters = {
  skills (state: any) {
    return state.floor.allFloors
  },
  facesFound (state: any) {
    return state.facesFound
  }
}

export default getters
