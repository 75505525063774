import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, vShow as _vShow, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/img/icon-phone-grey.svg'
import _imports_1 from '@/assets/img/icon-video-grey.svg'
import _imports_2 from '@/assets/img/icon-search-grey.svg'
import _imports_3 from '@/assets/img/icon-options-grey.svg'


const _withScopeId = n => (_pushScopeId("data-v-b2c0b4e4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex justify-content-between align-items-center mb-2 text-capitalize" }
const _hoisted_2 = { class: "options d-flex" }
const _hoisted_3 = { class: "btn p-0 mx-3" }
const _hoisted_4 = { class: "btn p-0 mx-3" }
const _hoisted_5 = { class: "btn p-0 mx-3" }
const _hoisted_6 = { class: "btn p-0 mx-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OptionsTooltip = _resolveComponent("OptionsTooltip")!
  const _component_RoomInfoModal = _resolveComponent("RoomInfoModal")!
  const _directive_outside_click = _resolveDirective("outside-click")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createTextVNode(_toDisplayString(_ctx.mxRoom.isRoomDM(_ctx.room) ? _ctx.getDMUser(_ctx.room)?.name : '# ' + _ctx.room?.name) + " ", 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", _hoisted_3, [
        _createElementVNode("img", {
          src: _imports_0,
          width: "32",
          height: "32",
          alt: "search",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.startVoiceCall && _ctx.startVoiceCall(...args)))
        })
      ]),
      _createElementVNode("button", _hoisted_4, [
        _createElementVNode("img", {
          src: _imports_1,
          width: "32",
          height: "32",
          alt: "search",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.startVideoCall()))
        })
      ]),
      _createElementVNode("button", _hoisted_5, [
        _createElementVNode("img", {
          src: _imports_2,
          width: "32",
          height: "32",
          alt: "search",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.openSearchView && _ctx.openSearchView(...args)))
        })
      ]),
      _createElementVNode("button", _hoisted_6, [
        _createElementVNode("img", {
          src: _imports_3,
          width: "32",
          height: "32",
          alt: "options",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.toggleOptionsTooltipBlock($event)))
        })
      ]),
      _withDirectives(_createVNode(_component_OptionsTooltip, {
        options: _ctx.roomOptions,
        pos: _ctx.tooltipPositions,
        onHide: _ctx.hideTooltipBlock,
        class: "tooltip-header"
      }, null, 8, ["options", "pos", "onHide"]), [
        [_vShow, _ctx.isTooltip],
        [_directive_outside_click, _ctx.hideTooltipBlock]
      ]),
      (_ctx.isInfoModalVisible)
        ? (_openBlock(), _createBlock(_component_RoomInfoModal, {
            key: 0,
            onClose: _ctx.toggleInfoModal
          }, null, 8, ["onClose"]))
        : _createCommentVNode("", true)
    ])
  ]))
}