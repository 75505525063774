<template>
	<div class="common-tile feed-event p-2">
		<div class="d-flex justify-content-between w-100">
			<div class="d-flex justify-content-start">
				<img src="@/assets/img/avatar-placeholder.svg" height="20" width="20" alt="" class="rounded-circle me-1 mt-1">
				<div class="mt-1">Uruveua Himona</div>
			</div>
			<div class="float-end">
				2h Ago
			</div>
		</div>
		<div class="feed-content text-center">Should we opt for a four-day work week with extended hours?</div>
		<div class="d-flex justify-content-between w-100 m-3">
			<div><button class="btn primary-green">Yes</button></div>
			<div><button class="btn primary-red float-end">No</button></div>
		</div>
	</div>
</template>
<script lang="script" src="./FeedPoll.ts"></script>
<style lang="scss" scoped src="./FeedPoll.scss"></style>
