import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-21e85f9e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container container-content" }
const _hoisted_2 = { class: "middle-content" }
const _hoisted_3 = { class: "d-flex w-100" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "label text-capitalize" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.audioRooms, (room) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "wrapper ms-3",
            key: room.id
          }, [
            _createElementVNode("div", {
              id: "coffee",
              class: "tile",
              onClick: ($event: any) => (_ctx.gotoRoomDetails(room.id))
            }, [
              _createElementVNode("div", _hoisted_5, _toDisplayString(room.title), 1)
            ], 8, _hoisted_4)
          ]))
        }), 128))
      ])
    ])
  ]))
}