import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-78de80d0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-backdrop" }
const _hoisted_2 = {
  class: "modal d-flex flex-column",
  role: "dialog",
  "aria-labelledby": "modalTitle",
  "aria-describedby": "modalDescription",
  id: "person-modal"
}
const _hoisted_3 = { class: "activity" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Management = _resolveComponent("Management")!
  const _directive_outside_click = _resolveDirective("outside-click")!

  return (_openBlock(), _createBlock(_Transition, { name: "modal-fade" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", null, [
              _createVNode(_component_Management, { employee: _ctx.employee }, null, 8, ["employee"])
            ])
          ])
        ])), [
          [_directive_outside_click, _ctx.close]
        ])
      ])
    ]),
    _: 1
  }))
}