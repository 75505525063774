
import MxRoom from '@/common/services/MxRoom'
import sdk, { Room, MatrixClient } from 'matrix-js-sdk'
import { CallErrorCode, CallEvent, CallType, MatrixCall } from 'matrix-js-sdk/lib/webrtc/call'
import { CallFeed } from 'matrix-js-sdk/lib/webrtc/callFeed'
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import MediaDeviceHandler from '../services/MediaDeviceHandler'

// TODO: Added as a temporary fix as defining type for this variable inside 'data' is not woorking.
let matrixCall: MatrixCall

export default defineComponent({
  data () {
    return {
      store: useStore(),
      mediaDeviceHandler: new MediaDeviceHandler()
    }
  },

  async mounted () {
    this.disableButtons(true, true)
    await this.mediaDeviceHandler.requestMediaPermissions(this.store.getters.matrixClient)
    this.matrixClient.on('Call.incoming', (call: MatrixCall) => this.onIncomingCall(call))
  },

  methods: {
    async placeCall () {
      await this.mediaDeviceHandler.requestMediaPermissions(this.store.getters.matrixClient)
      matrixCall = sdk.createNewMatrixCall(
        this.store.getters.matrixClient, this.room.roomId
      )
      this.addListeners(matrixCall)
      this.ring()
      matrixCall.placeVoiceCall()
      this.disableButtons(true, false)
    },

    hangupCall () {
      matrixCall.hangup(CallErrorCode.UserHangup, false)
      this.pauseRing()
      this.$emit('setVoiceCallView', false)
    },

    answerCall () {
      matrixCall.answer()
      this.disableButtons(true, false)
      this.pauseRing()
    },

    onIncomingCall (call: MatrixCall) {
      if (call.type === CallType.Voice) {
        matrixCall = call
        this.$emit('setVoiceCallView', true)
        this.disableButtons(false, false)
        this.addListeners(call)
        this.ring()
      }
    },

    ring () {
      const audio = document.getElementById('ringAudio') as HTMLAudioElement
      audio.play()
    },

    pauseRing () {
      const audio = document.getElementById('ringAudio') as HTMLAudioElement
      audio.pause()
    },

    addListeners (call: MatrixCall) {
      call.on(CallEvent.Hangup, () => {
        this.disableButtons(true, true)
        this.pauseRing()
        this.$emit('setCallView', false)
      })

      call.on(CallEvent.Error, (err: Error) => {
        this.pauseRing()
        console.error('Call error!!', err)
      })

      call.on(CallEvent.FeedsChanged, (feeds: CallFeed[]) => {
        // const localFeed = feeds.find((feed) => feed.isLocal())
        // const remoteFeed = feeds.find((feed) => !feed.isLocal())

        // const remoteElement = document.getElementById('remote')! as HTMLVideoElement
        // const localElement = document.getElementById('local')! as HTMLVideoElement

        // if (remoteFeed) {
        //   remoteElement.srcObject = remoteFeed.stream
        //   remoteElement.play()
        // }
        // if (localFeed) {
        //   localElement.muted = true
        //   localElement.srcObject = localFeed.stream
        //   localElement.play()
        // }
      })
    },

    disableButtons (answer: boolean, hangup: boolean) {
      const hangupBtn = document.getElementById('hangupVoice')! as HTMLButtonElement
      const answerBtn = document.getElementById('answerVoice')! as HTMLButtonElement
      hangupBtn.disabled = hangup
      answerBtn.disabled = answer
    },

    getCallDetails (): Room | undefined {
      if (matrixCall) {
        return this.matrixClient!.getRoom(matrixCall.roomId)
      }
    }
  },

  computed: {
    matrixClient (): MatrixClient {
      return this.store.getters.matrixClient
    },

    room (): Room {
      return this.store.getters.currentRoom.room
    },

    usersByUsername(): any {
      return this.store.getters.usersByUsername
    },

    roomName (): string | undefined {
      if (this.room && this.room.roomId && this.matrixClient) {
        const callRoom = MxRoom.getDMUser(this.room)
        return this.usersByUsername[MxRoom.getDMUser(this.room)]?.name
      }
      return ''
    }
  }
})
