import axios, { AxiosPromise } from 'axios'
import { Organization, Room, InviteRequest } from '../interfaces'

export default class OnboardingApiService {
  multipartHeader = {
    'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW'
  }

  createOrganization (payload: Organization): AxiosPromise<Organization> {
    return axios.post('blox/organizations', payload, {
      headers: this.multipartHeader
    })
  }

	createOrganizationRooms (payload: Room[]): AxiosPromise<Room[]> {
    return axios.post('blox/organizations/rooms', payload)
  }

  addMemberToRoom (roomId: string, username: string): AxiosPromise<Room[]> {
    return axios.put(`blox/rooms/${roomId}/add?username=${username}`)
  }

	addOrganizationMembers (payload: InviteRequest[]): AxiosPromise<InviteRequest[]> {
    return axios.put('blox/organizations/members', payload)
  }

	deleteOrganizationMembers (payload: any): AxiosPromise<any> {
    return axios.put('blox/organizations/members/remove', payload)
  }

  addOrganizationManagers (payload: any): AxiosPromise<any> {
    return axios.put('blox/organizations/members/promoteToManager', payload)
  }

  removeOrganizationManagers (payload: any): AxiosPromise<any> {
    return axios.delete('blox/organizations/members/demoteToEmployee', payload)
  }

  editOrganization (payload: any): AxiosPromise<any> {
    return axios.put(`blox/organizations/${payload.id}`, payload.form)
  }

  editOrganizationLogo (payload: any): AxiosPromise<any> {
    return axios.post('blox/organizations/logo', payload, {
      headers: this.multipartHeader
    })
  }

	getOrganization (id: string): AxiosPromise<Organization> {
    return axios.get(`blox/organizations/${id}`)
  }

	getAllIndustries (): AxiosPromise<any> {
    return axios.get('blox/organizations/industries')
  }

	getCompanySizes (): AxiosPromise<any> {
    return axios.get('blox/organizations/sizes')
  }

  getAllRhythm (): AxiosPromise<any> {
    return axios.get('blox/organizations/rhythm')
  }

  inviteNewUser (payload: any): AxiosPromise<any> {
    return axios.put('blox/organizations/members/invite', payload)
  }
}
