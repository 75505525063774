<!-- This component is used for instant one-on-one video calls using Jitsi.-->
<template>
  <div class="container-dropin">
    <div
      id="jitsiVideoContainer"
      ref="jitsiVideoContainer"
    ></div>
  </div>
</template>

<script lang="js">
import {
  isWebPlatform,
  importIpcRenderer,
  ipcRenderer,
  importJitsiScreenshareModules,
  setupScreenSharingRender,
  initPopupsConfigurationRender,
  setupPowerMonitorRender
} from '@/config/PlatformConfig'
import { defineComponent } from 'vue'
import { useStore } from 'vuex'

importIpcRenderer()

export default defineComponent({
  data() {
    return {
      store: useStore(),
      isMeetingActive: false,
      options: {
        width: '100%',
        height: '100%',
        noSSL: false,
        configOverwrite: {
          enableNoisyMicDetection: false,
          disableModeratorIndicator: true,
          prejoinConfig: { enabled: false },
          prejoinPageEnabled: false,
          toolbarButtons: ['hangup', 'microphone', 'camera', 'tileview', 'raisehand', 'fullscreen', 'desktop', 'recording'],
          logoImageUrl: ''
        },
        interfaceConfigOverwrite: {
          DEFAULT_LOGO_URL: '',
          DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
          SHOW_JITSI_WATERMARK: false,
          SHOW_WATERMARK_FOR_GUESTS: false,
          HIDE_DEEP_LINKING_LOGO: true,
          SHOW_BRAND_WATERMARK: false,
          SHOW_CHROME_EXTENSION_BANNER: false,
          SHOW_PROMOTIONAL_CLOSE_PAGE: false,
          VIDEO_LAYOUT_FIT: 'height'
        }
      },
      jitsiApi: {}
    }
  },

  mounted() {
    this.loadScript(`https://${process.env.VUE_APP_JITSI_URL}/external_api.js`, this.embedJitsiWidget)
    this.askMediaPermissions()
  },

  methods: {
    loadScript(src, cb) {
      const scriptEl = document.createElement('script')
      scriptEl.src = src
      scriptEl.async = true
      document.querySelector('head').appendChild(scriptEl)
      scriptEl.addEventListener('load', cb)
    },

    askMediaPermissions() {
      if (ipcRenderer) {
        ipcRenderer.on('CAMERA_ACCESS_SUCCESS', (res) => {
          localStorage.setItem('cameraEnabled', 'true')
          ipcRenderer.invoke('ASK_MICROPHONE_ACCESS')
        })
        ipcRenderer.on('MICROPHONE_ACCESS_SUCCESS', (res) => {
          localStorage.setItem('microphoneEnabled', 'true')
        })
        ipcRenderer.invoke('ASK_CAMERA_ACCESS')
        ipcRenderer.invoke('ASK_MICROPHONE_ACCESS')
      }
    },

    embedJitsiWidget() {
      const options = {
        ...this.options,
        userInfo: {
          email: this.user.email,
          displayName: this.user.name,
          avatarUrl: this.store.getters.usersByUsername[this.user.username].avatarUrl
        },
        roomName: this.store.getters.activeJitsiVideoCallRoom,
        parentNode: document.querySelector('#jitsiVideoContainer')
      }

      this.jitsiApi = new window.JitsiMeetExternalAPI(process.env.VUE_APP_JITSI_URL, options)
      this.jitsiApi.on('readyToClose', (res) => {
        this.jitsiApi.dispose()
        this.store.commit('setJitsiVideoCallActive', { value: false, room: '' })
      })
      if (!isWebPlatform()) {
        initPopupsConfigurationRender(this.jitsiApi)
        setupScreenSharingRender(this.jitsiApi)
        setupPowerMonitorRender(this.jitsiApi)
      }
    }
  },

  computed: {
    user() {
      return this.store.getters.user
    }
  }
})
</script>

<style scoped lang="scss">
.container-dropin{
  background: #00000099;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1001;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
#jitsiVideoContainer {
  z-index: 1002;
  width: 90vw;
  aspect-ratio: 16/9;
}
</style>
