import { Vue, Options } from 'vue-class-component'

@Options({})
export default class Whiteboard extends Vue {
  getWhiteboardUrl (): string {
    return this.$route.params.whiteboardUrl.toString()
  }

  close () {
    this.$emit('close')
  }
}
