import { createElementVNode as _createElementVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/img/left-arrow.svg'
import _imports_1 from '@/assets/img/icon-search-grey.svg'


const _withScopeId = n => (_pushScopeId("data-v-2d9136d6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex my-4 ms-3 align-items-center search-header" }
const _hoisted_2 = { class: "search ms-4" }
const _hoisted_3 = { class: "d-flex fs-12 align-items-center" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "ms-1" }
const _hoisted_6 = { class: "fs-13 mt-2" }
const _hoisted_7 = {
  key: 0,
  class: "fs-13 mt-2 p-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        class: "cursor-pointer",
        src: _imports_0,
        height: "18",
        width: "18",
        alt: "",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.backToRoom && _ctx.backToRoom(...args)))
      }),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("img", {
          src: _imports_1,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.search(_ctx.query))),
          class: "cursor-pointer"
        }),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          placeholder: "Search",
          class: "ms-0 ps-0 p-1",
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.query) = $event)),
          onKeyup: _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.search(_ctx.query)), ["enter"]))
        }, null, 544), [
          [_vModelText, _ctx.query]
        ])
      ])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchResults?.search_categories?.room_events?.results, (each) => {
      return (_openBlock(), _createElementBlock("div", {
        key: each,
        class: "border-bottom p-3"
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("img", {
            class: "avatar me-1",
            src: _ctx.getUserAvatar(each.result.user_id),
            alt: ""
          }, null, 8, _hoisted_4),
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.getEventSender(each.result.room_id, each.result.event_id)) + " on " + _toDisplayString(_ctx.moment(new
          Date(each.result.origin_server_ts)).format('MMMM D')), 1)
        ]),
        _createElementVNode("div", _hoisted_6, _toDisplayString(each.result.content.body), 1)
      ]))
    }), 128)),
    (!_ctx.searchResults?.search_categories?.room_events?.results.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, " No results found! "))
      : _createCommentVNode("", true)
  ], 64))
}