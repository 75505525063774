
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import router from '@/router'
import moment from 'moment'
import UtilityService from '@/common/services/TimeUtilityService'
import Zoom from '@/common/services/Zoom'
import Loader from '@/common/components/Loader.vue'
import ObjectUtils from '@/common/services/ObjectUtils'
import MeetingTile from './MeetingTile.vue'
import UserMultiselectModal from '@/common/components/UserMultiselectModal.vue'
import { CustomMxEvent, MeetingPreference } from '@/common/enums'
import MxRoom from '@/common/services/MxRoom'

export default defineComponent({
  components: {
    Loader,
    MeetingTile,
    UserMultiselectModal
  },

  data() {
    return {
      store: useStore(),
      currentTab: 'Live',
      utilityService: new UtilityService(),
      zoom: Zoom,
      showDialog_: false,
      animationDoor: false,
      meetingParticipant: {} as any,
      selectedUser_: '',
      showUserTimelineModal_: false,
      quickMeetingParticipants: [],
      isMembersModalVisible: false
    }
  },

  mounted() {
    this.store.dispatch('getMyMeetings')
    this.store.dispatch('getSharedMeetings')
    this.store.dispatch('getActiveMeetings')
    this.store.commit('setBreadcrumbs', [
      {
        label: 'Meetings',
        routeName: '/meetings'
      }
    ])
  },

  methods: {
    momentFormat(value: string, format: string): string {
      return moment(value).format(format)
    },

    openMeeting(meetingId: string) {
      this.$router.push({
        name: 'MeetingDetails',
        path: '/meeting-details',
        params: { id: meetingId },
        query: { id: meetingId }
      })
      this.store.commit('setBreadcrumbs', [
        { label: 'Meetings', routeName: '/meetings' },
        { label: 'Meeting Details', routeName: '/meeting-details' }
      ])
    },

    setCurrentTab(tab: string) {
      this.currentTab = tab
    },

    filterByCreator(creator: string) {
      //
    },

    openNewMeeting() {
      this.$router.push('/new-meeting')
      this.store.commit('setBreadcrumbs', [
        { label: 'Meetings', routeName: '/meetings' },
        { label: 'New Meeting', routeName: '/new-meeting' }
      ])
    },

    useRandomMeetingName() {
      const adjectives = [
        'innovative', 'strategic', 'impactful',
        'productive', 'insightful', 'constructive',
        'progressive', 'cutting-edge', 'visionary',
        'awesome', 'epic', 'incredible', 'amazing', 'exciting'
      ]

      const nouns = [
        'initiative', 'project', 'session',
        'meeting', 'summit', 'workshop',
        'discussion', 'collaboration', 'brainstorm',
        'adventure', 'journey', 'experience', 'opportunity', 'endeavor'
      ]

      const adjective = adjectives[Math.floor(Math.random() * adjectives.length)]
      const noun = nouns[Math.floor(Math.random() * nouns.length)]

      const meetingName = 'Instant ' + adjective + ' ' + noun

      return meetingName
    },

    useRandomEmoji() {
      const emojis = [
        '🎉', '💡', '🚀', '🤖', '🎊',
        '💯', '✨', '🌟', '🎈', '💬',
        '🔬', '🎁', '⛳️', '🛠️', '📈'
      ]
      const emoji = emojis[Math.floor(Math.random() * emojis.length)]
      return emoji
    },

    onQuickMeetingParticipantsSelected(selected: any) {
      this.quickMeetingParticipants = selected
      const form = {
        title: this.useRandomMeetingName(),
        icon: this.useRandomEmoji(),
        description: 'Quick meeting',
        type: 'MEETING',
        recurringDays: [],
        preferences: [MeetingPreference.AUTO_JOIN],
        start: new Date().toISOString(),
        end: moment(new Date()).add(15, 'minutes').toISOString(),
        participants: [],
        agendaBlock: []
      }
      form.participants = selected.map((member: any) => member.username)

      this.store.dispatch('createAndStartMeeting', form)
        .then((response: any) => {
          this.notifyQuickMeetingStarted(response, form.participants)
          this.gotoMeeting(response.id)
        })
    },

    notifyQuickMeetingStarted(meeting: any, participants: string[]) {
      participants.forEach((participant: string) => {
        const content = {
          body: '',
          metadata: {
            to: participant,
            meeting_id: meeting.id
          },
          msgtype: CustomMxEvent.MEETING_STARTED
        }
        MxRoom.sendCustomNotification(
          this.matrixClient,
          this.store.getters.organization.organizationNotificationSpaceId,
          content
        )
      })
    },

    startMeeting() {
      this.showDialog_ = false
      this.$router.push({
        name: 'NewMeeting',
        params: { members: JSON.stringify(this.meetingParticipant) }
      })
    },

    openTheDoor(event: any) {
      event.preventDefault()
      this.animationDoor = false
    },

    startDragToConfRoom(member: any) {
      this.meetingParticipant = member
      this.animationDoor = true
      setTimeout(() => {
        this.animationDoor = false
      }, 4000)
    },

    openDialog() {
      this.showDialog_ = true
    },

    closeDialog() {
      this.showDialog_ = false
    },

    openUserTimelineModal(event: MouseEvent, username: string) {
      this.selectedUser_ = username
      this.store.dispatch('getEmployeeTimeline', username)
      this.toggleUserTimelineModal(event)
    },

    toggleUserTimelineModal(event: MouseEvent) {
      event.stopPropagation()
      this.showUserTimelineModal_ = !this.showUserTimelineModal_
    },

    toggleMembersModal() {
      this.isMembersModalVisible = !this.isMembersModalVisible
    },

    gotoMeeting(id: any) {
      this.$router.push({
        name: 'Conference',
        path: '/conference',
        params: { id }
      })
    },

    viewMoreMeetings(section: string, sectionTitle: string, list: any[]) {
      this.store.commit('setBreadcrumbs',
        [
          {
            label: 'Meetings',
            routeName: '/meetings'
          },
          {
            label: section,
            routeName: `/meetings/${section}`
          }
        ]
      )
      this.store.commit('setCurrentMeetingList', list)
      this.$router.push({
        name: 'MeetingList',
        path: '/meetings',
        params: { sectionTitle }
      })
    }
  },

  computed: {
    allMeetings(): any {
      return this.store.getters.allMeetings
    },

    myMeetings(): any {
      return this.store.getters.myMeetings
    },

    matrixClient(): any {
      return this.store.getters.matrixClient
    },

    groupedMeetings(): any {
      return ObjectUtils.groupByKey(this.allMeetings, 'state')
    },

    usersByUsername(): any {
      return this.store.getters.usersByUsername
    },

    isPageLoading(): any {
      return this.store.getters.isPageLoading
    },

    activeMeetings(): any {
      const meetings = this.store.getters.activeMeetings
      // if (meetings.length > 3) {
      //   return meetings.slice(0, 3)
      // }
      return meetings
    },

    allActiveMeetings(): any {
      return this.store.getters.activeMeetings
    },

    membersData (): any {
			return this.store.getters.organizationMembers
        .filter((member: any) => member.username !== this.store.getters.user.username)
		}
  }
})
