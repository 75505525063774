import OnboardingApiService from '../services/OnboardingApiService'
import organizationApi from '@/common/services/OrganizationApiService'
import { Organization, Room, InviteRequest } from '../interfaces'

const apiService = new OnboardingApiService()

const actions = {
  createOrganization (context: any, payload: Organization) {
    context.commit('isPageLoading', true)
    return apiService.createOrganization(payload)
      .then((response: any) => {
        context.commit('setOrganization', response.data)
        context.commit('isPageLoading', false)
				return response.data
      })
      .catch((error: any) => {
        console.error('Error in creating organization', error)
      })
  },

	createOrganizationRooms (context: any, rooms: Room[]) {
    apiService.createOrganizationRooms(rooms)
      .then((response: any) => {
        context.commit('setOrganizationRooms', response.data)
				// router.push('/add-members')
      })
      .catch((error: any) => {
        console.error('Error in adding organization rooms', error)
      })
  },

  addMemberToRoom (context: any, payload: any) {
    apiService.addMemberToRoom(payload.roomId, payload.username)
      .then((response: any) => {
        context.commit('setOrganizationMembers', response.data)
      })
      .catch((error: any) => {
        console.error('Error in adding organization members', error)
      })
  },

	addOrganizationMembers (context: any, members: InviteRequest[]) {
    context.commit('isPageLoading', true)
    return apiService.addOrganizationMembers(members)
      .then((response: any) => {
        context.commit('setOrganizationManagers', response.data)
        context.dispatch('getOrganizationMembers')
        context.commit('isPageLoading', false)
        context.commit('showSuccess', true)
        return response.data
      })
      .catch((error: any) => {
        context.commit('isPageLoading', false)
        context.commit('showError', true)
        console.error('Error in adding organization members', error)
      })
  },

	deleteOrganizationMembers (context: any, members: any) {
    apiService.deleteOrganizationMembers(members)
      .then((response: any) => {
        context.commit('deleteOrganizationMembers', response.data)
      })
      .catch((error: any) => {
        console.error('Error in deleting organization member', error)
      })
  },

  addOrganizationManagers (context: any, managers: any) {
    return apiService.addOrganizationManagers(managers)
      .then((response: any) => {
        return response.data
      })
      .catch((error: any) => {
        console.error('Error in adding organization managers', error)
      })
  },

  removeOrganizationManagers (context: any, managers: any) {
    return apiService.addOrganizationManagers(managers)
      .then((response: any) => {
        return response.data
      })
      .catch((error: any) => {
        console.error('Error in removing organization managers', error)
      })
  },

  editOrganization (context: any, payload: any) {
    apiService.editOrganization(payload)
      .then((response: any) => {
        context.commit('editOrganization', response.data)
        return response.data
      })
      .catch((error: any) => {
        console.error('Error in editing organization', error)
      })
  },

  editOrganizationLogo (context: any, payload: any) {
    return apiService.editOrganizationLogo(payload)
      .then((response: any) => {
        context.commit('editOrganization', response.data)
        return response.data
      })
      .catch((error: any) => {
        console.error('Error in editing organization', error)
      })
  },

	getOrganization (context: any, payload: string) {
    apiService.getOrganization(payload)
      .then((response: any) => {
        response.data.avatar = URL.createObjectURL(response.data.avatar)
        context.commit('setOrganization', response.data)
      })
      .catch((error: any) => {
        console.error('Error in getting organization', error)
      })
  },

  getOrganizationMembers (context: any, payload: string) {
    return organizationApi.getOrganizationMembers(payload)
      .then((response: any) => {
        context.commit('setOrganizationMembers', response.data)
        response.data.forEach((member: any, index: any) => {
          context.commit('setUserByUsername', member)
          if (member.username === 'amunavvira') {
            response.data.splice(index, 1)
          } else {
            context.dispatch('getTimerByUser', { user: member.username, date: new Date().toISOString() })
          }
        })
        return response.data
      })
      .catch((error: any) => {
        console.error('Error in getting organization', error)
      })
  },

	getAllIndustries (context: any) {
    apiService.getAllIndustries()
      .then((response: any) => {
        context.commit('setAllIndustries', response.data)
      })
      .catch((error: any) => {
        console.error('Error in getting all industries', error)
      })
  },

	getCompanySizes (context: any) {
    apiService.getCompanySizes()
      .then((response: any) => {
        context.commit('setCompanySizes', response.data)
      })
      .catch((error: any) => {
        console.error('Error in getting all company sizes', error)
      })
  },

  getAllRhythm (context: any) {
    apiService.getAllRhythm()
      .then((response: any) => {
        context.commit('setAllRhythm', response.data)
      })
      .catch((error: any) => {
        console.error('Error in getting all rhythm', error)
      })
  },

  inviteNewUser (context: any, payload: any) {
    apiService.inviteNewUser(payload)
      .then((response: any) => {
        context.commit('setCompanySizes', response.data)
      })
      .catch((error: any) => {
        console.error('Error in getting all company sizes', error)
      })
  },

	setTeams (context: any, payload: any) {
    context.commit('setTeams', payload)
  }
}

export default actions
