
import { Room, MatrixEvent, MatrixClient, EventType } from 'matrix-js-sdk'
import { defineComponent, PropType } from 'vue'
import { useStore } from 'vuex'
import EventContent from './EventContent.vue'
import MxRoom from '@/common/services/MxRoom'
import UtilityService from '@/common/services/TimeUtilityService'
import EventUtils from '../services/EventUtils'

export default defineComponent({
  props: {
    room: { type: Object as PropType<Room>, required: true }
  },

  components: { EventContent },

  data () {
    return {
      store: useStore(),
      mxRoom: MxRoom,
      eventUtils: new EventUtils(),
      utilityService: new UtilityService(),
      isTooltipVisible: false,
      tooltipPos: { top: '0', left: '0' },
      tooltipOptions: [
        {
          title: 'Unpin',
          icon: 'icon-pin-grey-16.svg',
          onClick: this.unpinMessage.bind(this)
        }
      ]
    }
  },

  methods: {
    getUserAvatar (userId: string): string {
      const username = userId.slice(1, userId.indexOf(':'))
      return this.store.getters.usersByUsername[username]?.avatarUrl
    },

    unpinMessage (event: MatrixEvent) {
      const cli = this.matrixClient
      const room = cli.getRoom(event.getRoomId()!)
      const eventId = event.getId()

      const pinnedIds = room?.currentState?.getStateEvents(EventType.RoomPinnedEvents, '')?.getContent().pinned || []
      if (pinnedIds.includes(eventId)) {
          pinnedIds.splice(pinnedIds.indexOf(eventId), 1)
      }
      cli.sendStateEvent(event.getRoomId()!, EventType.RoomPinnedEvents, { pinned: pinnedIds }, '')
    },

    showOptionsTooltip (e: MouseEvent) {
      this.isTooltipVisible = true
      this.tooltipPos = { top: e.clientY + 12 + 'px', left: e.clientX + 12 + 'px' }
    },

    hideTooltip () {
      this.isTooltipVisible = false
    }
  },

  computed: {
    matrixClient (): MatrixClient {
      return this.store.getters.matrixClient
    },

    pinnedEvents (): MatrixEvent[] {
      return this.store.getters.currentRoom.pinnedEvents
    }
  }
})
