
import { PropType, defineComponent } from 'vue'
import { useStore } from 'vuex'
import { Roles } from '../../../common/enums'
import { Organization } from '../interfaces'
import OrganizationRoles from '@/common/json/organizationRoles.json'

export default defineComponent({
  props: {
    formProp: {
      type: Object as PropType<Partial<Organization>>,
      required: true
    },
    logo: {} as any
  },

  data() {
    return {
      store: useStore(),
      orgRoles: Roles,
      companyLogo: '' as any,
      recurring: true,
      location: '' as any,
      addedLocations: '' as any,
      form: {} as any,
      days: [
        { label: 'M', dayString: 'Monday' },
        { label: 'T', dayString: 'Tuesday' },
        { label: 'W', dayString: 'Wednesday' },
        { label: 'T', dayString: 'Thursday' },
        { label: 'F', dayString: 'Friday' },
        { label: 'S', dayString: 'Saturday' },
        { label: 'S', dayString: 'Sunday' }
      ]
    }
  },

  mounted() {
    this.form = this.formProp
  },

  methods: {
    createOrganisation(e: any): void {
      const locations = this.addedLocations.split(/\s*,\s*/)
      this.form.locations = [] as any
      this.form.locations.push(this.location)
      this.form.locations.push(...locations)
      const apiPayload = new FormData()
      apiPayload.append('logo', this.logo)
      apiPayload.append('dto', JSON.stringify(this.form))
      this.store.dispatch('createOrganization', apiPayload)
        .then((response: Organization) => {
          // this.$emit('next', { form: response, nextTab: 3 })
          this.$emit('close', e)
          this.$router.push('/organization-details')
        })
    },

    close(event: any) {
      event.stopPropagation()
      this.$emit('close', event)
    }
  },

  computed: {
    user(): any {
      return this.store.getters.user
    },

    usersByUsername(): any {
      return this.store.getters.usersByUsername
    },

    roles(): any {
      return OrganizationRoles
    },

    industries(): any {
      return this.store.getters.industries
    },

    locations(): any {
      return this.store.getters.locations
    },

    companySizes(): any {
      return this.store.getters.companySizes
    },

    organization(): any {
      return this.store.getters.organization
    }
  }
})
