
import moment from 'moment'
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import History from './History.vue'
import UtilityService from '@/common/services/TimeUtilityService'

export default defineComponent({
  components: {
    History
  },

  data() {
    return {
      moment: moment,
      store: useStore(),
      utilityService: new UtilityService(),
      expandTimeline: true,
      timelineHover: '',
      selectedDate: moment()
    }
  },

  methods: {
    calculateTimeGap(prev: any, current: any, index: any) {
      let width = 0
      let margin = 0
      if (current[index + 1]) {
        const startTime = moment(prev.submittedOn * 1000)
        const endTime = moment(current[index + 1].submittedOn * 1000)
        const totalDiff = endTime.diff(startTime, 'seconds')
        if (totalDiff > 120) {
          width = totalDiff / 120
        } else {
          width = 1
        }
        // from midnight
        startTime.set('hours', 0)
        startTime.set('minutes', 0)
        startTime.set('seconds', 0)
        margin = endTime.diff(startTime, 'seconds') / 120 - width
        // minus width because of LIFO
      }
      return 'width: ' + width + 'px; margin-left: ' + margin + 'px'
    },

    gotoPage(page: string) {
      if (page === 'prev') {
        this.selectedDate = this.selectedDate.subtract(1, 'days')
      } else {
        this.selectedDate = this.selectedDate.add(1, 'days')
      }
      this.organizationMembers.forEach((user: any) => {
        this.store.dispatch('getEmployeeTimelineByDate', { username: user.username, date: this.selectedDate })
      })
    },

    closeMe() {
      this.$emit('close')
    }
  },

  computed: {
    usersByUsername(): any {
      return this.store.getters.usersByUsername
    },

    organizationMembers(): any {
      return this.store.getters.organizationMembers
    }
  }
})
