import { createStore } from 'vuex'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import onboardingModule from '@/modules/onboarding/store'
import chatsModule from '@/modules/chats/store'
import feedModule from '@/modules/feed/store'

const state = {
  isAuthenticated: false,
  accessToken: '',
  loginWithPin: false,
  matrixClient: null,
  feedRoom: null,
  feedRoomId: null,
  mobileMenu: false,
  userData: {
    attendance: [],
    realmRoles: []
  },
  system: {
    isLocked: false
  },
  floor: {
    allFloors: []
  },
  dataSources: {
    allSkills: []
  },
  user: {},
  avatar: '',
  allUsers: [],
  organization: {},
  rooms: [],
  timer: [],
  timerList: [],
  myMeetings: [],
  sharedMeetings: [],
  currentMeetingList: [],
	meeting: {},

  /** TODO: Move all meeting related data here */
  meetingData: {
    isConferenceActive: false,
    isJitsiVideoCallActive: false,
    activeJitsiVideoCallRoom: ''
  },

  allMembers: {},
  usersByUsername: {},
  attendance: [],
  attendanceList: [],
  showModal: false,
  showSuccess: false,
  showError: false,
  allEvents: [],
	dayEvents: [],
	monthEvents: [],
	weekEvents: [],
  activeMeetings: [],
	todoList: {},
  employeeTimeline: [],
  latestWebcamshots: [],
	isComponentLoading: false,
  breadcrumbs: [],
  loader: {
    isPageLoading: false,
    isComponentLoading: false,
    metaData: {
      isWebcamshotLoading: false,
      isScreenshotLoading: false
    },
    isTimelineLoading: false
  },
  hopReports: [],
  bugReports: [],
  wave: {},
  notes: {},
  voiceMessages: [],
  search: {
    globalSearchQuery: ''
  },
  facesFound: {},
  immediateMeeting: {},
  searchResults: [],
  isLiveRefreshInProgress: false
}

export default createStore({
  state,
  getters,
  mutations,
  actions,
  modules: {
    onboarding: onboardingModule,
    chats: chatsModule,
		feed: feedModule
  }
})
