import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Options({})
export default class WhiteboardModal extends Vue {
  @Prop() whiteboardUrl: any = ''

  expandWhiteboard () {
    this.close()
    this.$router.push({ name: 'Whiteboard', params: { whiteboardUrl: this.whiteboardUrl } })
  }

  close () {
    this.$emit('closeModal')
  }
}
