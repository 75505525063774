
import { useStore } from 'vuex'
import { defineComponent } from 'vue'

export default defineComponent({
  data () {
    return {
			store: useStore()
    }
  },

  methods: {
		toggleModal (e: any, popup: any): void {
			this.$emit('togglePopup', e, popup)
		}
  }
})
