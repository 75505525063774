import { MatrixEvent } from 'matrix-js-sdk'

export default class EventUtils {
  /**
   * Returns related events. Considering only two cases now.
   * TODO: Handle more related events.
   */
  getRelatedEvent (event: MatrixEvent) {
    const relatedEvent: any = event.event.content?.['m.relates_to'] || null
    if (relatedEvent) {
      return relatedEvent.event_id ||
        relatedEvent['m.relates_to']?.event_id ||
        relatedEvent['m.in_reply_to']!.event_id ||
        null
    }
    return null
  }

  /** Replace relation will be present incase of edited message. */
  isReplaceEvent (event: MatrixEvent): boolean {
    return event.event.content?.['m.relates_to']?.rel_type === 'm.replace'
  }
}
