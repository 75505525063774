import { readFileAsArrayBuffer } from '@/common/services/FileUtils'
import { MatrixClient, IUploadOpts, IEncryptedFile, MsgType } from 'matrix-js-sdk'
import { IAbortablePromise } from 'matrix-js-sdk/lib/@types/partials'
import encrypt from 'browser-encrypt-attachment'
import { getBlobSafeMimeType } from '@/common/services/Blobs'

export class UploadCanceledError extends Error {}

export function getMessageTypeForMedia (file: File | Blob): MsgType {
  if (file.type.indexOf('image/') === 0) {
    return MsgType.Image
  } else if (file.type.indexOf('audio/') === 0) {
    return MsgType.Audio
  } else if (file.type.indexOf('video/') === 0) {
    return MsgType.Video
  } else {
    return MsgType.File
  }
}

export function uploadFile (
  matrixClient: MatrixClient,
  roomId: string,
  file: File | Blob,
  progressHandler?: IUploadOpts['progressHandler']
): IAbortablePromise<{ url?: string; file?: IEncryptedFile }> {
  let canceled = false
  if (matrixClient.isRoomEncrypted(roomId)) {
    // If the room is encrypted then encrypt the file before uploading it.
    // First read the file into memory.
    let uploadPromise: IAbortablePromise<string>
    const prom = readFileAsArrayBuffer(file)
      .then(function (data) {
        if (canceled) throw new UploadCanceledError()
        // Then encrypt the file.
        return encrypt.encryptAttachment(data)
      })
      .then(function (encryptResult) {
        if (canceled) throw new UploadCanceledError()

        // Pass the encrypted data as a Blob to the uploader.
        const blob = new Blob([encryptResult.data])
        uploadPromise = matrixClient.uploadContent(blob, {
          progressHandler,
          includeFilename: false
        })

        return uploadPromise.then((url) => {
          if (canceled) throw new UploadCanceledError()

          // If the attachment is encrypted then bundle the URL along
          // with the information needed to decrypt the attachment and
          // add it under a file key.
          return {
            file: {
              ...encryptResult.info,
              url
            }
          }
        })
      }) as IAbortablePromise<{ file: IEncryptedFile }>
    prom.abort = () => {
      canceled = true
      if (uploadPromise) matrixClient.cancelUpload(uploadPromise)
    }
    return prom
  } else {
    const basePromise = matrixClient.uploadContent(file, { progressHandler })
    const promise1 = basePromise.then(function (url) {
      if (canceled) throw new UploadCanceledError()
      // If the attachment isn't encrypted then include the URL directly.
      return {
        file: {
          url,
          file,
          mimetype: file.type
        }
      }
    }) as IAbortablePromise<{ url: string }>
    promise1.abort = () => {
      canceled = true
      matrixClient.cancelUpload(basePromise)
    }
    return promise1
  }
}

/**
 * Decrypt a file attached to a matrix event.
 * @param {IEncryptedFile} file The encrypted file information taken from the matrix event.
 *   This passed to [link]{@link https://github.com/matrix-org/browser-encrypt-attachments}
 *   as the encryption info object, so will also have the those keys in addition to
 *   the keys below.
 * @param {IMediaEventInfo} info The info parameter taken from the matrix event.
 * @returns {Promise<Blob>} Resolves to a Blob of the file.
 */
export function decryptFile (
  mxClient: MatrixClient,
  file: IEncryptedFile
): Promise<Blob> {
  const media = {
    mxc: file?.url,
    file: file
  }
  // Download the encrypted file as an array buffer.
  return fetch(mxClient.mxcUrlToHttp(media.mxc)!)
    .then((response) => {
      return response.arrayBuffer()
    })
    .then((responseData) => {
      // Decrypt the array buffer using the information taken from
      // the event content.
      return encrypt.decryptAttachment(responseData, file)
    })
    .then((dataArray) => {
      // Turn the array into a Blob and give it the correct MIME-type.

      // IMPORTANT: we must not allow scriptable mime-types into Blobs otherwise
      // they introduce XSS attacks if the Blob URI is viewed directly in the
      // browser (e.g. by copying the URI into a new tab or window.)
      // See warning at top of file.
      let mimetype = file?.mimetype ? file.mimetype.split(';')[0].trim() : ''
      mimetype = getBlobSafeMimeType(mimetype)

      return new Blob([dataArray], { type: mimetype })
    })
}
