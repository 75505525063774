
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import UtilityService from '@/common/services/TimeUtilityService'
import moment from 'moment'
import ObjectUtils from '@/common/services/ObjectUtils'

export default defineComponent({
  data () {
    return {
      store: useStore(),
      utilityService: new UtilityService(),
      sectionTitle: ''
    }
  },

  mounted () {
    if (this.$route.params.sectionTitle) {
      this.sectionTitle = this.$route.params.sectionTitle.toString()
    }
  },

  methods: {
    momentFormat (value: string, format: string): string {
			return moment(value).format(format)
		},

    openMeeting (meetingId: string) {
			this.$router.push({
				name: 'MeetingDetails',
				path: `/${this.sectionTitle}/meeting-details`,
				params: { id: meetingId },
				query: { id: meetingId }
			})
      this.store.commit(
        'setBreadcrumbs',
        [{
          label: 'Meetings', routeName: '/meetings'
        },
        {
          label: `${this.sectionTitle}`, routeName: `/meetings/${this.sectionTitle}`
        },
        {
          label: 'Meeting Details', routeName: '/meetings/'
        }]
      )
		}
  },

  computed: {
    user (): any {
      return this.store.getters.user
    },

    usersByUsername (): any {
      return this.store.getters.usersByUsername
    },

    meetings (): any {
      return this.store.getters.currentMeetingList
    }
  }
})
