import { defineComponent } from 'vue'
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
import { useStore } from 'vuex'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import UtilityService from '@/common/services/TimeUtilityService'
import AddCalendarEvent from '@/common/components/AddCalendarEvent.vue'

export default defineComponent({
	components: { flatPickr, VueCal, AddCalendarEvent },

	data () {
		return {
			store: useStore(),
			utilityService: new UtilityService(),
			moment: moment,
			selectedDate: null,
			selectedEvent: {},
			expandedCalendar: [],
			monthCalendar: [],
			eventsToday: [],
			eventsTomorrow: [],
			thisWeekEvents: [],
			selectedMembers: [] as any,
			flatTimePickerConfig: {
				enableTime: true,
				noCalendar: true,
				dateFormat: 'H:i',
				time_24hr: true
			},

			formErrors: [] as any,
			isEventModalVisible: false,
			currentWeek: 0 as number,
			colors: ['red', 'green', 'blue']
		}
	},

	mounted () {
		this.selectedMembers.push(this.user)
		const today = new Date()
		let month = ('0' + (today.getMonth() + 1)).slice(-2)
		let day = ('0' + today.getDate()).slice(-2)
		let year = today.getFullYear()
		this.store.dispatch('getDayEvents', { year, month, day })
			.then((response) => {
				this.eventsToday = response
			})
		const tomorrow = new Date()
		tomorrow.setDate(tomorrow.getDate() + 1)
		month = ('0' + (tomorrow.getMonth() + 1)).slice(-2)
		day = ('0' + tomorrow.getDate()).slice(-2)
		year = tomorrow.getFullYear()
		this.store.dispatch('getDayEvents', { year, month, day })
			.then((response) => {
				this.eventsTomorrow = response
			})
	},

	methods: {
		// TODO - Should be done from backend
		correctTimeFormat (backendTime: any) {
			for (let i = 0; i < backendTime.length; i++) {
				backendTime[i].start = this.utilityService.calendarTime(backendTime[i].start)
				backendTime[i].end = this.utilityService.calendarTime(backendTime[i].end)
			}
			return backendTime
		},

		expandedViewCalendar (data: { view: string, startDate: string, endDate: string, week: number }) {
			const date = new Date(data.startDate)
			const month = date.getMonth() + 1
			const day = date.getDate()
			const year = date.getFullYear()
			switch (data.view) {
				case 'week':
					this.store.dispatch('getWeekEvents', data.week)
						.then((response) => {
							this.expandedCalendar = this.correctTimeFormat(response)
						})
					break

				case 'day':
					this.store.dispatch('getDayEvents', { year, month, day })
						.then((response) => {
							this.expandedCalendar = this.correctTimeFormat(response)
						})
					break

				default:
					break
			}
		},

		monthViewCalendar (data: { view: string, startDate: string, endDate: string, week: number }) {
			const date = new Date(data.startDate)
			const month = ('0' + (date.getMonth() + 1)).slice(-2)
			const year = date.getFullYear()

			switch (data.view) {
				case 'week':
					break

				case 'month':
					this.store.dispatch('getMonthEvents', { year, month })
						.then((response) => {
							this.monthCalendar = this.correctTimeFormat(response)
						})
					break
			}
		},

		close () {
			this.$emit('close')
		},

		toggleEventModal (event: any) {
			event.stopPropagation()
			this.isEventModalVisible = !this.isEventModalVisible
		}
	},

	computed: {
		todayEvents (): any {
			return this.eventsToday
		},

		tomorrowEvents (): any {
			return this.eventsTomorrow
		},

		weekEventsCalendar (): any {
			return this.expandedCalendar
		},

		monthEventsCalendar (): any {
			return this.monthCalendar
		},

		bgColors (): any {
			return this.colors
		},

		displayMembersList (): any {
			return this.selectedMembers
		},

		user (): any {
			return this.store.getters.user
		}
	}
  })
