
import { defineComponent, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import { globalMatrixClient } from '@/initMatrix'

import SideNavbar from './components/SideNavbar.vue'
import TopNavbar from './components/topNavbar/TopNavbar.vue'
import VideoCallView from '@/modules/chats/components/VideoCallView.vue'
import VoiceCall from './modules/chats/components/VoiceCall.vue'
import { MatrixClient } from 'matrix-js-sdk'
import { importIpcRenderer, isWebPlatform, ipcRenderer, isUserAgentMobile } from './config/PlatformConfig'
import SuccessToast from '@/common/components/SuccessToast.vue'
import ErrorToast from '@/common/components/ErrorToast.vue'
import Conference from './components/Conference.vue'
import { UserStatus } from './common/enums'
import Attendance from './common/services/Attendance'
import MxEventListener from './common/services/MxEventListener'
import PinLogin from './components/PinLogin.vue'
import JitsiVideoCall from './components/JitsiVideoCall.vue'
import StatusUtils from './common/services/StatusUtils'
import ChatterTile from './components/tiles/ChatterTile.vue'

importIpcRenderer()

export default defineComponent({
  components: {
    PinLogin,
    SideNavbar,
    TopNavbar,
    VideoCallView,
    VoiceCall,
    Conference,
    SuccessToast,
    ErrorToast,
    JitsiVideoCall,
    ChatterTile
  },

  data () {
    return {
      emitter: getCurrentInstance()?.appContext.config.globalProperties.emitter,
      store: useStore(),
      showCallView: false,
      showVoiceCallView: false,
      matrixRooms_: [],
      isReady: false,
      notification: {},
      refreshTimer: 0,
      statusUtils: new StatusUtils()
    }
  },

  async mounted () {
    if (isWebPlatform()) {
      // this.$router.push('/download')
      window.addEventListener('beforeunload', async (e: any) => {
        e.preventDefault()
        await this.store.dispatch('updateUserStatus', { status: 'AWAY', statusDescription: '' })
        return null
      })
    }
    await this.store.dispatch('getUser')

    if (isUserAgentMobile()) {
      this.store.dispatch('updateUserStatus', { status: UserStatus.AWAY, statusDescription: '' })
    } else {
      this.store.dispatch('updateUserStatus', { status: 'AVAILABLE', statusDescription: '' })
    }

    const permission = await Notification.requestPermission()
    await this.store.dispatch('getMyOrganization')
    await this.store.dispatch('getAllFloors')
    this.store.dispatch('setMatrixClient', globalMatrixClient)
    this.store.dispatch('getAvatar')
    this.store.dispatch('getAllUsers')
    this.store.dispatch('getOrganizationMembers', this.store.getters.organization?.id).then((members: any) => {
      this.statusUtils.notifyEmployeeStatus()
    }).catch(e => console.error(e))
    this.store.dispatch('getTimer')

    this.emitter.on('setCallView', (type?: string) => {
      this.placeVideoCall(type)
    })

    this.emitter.on('setVoiceCallView', () => {
      this.placeVoiceCall()
    })

    if (!this.matrixClient) {
      await this.store.dispatch('setMatrixClient', globalMatrixClient)
      Attendance.punchIn()
    } else {
      this.isReady = true
      Attendance.punchIn()
    }

    if (ipcRenderer) {
      ipcRenderer.once('SET_STATUS_LEFT', async () => {
        Attendance.punchOut(this.emitter)
        this.store.commit('isPageLoading', true)
        this.store.dispatch('updateUserStatus', { status: 'LEFT', statusDescription: '' }).then(() => {
          ipcRenderer.invoke('QUIT_APP')
        })
        setTimeout(() => {
          this.store.commit('isPageLoading', false)
        }, 3000)
      })

      ipcRenderer.once('CHECK_APP_QUIT', async () => {
        if (this.loginWithPin) {
          ipcRenderer.invoke('QUIT_APP')
        } else {
          ipcRenderer.invoke('PUNCH_OUT_AND_QUIT')
        }
      })

      /** TODO: Remove console logs once production build is tested. */
      ipcRenderer.once('UPDATE_STATUS_ON_SCREEN_LOCK', async () => {
        localStorage.setItem('statusBeforeScreenLock', this.user.status)
        this.store.commit('setSystemLocked', true)
        this.store.dispatch('updateUserStatus', { status: 'AWAY', statusDescription: '' })
        console.log('screen locked')
      })

      ipcRenderer.once('UPDATE_STATUS_ON_SCREEN_UNLOCK', async () => {
        const previousStatus = localStorage.getItem('statusBeforeScreenLock')
        if (previousStatus) {
          new StatusUtils().manuallyUpdateStatus(previousStatus as UserStatus)
        }
        this.store.commit('setSystemLocked', false)
        console.log('screen un-locked', previousStatus)
      })
    }

    MxEventListener.listenEvents()

    globalMatrixClient.on('sync', (state: any) => {
      switch (state) {
        case 'ERROR':
          // Update UI to say 'Connection Lost'
          break

        case 'SYNCING':
        case 'PREPARED':
          // Update UI to remove any 'Connection Lost' message
          /** TODO: Not sure if this is what we need to do during syncing. Check and update if needed. */
          this.isReady = true
          MxEventListener.updateRooms()
          MxEventListener.updateFeedRoom()
          // this.scrollToBottom()
          break

        default:
          break
      }
    })

    localStorage.setItem('cameraEnabled', 'false')
    localStorage.setItem('microphoneEnabled', 'false')

    this.refreshEmployeeStatus()
  },

  async beforeUnmount() {
    clearInterval(this.refreshTimer)
    if (isWebPlatform()) {
      await this.store.dispatch('updateUserStatus', { status: 'AWAY', statusDescription: '' })
    }
  },

  methods: {
    refreshEmployeeStatus() {
      this.refreshTimer = setInterval(() => {
        this.store.dispatch('getOrganizationMembers', this.store.getters.organization?.id)
        this.store.dispatch('getLatestEmployeeTimeline')
      }, 5 * 60 * 1000)
    },

    placeVideoCall (type?: string) {
      this.setCallView(true);
      (this.$refs.callView as any).placeCall(type)
    },

    placeVoiceCall () {
      this.setVoiceCallView(true);
      (this.$refs.voiceCallView as any).placeCall()
    },

    setCallView (value: boolean) {
      this.showCallView = value
    },

    setVoiceCallView (value: boolean) {
      this.showVoiceCallView = value
    }
  },

  computed: {
    loginWithPin (): boolean {
      return this.store.getters.loginWithPin
    },

    currentRoute (): any {
      return this.$route.path
    },

    matrixClient (): MatrixClient {
      return this.store.getters.matrixClient
    },

    user (): any {
      return this.store.getters.user
    },

    feedRoomId (): string {
      return this.store.getters.feedRoomId
    },

    isMxReady (): boolean {
      return this.isReady
    },

    organization (): any {
      return this.store.getters.organization
    },

    organizationMembers (): any {
      return this.store.getters.organizationMembers
    },

    showError (): any {
      return this.store.getters.showError
    },

    showSuccess (): any {
      return this.store.getters.showSuccess
    },

    isConferenceActive (): boolean {
      return this.store.getters.isConferenceActive
    },

    isJitsiVideoCallActive (): boolean {
      return this.store.getters.isJitsiVideoCallActive
    },

    isPageLoading (): any {
      return this.store.getters.isPageLoading
    }
  }
})
