
import { useStore } from 'vuex'
import { computed, defineComponent } from 'vue'
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
import UtilityService from '@/common/services/TimeUtilityService'
import moment from 'moment'

export default defineComponent({
  components: { VueCal },

  props: {
    username: { type: String, required: true }
  },

  data () {
    return {
			store: useStore(),
      utilityService: new UtilityService(),
      userMonthEvents: [] as any,
      selectedDate: new Date(),
      moment: moment
    }
  },

  methods: {
    close(e: MouseEvent) {
      e.stopPropagation()
      this.$emit('close')
    },

    correctTimeFormat (backendTime: any) {
			for (let i = 0; i < backendTime.length; i++) {
				backendTime[i].start = this.utilityService.calendarTime(backendTime[i].start)
				backendTime[i].end = this.utilityService.calendarTime(backendTime[i].end)
			}
			return backendTime
		},

    userMonthViewCalendar (data: { view: string, startDate: string, endDate: string, week: number }) {
      const date = new Date(data.startDate)
      const month = date.getMonth() + 1
			const year = date.getFullYear()
      this.store.dispatch('getEventsByUserByMonth', { username: this.username, month, year })
        .then((response) => {
          this.userMonthEvents = this.correctTimeFormat(response)
        })
		}
  },
  computed: {
    usersByUsername ():any {
		return this.store.getters.usersByUsername
		}
  }
})
