
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import ObjectUtils from '@/common/services/ObjectUtils'
// import MeetingTile from './MeetingTile.vue'
import Loader from '@/common/components/Loader.vue'

export default defineComponent({
  components: {
    Loader
    // MeetingTile
  },

  data () {
    return {
      store: useStore()
    }
  },

  methods: {
    // viewMoreMeetings (section: string, sectionTitle: string, list: any[]) {
    //   this.store.commit('setBreadcrumbs',
    //     [
    //       {
    //         label: 'Meetings',
    //         routeName: '/meetings'
    //       },
    //       {
    //         label: section,
    //         routeName: `/meetings/${section}`
    //       }
    //     ]
    //   )
    //   this.store.commit('setCurrentMeetingList', list)
    //   this.$router.push({
    //     name: 'MeetingList',
    //     path: '/meetings',
    //     params: { sectionTitle }
    //   })
    // }
  },

  computed: {
    // user (): any {
    //   return this.store.getters.user
    // },

    // allMeetings (): any {
    //   return this.store.getters.allMeetings
    // },

    // myMeetings (): any {
    //   return this.store.getters.myMeetings
    // },

    // groupedMeetings (): any {
    //   return ObjectUtils.groupByKey(this.allMeetings, 'state')
    // },

    // usersByUsername (): any {
    //   return this.store.getters.usersByUsername
    // },

    isPageLoading (): any {
      return this.store.getters.isPageLoading
    }
  }
})
