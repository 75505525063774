
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import UserMultiselectModal from '@/common/components/UserMultiselectModal.vue'

export default defineComponent({
  components: { UserMultiselectModal },

  data () {
    return {
      store: useStore(),
      floorName: '',
      usersList: [],
      selectedUsers: [],
      showUsersList: false
    }
  },

  mounted () {
    this.usersList = this.store.getters.organizationMembers
  },

  methods: {
    createFloor (event: MouseEvent) {
      // TODO: API integration
      this.store.dispatch('createFloor', { name: this.floorName })
      this.closeMe(event)
    },

    toggleMembersModal (event: MouseEvent) {
      event!.stopPropagation()
      this.showUsersList = !this.showUsersList
    },

    selectUsers (selected: any, event: any) {
			this.toggleMembersModal(event)
      this.selectedUsers = selected.filter((user: any) =>
        user.name !== '' && user.username !== this.account.username
      )
    },

    closeMe (event: any) {
      event.stopPropagation()
      if (!this.showUsersList) {
        this.$emit('close', event)
      }
    }
  },

  computed: {
    account (): any {
      return this.store.getters.user
    },

    users (): string {
      return this.selectedUsers.map((user: any) => user.name).join(', ')
    }
  }
})
