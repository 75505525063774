import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/img/icon-plus-white.svg'


const _withScopeId = n => (_pushScopeId("data-v-01c6f9a5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chats-header grey-bg px-2" }
const _hoisted_2 = { class: "groups" }
const _hoisted_3 = { class: "fs-12 o-6 mt-3 mb-2 d-flex justify-content-between align-items-center" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "text-start" }
const _hoisted_6 = { class: "people" }
const _hoisted_7 = { class: "fs-12 o-6 mt-3 mb-2 d-flex justify-content-between align-items-center" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "d-flex align-items-center justify-content-start" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "text-capitalize" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CreateChannel = _resolveComponent("CreateChannel")!
  const _component_UserMultiselectModal = _resolveComponent("UserMultiselectModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createTextVNode(" Channels "),
          _createElementVNode("img", {
            class: "cursor-pointer me-2",
            src: _imports_0,
            alt: "icon-add",
            width: "10",
            height: "10",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleCreateChannelModal && _ctx.toggleCreateChannelModal(...args)))
          })
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rooms, (room) => {
          return (_openBlock(), _createElementBlock("div", {
            key: room.roomId
          }, [
            (_ctx.isRoomVisibleInChannels(room))
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  onClick: ($event: any) => (_ctx.setActiveRoom(room)),
                  class: _normalizeClass(["chats d-flex justify-content-between py-1 pe-2 mb-1", { 'active': _ctx.activeRoom?.roomId === room?.roomId }])
                }, [
                  _createElementVNode("div", _hoisted_5, "# " + _toDisplayString(room.name), 1),
                  _withDirectives(_createElementVNode("div", { class: "msg-count text-center rounded-circle float-end mt-1 ms-1" }, _toDisplayString(room.notificationCounts.total), 513), [
                    [_vShow, room.notificationCounts.total > 0]
                  ])
                ], 10, _hoisted_4))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createTextVNode(" People "),
          _createElementVNode("img", {
            class: "cursor-pointer me-2",
            src: _imports_0,
            alt: "icon-add",
            width: "10",
            height: "10",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleDMUsersModal && _ctx.toggleDMUsersModal(...args)))
          })
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rooms, (room) => {
          return (_openBlock(), _createElementBlock("div", {
            key: room.roomId
          }, [
            (_ctx.mxRoom.isRoomDM(room) && room.getMyMembership() !== 'leave')
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(["chats ps-0 d-flex justify-content-between align-items-center mb-3", { 'active': _ctx.activeRoom?.roomId === room?.roomId }]),
                  onClick: ($event: any) => (_ctx.setActiveRoom(room))
                }, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("img", {
                      src: _ctx.getDMUser(room)?.avatarUrl,
                      class: "avatar avatar-bg me-2"
                    }, null, 8, _hoisted_10),
                    _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.getDMUser(room)?.name), 1),
                    (_ctx.getDMUser(room)?.status === 'AVAILABLE')
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 0,
                          class: _normalizeClass(["status btn-tooltip status m-0", _ctx.getDMUser(room)?.status]),
                          "data-bs-toggle": "tooltip",
                          "data-bs-placement": "top",
                          "data-container": "body",
                          "data-animation": "true"
                        }, "  ●", 2))
                      : _createCommentVNode("", true)
                  ]),
                  _withDirectives(_createElementVNode("div", { class: "msg-count text-center rounded-circle" }, _toDisplayString(room.notificationCounts.total), 513), [
                    [_vShow, room.notificationCounts.total > 0]
                  ])
                ], 10, _hoisted_8))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ])
    ]),
    (_ctx.showCreateChannelModal)
      ? (_openBlock(), _createBlock(_component_CreateChannel, {
          key: 0,
          onClose: _ctx.toggleCreateChannelModal
        }, null, 8, ["onClose"]))
      : _createCommentVNode("", true),
    (_ctx.showDMUsers)
      ? (_openBlock(), _createBlock(_component_UserMultiselectModal, {
          key: 1,
          title: 'Add members',
          buttonLabel: 'ADD',
          list: _ctx.dMUsersList,
          selected: _ctx.selectedDMUsers,
          onFinished: _ctx.startDM,
          onClose: _ctx.toggleDMUsersModal
        }, null, 8, ["list", "selected", "onFinished", "onClose"]))
      : _createCommentVNode("", true)
  ], 64))
}